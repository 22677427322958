<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <strong
                ><i class="fa fa-exclamation-triangle"></i> Disclamer: </strong
            >This tool should be used for screening and monitoring symptom
            severity and cannot replace a clinical assessment and diagnosis. You
            are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <div class="report-user mt-3">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p class="mt-3"><strong>Your Score:</strong></p>
            <p>Burnout: {{ burnoutScore }}</p>
            <p class="mt-2"><strong>Your Result:</strong></p>
            <p>
                Your score indicates
                <span
                    >{{ burnoutPhrase }}
                    <span class="scrollTxt" (click)="scrollToElem('target')"
                        >Detailed Analysis</span
                    ></span
                >
            </p>
            <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
        </div>
    </div>

    <div class="container graph-section mt-3">
        <div>
            <p class="mb-3" *ngIf="burnoutScore >= 0 && burnoutScore <= 20">
                Your results may not be a sign of concern, but read on to find
                out a more detailed analysis of your score. Please Coach is here
                to assist you in improving your mental well-being.
            </p>
            <p class="mb-3" *ngIf="burnoutScore >= 21 && burnoutScore <= 60">
                Your results may be slightly concerning, but do not worry too
                much - Please Coach has got you covered. We offer a variety of
                services that you can access at any time, anywhere.
            </p>
            <p class="mb-3" *ngIf="burnoutScore >= 61 && burnoutScore <= 88">
                Seeing the report can be overwhelming, but Please Coach has got
                you covered. We offer a variety of services that you can access
                at any time, anywhere.
            </p>
        </div>
        <div class="report-user">
            <h3>Understand Your Caregiver Burnout Assessment</h3>
        </div>
        <div class="graph-right">
            <img src="../../../assets/images/caregiver-stress.png" />
        </div>
    </div>

    <div class="container scores-section">
        <div class="about-scores report-user">
            <h5 class="mb-3">Your Score Interpretation</h5>
            <p
                class="para_scores mb-3"
                *ngIf="burnoutScore >= 0 && burnoutScore <= 20"
            >
                A low scorer on the scale, lying between 0 and 20, indicates a
                manageable and perfectly acceptable degree of burden. But
                because providing care for a loved one is often a long-term
                commitment, it's essential for you to be aware of your feelings
                and to monitor your stress levels frequently. So, recognize your
                limits early on, and be honest with yourself and your loved one
                about how much you can handle - because as your loved one’s
                needs evolve, so will you need to, and having a solid foundation
                of boundaries can really aid you in keeping good health, which
                will not only benefit you but also the person you are caring
                for.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="burnoutScore >= 21 && burnoutScore <= 40"
            >
                A score that lies between 21 to 40 indicates mild levels of
                caregiver stress and burnout. Though this is a fairly harmless
                range to lie in - everyone experiences fluctuating levels of
                stress, especially early on in the caregiving process - you need
                to be extremely aware of your own limitations since this can
                often be a tipping point for many people into experiencing
                increasing levels of stress and burnout, which can lead to
                unmanageable anger, frustration, and guilt in the caregiver. You
                may not have reached your limits yet, but this is why you need
                to be extra cautious about your mental and emotional state. This
                can be a good time to assess all your resources and evaluate
                what caregiving for your loved one may look like in the future:
                if your loved one’s condition worsens, will you be able to
                dedicate the same amount of time, love, and financial resources
                to take care of them? Discussing these topics with your support
                and with the person you are caring for will ensure that you are
                on the same page, and avoid any negative feelings in the future.
                This can be a very stressful and exhausting experience for some,
                so do not hesitate to seek professional help if you need it. You
                can get in touch with the mental healthcare providers at Please
                Coach for the same.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="burnoutScore >= 41 && burnoutScore <= 60"
            >
                Scores between 41 to 60 indicate moderate levels of burnout that
                lie on the threshold of severe burnout. You may be experiencing
                a burden of responsibility, both emotionally as well as
                materially (with finances, for instance), and this may not bode
                well in the long term, not only because it means the
                deteriorating health of the caregiver, but also because they
                become increasingly incapable of taking care of their loved one.
                Moderate levels of burnout can look like a marked change in
                moods or frequent mood swings, having health issues like
                frequent colds and headaches, and the inability to focus or
                think straight, to the point of developing a mental health
                condition themselves. In order to prevent this from worsening,
                seeking professional help in the form of an additional hand to
                take care of your loved one for the time being could help, while
                you try to set better goals for yourself, involving developing
                coping skills so you don’t go through this situation again. Many
                caregivers feel guilt giving the caring responsibilities to
                someone outside of the family or even apart from solely
                themselves - this kind of thinking needs to be changed
                immediately, and seeking out support facilities is something
                that should be utilized, even if it’s just for a short stay.
                Additionally, you can reach out to mental health practitioners
                to help you develop strategies to maintain your health
                throughout the process of caregiving - for this, you can get in
                touch with the psychologists and psychotherapists at Please
                Coach, if you are experiencing moderate to severe symptoms of
                burnout.
            </p>
            <p class="para_scores mb-3" id="target" *ngIf="burnoutScore > 61">
                Scores that lie above 61 indicate high levels of burnout, which
                is a clear warning sign that you need to take heed of. Not
                listening to your body’s physical and emotional signs of early
                exhaustion is what might have caused this level of caregiver
                burnout to develop, and at this point, it means that the welfare
                of you and your loved one is at risk. Prolonged withdrawal from
                the family or family events, feelings of irritability towards
                your caregiver, feeling emotionally withdrawn from them, and in
                extreme cases, even refusing to provide them with the adequate
                care that they need, can be some of the signs of caregiver
                burnout. If you have been noticing similar things in yourself in
                the past few weeks or so, then it is high time you seek the help
                you need from support facilities, care homes, your family and
                friends, and mental health professionals. Letting this go
                unchecked can lead to compassion fatigue, and conditions like
                depression, and anxiety. You may have made a promise to your
                loved one, but if caregiving is placing such a heavy burden on
                you, it may be time to redirect your empathy. Although it may
                seem counterintuitive, you are currently the most important
                person, thus regaining balance in your life should be your first
                concern. This does not raise a question about your character or
                your moral compass if that is your worry. You can get in touch
                with Please Coach to help you devise new tools for coping and
                ease you through this tough time in your life.
            </p>
        </div>
    </div>

    <!-- TODO: Add CSS -->
    <br />
    <div class="container about-scores">
        <div class="mt-4 mb-4">
            <h5 class="mb-3">Detailed Analysis</h5>
            <p class="mb-3">
                The caregiver burden assessment has been both used in a clinical
                setting, as well as by individuals in a family who are concerned
                about their welfare. Caregivers are often so concerned with
                caring for the relative’s needs that they lose sight of their
                own well-being, so taking some time out and evaluating your own
                mental and physical health is beneficial in the long term.
            </p>
        </div>
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>

    <!-- <div class="disclaimer-section">
      <div class="head-disclaimer">
        <h3>Disclaimer</h3>
        <p>
          This tool should be used for screening and monitoring symptom severity
          and cannot replace a clinical assessment and diagnosis. You are
          encouraged to share these results with a mental health professional.
          Please Coach disclaims any liability, loss or risk incurred as a consequence
          , directly or indirectly, from the use of this assessment & it's
          results.
        </p>
      </div>
    </div> -->
</div>
