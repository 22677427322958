import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistAuthService } from "src/app/therapist-auth/therapist-auth.service";
import { TherapistDiscussionFourmService } from "src/app/therapist/therapist-discussion-fourm.service";
import { DiscussionForumService } from "src/app/user/discussion-forum.service";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "ngx-editor";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { environment } from "src/environments/environment";
import { DiscussionForumService as GuestDiscussionService } from "../../discussion-forum.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import clevertap from "clevertap-web-sdk";

@Component({
  selector: "app-view-discussion",
  templateUrl: "./view-discussion.component.html",
  styleUrls: ["./view-discussion.component.css"],
})
export class ViewDiscussionComponent implements OnInit {
  itemId = "";
  categories = [];
  forumData;
  comments = [];
  name = 'ngx sharebuttons';
  faCommentAlt = faCommentAlt;
  isLoading = true;
  userIsAuthenticated = false;
  therapistIsAuthenticated = false;
  comment = new FormControl("", Validators.required);
  replyToComment = new FormControl("", Validators.required);
  hideModal = true;
  editor: Editor;
  categoryitems = [];
  discussionId = null;
  mode: string;
  selectedTags = [];
  userId = null;
  items = [];
  imageUrl = environment.apiUrl;
  discussionForm = new FormGroup({
    topic: new FormControl("", Validators.required),
    tags: new FormControl("", Validators.required),
    description: new FormControl("", Validators.required),
    isAnonymous: new FormControl(false),
  });
  constructor(
    private actRoute: ActivatedRoute,
    private discussionService: DiscussionForumService,
    private guestdiscussionService: GuestDiscussionService,
    private therapistDiscussionService: TherapistDiscussionFourmService,
    private userAuth: AuthService,
    private therapistAuth: TherapistAuthService,
    private sidenavService: SideNavBarService,
    private therapistAuthService: TherapistAuthService,
    private router: Router,
    private alertService: BootstrapAlertService,
    private metaSeo: SeoMetaService
  ) { }
  // userAuthService
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  toggleLike(id) {
    this.discussionService.toggleLikeOnDiscussion(id).subscribe((res) => {
      this.changeIsLiked(id);
    });
  }
  changeIsLiked(id) {
    if (this.forumData._id == id) {
      this.forumData["isLiked"] = !this.forumData["isLiked"];
      if (this.forumData["isLiked"]) {
        this.forumData.likes++;
      } else {
        this.forumData.likes--;
      }
    }
  }
  toggleLikeOnComment(id) {
    this.discussionService
      .toggleLikeOnDiscussionComment(id)
      .subscribe((res) => {
        this.changeIsLikedOnComment(id);
      });
  }
  changeIsLikedOnComment(id) {
    this.comments.forEach((comment, index) => {
      if (comment._id == id) {
        this.comments[index]["isLiked"] = !this.comments[index]["isLiked"];
        if (this.comments[index]["isLiked"]) {
          comment.likes++;
        } else {
          comment.likes--;
        }
      }
    });
  }
  addTagsToArray() {
    let indexInArray = null;
    let tagsToAdd = this.discussionForm.value.tags;
    if (this.discussionForm.value.tags) {
      this.selectedTags.find(function (tags, index) {
        if (tagsToAdd == tags.tags) {
          indexInArray = index;
        }
      });
      if (indexInArray == null) {
        this.selectedTags.push({
          tags: this.discussionForm.value.tags,
        });
      }
    }
  }
  ngOnInit(): void {
    this.editor = new Editor();
    this.userIsAuthenticated = this.userAuth.getIsAuth();
    this.therapistIsAuthenticated = this.therapistAuthService.getIsAuth();
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.getComments();
      this.guestdiscussionService
        .getForumById(this.itemId)
        .subscribe((data) => {
          this.forumData = data;
          this.metaSeo.updateTitle('Online Counseling | PleaseCoach')
        });
    });
    this.guestdiscussionService.getCategories().subscribe((data) => {
      this.categories = data["category"]
    })
    this.userId = localStorage.getItem("userId");
  }

  onSubmit() {
    if (this.discussionForm.invalid) {
      return;
    }
    if (this.userAuth.getIsAuth()) {
      this.discussionService
        .createDiscussion(this.discussionForm.value)
        .subscribe((data) => {
          clevertap.event.push("Discussion Started")
          if (data) {
            this.alertService.alert(
              new BootstrapAlert(
                "Discussion was created successfully",
                "alert-success"
              )
            );
          }
        });
    } else if (this.therapistAuth.getIsAuth()) {
      this.therapistDiscussionService.createDiscussion(this.discussionForm.value).subscribe((data) => {
        if (data) {
          this.alertService.alert(
            new BootstrapAlert(
              "Discussion was created successfully",
              "alert-success"
            )
          );
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
  addComment() {
    if (this.comment.invalid) {
      return;
    }
    if (this.userAuth.getIsAuth()) {
      let commentObj = {
        commentDescription: this.comment.value,
      };
      this.discussionService
        .postDiscussionComments(this.itemId, commentObj)
        .subscribe((data) => {
          clevertap.event.push("Comment Added")
          this.comment.setValue("");
          data["isLiked"] = false;
          data["addReplyToggle"] = false;
          data["showMoreReplies"] = false;
          this.comments.unshift(data);
          this.forumData["replies"]++;
        });
    } else if (this.therapistAuth.getIsAuth()) {
      let commentObj = {
        commentDescription: this.comment.value,
      };
      this.therapistDiscussionService
        .postDiscussionComments(this.itemId, commentObj)
        .subscribe((data) => {
          this.comment.setValue("");
          data["isLiked"] = false;
          data["addReplyToggle"] = false;
          data["showMoreReplies"] = false;
          this.comments.unshift(data);
          this.forumData["replies"]++;
        });
    }
  }

  addReply(commentId) {
    if (this.replyToComment.invalid) {
      return;
    }
    let commentObj = {
      commentDescription: this.replyToComment.value,
      parentCommentId: commentId,
    };
    if (this.userAuth.getIsAuth()) {
      this.discussionService
        .postDiscussionComments(this.itemId, commentObj)
        .subscribe((data) => {
          this.replyToComment.setValue("");
          clevertap.event.push("Comment Added")
          this.addReplyInData(data);
        });
    } else if (this.therapistAuth.getIsAuth()) {
      this.therapistDiscussionService
        .postDiscussionComments(this.itemId, commentObj)
        .subscribe((data) => {
          this.replyToComment.setValue("");
          this.addReplyInData(data);
        });
    }
  }

  addReplyInData(replyData) {
    this.comments.forEach((comment, index) => {
      if (comment._id == replyData.parentCommentId) {
        replyData["isLiked"] = false;
        replyData["addReplyToggle"] = false;
        replyData["showMoreReplies"] = false;
        this.comments[index].replies.unshift(replyData);
      }
    });
    this.forumData["replies"]++;
  }

  viewAllReplies(id) {
    this.comments.forEach((comment, index) => {
      if (comment._id == id) {
        this.comments[index]["showMoreReplies"] =
          !this.comments[index]["showMoreReplies"];
      }
    });
  }

  getComments() {
    this.discussionService
      .getDiscussionComments(this.itemId)
      .subscribe((data) => {
        this.comments = this.comments.concat(data);

        this.comments.forEach((comment, index1) => {
          this.comments[index1]["isLiked"] = false;
          this.comments[index1]["addReplyToggle"] = false;
          this.comments[index1]["showMoreReplies"] = false;
          if (this.userId != null) {
            comment.likedBy.forEach((like) => {
              if (this.userId == like) {
                this.comments[index1]["isLiked"] = true;
              }
            });
          }
          if (comment.replies.length > 0) {
            comment.replies.forEach((reply, index) => {
              comment.replies[index]["isLiked"] = false;
              comment.replies[index]["addReplyToggle"] = false;
              if (this.userId != null) {
                reply.likedBy.forEach((like) => {
                  if (this.userId == like) {
                    comment.replies[index1]["isLiked"] = true;
                  }
                });
              }
            });
          }
        });
      });
  }
  askQuestion() {
    if (this.userAuth.getIsAuth()) {
      this.hideModal = false;
      this.discussionForm.reset();
    } else if (this.therapistAuthService.getIsAuth()) {
      this.hideModal = false;
      this.discussionForm.reset();
    } else {
      this.router.navigateByUrl("/login?redirect=discussion-forum");
    }
  }
  signUp() {
    this.router.navigate(["/sign-up"]);
  }

  addCommentCheck() {
    if (this.userAuth.getIsAuth()) {
      this.router.navigate(["../wellness-forum"]);
    } else if (this.therapistAuthService.getIsAuth()) {
      this.router.navigate(["../wellness-forum"]);
    } else {
      this.router.navigateByUrl("/login?redirect=wellness-forum");
    }
  }
  reply(id) {
    this.comments.forEach((comment, index) => {
      if (comment._id == id) {
        this.comments[index].addReplyToggle = true;
      }
    });
  }

  replyOnReply(parentId, replyId) {
    this.comments.forEach((comment, index) => {
      if (comment._id == parentId) {
        this.comments[index]["replies"].forEach((reply, index1) => {
          if (reply._id == replyId) {
            this.comments[index]["replies"][index1]["addReplyToggle"] =
              !this.comments[index]["replies"][index1]["addReplyToggle"];
          }
        });
      }
    });
  }

  addReplyToReply(topCommentId, parentCommentId) {
    if (this.replyToComment.invalid) {
      return;
    }
    let commentObj = {
      commentDescription: this.replyToComment.value,
      parentCommentId: parentCommentId,
    };
    if (this.userAuth.getIsAuth()) {
      this.discussionService
        .postDiscussionComments(this.itemId, commentObj)
        .subscribe((data) => {
          this.replyToComment.setValue("");
          clevertap.event.push("Comment Added")
          this.pushReplyToReply(data, topCommentId, parentCommentId);
        });
    } else if (this.therapistAuth.getIsAuth()) {
      this.therapistDiscussionService
        .postDiscussionComments(this.itemId, commentObj)
        .subscribe((data) => {
          this.replyToComment.setValue("");
          this.pushReplyToReply(data, topCommentId, parentCommentId);
        });
    }
  }

  pushReplyToReply(comment, topCommentId, parentId) {
    this.comments.forEach((commentItr, index) => {
      if (topCommentId === commentItr._id) {
        this.comments[index].replies.forEach((reply, index1) => {
          if (parentId === reply._id) {
            this.comments[index].replies.splice(index1 + 1, 0, comment);
            this.comments[index].replies[index1]["addReplyToggle"] = false;
          }
        });
      }
    });
  }
  copyUrl() {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = environment.base_url + "view-discussion/" + this.itemId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.alertService.alert(
      new BootstrapAlert(
        "Successfully copied url to clipboard!",
        "alert-success"
      )
    );
  }
}
