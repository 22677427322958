import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { Location } from "@angular/common";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "app-general-counselling",
  templateUrl: "./general-counselling.component.html",
  styleUrls: ["./general-counselling.component.css"],
})
export class GeneralCounsellingComponent implements OnInit {
  isLoggedIn = false;
  therapistRedirection = '';
  faArrowLeft = faArrowLeft;
  constructor(
    private sidenavService: SideNavBarService,
    private userAuth: AuthService,
    private router: Router,
    private metaSeo: SeoMetaService,
    private actRoute: ActivatedRoute,
    private location: Location
  ) { }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.isLoggedIn = this.userAuth.getIsAuth();
    this.metaSeo.updateTitle("Online Counseling | PleaseCoach")
    this.metaSeo.updateMetaInfo("Affordable counseling & therapy with best in class psychologists. Buy now & elevate your life.", "Counselor price, cost of counseling, therapy cost");
    this.actRoute.queryParamMap.subscribe((params) => {
      this.therapistRedirection = params.get("counselor")
    })
  }

  goBack() {
    if (this.therapistRedirection) {
      window.history.go(-2)
    } else {
      this.location.back()
    }
  }

  buyNow(planKey) {
    if (this.isLoggedIn) {
      this.router.navigate(["../order-summary/" + planKey], { queryParams: { therapistId: this.therapistRedirection } });
    } else {
      this.router.navigateByUrl("/sign-up?plan=" + planKey);
    }
  }
}
