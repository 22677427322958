<div *ngIf="isDataLoad; else notLoaded">
    <ngx-skeleton-loader
        appearance="line"
        [theme]="{
            'border-radius': '12px',
            width: '100%',
            height: '185px',
            display: 'flex !important',
            'margin-top': '3px',
            'margin-right': '10px'
        }"
    ></ngx-skeleton-loader>
</div>
<ng-template #notLoaded>
    <div class="welcomeBoxWrap">
        <div class="contentBoxBlock">
            <h3>Welcome to Please Coach, {{ userName }}</h3>
            <p>"{{ quoteforuser }}"</p>
        </div>
        <div class="welcomeImage">
            <img
                src="../../../assets/images/danc.svg"
                class="img-fluid"
                draggable="false"
                alt=""
            />
        </div>
    </div>
</ng-template>
<br />
<div>
    <div class="sectionHeading">
        <div *ngIf="isDataLoad; else notHeadingLoaded">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '25%',
                    height: '40px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
        <ng-template #notHeadingLoaded>
            <h3>Explore</h3>
            <hr />
        </ng-template>
    </div>
    <div class="wexerpboxWrp">
        <div class="row justify-content-between">
            <div class="col-md-6 col-sm-6">
                <div *ngIf="isDataLoad; else notBoxOne">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            width: '95%',
                            height: '185px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #notBoxOne>
                    <div class="exploreBoxWrap">
                        <div class="exploreImageBox">
                            <img
                                src="../../../assets/images/chat2.svg"
                                draggable="false"
                                class="img-fluid"
                            />
                        </div>
                        <div class="exploreRightContent">
                            You can chat with your clients and address their
                            concerns anytime as per your convenience.
                        </div>
                    </div>
                </ng-template>
                <div class="exploreButton">
                    <div *ngIf="isDataLoad; else notBtnOne">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '50px',
                                width: '50%',
                                height: '52px',
                                display: 'flex !important',
                                'margin-top': '3px',
                                'margin-right': '10px'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <ng-template #notBtnOne>
                        <a routerLink="../my-chats" class="thm-2"
                            >Start Chatting</a
                        >
                    </ng-template>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div *ngIf="isDataLoad; else notBoxTwo">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            width: '95%',
                            height: '185px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #notBoxTwo>
                    <div class="exploreBoxWrap">
                        <div class="exploreImageBox">
                            <img
                                src="../../../assets/images/video.svg"
                                draggable="false"
                                class="img-fluid"
                            />
                        </div>
                        <div class="exploreRightContent">
                            In the sessions tab, you will find all the upcoming
                            and completed sessions of all your clients.
                        </div>
                    </div>
                </ng-template>
                <div class="exploreButton">
                    <div *ngIf="isDataLoad; else notBtnTwo">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '50px',
                                width: '50%',
                                height: '52px',
                                display: 'flex !important',
                                'margin-top': '3px',
                                'margin-right': '10px'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <ng-template #notBtnTwo>
                        <a routerLink="../sessions" class="thm-2"
                            >View Sessions</a
                        >
                    </ng-template>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div *ngIf="isDataLoad; else notBoxThree">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            width: '95%',
                            height: '185px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #notBoxThree>
                    <div class="exploreBoxWrap">
                        <div class="exploreImageBox">
                            <img
                                src="../../../assets/images/mind.svg"
                                draggable="false"
                                class="img-fluid"
                            />
                        </div>
                        <div class="exploreRightContent">
                            We know your notes are important to you, which why
                            we have neatly organised all your client notes.
                        </div>
                    </div>
                </ng-template>
                <div class="exploreButton">
                    <div *ngIf="isDataLoad; else notBtnThree">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '50px',
                                width: '50%',
                                height: '52px',
                                display: 'flex !important',
                                'margin-top': '3px',
                                'margin-right': '10px'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <ng-template #notBtnThree>
                        <a routerLink="../client-notes" class="thm-2"
                            >Check Client Notes</a
                        >
                    </ng-template>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div *ngIf="isDataLoad; else notBoxFour">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            width: '95%',
                            height: '185px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #notBoxFour>
                    <div class="exploreBoxWrap">
                        <div class="exploreImageBox">
                            <img
                                src="../../../assets/images/share.svg"
                                draggable="false"
                                class="img-fluid"
                            />
                        </div>
                        <div class="exploreRightContent">
                            Become a sought after Therapist. Actively
                            participate in the discussion forum and answer
                            questions.
                        </div>
                    </div>
                </ng-template>
                <div class="exploreButton">
                    <div *ngIf="isDataLoad; else notBtnFour">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '50px',
                                width: '50%',
                                height: '52px',
                                display: 'flex !important',
                                'margin-top': '3px',
                                'margin-right': '10px'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <ng-template #notBtnFour>
                        <a routerLink="../discussion" class="thm-2"
                            >Start A Discussion</a
                        >
                    </ng-template>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div *ngIf="isDataLoad; else notBoxFive">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            width: '95%',
                            height: '185px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #notBoxFive>
                    <div class="exploreBoxWrap">
                        <div class="exploreImageBox">
                            <img
                                src="../../../assets/images/laptgrid.svg"
                                draggable="false"
                                class="img-fluid"
                            />
                        </div>
                        <div class="exploreRightContent">
                            Want to get an insight into your activity on the
                            platform. - sessions, clients, ratings, earnings
                            etc. All you need to do is click.
                        </div>
                    </div>
                </ng-template>
                <div class="exploreButton">
                    <div *ngIf="isDataLoad; else notBtnFive">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '50px',
                                width: '50%',
                                height: '52px',
                                display: 'flex !important',
                                'margin-top': '3px',
                                'margin-right': '10px'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                    <ng-template #notBtnFive>
                        <a routerLink="../report" class="thm-2"
                            >Access Report</a
                        >
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
