<div (click)="closenavbar()">
    <div class="forCounselorsMessgImg">
        <div class="container">
            <div class="row-main rowPositionbox">
                <div class="col-md-7">
                    <div class="backgroundImgMesg">
                        <h3>
                            GROW your counselling practice from the comfort of
                            your home at ZERO OPERATING COST
                        </h3>
                        <a
                            routerLink="/counseling-therapy-clinical-psychology-jobs/get-onboard/questions"
                            class="thm-2"
                            >Apply Now</a
                        >

                        <p class="insideptag">
                            Already have an account ?
                            <a routerLink="/therapist-login">
                                <u>Log In</u>
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 imageWrapper">
                    <div class="imageWrapperclass">
                        <img
                            src="../../../../assets/images/join-pleasecoach-as-counselor-therapist/online-psychology-practice.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="set up psychology practice, online counseling therapy for psychologists from home"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="forCounselorsContentWrap sectionPadding">
        <div class="container">
            <div class="sectionHeading">
                <h2>Why Work With Us?</h2>
            </div>
            <div class="counselorsContentbox">
                <div class="row">
                    <div class="singleBoxContent col-sm-6">
                        <div class="contentBoxStyling individualBoxcss">
                            <div class="alignImgInsideEachbox">
                                <img
                                    src="../../../../assets/images/join-pleasecoach-as-counselor-therapist/find-counseling-therapy-clients.svg"
                                    class="img-fluid"
                                    alt="find regular online counseling therapy clients from home"
                                    draggable="false"
                                />
                            </div>
                            <div class="contentStyle">
                                <h5>New Customer Acquisition</h5>
                                <p>
                                    Please Coach takes care of getting you new
                                    clients so that you can focus on your core
                                    competence - therapy
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="singleBoxContent col-sm-6">
                        <div class="contentBoxStyling individualBoxcss">
                            <div class="alignImgInsideEachbox">
                                <img
                                    src="../../../../assets/images/join-pleasecoach-as-counselor-therapist/earn-money-online-psychology-practice.svg"
                                    class="img-fluid"
                                    alt="increase earnings, run online psychology practice"
                                    draggable="false"
                                />
                            </div>
                            <div class="contentStyle">
                                <h5>More Revenue, Zero Costs</h5>
                                <p>
                                    Increase your earnings by using platform to
                                    deliver therapy – you incur zero overheads
                                    to run your practice
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="singleBoxContent col-sm-6">
                        <div class="contentBoxStyling individualBoxcss">
                            <div class="alignImgInsideEachbox">
                                <img
                                    src="../../../../assets/images/join-pleasecoach-as-counselor-therapist/training-learning-psychology.svg"
                                    class="img-fluid"
                                    alt="get trained by top psychology experts on counseling therapy"
                                    draggable="false"
                                />
                            </div>
                            <div class="contentStyle">
                                <h5>Continuous Learning & Relevance</h5>
                                <p>
                                    Get trained by the best names in psychology
                                    on counselling best practices and stay
                                    abreast on the latest in the field
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="singleBoxContent col-sm-6">
                        <div class="contentBoxStyling individualBoxcss">
                            <div class="alignImgInsideEachbox">
                                <img
                                    src="../../../../assets/images/join-pleasecoach-as-counselor-therapist/flexibility.svg"
                                    class="img-fluid"
                                    alt="flexible hours job psychologist, work from home convenient timings"
                                    draggable="false"
                                />
                            </div>
                            <div class="contentStyle">
                                <h5>
                                    Flexibility – Work At Your Preferred Time
                                </h5>
                                <p>
                                    Work at your convenience – all you need is
                                    an internet connection, choose when you want
                                    to work
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sectionPadding-top">
        <div class="container">
            <div class="sectionHeading">
                <h2>How It Works</h2>
                <hr />
            </div>
            <div class="howItWorksWrapper">
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/choose-counseling-therapy-plan.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="choose counseling therapy sessions plan"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Apply</h3>
                            <p>
                                Read the requirements, if you fit the bill,
                                answer few quick questions & upload your resume
                                to apply.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../../assets/images/how-it-works-counselors/get-selected-pleasecoach-counseling.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="get selected as counselor therapist with Please Coach, join as psychologist"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Get Selected</h3>
                            <p>
                                We have a 3-tier selection process to make sure
                                the platform houses only the best - you will
                                undergo a phone screening followed by an
                                interview and a demo round.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../assets/images/how-it-works-counselors/create-profile.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="create profile on Please Coach, join as counselor therapist"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Create Profile</h3>
                            <p>
                                You need to have a well-designed profile to get
                                matched with prospects. Use our intuitive
                                profile creator or take help from our matching
                                expert.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../../assets/images/how-it-works-counselors/join-pleasecoach-online-counseling.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="join as psychologist, online sessions with clients"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Get Inducted</h3>
                            <p>
                                At this stage, we do an infra check to make sure
                                you have a conducive environment to be able to
                                deliver therapy online and train you in Please
                                Coach best practices. You are deemed fit to
                                start therapy.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/get-matched-with-counseling-psychologist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="find online counselor, get matched with best psychologists suited to your needs"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Get Matched</h3>
                            <p>
                                At this stage you are all set to acquire new
                                clients – we match you to the right ones taking
                                into consideration your profile and working
                                time.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../assets/images/how-it-works-counselors/give-online-counseling.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="take online video counseling therapy sessions with clients"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Give Therapy</h3>
                            <p>
                                Join sessions with your clients at the scheduled
                                time via the Please Coach platform. You are also
                                expected to respond on chat 1-2 times a day.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../assets/images/how-it-works-counselors/client-rating-payment.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="get easy online payment from counseling clients"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Get Rated & Get Paid</h3>
                            <p>
                                You are rated by the client post every session &
                                paid in monthly cycles for all sessions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItWorksGroup">
                    <div class="contentTowardsLeft">
                        <div class="howItWorksIconclass">
                            <img
                                src="../../../assets/images/how-it-works-counselors/manage-your-account.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="manage your online counseling practice account, calender, invoice, session management and billing"
                            />
                        </div>
                    </div>
                    <div class="contentTowardsRight">
                        <div class="contentTagclass">
                            <h3>Manage Your Account</h3>
                            <p>
                                On your Please Coach dashboard, you can join
                                sessions, you can chat with your clients, update
                                your profile, manage your calendar, view your
                                invoices & raise tickets for any issues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sectionPadding-bottom">
        <div class="container">
            <div class="boxRoundCircle backGroundColor">
                <div class="sectionHeading">
                    <h2>Join pleasecoach</h2>
                    <hr />
                    <p>Help us make the world happier, one life at a time.</p>
                    <a
                        routerLink="/counseling-therapy-clinical-psychology-jobs/get-onboard/questions"
                        class="thm-2"
                        >Apply Now</a
                    >
                    <p class="styleclass" style="font-size: 14px">
                        Already have an account ?
                        <a routerLink="/therapist-login">
                            <u>Log In</u>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
