import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { NotificationService } from "../user/notification.service";
import clevertap from "clevertap-web-sdk";

const userBackendUrl = environment.apiUrl + "/user/login";
const googleAuthUrl = environment.apiUrl + "/user/google-auth";
const facebookAuthUrl = environment.apiUrl + "/user/facebook-auth/facebook/";
const deleteuserAccountUrl = environment.apiUrl + "/user/user";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthenticated = false;
  private userToken: string;
  private tokenTimer: NodeJS.Timer;
  private userId: string;
  private authStatusListener = new Subject<boolean>();
  expiresIn: number = 86400000; //assuming token expires in 24hrs
  constructor(
    private http: HttpClient,
    private router: Router,
    private NgZone: NgZone,
    private notificationService: NotificationService,
    private actRoute: ActivatedRoute
  ) { }

  getToken() {
    return this.userToken;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  private saveAuthData(
    token: string,
    expirationDate: Date,
    userId: string,
    tzcode: string = ""
  ) {
    localStorage.clear();
    localStorage.setItem("userToken", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("userId", userId);
    if (tzcode !== "") {
      localStorage.setItem("userTzcode", tzcode);
    }
  }

  private salesIQData() {
    if (localStorage.getItem("email")) {
      window["$zoho"].salesiq.visitor.email(localStorage.getItem("email"));
    }
    if (localStorage.getItem("name")) {
      window["$zoho"].salesiq.visitor.name(localStorage.getItem("name"));
    }
  }

  private getAuthData() {
    const token = localStorage.getItem("userToken");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
    };
  }

  private clearAuthData() {
    localStorage.clear();
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.userToken = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
      this.notificationService.listen(this.userId);
    }
  }

  logout() {
    this.userToken = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/"]);
    if (window['clevertap']) {
      window['clevertap'].logout();
    }
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setInterval(() => {
      this.http.get<any>(userBackendUrl + "/refresh-token").subscribe(
        (response) => {
          const token = response.authToken;
          this.isAuthenticated = true;
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          let userTimezone = "";
          if (response?.user?.TimeZone) {
            userTimezone = response.user.TimeZone["tzCode"];
          }
          this.saveAuthData(token, expirationDate, this.userId, userTimezone);
          this.setAuthTimer(this.expiresIn);
        },
        (error) => {
          this.logout();
        }
      );
    }, duration * 1000);
  }

  registerUser(name: string, email: string, phone: string, password: string, countryCode: Object, stopLoader) {
    const reqData = {
      name: name,
      email: email,
      phone: phone,
      password: password,
      countryCode: countryCode
    };
    if (localStorage.getItem('GTMcid')) {
      reqData['GTMcid'] = localStorage.getItem('GTMcid')
    }
    this.http.post<any>(userBackendUrl + "/register", reqData).subscribe(
      (response) => {
        const token = response.authToken;
        this.userToken = token;
        if (token) {
          if (clevertap) {
            let userObj = {
              "Name": response.user.name,
              "Email": response.user.email
            }
            if (response.user.phone) {
              userObj["Phone"] = response.user.phone
              if (response.user.countryCode) {
                userObj["Phone"] = "+" + String(response.user.countryCode.dialCode) + String(userObj["Phone"])
              }
            }
            clevertap.onUserLogin.push({
              "Site": userObj
            })
            clevertap.event.push("Sign Up")
            clevertap.profile.push({
              "Site": userObj
            })
          }
          const expiresIn = 86400000; //assuming token expires in 24hrs
          this.setAuthTimer(expiresIn);
          this.isAuthenticated = true;
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresIn);
          if (response.user.phone) {
            if (localStorage.getItem("therapistToken")) {
              window.location.replace(environment.base_url + "/user-dashboard");
            } else {
              let authRed = "";
              authRed = this.actRoute.snapshot.queryParams["authRed"];
              if (authRed) {
                this.router.navigateByUrl(authRed);
              } else {
                if (this.actRoute.snapshot.queryParams["plan"]) {
                  this.router.navigate([
                    "/order-summary/" +
                    this.actRoute.snapshot.queryParams["plan"],
                  ]);
                } else if (this.actRoute.snapshot.queryParams["therapist"]) {
                  this.router.navigate([
                    "/view-counsellor-calendar/" +
                    this.actRoute.snapshot.queryParams["therapist"],
                  ]);
                } else if (this.actRoute.snapshot.queryParams["redirect"]) {
                  this.router.navigate([
                    this.actRoute.snapshot.queryParams["redirect"],
                  ]);
                } else {
                  this.router.navigate(["/user-dashboard"]);
                }
              }
            }
          } else {
            if (this.actRoute.snapshot.queryParams["plan"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  plan: this.actRoute.snapshot.queryParams["plan"],
                },
              });
            } else if (this.actRoute.snapshot.queryParams["therapist"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  therapist: this.actRoute.snapshot.queryParams["therapist"],
                },
              });
            } else if (this.actRoute.snapshot.queryParams["redirect"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  redirect: this.actRoute.snapshot.queryParams["redirect"],
                },
              });
            } else {
              this.router.navigate(["/complete-profile"]);
            }
          }
          stopLoader()
          this.saveAuthData(token, expirationDate, this.userId);
          if (clevertap) {
            let userObj = {
              Name: response.user.name,
              Email: response.user.email,
            };
            if (response.user.phone) {
              userObj["Phone"] = response.user.phone;
              localStorage.setItem("phone", response.user.phone);
              if (response.user.countryCode) {
                userObj["Phone"] =
                  "+" +
                  String(response.user.countryCode.dialCode) +
                  String(userObj["Phone"]);
                localStorage.setItem(
                  "dialCode",
                  response.user.countryCode.dialCode
                );
                localStorage.setItem(
                  "countryCode",
                  response.user.countryCode.countryCode
                );
              }
            }
            clevertap.onUserLogin.push({
              Site: userObj,
            });
            clevertap.event.push("Sign Up");
            clevertap.profile.push({
              Site: userObj,
            });
          }
          localStorage.setItem("name", response.user.name);
          localStorage.setItem("email", response.user.email);
          this.salesIQData();
          this.notificationService.listen(this.userId);
        } else if (token === null){
          // redirect to validate email page
          this.router.navigate(['/unverified-email'])
        }
      },
      (error) => {
        this.authStatusListener.next(false);
        stopLoader()
      }
    );
  }

  googleAuth(token) {
    if (localStorage.getItem('GTMcid')) {
      token['GTMcid'] = localStorage.getItem('GTMcid')
    }
    this.http.post<any>(userBackendUrl + "/social-auth", token).subscribe(
      (response) => {
        const token = response.authToken;
        this.userToken = token;
        if (token) {
          if (clevertap) {
            let userObj = {
              "Name": response.user.name,
              "Email": response.user.email
            }
            if (response.user.phone) {
              userObj["phone"] = response.user.phone
              if (response.user.countryCode) {
                userObj["phone"] = "+" + response.user.countryCode.dialCode + userObj["phone"]
              }
            }
            clevertap.onUserLogin.push({
              "Site": userObj
            })
          }
          this.setAuthTimer(this.expiresIn);
          this.isAuthenticated = true;
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          let userTimezone = "";
          if (response?.user?.TimeZone) {
            userTimezone = response.user.TimeZone["tzCode"];
          }
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          this.saveAuthData(token, expirationDate, this.userId, userTimezone);
          localStorage.setItem("name", response.user.name);
          localStorage.setItem("email", response.user.email);
          this.salesIQData();
          this.notificationService.listen(this.userId);
          if (response.user.phone) {
            if (localStorage.getItem("therapistToken")) {
              window.location.replace(environment.base_url + "/user-dashboard");
            } else {
              let authRed = "";
              authRed = this.actRoute.snapshot.queryParams["authRed"];
              if (authRed) {
                this.router.navigateByUrl(authRed);
              } else {
                if (this.actRoute.snapshot.queryParams["plan"]) {
                  this.router.navigate([
                    "/order-summary/" +
                    this.actRoute.snapshot.queryParams["plan"],
                  ]);
                } else if (this.actRoute.snapshot.queryParams["therapist"]) {
                  this.router.navigate([
                    "/view-counsellor-calendar/" +
                    this.actRoute.snapshot.queryParams["therapist"],
                  ]);
                } else if (this.actRoute.snapshot.queryParams["redirect"]) {
                  this.router.navigate([
                    this.actRoute.snapshot.queryParams["redirect"],
                  ]);
                } else {
                  this.router.navigate(["/user-dashboard"]);
                }
              }
            }
            if (response.user.countryCode) {
              if (response.user.phone) {
                if (clevertap) {
                  clevertap.profile.push({
                    "Site": {
                      "Phone": "+" + response.user.countryCode.dialCode + response.user.phone
                    }
                  })
                }
              }
            }
          } else {
            if (this.actRoute.snapshot.queryParams["plan"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  plan: this.actRoute.snapshot.queryParams["plan"],
                },
              });
            } else if (this.actRoute.snapshot.queryParams["therapist"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  therapist: this.actRoute.snapshot.queryParams["therapist"],
                },
              });
            } else if (this.actRoute.snapshot.queryParams["redirect"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  redirect: this.actRoute.snapshot.queryParams["redirect"],
                },
              });
            } else {
              this.router.navigate(["/complete-profile"]);
            }
          }
        }
      },
      (error) => {
        this.authStatusListener.next(false);
      }
    );
  }

  campaignLogin(user, token) {
    if (token) {
      this.userToken = token;
      const expiresIn = 86400000; //assuming token expires in 24hrs
      this.setAuthTimer(expiresIn);
      this.isAuthenticated = true;
      this.userId = user._id;
      this.authStatusListener.next(true);
      const now = new Date();
      const expirationDate = new Date(now.getTime() + expiresIn);
      this.saveAuthData(token, expirationDate, this.userId);
      localStorage.setItem("name", user.name);
      localStorage.setItem("email", user.email);
      this.notificationService.listen(this.userId);
      this.salesIQData();
    }
  }

  loginUser(email: string, password: string, stopLoader) {
    const reqData = {
      email: email,
      password: password,
    };
    this.http.post<any>(userBackendUrl + "/login", reqData).subscribe(
      (response) => {
        const token = response.authToken;
        this.userToken = token;
        if (token) {
          this.setAuthTimer(this.expiresIn);
          this.isAuthenticated = true;
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          let userTimezone = "";
          if (response?.user?.TimeZone) {
            userTimezone = response.user.TimeZone["tzCode"];
          }
          const now = new Date();
          // window['clevertap']['onUserLogin'].push({
          //   "Site" : {
          //     "Name" : response.user.name,
          //     "Email" : response.user.email
          //   }
          // })
          if (clevertap) {
            let userObj = {
              "Name": response.user.name,
              "Email": response.user.email
            }
            if (response.user.phone) {
              userObj["Phone"] = response.user.phone
              if (response.user.countryCode) {
                userObj["Phone"] = "+" + response.user.countryCode.dialCode + response.user.phone
              }
            }
            clevertap.onUserLogin.push({
              "Site": userObj
            })
            clevertap.event.push("Web Log In")
            clevertap.profile.push({ "Site": userObj })
          }
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          if (response.user.phone) {
            if (localStorage.getItem("therapistToken")) {
              window.location.replace(environment.base_url + "/user-dashboard");
            } else {
              let authRed = "";
              authRed = this.actRoute.snapshot.queryParams["authRed"];
              if (authRed) {
                this.router.navigateByUrl(authRed);
              } else {
                if (this.actRoute.snapshot.queryParams["plan"]) {
                  this.router.navigate([
                    "/order-summary/" +
                    this.actRoute.snapshot.queryParams["plan"],
                  ]);
                } else if (this.actRoute.snapshot.queryParams["therapist"]) {
                  this.router.navigate([
                    "/view-counsellor-calendar/" +
                    this.actRoute.snapshot.queryParams["therapist"],
                  ]);
                } else if (this.actRoute.snapshot.queryParams["redirect"]) {
                  this.router.navigate([
                    this.actRoute.snapshot.queryParams["redirect"],
                  ]);
                } else {
                  this.router.navigate(["/user-dashboard"]);
                }
              }
            }
          } else {
            if (this.actRoute.snapshot.queryParams["plan"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  plan: this.actRoute.snapshot.queryParams["plan"],
                },
              });
            } else if (this.actRoute.snapshot.queryParams["therapist"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  therapist: this.actRoute.snapshot.queryParams["therapist"],
                },
              });
            } else if (this.actRoute.snapshot.queryParams["redirect"]) {
              this.router.navigate(["/complete-profile"], {
                queryParams: {
                  redirect: this.actRoute.snapshot.queryParams["redirect"],
                },
              });
            } else {
              this.router.navigate(["/complete-profile"]);
            }
          }
          stopLoader()
          this.saveAuthData(token, expirationDate, this.userId, userTimezone);
          if (clevertap) {
            let userObj = {
              Name: response.user.name,
              Email: response.user.email,
            };
            if (response.user.phone) {
              userObj["Phone"] = response.user.phone;
              localStorage.setItem("phone", String(response.user.phone));
              if (response.user.countryCode) {
                userObj["Phone"] =
                  "+" +
                  response.user.countryCode.dialCode +
                  response.user.phone;
                localStorage.setItem(
                  "dialCode",
                  response.user.countryCode.dialCode
                );
                localStorage.setItem(
                  "countryCode",
                  response.user.countryCode.countryCode
                );
              }
            }
            clevertap.onUserLogin.push({
              Site: userObj,
            });
            clevertap.event.push("Web Log In");
            clevertap.profile.push({ Site: userObj });
          }
          // this.http.get(userBackendUrl + "/refresh-token").subscribe((res) => {
          //   console.log(res)
          // })
          localStorage.setItem("name", response.user.name);
          localStorage.setItem("email", response.user.email);
          this.salesIQData();
          this.notificationService.listen(this.userId);
        }
      },
      (error) => {
        this.authStatusListener.next(false);
        stopLoader()
      }
    );
  }

  loginGoogleUser(email: string) {
    const reqData = {
      email: email,
    };
    this.http.post<any>(googleAuthUrl + "/login", reqData).subscribe(
      (response) => {
        const token = response.authToken;
        this.userToken = token;
        if (token) {
          this.setAuthTimer(this.expiresIn);
          this.isAuthenticated = true;
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          if (localStorage.getItem("therapistToken")) {
            window.location.replace("http://localhost:4200/user-dashboard");
          } else {
            this.NgZone.run(() => {
              this.router.navigate(["/user-dashboard"]);
            });
          }
          this.saveAuthData(token, expirationDate, this.userId);
          this.notificationService.listen(this.userId);
        }
      },
      (error) => {
        this.authStatusListener.next(false);
      }
    );
  }

  registerGoogleUser(email: string, name: string) {
    const reqData = {
      email: email,
      name: name,
      socialType: "google",
    };

    this.http.post<any>(googleAuthUrl + "/register", reqData).subscribe(
      (response) => {
        const token = response.authToken;
        this.userToken = token;
        if (token) {
          this.setAuthTimer(this.expiresIn);
          this.isAuthenticated = true;
          this.userId = response.user._id;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + this.expiresIn);
          if (localStorage.getItem("therapistToken")) {
            window.location.replace("http://localhost:4200/user-dashboard");
          } else {
            this.NgZone.run(() => {
              this.router.navigate(["/user-dashboard"]);
            });
          }
          this.saveAuthData(token, expirationDate, this.userId);
          this.notificationService.listen(this.userId);
        }
      },
      (error) => {
        this.authStatusListener.next(false);
      }
    );
  }

  forgotPassword(data) {
    return this.http.post(userBackendUrl + "/forgotPassword", data);
  }

  verifyResetCode(code) {
    return this.http.get(userBackendUrl + "/resetPassword/" + code);
  }

  resetPassword(data) {
    return this.http.post(userBackendUrl + "/resetPassword", data);
  }
  deleteUserAccount() {
    return this.http.delete(deleteuserAccountUrl);
  }

  changePassword(passwordData) {
    return this.http.put(
      environment.apiUrl + "/user/user/update-password",
      passwordData
    );
  }

  facebookRegister() {
    return this.http.get(
      environment.apiUrl + "/user/facebook-auth/facebook/register"
    );
  }
}
