<div class="bodyPageWrap">
    <div>
        <div class="frequentHeadWrap">
            <div class="row">
                <div class="col-md-12">
                    <div class="sectionHeading">
                        <h2>Talk therapy Related FAQ's</h2>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentQuestionBox">
            <div class="row">
                <div id="1" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>What can I expect from a talk therapy session?</h4>
                        <ul>
                            <li>
                                First session is typically about understanding
                                where you stand and discussing a way forward.
                                From longer programs (12 plus sessions), you can
                                expect behavioural changes and enhanced
                                wellbeing depending on your starting point.
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="2" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>
                            Can talk therapy be delivered effectively online?
                        </h4>
                        <p class="ptagCss">
                            Yes. Three of the most important elements of
                            successful talk therapy are – approach of the
                            therapist, comfort level between the user and
                            therapist and a conducive environment. At Please
                            Coach we work towards enhancing all these goals.
                        </p>
                        <ul>
                            <li>
                                Firstly, by following a very rigorous selection
                                process to recruit therapists. We only hire
                                people who have full time degrees in counselling
                                psychology, clear our demo session requirement
                                and 3 tier reference checks. Only the top 0.5%
                                percentile make it to the final round.
                            </li>
                            <li>
                                Secondly, our matching algorithm and experts do
                                a very nuanced matching of a user to a talk
                                therapist. It takes an individual’s needs and
                                personality profile into consideration and goes
                                way beyond just matching slots. This ensures
                                development of a constructive equation between
                                the user and the therapist.
                            </li>
                            <li>
                                Thirdly, we employ state of art technology to
                                deliver an experience on par with a face to face
                                session. So, no more long commutes and
                                awkwardness that you experience in the waiting
                                room at clinics. It offers better safety,
                                anonymity and comfort.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentHeadWrap">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <h3>
                            Have More Questions?
                            <span class="spanCss"
                                >(Access following heads)</span
                            >
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentQuestionBox">
            <div class="row">
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../subscription-faq"
                                >Subscription Related</a
                            ></b
                        >
                    </div>
                </div>

                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../program-related-faq"
                                >Program Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../account-related-faq"
                                >Account Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../technology-related-faq"
                                >Technology Related</a
                            ></b
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
