import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { SideNavBarService } from "../side-nav-bar.service";
import { TherapistAuthService } from "../therapist-auth/therapist-auth.service";
import clevertap from "clevertap-web-sdk";
import { ProfileService } from "../user/profile.service";
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { faCommentAlt, faVideo, faLongArrowAltRight, faStar, faChevronLeft, faChevronRight, faArrowRight, faEnvelope, faPhone, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Options } from "@angular-slider/ngx-slider";
import { FormControl } from "@angular/forms";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ViewportScroller } from "@angular/common";
// import LocomotiveScroll from 'locomotive-scroll';

@Component({
  selector: 'app-corporate-landing',
  templateUrl: './corporate-landing.component.html',
  styleUrls: ['./corporate-landing.component.css']
})
export class CorporateLandingComponent implements OnInit, OnDestroy {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2') slickModal2: SlickCarouselComponent;
  isVisible1: boolean = false;
  isVisible2: boolean = false;
  isVisible3: boolean = false;
  isVisible4: boolean = false;
  VisibleImages: string;
  ngAfterViewInit() {
    const observer1 = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        this.isVisible1 = true;
        this.VisibleImages = "../../assets/images/corporate/landing_4.png";
        this.isVisible2 = false;
        this.isVisible3 = false;
        this.isVisible4 = false;


      }
    }, {
      threshold: 1.0,
    })
    const observer2 = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        this.isVisible1 = false;
        this.VisibleImages = "../../assets/images/corporate/landing_5.png";
        this.isVisible2 = true;
        this.isVisible3 = false;
        this.isVisible4 = false;


      }
    }, {
      threshold: 1.0,
    })
    const observer3 = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        this.isVisible1 = false;
        this.isVisible2 = false;
        this.VisibleImages = "../../assets/images/corporate/landing_6.png";
        this.isVisible3 = true;
        this.isVisible4 = false;


      }
    }, {
      threshold: 1.0,
    })
    const observer4 = new IntersectionObserver((e) => {
      if (e[0].isIntersecting) {
        this.isVisible1 = false;
        this.isVisible2 = false;
        this.isVisible3 = false;
        this.VisibleImages = "../../assets/images/corporate/landing_7.png";
        this.isVisible4 = true;
      }
    }, {
      threshold: 1.0,
    })
    observer1.observe(document.getElementById("mobileScroll1"))
    observer2.observe(document.getElementById("mobileScroll2"))
    observer3.observe(document.getElementById("mobileScroll3"))
    observer4.observe(document.getElementById("mobileScroll4"))
  }

  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  sideNav = false;
  faCommentAlt = faCommentAlt;
  faVideo = faVideo;
  faLongArrowAltRight = faLongArrowAltRight;
  faArrowRight = faArrowRight;
  faStar = faStar;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faAngleDown = faAngleDown;
  private therapistAuthListenerSubs: Subscription;
  therapistIsAuthenticated = false;
  private sidenavListener: Subscription;
  currentYear;
  base_url = environment.base_url;
  isDashboardRedirect: boolean;
  scroll;
  companyImages = [
    {
      cardimages: "../../assets/images/corporate/image_1.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_2.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_3.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_4.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_5.png"
    },
    {
      cardimages: "../../assets/images/corporate/image_6.png"
    }
  ];
  companyReview = [
    {
      review: "Majority of employees struggle with some form of mental health challenge, leading to issues with with productivity, burnout, and more. With the arrival of COVID-19, this has drastically increased.",
      reviewedBy: "Sahil Parashar, Director at Hines"
    },
    {
      review: "Majority of employees struggle with some form of mental health challenge, leading to issues with with productivity, burnout, and more. With the arrival of COVID-19, this has drastically increased.",
      reviewedBy: "Sahil Parashar, Director at Hines"
    },
    {
      review: "Majority of employees struggle with some form of mental health challenge, leading to issues with with productivity, burnout, and more. With the arrival of COVID-19, this has drastically increased.",
      reviewedBy: "Sahil Parashar, Director at Hines"
    },
    {
      review: "Majority of employees struggle with some form of mental health challenge, leading to issues with with productivity, burnout, and more. With the arrival of COVID-19, this has drastically increased.",
      reviewedBy: "Sahil Parashar, Director at Hines"
    },
    {
      review: "Majority of employees struggle with some form of mental health challenge, leading to issues with with productivity, burnout, and more. With the arrival of COVID-19, this has drastically increased.",
      reviewedBy: "Sahil Parashar, Director at Hines"
    },
  ];
  testimonialsSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 2,
          autoPlay: true,
          autoplaySpeed: 2000,
          infite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          autoPlay: true,
          autoplaySpeed: 2000,
          infite: true,
        }
      },
    ]
  }
  companySlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    centerMode: true,
    centerPadding: "10px",
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1
        }
      },
    ]
  }

  wellbeing_content = [
    {
      title: "Lack of motivation & employee morale",
      images: "../../assets/images/corporate/card_1.png",
      subTitle: "Motivational issues hinder employee performance and lead to low employee morale."
    },
    {
      title: "Team conflict & low connectedness",
      images: "../../assets/images/corporate/card_2.png",
      subTitle: "Poor team dynamics inhibit progress, innovation, and the achievement of goals."
    },
    {
      title: "Low productivity & output",
      images: "../../assets/images/corporate/card_3.png",
      subTitle: "Low employee productivity negatively impacts businesses' bottom lines."
    },
    {
      title: "Decreased mood & wellbeing",
      images: "../../assets/images/corporate/card_4.png",
      subTitle: "Low mood and wellbeing is challenging to manage and impact people in both their work and personal lives."
    },
  ];

  OrganizationGrowth = [
    {
      title: "../../assets/images/corporate/1_new.png",
      heading: "Understand",
      content: "How we can understand your needs around employee no's, specific subsets, etc."
    },
    {
      title: "../../assets/images/corporate/2_new.png",
      heading: "Agree",
      content: "Agreement on price/session, total subscription value payment, activation for web and app access."
    },
    {
      title: "../../assets/images/corporate/3_new.png",
      heading: "Use",
      content: "Employess receive corporate email id's to access the platform via web/ios/android."
    },
    {
      title: "../../assets/images/corporate/4_new.png",
      heading: "Evaluate",
      content: "We  evaluate weekly data usage report & monthly feedback report to review the impact."
    },
  ];

  WhyUs_section = [
    {
      id: 1,
      title: "Curated by clinical experts",
      content: "Our programmes have been designed by psychiatrists and clinical psychologists. We combine decades of experience with cutting-edge clinical science to create solutions that effect real change.",
      images: "../../../assets/images/corporate/landing_1.png"
    },
    {
      id: 2,
      title: "Complete care model",
      content: "According to research, combining mental health solutions yields a higher return than standalone offerings. pleasecoach model provides a variety of solutions like Webinars, Self-care modules, 1-1 consultations, all included in one.",
      images: "../../../assets/images/corporate/landing_2.png"
    },
    {
      id: 3,
      title: "24/7 support",
      content: "Our Client Success team adds the human touch to digital. We know that humans are as unique and complex. We get under the skin of our Users to help drive a meaningful change. And, we are there for you 24/7.",
      images: "../../../assets/images/corporate/landing_3.png"
    }
  ];

  ImageUrl: String = "../../../assets/images/corporate/landing_1.png";

  WhatYouWillGet = [
    {
      subHeading: "How you TALK is the key",
      heading: "Regional languages",
      content: "Along with English as a primary form of communication, we also have a team of specialized experts who are well-versed in all major regional languages of India.",
      images: "../../assets/images/corporate/landing_4.png"
    },
    {
      subHeading: "understand your Team better",
      heading: "Periodic Insights",
      content: "Our platform provides you with data on how employees are interacting with the programme. We also provide timely reports so that you can assess the program's effectiveness.",
      images: "../../assets/images/corporate/landing_5.png"
    },
    {
      subHeading: "An evolutionary effort",
      heading: "Continual customization",
      content: "You have a better sense of your organization than anyone else, so we believe in working with you to understand how we can improve and customize the programme on a regular basis.",
      images: "../../assets/images/corporate/landing_6.png"
    },
    {
      subHeading: "vast resources curated for you",
      heading: "Unlimited self-care access",
      content: "Our platform provides personalised mental health plans and self-care tools for a variety of mental health issues. Employees can participate in emotional well-being activities, a mood tracker along with a Journal , mental health assessments, and much more.",
      images: "../../assets/images/corporate/landing_7.png"
    },
  ];


  // SLider
  sliderControl: FormControl = new FormControl(10);
  options: Options = {
    floor: 10,
    ceil: 40000,
    step: 10
  };
  constructor(
    public authService: AuthService,
    public therapistAuthService: TherapistAuthService,
    private sidenavService: SideNavBarService,
    private userService: ProfileService,
    private viewportScroller: ViewportScroller
  ) {

    // this.listener = this.renderer2.listen('window', 'scroll', (e) => {
    //   console.log(this.getYPosition(e));
    // });
  }
  setIsVisible
  setCurrentImg
  isVisible: boolean = false;

  commaSeprateNumber(number) {
    return Number(number).toLocaleString('en-IN')
  }
  ngOnInit(): void {
    AOS.init();
    this.currentYear = new Date().getFullYear();
    this.userIsAuthenticated = this.authService.getIsAuth();
    if (this.userIsAuthenticated) {
      this.userService.getBookingAvailable().subscribe((res) => {
        let plans = res["bookingAvailable"];
        this.isDashboardRedirect = plans > 0;
      })
    }
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.userIsAuthenticated = isAuthenticated;
      });
    this.sidenavListener = this.sidenavService
      .sideNavBarListener()
      .subscribe((isOpen) => {
        this.sideNav = isOpen;
      });
    this.therapistIsAuthenticated = this.therapistAuthService.getIsAuth();
    this.authListenerSubs = this.therapistAuthService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.therapistIsAuthenticated = isAuthenticated;
      });
  }



  getStarted() {
    if (clevertap) {
      clevertap.event.push("Get Started - Header")
    }
  }

  openImage(openImageContext) {
    if (openImageContext == "collapseOne") {
      return this.ImageUrl = "../../../assets/images/corporate/landing_1.png"
    } else if (openImageContext == "collapseTwo") {
      return this.ImageUrl = "../../../assets/images/corporate/landing_2.png"
    } else if (openImageContext == "collapseThree") {
      return this.ImageUrl = "../../../assets/images/corporate/landing_3.png"
    } else {
      return this.ImageUrl = "../../../assets/images/corporate/landing_1.png"
    }
  }

  closeOtherCheckBox(id) {
    switch (id) {
      case 'collapsible1':
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        break;
      case 'collapsible2':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        break;
      case 'collapsible3':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible4');
        break;
      case 'collapsible4':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        break;
      case 'collapsible4':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        break;
      default:
        break;
    }
  }

  scrollTo(id) {
    this.viewportScroller.scrollToAnchor(id)
  }

  closeCheckBox(id: string) {
    document.getElementById(id)['checked'] = false
  }

  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  nextSlideTestimonials() {
    this.slickModal1.slickNext();
  }
  prevSlideTestimonials() {
    this.slickModal1.slickPrev();
  }
  nextSlideCompanyReview() {
    this.slickModal2.slickNext();
  }
  prevSlideCompanyReview() {
    this.slickModal2.slickPrev();
  }


  switchSideNav() {
    this.sideNav = !this.sideNav;
  }
  closeNavBar() {
    this.sidenavService.closesidenav();
  }
  ngOnDestroy() {
    if (this.authListenerSubs) {
      this.authListenerSubs.unsubscribe();
    }
    if (this.therapistAuthListenerSubs) {
      this.therapistAuthListenerSubs.unsubscribe();
    }
  }

  onLogout() {
    if (this.userIsAuthenticated) {
      this.authService.logout();
    }
    if (this.therapistIsAuthenticated) {
      this.therapistAuthService.logout();
    }
  }

}
