import { Component, OnInit, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-tawk-widget',
  templateUrl: './tawk-widget.component.html',
  styleUrls: ['./tawk-widget.component.css']
})
export class TawkWidgetComponent implements OnInit {
  tawkScript = this._renderer.createElement('script');
  interaktScript = this._renderer.createElement('script');
  constructor(private _renderer: Renderer2, @Inject(DOCUMENT) private _document) { }

  ngOnInit(): void {
    // this.tawkScript.text = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "f1205aee03d81fa0700cc880a600fbdcebf2bc9e4da10c47d81932fd4d8be42b", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`
    // this.interaktScript.text = `(function(w,d,s,c,r,a,m){w['KiwiObject']=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)};w[r].l=1*new Date();a=d.createElement(s);m=d.getElementsByTagName(s)[0];a.async=1;a.src=c;m.parentNode.insertBefore(a,m)})(window,document,'script','https://app.interakt.ai/kiwi-sdk/kiwi-sdk-17-prod-min.js?v='+ new Date().getTime(),'kiwi');window.onload=function(){kiwi.init('','hNdOoWoMrw68WSARC4BymQqFloLlfWkK',{});}`
    // this._renderer.appendChild(this._document.body, this.tawkScript);
    // this._renderer.appendChild(this._document.body, this.interaktScript)
  }
}
