<!-- [ + ] Banner -->
<div class="CorporateBanner topMenuMargin">
    <section (click)="closenavbar()" class="section_container allCenter">
        <div class="container">
            <div class="row ordering allCenter">
                <div
                    class="col-md-8 col-lg-8 col-xl-8 center_heading tabView fadeInRight"
                >
                    <h1 class="headingTxt ChronicleDisplay">
                        Healthy employees are a key to
                        <span class="headingSpan">Success</span>
                    </h1>
                    <p class="subText GilroyRegular">
                        We provide organisations with an easy access to therapy
                        sessions, webinars, self-care tools, a community and an
                        end to end professional support. Thus, taking care of
                        people who take care of your business.
                    </p>
                    <div class="banner_btn">
                        <button
                            class="buttonTheme1 loginButton widthForButton newColor cmr-5 GilroyMedium"
                            routerLink="get-in-touch"
                        >
                            Get In Touch
                        </button>
                        <button
                            class="buttonTheme2 newColorOutline widthForButton GilroyMedium"
                            (click)="scrollTo('whyEmployee')"
                        >
                            Scroll To Explore
                        </button>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xl-4 user_round fadeInBottom">
                    <img
                        src="../../assets/images/user_round.png"
                        class="img-fluid"
                    />
                </div>
            </div>
        </div>
    </section>
</div>

<!-- [ + ] Company Heading -->
<section (click)="closenavbar()" class="CompanySection">
    <h1 class="allCenter">Brands That Trust Us</h1>
</section>

<!-- [ + ] Company Images -->
<section (click)="closenavbar()" class="company_img_slider" style="width: 95vw">
    <div class="container-fluid">
        <div>
            <ngx-slick-carousel
                class="carousel testimonial-slider"
                #slickModal1="slick-carousel"
                [config]="testimonialsSlideConfig"
                (init)="slickInit($event)"
                (breakpoint)="breakpoint($event)"
                (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)"
            >
                <div
                    class=""
                    ngxSlickItem
                    *ngFor="let company of companyImages"
                >
                    <div
                        class="d-flex justify-content-center align-items-center m-3 h3w5"
                    >
                        <img
                            src="{{ company.cardimages }}"
                            alt=""
                            style="object-fit: contain"
                            class="quote-sign img-fluid"
                        />
                    </div>
                </div>
            </ngx-slick-carousel>
            <div
                class="d-flex flex-row justify-content-center align-items-center"
            >
                <button
                    aria-label="Previous"
                    class="prev sliderIndicator mx-2"
                    (click)="prevSlideTestimonials()"
                >
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </button>
                <button
                    aria-label="Next"
                    class="next sliderIndicator mx-2"
                    (click)="nextSlideTestimonials()"
                >
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</section>

<!-- [ + ] Company Review  -->

<!-- <section (click)="closenavbar()" class="mt-100" style="width: 95vw">
  <div class="container-fluid">
    <div>
      <ngx-slick-carousel
        class="carousel reviewCards"
        #slickModal2="slick-carousel"
        [config]="companySlideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
      >
        <div class="" ngxSlickItem *ngFor="let company of companyReview">
          <div
            class="d-flex flex-row customMarginCard"
            style="border-left: 4px solid #6D9566"
          >
            <div style="margin-top: -16px">
              <h1 class="GilroyRegular sliderQuote">"</h1>
            </div>
            <div class="d-flex ml-3 flex-column ml-8">
              <p class="GilroyRegular companyReview">{{ company.review }}</p>
              <p class="GilroyMedium companyReviewBy mt-3">
                {{ company.reviewedBy }}
              </p>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
      <div class="d-flex flex-row justify-content-center align-items-center">
        <button
          aria-label="Previous"
          class="prev sliderIndicator mx-2"
          (click)="prevSlideCompanyReview()"
        >
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
        <button
          aria-label="Next"
          class="next sliderIndicator mx-2"
          (click)="nextSlideCompanyReview()"
        >
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</section> -->

<!-- [ + ] Why employee wellbeing is crucial now more than ever? -->
<section (click)="closenavbar()" class="wellbeing" id="whyEmployee">
    <div class="allCenter flex-column">
        <h1 class="wellbeingHeading">
            Why employee wellbeing is crucial now more than ever?
        </h1>
        <div class="wellbeingsubHeading">
            <p class="GilroyRegular text-center mt-3">
                Majority of employees struggle with some form of mental health
                challenge, leading to issues with productivity, burnout, and
                more. With the arrival of COVID-19, this has drastically
                increased.
            </p>
        </div>
    </div>
    <div class="mt-3 wellbeingCard">
        <div class="row">
            <div
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                *ngFor="let item of wellbeing_content"
            >
                <div class="wellbeingCards">
                    <div class="wellbeingInnerCard">
                        <h1 class="GilroyMedium p-3">
                            {{ item.title }}
                        </h1>
                    </div>
                    <div class="allCenter wellbeingContent">
                        <img src="{{ item.images }}" class="img-fluid" />
                    </div>
                    <div style="overflow: hidden; height: 250px">
                        <p class="GilroyRegular mt-3 py-4">
                            {{ item.subTitle }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- [ + ] This is where Please Coach comes in! -->
<section (click)="closenavbar()" class="mt-5">
    <div
        class="d-flex justify-content-center align-items-center flex-column mt-5"
    >
        <h1 class="wellbeingHeading">This is where Please Coach comes in!</h1>
        <div class="wellbeingsubHeading">
            <p class="GilroyRegular text-center mt-3">
                Your Organization's growth divided into simple steps.
            </p>
        </div>
    </div>
    <div class="px-50">
        <div class="row mt-3">
            <div
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                *ngFor="let item of OrganizationGrowth"
            >
                <div class="OrganizationCard">
                    <div class="d-flex" style="flex-direction: column">
                        <div class="flex-1">
                            <div
                                style="
                                    overflow: hidden;
                                    height: 170px;
                                    max-height: 170px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                "
                            >
                                <img src="{{ item.title }}" class="img-fluid" />
                            </div>
                            <div>
                                <h1 class="GilroyMedium">
                                    {{ item.heading }}
                                </h1>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div>
                                <p class="GilroyRegular mt-3 numberTitles">
                                    {{ item.content }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
        <div class="RequestCallbackText" routerLink="get-in-touch">
            <h1 class="GilroyBold" style="margin-bottom: 0; cursor: pointer">
                Request a callback
            </h1>
            <fa-icon style="margin-left: 12px" [icon]="faArrowRight"></fa-icon>
        </div>
    </div>
</section>

<!-- [ + ] Why Us? Heading -->
<section (click)="closenavbar()" class="container whyUsSection">
    <h1 class="ChronicleDisplay whyUsh1">Why Us?</h1>

    <div style="margin-top: 0px">
        <div class="row -p-14 whyUsInnerSection">
            <div
                class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center align-items-center"
                style="cursor: pointer"
            >
                <div
                    class="accordion"
                    id="accordionExample"
                    style="width: 100%"
                >
                    <div>
                        <div
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            class="custom_collapse collapsed"
                            aria-expanded="true"
                            (click)="openImage('collapseOne')"
                            aria-controls="collapseOne"
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <h1 class="accordion_h1">
                                    Curated by clinical experts
                                </h1>

                                <fa-icon
                                    class="WhyUsIcon"
                                    [icon]="faAngleDown"
                                ></fa-icon>
                            </div>
                            <hr class="colored" />
                        </div>

                        <div
                            id="collapseOne"
                            class="collapse show accordion_content"
                            data-parent="#accordionExample"
                            style="margin-top: 0px"
                        >
                            <p class="GilroyMedium">
                                Our programmes have been designed by
                                psychiatrists and clinical psychologists. We
                                combine decades of experience with cutting-edge
                                clinical science to create solutions that effect
                                real change.
                            </p>
                            <!-- <img src="{{ ImageUrl }}" class="img-fluid imgDisp" /> -->
                        </div>

                        <!-- Content two -->

                        <div
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            (click)="openImage('collapseTwo')"
                            aria-controls="collapseTwo"
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <h1 class="accordion_h1">
                                    Complete care model
                                </h1>

                                <fa-icon
                                    class="WhyUsIcon"
                                    [icon]="faAngleDown"
                                ></fa-icon>
                            </div>
                            <hr class="colored" />
                        </div>
                        <div
                            id="collapseTwo"
                            class="collapse"
                            data-parent="#accordionExample"
                            style="margin-top: 0px"
                        >
                            <p class="GilroyMedium">
                                According to research, combining mental health
                                solutions yields a higher return than standalone
                                offerings. Please Coach's model provides a
                                variety of solutions like Webinars, Self-care
                                modules, 1-1 consultations, all included in one.
                            </p>
                            <!-- <img src="{{ ImageUrl }}" class="img-fluid imgDisp" /> -->
                        </div>

                        <!-- Content Three -->
                        <div
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            (click)="openImage('collapseThree')"
                            aria-controls="collapseThree"
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <h1 class="accordion_h1">24/7 support</h1>

                                <fa-icon
                                    class="WhyUsIcon"
                                    [icon]="faAngleDown"
                                ></fa-icon>
                            </div>
                            <hr class="colored" />
                        </div>
                        <div
                            id="collapseThree"
                            class="collapse"
                            data-parent="#accordionExample"
                            style="margin-top: 0px"
                        >
                            <p class="GilroyMedium">
                                Our Client Success team add the human touch to
                                digital. We know that humans are as unique and
                                complex. We get under the skin of our Users to
                                help drive a meaningful change. And, we are
                                there for you 24/7.
                            </p>
                            <!-- <img src="{{ ImageUrl }}" class="img-fluid imgDisp" /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center align-items-center"
            >
                <div>
                    <img
                        src="{{ ImageUrl }}"
                        class="img-fluid imageNone"
                        style="margin-right: -50px"
                        data-aos="fade-left"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                    />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- [ + ] What you will get Section 1 -->
<section (click)="closenavbar()" class="wget1">
    <div class="container">
        <h1 class="ChronicleDisplay whyUsh1">What you will get</h1>
    </div>

    <div class="mobTabView">
        <div class="container">
            <!-- Content 1 -->

            <div class="allCenter WhatWillGetSection">
                <div class="row">
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1100"
                    >
                        <div
                            class=""
                            style="display: flex; flex-direction: row"
                        >
                            <p class="WhatGetSubItem GilroyBold">
                                <span></span>{{ WhatYouWillGet[0].subHeading }}
                            </p>
                        </div>
                        <div class="">
                            <h1 class="ChronicleDisplay WhatGetheading">
                                {{ WhatYouWillGet[0].heading }}
                            </h1>
                            <p class="GilroyMedium WhatGetcontent">
                                {{ WhatYouWillGet[0].content }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1100"
                    >
                        <div class="allCenter">
                            <img
                                src="{{ WhatYouWillGet[0].images }}"
                                class="img-fluid ml-50"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content 2 -->

            <div class="allCenter WhatWillGetSection">
                <div class="row">
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1200"
                    >
                        <div
                            class=""
                            style="display: flex; flex-direction: row"
                        >
                            <p class="WhatGetSubItem GilroyBold">
                                <span></span>{{ WhatYouWillGet[1].subHeading }}
                            </p>
                        </div>
                        <div class="">
                            <h1 class="ChronicleDisplay WhatGetheading">
                                {{ WhatYouWillGet[1].heading }}
                            </h1>
                            <p class="GilroyMedium WhatGetcontent">
                                {{ WhatYouWillGet[1].content }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1200"
                    >
                        <div class="allCenter">
                            <img
                                src="{{ WhatYouWillGet[1].images }}"
                                class="img-fluid ml-50"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Content 3 -->

            <div class="allCenter WhatWillGetSection">
                <div class="row">
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1300"
                    >
                        <div
                            class=""
                            style="display: flex; flex-direction: row"
                        >
                            <p class="WhatGetSubItem GilroyBold">
                                <span></span>{{ WhatYouWillGet[2].subHeading }}
                            </p>
                        </div>
                        <div class="">
                            <h1 class="ChronicleDisplay WhatGetheading">
                                {{ WhatYouWillGet[2].heading }}
                            </h1>
                            <p class="GilroyMedium WhatGetcontent">
                                {{ WhatYouWillGet[2].content }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1300"
                    >
                        <div class="allCenter">
                            <img
                                src="{{ WhatYouWillGet[2].images }}"
                                class="img-fluid ml-50"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Content 4 -->

            <div class="allCenter WhatWillGetSection">
                <div class="row">
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1400"
                    >
                        <div
                            class=""
                            style="display: flex; flex-direction: row"
                        >
                            <p class="WhatGetSubItem GilroyBold">
                                <span></span>{{ WhatYouWillGet[3].subHeading }}
                            </p>
                        </div>
                        <div class="">
                            <h1 class="ChronicleDisplay WhatGetheading">
                                {{ WhatYouWillGet[3].heading }}
                            </h1>
                            <p class="GilroyMedium WhatGetcontent">
                                {{ WhatYouWillGet[3].content }}
                            </p>
                        </div>
                    </div>
                    <div
                        class="col-md-12 col-lg-6 col-xl-6 col-12 col-sm-12 p-3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1400"
                    >
                        <div class="allCenter">
                            <img
                                src="{{ WhatYouWillGet[3].images }}"
                                class="img-fluid ml-50"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- What you will get Section CRED -->
<section (click)="closenavbar()" class="mt-100 wget2">
    <div class="container">
        <h1 class="ChronicleDisplay whyUsh1">What you will get</h1>
    </div>

    <div class="webCredView container">
        <div class="mobile-scroll flex max-width">
            <div class="scroll-full-screen-wrapper">
                <div class="scroll-full-screen">
                    <div
                        [class]="
                            isVisible1
                                ? 'screen-text mt-100 text-visible'
                                : 'screen-text mt-100'
                        "
                        id="mobileScroll1"
                        style="height: 300px"
                    >
                        <div
                            data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-duration="1100"
                        >
                            <div
                                class=""
                                style="display: flex; flex-direction: row"
                            >
                                <p class="WhatGetSubItem GilroyBold">
                                    <span></span
                                    >{{ WhatYouWillGet[0].subHeading }}
                                </p>
                            </div>
                            <div class="">
                                <h1 class="ChronicleDisplay WhatGetheading">
                                    {{ WhatYouWillGet[0].heading }}
                                </h1>
                                <p class="GilroyMedium WhatGetcontent">
                                    {{ WhatYouWillGet[0].content }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        [class]="
                            isVisible2
                                ? 'screen-text mt-100 text-visible'
                                : 'screen-text mt-100'
                        "
                        id="mobileScroll2"
                        style="height: 300px"
                    >
                        <!-- <div class="screen-heading">{{ scrollData[1].heading }}</div>
          <div class="mobile-mockup-wrapper only-mobile">
            <div class="mobile-mockup">
              <div
                class="mobile-mockup-screen flex absolute-center slide-in-right"
              >
                <img
                  src="{{ VisibleImages }}"
                  class="mobile-screen-img slide-in-right"
                />
              </div>
            </div>
          </div>
          <div class="screen-description">{{ scrollData[1].description }}</div> -->
                        <div
                            data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-duration="1100"
                        >
                            <div
                                class=""
                                style="display: flex; flex-direction: row"
                            >
                                <p class="WhatGetSubItem GilroyBold">
                                    <span></span
                                    >{{ WhatYouWillGet[1].subHeading }}
                                </p>
                            </div>
                            <div class="">
                                <h1 class="ChronicleDisplay WhatGetheading">
                                    {{ WhatYouWillGet[1].heading }}
                                </h1>
                                <p class="GilroyMedium WhatGetcontent">
                                    {{ WhatYouWillGet[1].content }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        [class]="
                            isVisible3
                                ? 'text-visible screen-text mt-100 '
                                : 'screen-text mt-100 '
                        "
                        id="mobileScroll3"
                        style="height: 300px"
                    >
                        <!-- <div class="screen-heading">{{ scrollData[2].heading }}</div>
          <div class="mobile-mockup-wrapper only-mobile">
            <div class="mobile-mockup">
              <div class="mobile-mockup-screen flex absolute-center">
                <img
                  src="{{ VisibleImages }}"
                  class="mobile-screen-img slide-in-right"
                />
              </div>
            </div>
          </div>
          <div class="screen-description">{{ scrollData[2].description }}</div> -->
                        <div
                            data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-duration="1100"
                        >
                            <div
                                class=""
                                style="display: flex; flex-direction: row"
                            >
                                <p class="WhatGetSubItem GilroyBold">
                                    <span></span
                                    >{{ WhatYouWillGet[2].subHeading }}
                                </p>
                            </div>
                            <div class="">
                                <h1 class="ChronicleDisplay WhatGetheading">
                                    {{ WhatYouWillGet[2].heading }}
                                </h1>
                                <p class="GilroyMedium WhatGetcontent">
                                    {{ WhatYouWillGet[2].content }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        [class]="
                            isVisible4
                                ? 'text-visible screen-text mt-100 '
                                : 'screen-text mt-100 '
                        "
                        id="mobileScroll4"
                        style="height: 300px"
                    >
                        <!-- <div class="screen-heading">{{ scrollData[3].heading }}</div>
          <div class="mobile-mockup-wrapper only-mobile">
            <div class="mobile-mockup">
              <div class="mobile-mockup-screen flex absolute-center">
                <img
                  src="{{ VisibleImages }}"
                  class="mobile-screen-img slide-in-right"
                />
              </div>
            </div>
          </div>
          <div class="screen-description">{{ scrollData[3].description }}</div> -->
                        <div
                            data-aos="fade-right"
                            data-aos-easing="linear"
                            data-aos-duration="1100"
                        >
                            <div
                                class=""
                                style="display: flex; flex-direction: row"
                            >
                                <p class="WhatGetSubItem GilroyBold">
                                    <span></span
                                    >{{ WhatYouWillGet[3].subHeading }}
                                </p>
                            </div>
                            <div class="">
                                <h1 class="ChronicleDisplay WhatGetheading">
                                    {{ WhatYouWillGet[3].heading }}
                                </h1>
                                <p class="GilroyMedium WhatGetcontent">
                                    {{ WhatYouWillGet[3].content }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-mockup-wrapper non-mobile">
                <div class="mobile-mockup">
                    <div class="mobile-mockup-screen flex absolute-center">
                        <img
                            src="{{ VisibleImages }}"
                            class="mobile-screen-img slide-in-right img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- [ + ] How many employees are in your organization? -->
<section (click)="closenavbar()" class="container howManySection">
    <h1
        class="ChronicleDisplay organizationSliderHeading"
        style="margin-top: 100px"
    >
        How many employees are in your organization?
    </h1>

    <div class="slider" style="margin-top: 100px">
        <h1 class="ChronicleDisplay counterHeading">
            {{ commaSeprateNumber(sliderControl.value) }}
        </h1>
        <p class="GilroyMedium counterSubHeading">employees</p>
        <ngx-slider [formControl]="sliderControl" [options]="options">
        </ngx-slider>
    </div>

    <div class="row" style="margin-top: 30px">
        <div
            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5"
            style="padding-right: 100px"
        >
            <h1 class="ChronicleDisplay counterHeadingSmall">
                {{ commaSeprateNumber(sliderControl.value / 2) }}
            </h1>
            <p class="GilroyMedium counterSubHeading">
                colleagues are currently struggling with some form of mental
                health issue.
            </p>
        </div>
        <div
            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5"
            style="padding-right: 100px"
        >
            <h1 class="ChronicleDisplay counterHeadingSmall">
                {{ commaSeprateNumber(sliderControl.value * 3) }}
            </h1>
            <p class="GilroyMedium counterSubHeading">
                days lost per year due to mental health issues alone.
            </p>
        </div>
        <div
            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5"
            style="padding-right: 100px"
        >
            <h1 class="ChronicleDisplay counterHeadingSmall">
                ₹ {{ commaSeprateNumber(sliderControl.value * 67305) }}
            </h1>
            <p class="GilroyMedium counterSubHeading">
                the annual loss due to mental health issues in the workplace.
            </p>
        </div>
    </div>
</section>

<!-- It’s 2022 Section -->
<section (click)="closenavbar()" class="allCenter itFooterSection">
    <div class="text-center d-flex" style="flex-direction: column">
        <h1 class="ChronicleDisplay">It's {{ currentYear }}</h1>
        <p class="ChronicleDisplay">Your Team deserves the best care</p>
    </div>
    <div>
        <button
            class="buttonTheme1 loginButton widthForButton"
            routerLink="get-in-touch"
        >
            Get In Touch
        </button>
    </div>
</section>
