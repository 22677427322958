import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SeoMetaService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  updateMetaInfo(content, category, title = '') {
    this.meta.updateTag({ name: 'description', content: content });
    this.meta.updateTag({property:'og:description',content:content});
    this.meta.updateTag({ name: 'keywords', content: category });
    this.meta.updateTag({property:'og:keywords',content:category});
    if(title !== ''){
      this.meta.updateTag({ name : "title", content: title });
      this.meta.updateTag({property:'og:title',content:title});
    }
    this.meta.updateTag({property:'og:type',content:'website'});
    this.meta.updateTag({property:'og:url',content:this.router.url});
}



updateTitle(title?: string) {
    if (!title) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) { route = route.firstChild; }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)).subscribe((event) => {
                  if(event['title']){
                    this.titleService.setTitle(event['title'] );
                  } else {
                    this.titleService.setTitle('Mental Health Counseling | pleasecoach')
                  }
                });
    } else {
        this.titleService.setTitle(title );
    }
}
}
