<div style="display: flex; align-items: center; margin-bottom: 20px">
    <span
        ><a
            routerLink="../report"
            style="
                font-size: 16px;
                color: #445e93;
                cursor: pointer;
                text-decoration: underline;
            "
            >Report</a
        ></span
    >
    <span style="font-size: 16px">&nbsp;>&nbsp;</span>
    <span style="font-size: 16px; color: #808080">Sessions Report</span>
</div>
<div>
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="secondaryHeading">
        <h2>Sessions Report</h2>
        <hr />
    </div>
    <div class="filterHolder">
        <div class="row">
            <div class="col-lg-3">
                <input
                    type="text"
                    placeholder=""
                    class="form-control formRound"
                    bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'DD MMM YY' }"
                    [(ngModel)]="startDate"
                    placeholder="Start Date"
                />
            </div>
            <div class="col-lg-3">
                <input
                    type="text"
                    placeholder=""
                    class="form-control formRound"
                    bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'DD MMM YY' }"
                    [(ngModel)]="endDate"
                    placeholder="End Date"
                />
            </div>
            <div class="col-lg-4">
                <input
                    type="text"
                    placeholder=""
                    class="form-control formRound"
                    [(ngModel)]="searchString"
                    placeholder="Client Name"
                />
            </div>
            <div *ngIf="isLoading" class="d-flex justify-content-center">
                <div
                    class="spinner-border"
                    style="
                        width: 2rem;
                        height: 2rem;
                        margin-top: 8px;
                        margin-left: 10px;
                    "
                    role="status"
                >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <button
                class="buttonTheme1"
                (click)="getCompletedSessions()"
                *ngIf="!isLoading"
            >
                Submit
            </button>
        </div>
    </div>
    <div class="notificaTable">
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th class="text-center ssid">Session Id</th>
                    <th class="name">Client Name</th>
                    <th class="text-center">Session Date</th>
                    <th class="text-center ssid">Start Time</th>
                    <th class="text-center ssid">End Time</th>
                    <th class="text-center ssid">Rating Recieved</th>
                    <th class="text-center">Earning</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Action</th>
                </tr>
                <tr *ngFor="let session of sessions">
                    <td class="text-center">{{ session?.SessionNo }}</td>
                    <td class="name">{{ session?.userId?.name }}</td>
                    <td class="text-center">
                        {{ session?.startDate | date : 'dd MMM y' }}
                    </td>
                    <td class="text-center">
                        {{ session?.startDate | date : 'HH:MM' }}
                    </td>
                    <td class="text-center">
                        {{ session?.endDate | date : 'HH:MM' }}
                    </td>
                    <td class="text-center">-</td>
                    <td class="text-center">{{ session?.earning }}</td>
                    <td class="text-center">{{ session?.isTherapistPaid }}</td>
                    <td class="text-center">
                        <span *ngIf="!session?.therapistRequestId"
                            ><button
                                class="buttonTheme1"
                                (click)="raiseRequest(session._id)"
                            >
                                Raise Request
                            </button></span
                        ><span
                            *ngIf="
                                session?.therapistRequestId?.status ===
                                    'completed' &&
                                session?.therapistRequestId?.recieptName
                            "
                            ><button
                                class="buttonTheme1"
                                (click)="
                                    downloadReceipt(
                                        session?.therapistRequestId?.recieptName
                                    )
                                "
                            >
                                View Receipt
                            </button></span
                        >
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-center"><strong>Total Earning</strong></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-center">{{ totalEarnings }}</td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>
    </div>
</div>
