import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { COMMON_DATA } from "src/app/shared/common";
import clevertap from "clevertap-web-sdk";

@Component({
  selector: 'app-psychological-distress-test',
  templateUrl: './psychological-distress-test.component.html',
  styleUrls: ['./psychological-distress-test.component.css']
})
export class PsychologicalDistressTestComponent implements OnInit {
  
  freeReportForm: FormGroup;
  userForm2: FormGroup;
  hideModal = true;
  firstPart: boolean = true;
  secondPart: boolean = false;
  currentIndex = 0;
  answers = COMMON_DATA.psychological_distress_options;
  questions = COMMON_DATA.psychological_distress_questions;
  progressPercentage = 0;
  userCountryCode = {
    countryCode: "in",
    dialCode: "91"
  };
  q3Removed = false;
  q6Removed = false;
  constructor(
    private reportService: FreeReportService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) { }
  checkError(valid) {
    if (!valid) {
      this.userForm2.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  previousPage() {
    if((this.freeReportForm.value['queNo5'] === '1' && this.currentIndex === 6) || (this.freeReportForm.value['queNo2'] === '1' && this.currentIndex ===3)){
      this.currentIndex -=2;
    } else {
      this.currentIndex--;
    }
  }
  currentIndexInc() {
    if(this.freeReportForm.value['queNo2'] === '1' && !this.q3Removed){
      this.currentIndex+=2;
      this.freeReportForm.patchValue({
        queNo3 : '1'
      })
      this.q3Removed = true;
    } else if(this.freeReportForm.value['queNo5'] === '1' && !this.q6Removed){
      this.currentIndex+=2;
      this.freeReportForm.patchValue({
        queNo6 : '1'
      })
      this.q6Removed = true;
    } else {
      this.currentIndex++;
    }
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction1 = function () {
    this.firstPart = true;
    this.secondPart = false;
  };
  ngOnInit() {
    this.initForms();
    this.initForms2();
    if (clevertap) {
      clevertap.event.push("Free Report Started", {
        "Report Name": "Psychological Distress"
      })
    }
  }

  showModal() {
    if (this.freeReportForm.invalid) {
      return;
    }
    this.hideModal = false;
  }

  onSubmit() {
    if (this.userForm2.invalid) {
      return;
    }
    let ansObj = {
      questionsAttempted: this.freeReportForm.value,
      ...this.userForm2.value,
      countryCode: this.userCountryCode
    };
    clevertap.profile.push({
      "Site": {
        "Name": this.userForm2.value.fullname,
        "Email": this.userForm2.value.email,
        "Phone": "+" + this.userCountryCode.dialCode + this.userForm2.value.phone
      }
    })
    this.reportService.generateReport(ansObj, "psychological-distress").subscribe((data) => {
      this.hideModal = true;
      this.router.navigate(["../free-report/psychological-distress-test-results/" + data["freeReportId"]]);
    });
  }

  initForms() {
    let group = {};
    this.questions.forEach((input_template, index) => {
      group[input_template["label"]] = new FormControl("", Validators.required);
    });
    this.freeReportForm = new FormGroup(group);
    this.freeReportForm.valueChanges.subscribe((data) => {
      let answers = 0;
      Object.values(data).forEach((value) => {
        if (value !== '') {
          answers++;
        }
      })
      this.progressPercentage = (answers / Object.values(data).length) * 100;
    })
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  initForms2() {
    this.userForm2 = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl("", Validators.required),
      fullname: new FormControl("", Validators.required),
    });
  }
  indexTracker(index: number, value: any) {
    return index;
  }
}
