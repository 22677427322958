<div (click)="closenavbar()" class="topMenuMargin">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <i class="fa fa-exclamation-triangle"></i
            ><strong> Disclamer: </strong>This tool should be used for screening
            and monitoring symptom severity and cannot replace a clinical
            assessment and diagnosis. You are encouraged to share these results
            with a mental health professional for a better understanding.
        </p>
        <div class="report-user mt-3">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p><strong>Your Result:</strong></p>
            <p>
                According to your score, it is likely that you exhibit signs of
                <span>{{ angryFinal }}</span> clinical anger.
            </p>
            <br />
            <p><strong>Your Score:</strong></p>
            <p>
                Your Total Score is: {{ angryScore }}
                <span class="scrollTxt" (click)="scrollToElem('target')"
                    >Detailed Analysis</span
                >
            </p>
            <!-- <button type="button" class="btn-report" (click)="reportData()">
        Email Report
      </button> -->
        </div>
    </div>
    <div class="container graph-section mt-3">
        <div>
            <div>
                <p class="mb-3">
                    Seeing the report can be overwhelming, but Please Coach has
                    got you covered. We offer a variety of services that you can
                    access at any time, anywhere.
                </p>
            </div>
            <h5>Understanding Your Anger Assessment</h5>
            <div class="graph-right">
                <img src="../../../assets/images/anger-test-graph.webp" />
            </div>
        </div>
    </div>
    <div class="container mb-4">
        <div class="about-scores report-user">
            <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
            <p class="mb-3">
                The possible range of scores is 0 to 63, 0 indicates ‘no anger
                at all’ and 63 indicates ‘severe anger’.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="angryScore >= 0 && angryScore <= 13"
            >
                A score of 0 to 13 indicates minimal display of anger. With this
                score, you might experience anger but the expression of it is at
                a negligible level. You might feel slightly in control of your
                anger which helps in minimal expression.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="angryScore >= 14 && angryScore <= 28"
            >
                A score of 14 to 19 indicates mild display of anger. The
                intensity of anger is mild with this score and it means that
                your expression of it is slightly higher than negligible. Using
                mindfulness techniques like mindful walking, expressing through
                art, and more along with meditation can help you feel relaxed
                and calmer.
            </p>
            <p
                class="para_scores mb-3"
                id="target"
                *ngIf="angryScore >= 20 && angryScore <= 28"
            >
                A score of 20 to 28 indicates moderate experience of anger. At
                this point, you might feel like the anger is getting out of
                control and that this is all your fault. You can get in touch
                with a mental health professional at Please Coach who can help
                you understand the cause of your anger and help you develop
                healthier ways of expressing it.
            </p>
            <p
                class="para_scores mb-3"
                id="target"
                *ngIf="angryScore >= 29 && angryScore <= 63"
            >
                A score of 29 to 63 indicates severe experience of anger. This
                is a very high score and it can feel extremely uncomfortable for
                you. At this point, anger has seeped into all or most facets of
                your life including personal, professional, social,
                interpersonal etc. You can get in touch with a mental health
                professional at Please Coach who can help you understand the
                cause of your anger and help you develop healthier ways of
                expressing it.
            </p>
        </div>
    </div>
    <div class="container about-scores">
        <div class="report-user">
            <h3 class="">Detailed Analysis</h3>
            <p class="mb-3">
                This scale measures clinical anger and has severity ranked in
                terms of scores. Clinical Anger Scale (CAS) measures one single
                factor - anger. It can be anger about the future, anger about
                failure, anger now, anger about things, annoying others, angry
                about self, angry hostile feelings, decision interference,
                alienating others, angry misery, wanting to hurt others,
                shouting at people, social interference, irritated now, work
                interference, sleep and appetite disturbances, heath, thinking
                and sexual interference. Anger can be for any of these reasons.
            </p>
            <p class="mb-3">
                It can get very frustrating to deal with all of these and Please
                Coach would like to offer support to help you get out of it in a
                healthy manner.
            </p>
        </div>
    </div>
    <div class="container scores-section">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
    <br />
</div>
