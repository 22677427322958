<div class="secPadding marginExtra" (click)="closenavbar()">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <div class="topImageHolder">
                    <img
                        src="../../../../assets/images/discussion forum.svg"
                        class="img-fluid"
                        draggable="false"
                    />
                </div>
            </div>
            <div class="col-md-8 extraCss">
                <div class="secondaryHeading">
                    <h2><b>India’s #1 Mental Health Support Community</b></h2>
                    <hr />
                    <h3 class="fontSize">
                        <b>Ask, comment and share anonymously.</b>
                    </h3>
                </div>
            </div>
            <div class="col-md-2 extraCss">
                <div class="askAnonButton">
                    <button
                        type="submit"
                        (click)="askQuestion()"
                        class="buttonTheme1"
                        data-toggle="modal"
                        data-target="#myModal"
                    >
                        + &nbsp;&nbsp;Start a new discussion
                    </button>
                </div>
            </div>
        </div>
        <div class="hrDivTag">
            <hr />
        </div>
        <br />
        <div class="mobileview">
            <div class="owl-carousel">
                <carousel
                    id="carousel"
                    [itemsPerSlide]="1"
                    [interval]="5000"
                    [showIndicators]="false"
                    class="wide-carousel"
                >
                    <slide class="sliderItem"
                        ><div class="exploreBoxWrap wrapcss">
                            <div class="categoryBoxHeader">
                                <h3><b>Ask a Psychologist</b></h3>
                            </div>
                            <div class="exploreImageBox">
                                <img
                                    src="../../../../assets/images/websitehome-psychiatricconsultation.png"
                                    draggable="false"
                                    class="img-fluid imageCss"
                                />
                            </div>
                            <div class="wrapContent">
                                <div class="pTagText">
                                    <p>
                                        Have something on your mind? Let Our
                                        Coach answer your queries.
                                    </p>
                                </div>
                                <div class="sectionHeading1">
                                    <button
                                        type="submit"
                                        (click)="askQuestion()"
                                        class="thm-2 classThm"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        ASK NOW !
                                    </button>
                                </div>
                            </div>
                        </div></slide
                    >
                    <slide class="sliderItem">
                        <div class="exploreBoxWrap1 wrapcss">
                            <div class="exploreImageBox">
                                <img
                                    src="../../../../assets/images/during-theraphy.svg"
                                    draggable="false"
                                    class="img-fluid imageCss"
                                />
                            </div>
                            <br />
                            <div class="wrapContent">
                                <div class="pTagText1 paddingForPtag">
                                    <p>
                                        <b
                                            >Counselling sessions with
                                            best-in-class psychologists.</b
                                        >
                                    </p>
                                </div>
                                <div
                                    class="sectionHeading1 paddingInSectionHeading"
                                >
                                    <a
                                        routerLink="../online-counseling-psychologists/plans-pricing"
                                        class="thm-2 classThm"
                                        >BOOK NOW !</a
                                    >
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-3">
                <div class="categoryBoxHeader margin">
                    <h3><b>Categories</b></h3>
                    <hr />
                </div>
                <div>
                    <ul class="categoryList">
                        <label *ngFor="let category of categories">
                            <button
                                type="button"
                                name="category"
                                class="thm-3 extraThm-3"
                                [checked]="selectedCategory == category._id"
                                (click)="setCategory(category._id)"
                                [ngClass]="{
                                    'thm-1': selectedCategory == category._id
                                }"
                            >
                                <span>{{ category.name }}</span>
                            </button>
                        </label>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-9">
                <div class="margin">
                    <fa-icon [icon]="faCommentAlt" class="faicon"></fa-icon>
                    <p class="alignLeft">&nbsp;&nbsp;Latest Discussions</p>
                    <!-- <p class="alignRight">Filter by v</p> -->
                </div>
                <div style="clear: both"></div>
                <div
                    class="exploreBox css"
                    *ngFor="let forum of items; let idx = index"
                >
                    <div class="hideDesktop">
                        <div class="padding">
                            <div class="forumBox">
                                <div *ngIf="forum.userId">
                                    <div class="imgBox">
                                        <img
                                            src="{{ forum?.userId?.imageUrl }}"
                                            draggable="false"
                                            class="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div *ngIf="forum.therapistId">
                                    <div class="imgBox">
                                        <img
                                            src="{{
                                                forum?.therapistId?.imageUrl
                                            }}"
                                            draggable="false"
                                            class="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="forumText1" *ngIf="forum.userId">
                                <p style="margin-bottom: 0px">
                                    Posted By
                                    <span class="forumTexta">{{
                                        forum.userId.name
                                    }}</span>
                                </p>
                            </div>
                            <div class="forumText1" *ngIf="forum.therapistId">
                                <p style="margin-bottom: 0px">
                                    Posted By
                                    <span class="forumTexta">{{
                                        forum.therapistId.firstname
                                    }}</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="fontSizeColor">
                                {{
                                    forum.createdAt
                                        | date : 'hh:mm a, dd MMMM yyyy '
                                }}
                            </p>
                        </div>
                    </div>

                    <a routerLink="../view-discussion/{{ forum._id }}">
                        <div class="forumBox">
                            <div class="forumContentBox">
                                <span class="tagsHolder-df">
                                    <span
                                        class="selected"
                                        *ngFor="let select of forum.tags"
                                    >
                                        {{ select.name }}
                                    </span>
                                </span>
                                <div class="forumText">
                                    <h5 class="h5Tag">
                                        <b> {{ forum.topic }}</b>
                                    </h5>
                                </div>
                                <div
                                    [innerHTML]="
                                        forum.description | safe : 'html'
                                    "
                                    class="forumText"
                                    style="display: block"
                                ></div>
                            </div>
                        </div>
                        <div class="hrDivTag1">
                            <hr class="hrClass" /></div
                    ></a>
                    <div class="row">
                        <div class="col-md-7">
                            <p class="alignLeft lastPart likeShareSection">
                                <span
                                    (click)="toggleLike(forum._id)"
                                    class="like-btn"
                                    [ngClass]="{ 'like-active': forum.isLiked }"
                                ></span>
                                <span class="heartText"
                                    >{{ forum.likes }} Like<span
                                        *ngIf="forum.likes > 1"
                                        >s</span
                                    >&nbsp;&nbsp;<fa-icon
                                        [icon]="faCommentAlt"
                                        class="faiconinside"
                                    ></fa-icon
                                    >&nbsp;&nbsp;{{
                                        forum.replies
                                    }}
                                    Comment<span *ngIf="forum.replies > 1"
                                        >s</span
                                    ></span
                                >
                            </p>
                        </div>
                        <div class="col-md-5 padding">
                            <div class="row">
                                <div class="col-md-2 padding hideMobile">
                                    <div class="forumBox">
                                        <br />
                                        <div *ngIf="forum.userId">
                                            <div class="imgBox">
                                                <img
                                                    src="{{
                                                        forum?.userId?.imageUrl
                                                    }}"
                                                    class="img-fluid"
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                        <div *ngIf="forum.therapistId">
                                            <div class="imgBox">
                                                <img
                                                    src="{{
                                                        forum?.therapistId
                                                            ?.imageUrl
                                                    }}"
                                                    class="img-fluid"
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7 paddingPost hideMobile">
                                    <div
                                        class="forumText1"
                                        *ngIf="forum.userId"
                                    >
                                        <p style="margin-bottom: 0px">
                                            Posted By
                                            <span class="forumTexta">{{
                                                forum.userId.name
                                            }}</span>
                                        </p>
                                    </div>
                                    <div
                                        class="forumText1"
                                        *ngIf="forum.therapistId"
                                    >
                                        <p style="margin-bottom: 0px">
                                            Posted By
                                            <span class="forumTexta">{{
                                                forum.therapistId.firstname
                                            }}</span>
                                        </p>
                                    </div>
                                    <p class="fontSizeColor">
                                        {{
                                            forum.createdAt
                                                | date
                                                    : 'hh:mm a, dd MMMM yyyy '
                                        }}
                                    </p>
                                </div>
                                <div class="col-md-2 paddingShare">
                                    <i
                                        (click)="copyUrl(forum)"
                                        class="fa fa-share-alt fa-2x"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center loadMoreText" *ngIf="!isLoading">
                    <a *ngIf="showNext" (click)="nextPage()">
                        Load More Discussions
                    </a>
                </div>
            </div>
            <div class="col-lg-3 inverseMobile pcview">
                <div class="exploreBoxWrap wrapcss">
                    <div class="categoryBoxHeader">
                        <h3><b>Ask a Psychologist</b></h3>
                    </div>
                    <div class="exploreImageBox">
                        <img
                            src="../../../../assets/images/websitehome-psychiatricconsultation.png"
                            draggable="false"
                            class="img-fluid imageCss"
                        />
                    </div>
                    <div class="wrapContent">
                        <div class="pTagText">
                            <p>
                                Have something on your mind? Let Our Coach
                                answer your queries.
                            </p>
                        </div>
                        <div class="sectionHeading1 sideBannerCss">
                            <button
                                type="submit"
                                (click)="askQuestion()"
                                class="thm-2 classThm sideBannerCss"
                                data-toggle="modal"
                                data-target="#myModal"
                            >
                                ASK NOW !
                            </button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="exploreBoxWrap1 wrapcss">
                    <div class="exploreImageBox">
                        <img
                            src="../../../../assets/images/during-theraphy.svg"
                            draggable="false"
                            class="img-fluid imageCss"
                        />
                    </div>
                    <br />
                    <div class="wrapContent">
                        <div class="pTagText1 paddingForPtag">
                            <p>
                                Counselling sessions with best-in-class
                                psychologists.
                            </p>
                        </div>
                        <div
                            class="sectionHeading1 paddingInSectionHeading sideBannerCss"
                        >
                            <a
                                *ngIf="userLoggedin && isDashboardRedirect"
                                routerLink="../user-dashboard"
                                class="thm-2 classThm"
                                >BOOK NOW !</a
                            >
                            <a
                                *ngIf="userLoggedin && !isDashboardRedirect"
                                routerLink="../online-counseling-psychologists/plans-pricing"
                                class="thm-2 classThm"
                                >BOOK NOW !</a
                            >
                            <a
                                *ngIf="!userLoggedin"
                                [routerLink]="['../sign-up']"
                                [queryParams]="{
                                    redirect:
                                        '/online-counseling-psychologists/plans-pricing'
                                }"
                                class="thm-2 classThm"
                                >BOOK NOW !</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="myModal" *ngIf="!hideModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="formRound">
                        <form
                            class="extraPadding"
                            [formGroup]="discussionForm"
                            name="discussionForm"
                        >
                            <div class="eachOptionsDivTag">
                                <h3>What is it about?</h3>
                                <div class="sectionWrap">
                                    <input
                                        type="text"
                                        id="topic"
                                        name="topic"
                                        formControlName="topic"
                                        class="form-control"
                                        placeholder="Enter a topic of your choice"
                                        required="required"
                                        [ngClass]="{
                                            'has-error':
                                                !discussionForm.controls.topic
                                                    .valid &&
                                                discussionForm.controls.topic
                                                    .touched,
                                            'has-success':
                                                discussionForm.controls.topic
                                                    .valid &&
                                                discussionForm.controls.topic
                                                    .touched
                                        }"
                                    />
                                    <div
                                        *ngIf="
                                            discussionForm.controls.topic.errors
                                                ?.required &&
                                            discussionForm.controls.topic
                                                .touched
                                        "
                                        class="errorContainer"
                                    >
                                        <p>Topic is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="eachOptionsDivTag extraCssforcategory">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="insideFormDivTags">
                                            <h3>Choose Tags</h3>
                                            <ng-select
                                                formControlName="tags"
                                                class="form-control"
                                                [multiple]="true"
                                                [selectableGroup]="true"
                                                [closeOnSelect]="false"
                                                [selectableGroup]="true"
                                                [ngClass]="{
                                                    'has-error':
                                                        !discussionForm.controls
                                                            .tags.valid &&
                                                        discussionForm.controls
                                                            .tags.touched,
                                                    'has-success':
                                                        discussionForm.controls
                                                            .tags.valid &&
                                                        discussionForm.controls
                                                            .tags.touched
                                                }"
                                            >
                                                <ng-option
                                                    *ngFor="
                                                        let category of categories
                                                    "
                                                    [value]="category._id"
                                                    >{{
                                                        category.name
                                                    }}</ng-option
                                                >
                                            </ng-select>
                                            <div
                                                *ngIf="
                                                    discussionForm.controls.tags
                                                        .errors?.required &&
                                                    discussionForm.controls.tags
                                                        .touched
                                                "
                                                class="errorContainer"
                                            >
                                                <p>
                                                    Please select minium one tag
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="eachOptionsDivTag">
                                    <h3>Description</h3>
                                    <div class="sectionWrap editor">
                                        <ngx-editor-menu [editor]="editor">
                                        </ngx-editor-menu>
                                        <ngx-editor
                                            [editor]="editor"
                                            [placeholder]="'Type here...'"
                                            formControlName="description"
                                            [ngClass]="{
                                                'has-error':
                                                    !discussionForm.controls
                                                        .description.valid &&
                                                    discussionForm.controls
                                                        .description.touched,
                                                'has-success':
                                                    discussionForm.controls
                                                        .description.valid &&
                                                    discussionForm.controls
                                                        .description.touched
                                            }"
                                        ></ngx-editor>
                                    </div>
                                    <div
                                        *ngIf="
                                            discussionForm.controls.description
                                                .errors?.required &&
                                            discussionForm.controls.description
                                                .touched
                                        "
                                        class="errorContainer"
                                    >
                                        <p>Description is required</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="modal-footer">
                        <div class="anonymousBox" [formGroup]="discussionForm">
                            <input
                                type="checkbox"
                                formControlName="isAnonymous"
                            />
                            Post Anonymously
                        </div>
                        <div class="forumBox">
                            <br />
                            <div class="imgBox">
                                <img
                                    src="../../../../assets/images/user.png"
                                    class="img-fluid"
                                    draggable="false"
                                />
                            </div>
                        </div>
                        &nbsp;
                        <div class="submitbtnn text-center">
                            <button
                                (click)="onSubmit()"
                                data-dismiss="modal"
                                [disabled]="!discussionForm?.valid"
                                class="thm-2"
                            >
                                Post
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
