<div class="topMenuMargin">
  <div class="container">
    <div class="row">
      <div class="secondaryHeading">
        <h2>Email Verification</h2>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="loading-section" *ngIf="status === 'loading'">
        <div class="spinner-border" style="
          width: 2rem;
          height: 2rem;
        " role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p>Please wait while we are verifying your account...</p>
      </div>
      <div class="loading-section" *ngIf="status === 'error'">
        <p>Failed to verify your account. Please try again.</p>
      </div>
      <div class="loading-section" *ngIf="status === 'verified'">
        <p>Email verified sucessfully!</p>
        <p>Redirecting to Login in <strong>{{countDown}} seconds</strong> or click <a href="#">here.</a></p>
      </div>
    </div>
  </div>
</div>