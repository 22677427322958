import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { NotificationService } from "../notification.service";
import { ProfileService } from "../profile.service";
// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.css"],
})
export class UserDashboardComponent implements OnInit, AfterViewInit {
  userData;
  isDashboard = false;
  isSideNav = true;
  newNotifications = 0;
  isMeetingSection = false
  isChatTab = false;
  isHeaderActions = true
  constructor(
    private authService: AuthService,
    private router: Router,
    private sideNavService: SideNavBarService,
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private metaSeo: SeoMetaService,
    // private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    // let deviceInfo = this.deviceService.getDeviceInfo();
    // console.log(deviceInfo)
    this.checkRoute();
    this.metaSeo.updateTitle("Online Counseling | PleaseCoach");
    this.router.events.subscribe((route) => {
      this.hideSalesIQ();
      this.checkRoute();
      this.metaSeo.updateTitle("Online Counseling | PleaseCoach")
    });
    this.sideNavService.sideNavBarListener().subscribe((toggle) => {
      this.isSideNav = toggle;
    });
    this.sideNavService.dashboardsideNavAndHeader().subscribe((toggle) => {
      this.isHeaderActions = toggle;
    });
    this.notificationService.getUnreadNotifications().subscribe((data) => {
      this.newNotifications = data["count"];
    });
    this.profileService.getProfile().subscribe((profile) => {
      if (!profile?.phone) {
        this.router.navigateByUrl("/complete-profile");
      }
    });
    setTimeout(() => {
      if (window["$zoho"]) {
        if (window["$zoho"].salesiq) {
          window["$zoho"].salesiq.floatbutton.visible("hide");
        }
      }
      if (window["kiwi"]) {
        window["kiwi"]["hide"]();
      }
    }, 2000)
  }

  ngAfterViewInit() {
    this.hideSalesIQ();
  }

  logOut() {
    this.authService.logout();
  }

  closeSidenav() {
    this.sideNavService.closesidenav();
  }

  checkRoute() {
    if (this.router.url.includes('user-dashboard/session-join-user')) {
      this.isMeetingSection = true;
    }
    else {
      this.isMeetingSection = false
    }
    if (this.router.url == "/user-dashboard/dashboard") {
      this.isDashboard = true;
    } else {
      this.isDashboard = false;
    }
    if (this.router.url == "/user-dashboard/my-chats") {
      this.isChatTab = true;
    } else {
      this.isChatTab = false;
    }
  }

  hideSalesIQ() {
    window["$zoho"].salesiq.ready = function () {
      window["$zoho"].salesiq.floatbutton.visible("hide");
    };
    window.onload = (event) => {
      window["kiwi"]["hide"]();
    };
  }
}
