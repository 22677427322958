<div class="eachOptionsDivTag">
    <div style="display: flex; align-items: center; margin-bottom: 20px">
        <span
            ><a
                routerLink="../../client-notes"
                style="
                    font-size: 16px;
                    color: #445e93;
                    cursor: pointer;
                    text-decoration: underline;
                "
                >Client Notes</a
            ></span
        >
        <span style="font-size: 16px">&nbsp;>&nbsp;</span>
        <span
            ><a
                routerLink="../../client-notes-view/{{ userId }}"
                style="
                    font-size: 16px;
                    color: #445e93;
                    cursor: pointer;
                    text-decoration: underline;
                "
                >{{ userName }}'s Notes</a
            ></span
        >
        <span style="font-size: 16px">&nbsp;>&nbsp;</span>
        <span style="font-size: 16px; color: #808080">Add Note</span>
    </div>
    <h3 *ngIf="noteId == null">Add note</h3>
    <h3 *ngIf="noteId != null && isEditable">Edit this note</h3>
    <h3 *ngIf="noteId != null && isEditable === false">
        Note added by - {{ notesData?.therapistId?.firstname }}
        {{ notesData?.therapistId?.lastname }}
    </h3>
</div>
<fieldset [disabled]="noteId != null && isEditable === false">
    <div class="sectionWrap" *ngIf="formType === 'old-data'">
        <form [formGroup]="addNoteForm" name="userForm">
            <div
                class="viewNote"
                *ngIf="mode == 'view' && formType === 'old-data'"
                [innerHtml]="addNoteForm.value.notes | safe : 'html'"
            ></div>
            <ngx-editor-menu
                [editor]="editor"
                *ngIf="noteId != null && isEditable"
            >
            </ngx-editor-menu>
            <ngx-editor
                *ngIf="noteId != null && isEditable"
                [editor]="editor"
                [placeholder]="'Type here...'"
                formControlName="notes"
                [ngClass]="{
                    'has-error':
                        !addNoteForm.controls.notes.valid &&
                        addNoteForm.controls.notes.touched,
                    'has-success':
                        addNoteForm.controls.notes.valid &&
                        addNoteForm.controls.notes.touched
                }"
            ></ngx-editor>
        </form>
    </div>
    <div
        class="sectionWrap"
        *ngIf="formType === 'first' || formType === 'later'"
    >
        <div *ngIf="formType === 'first'" class="formRound">
            <form [formGroup]="firstNoteForm">
                <div class="eachOption">
                    <h5>📅 Session Date</h5>
                    <div *ngIf="notesData.sessionDate">
                        <input
                            type="text"
                            class="form-control"
                            formControlName="sessionDate"
                            *ngIf="notesData.sessionDate"
                        />
                    </div>
                    <div *ngIf="!notesData.sessionDate">
                        <select
                            class="form-control"
                            formControlName="sessionDate"
                        >
                            <option
                                *ngFor="let session of sessionsDate"
                                value="{{ session._id }}"
                            >
                                {{ session.date }}
                            </option>
                        </select>
                    </div>
                </div>
                <h4>Primary Concern</h4>
                <div class="eachOption">
                    <div>
                        <textarea
                            class="form-control"
                            placeholder="Type here..."
                            formControlName="issues"
                        ></textarea>
                    </div>
                </div>
                <h4>Case History</h4>
                <div class="eachOption">
                    <div>
                        <textarea
                            class="form-control"
                            placeholder="Type here..."
                            formControlName="history"
                        ></textarea>
                    </div>
                </div>
                <h4>Therapy Goals</h4>
                <div class="eachOption">
                    <div>
                        <textarea
                            class="form-control"
                            placeholder="Type here..."
                            formControlName="therapyGoals"
                        ></textarea>
                    </div>
                </div>
                <h4>Refer to another Psychologist or Psychiatrist</h4>
                <div class="eachOption">
                    <select class="form-control" formControlName="refer">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <h4 *ngIf="firstNoteForm.value.refer === 'Yes'">
                    Note for the next professional
                </h4>
                <div
                    class="eachOption"
                    *ngIf="firstNoteForm.value.refer === 'Yes'"
                >
                    <textarea
                        class="form-control"
                        placeholder="Type here..."
                        formControlName="notesForNext"
                    ></textarea>
                </div>
                <h4 *ngIf="firstNoteForm.value.refer === 'No'">
                    Suggested Next Session Date
                </h4>
                <div
                    class="eachOption"
                    *ngIf="firstNoteForm.value.refer === 'No'"
                >
                    <div>
                        <input
                            type="text"
                            placeholder="Select a date"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="bsConfig"
                            formControlName="dateForNextSession"
                        />
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="formType === 'later'" class="formRound">
            <form [formGroup]="laterNoteForm">
                <div class="eachOption">
                    <h5>📅 Session Date</h5>
                    <div *ngIf="notesData.sessionDate">
                        <input
                            type="text"
                            class="form-control"
                            formControlName="sessionDate"
                            *ngIf="notesData.sessionDate"
                        />
                    </div>
                    <div *ngIf="!notesData.sessionDate">
                        <select
                            class="form-control"
                            formControlName="sessionDate"
                        >
                            <option
                                *ngFor="let session of sessionsDate"
                                value="{{ session._id }}"
                            >
                                {{ session.date }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="eachOption">
                    <h5>Changes since the last session</h5>
                    <div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type here..."
                            formControlName="changesSince"
                        />
                    </div>
                </div>
                <div class="eachOption">
                    <h5>Work done</h5>
                    <div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type here..."
                            formControlName="goal"
                        />
                    </div>
                </div>
                <div class="eachOption">
                    <h5>Plans for the next session</h5>
                    <div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type here..."
                            formControlName="progress"
                        />
                    </div>
                </div>
                <h4>Refer to another Psychologist or Psychiatrist</h4>
                <div class="eachOption">
                    <select class="form-control" formControlName="refer">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <h4 *ngIf="laterNoteForm.value.refer === 'Yes'">
                    Note for the next professional
                </h4>
                <div
                    class="eachOption"
                    *ngIf="laterNoteForm.value.refer === 'Yes'"
                >
                    <textarea
                        class="form-control"
                        placeholder="Type here..."
                        formControlName="notesForNext"
                    ></textarea>
                </div>
                <h4 *ngIf="laterNoteForm.value.refer === 'No'">
                    Suggested Next Session Date
                </h4>
                <div
                    class="eachOption"
                    *ngIf="laterNoteForm.value.refer === 'No'"
                >
                    <div>
                        <input
                            type="text"
                            placeholder="Select a date"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="bsConfig"
                            formControlName="dateForNextSession"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</fieldset>
<div
    class="form-group extraCss"
    *ngIf="!(noteId != null && isEditable === false)"
>
    <button type="submit" (click)="formSubmission()" class="btn btn-warning">
        Save
    </button>
</div>
