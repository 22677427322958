import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { BootstrapAlert, BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { FreeReportService } from "../../free-report.service";
import clevertap from "clevertap-web-sdk";
import { ViewportScroller } from "@angular/common";
@Component({
  selector: 'app-self-esteem-test-results',
  templateUrl: './self-esteem-test-results.component.html',
  styleUrls: ['./self-esteem-test-results.component.css']
})
export class SelfEsteemTestResultsComponent implements OnInit {

  reseResult = "";
  report;
  reseScore;
  itemId;
  answersArray = []
  constructor(
    private confirmationService: ConfirmationDialogService,
    private sidenavService: SideNavBarService,
    private freeReportService: FreeReportService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private viewPortScroller: ViewportScroller
  ) {
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit(): void {
    this.actRoute.paramMap.subscribe((params) => {
      this.itemId = params.get("id");
      this.freeReportService.getReport(this.itemId).subscribe((res) => {
        this.report = res["report"]
        this.reseScore = res["report"]["reportDetails"]["score"];
        this.answersArray = Object.values(this.report.reportDetails.answers);
        if (clevertap) {
          clevertap.event.push("Free Report Ended", {
            "Report Name": "RESE Assessment",
            "Score": this.reseScore
          })
        }
        this.relationshipFinalcal();
      });
    });
  }
  reportData() {
    let reqObj = {
      id: this.itemId,
      reportType: 'relationship'
    }
    this.freeReportService.sendReportToEmail(reqObj).subscribe((res) => {
      if (res) {
        this.alertService.alert(
          new BootstrapAlert('You will shorly recieve free report in your email', "alert-success")
        );
      }
    })
  }
  relationshipFinalcal() {
    if (this.reseScore >= 0 && this.reseScore <= 15) {
      this.reseResult = "Seeing the report can be overwhelming, but pleasecoach has got you covered. We offer a variety of services that you can access at any time, anywhere.";
    } else if (this.reseScore >= 15 && this.reseScore <= 25) {
      this.reseResult = "Your result lies within a normal range, which is optimum for maintaining a healthy lifestyle.";
    } else {
      this.reseResult = "Your results may not be an imminent sign of concern, but read on to find out a more detailed analysis of your score as well as a few suggestions from pleasecoach that you can always use to improve your lifestyle.";
    }
  }

  scrollToElem(el) {
    this.viewPortScroller.scrollToAnchor(el);
  }

}
