import { Component, OnInit, ViewChild } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { OurCounsellorsService } from "../../our-counsellors.service";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { environment } from "src/environments/environment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { COMMON_DATA } from "../../../shared/common";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { faChevronLeft, faChevronRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { debounce } from 'lodash';
import clevertap from "clevertap-web-sdk";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SingleButtonAlertService } from "src/app/single-button-alert.service";



@Component({
  selector: "app-community-talk-therapists",
  templateUrl: "./community-talk-therapists.component.html",
  styleUrls: ["./community-talk-therapists.component.css"],
})
export class CommunityTalkTherapistsComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  therapists = [];
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faStar = faStar;
  queryStringDate
  isOldDate = true
  isDateFilterEnabled = false
  pageNo = 1;
  pages = [1];
  nextAvailableAt;
  isShimmerLoader = true;
  showNext = false;
  totalCount = 0;
  isLoading = false;
  skip = 0;
  perPage = 6;
  isLoggedIn = false;
  imageUrl = environment.apiUrl;
  userTimezone = "Asia/Calcutta";
  isFirstLoad = true;
  isFirstLoad2 = true;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  slots = COMMON_DATA.TimeSlott;
  therapyTypes = COMMON_DATA.therapyType;
  specializeArea = COMMON_DATA.specializeareasOptions;
  languages = COMMON_DATA.language;
  today = new Date();
  isQueryParams = false;
  languagePlaceHolder = 'Select a language';
  userReviews = [
    {
      text: "Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.",
      name: "Sana Javed",
      work: "Software Engineer",
    },
    {
      name: "Naina Kashyap",
      work: "Mumbai",
      text: "When I finally held my baby in my arms after 9 months of waiting and expecting, I didn’t really feel flooded with love or attachment. To the contrary I felt overwhelmed and despondent. When I reached out to pleasecoach, I was full of guilt and shame for feeling this way. My pleasecoach therapist helped me understand that a biochemical shift in my brain was causing my despair. Within a few sessions she pulled me out of this really dark place & helped me find joy in my motherhood."
    },
    {
      text: "I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
      name: "Puja Mittal",
      work: "Homemaker",
    },
    {
      text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at pleasecoach who made helped me understand & treat my depression.",
      name: "Vikas Arora",
      work: "CFO",
    },
    {
      text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
      name: "Aishwarya Hegde",
      work: "Student",
    },
  ];
  testimonialsSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
    ]
  }

  constructor(
    private counsellorService: OurCounsellorsService,
    private sidenavService: SideNavBarService,
    private authService: AuthService,
    private router: Router,
    private metaSeo: SeoMetaService,
    private activatedRoute: ActivatedRoute,
    private singleButtonAlertService: SingleButtonAlertService,
  ) {
    this.getCounsellorsDebounce = debounce(this.getCounsellors, 1200);
  }


  ngOnInit(): void {

    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.isShimmerLoader = true;
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      date: new FormControl(""),
      slot: new FormControl(""),
      specialization: new FormControl(""),
      language: new FormControl("")
    });
    this.languages.shift();

    this.metaSeo.updateTitle(
      "Online Counseling | PleaseCoach"
    );
    this.metaSeo.updateMetaInfo(
      "Find a therapist who will help you heal. Answer a few quick questions and our magic formula will match you to the best psychologist tailored for your needs.",
      "Psychologist near me, clinical psychologist near me, best psychologist, online counselor, online therapist, online psychologist"
    );
    if (localStorage.getItem("userTzcode")) {
      this.userTimezone = localStorage.getItem("userTzcode");
    } else {
      this.userTimezone = momentTz.tz.guess();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      let searchQuery = params["search"]?.replace('%20', ' ');
      let dateQuery = params["date"]?.replace('%20', ' ');
      let slotQuery = params["slot"]?.replace('%20', ' ');;
      let specializationQuery = params["specialization"]?.replace('%20', ' ');
      let languageQuery = params["language"]?.replace('%20', ' ');
      if (searchQuery) {
        this.isQueryParams = true
        this.searchForm.controls.search.setValue(searchQuery)
      }
      if (dateQuery) {
        this.isQueryParams = true
        this.queryStringDate = new Date(moment(dateQuery, 'DD_MM_YYYY HH:mm').toDate())
        this.searchForm.controls.date.setValue(this.queryStringDate)
        if (moment(this.queryStringDate).isBefore(moment()) && moment().format('DD_MM_YYYY') !== moment(this.queryStringDate).format("DD_MM_YYYY")) {
          this.isOldDate = true
          this.isDateFilterEnabled = false
          this.singleButtonAlertService
            .confirm(
              "This link has been expired",
              `The counsellor's record you're looking for ${moment(this.queryStringDate).format('DD MMM YYYY')} is already past! Please choose another date.`,
              "Close",
            )
          this.doResetFilter()
          this.queryStringDate = ''
          // .subscribe((res) => {
          //   if (res) {
          //     alert()
          //   }
          // });
        }
        else {
          this.isDateFilterEnabled = true
          this.searchForm.controls.date.setValue(this.queryStringDate)
        }
      }
      if (slotQuery) {
        this.isQueryParams = true
        this.searchForm.controls.slot.setValue(slotQuery)
      }
      if (specializationQuery) {
        this.isQueryParams = true
        this.searchForm.controls.specialization.setValue(specializationQuery)
      }
      if (languageQuery) {
        this.isQueryParams = true
        this.searchForm.controls.language.setValue(languageQuery)
      }
    })

    if (this.isQueryParams) {
      this.getCounsellors(true);
    }
    else {
      this.getCounsellors(false);
    }
    this.isLoggedIn = this.authService.getIsAuth();
  }
  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }
  nextSlideTestimonials() {
    this.slickModal1.slickNext();
  }
  prevSlideTestimonials() {
    this.slickModal1.slickPrev();
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  checkPage(isSearch = false) {
    if (isSearch && this.totalCount == this.therapists?.length) {
      this.showNext = false;
    } else {
      if (this.pageNo === this.pages[this.pages.length - 1]) {
        this.showNext = false;
      } else {
        this.showNext = true;
      }
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;

    this.pageNo = this.pageNo + 1;
    this.getCounsellors(false);
    this.checkPage();
  }


  doApplyFilter() {
    ;
    let isFilterEnabled = false
    let queryParams = {
    }

    if (this.searchForm.value.date) {
      isFilterEnabled = true
      let dateFilter = this.searchForm.value.date
      let formatDate = this.queryStringDate ? moment(this.queryStringDate).format("DD_MM_YYYY") : false
      if (this.queryStringDate && formatDate && formatDate == moment(dateFilter).format("DD_MM_YYYY")) {
        dateFilter = moment(this.queryStringDate).format("DD_MM_YYYY HH:mm")
      }
      else if (dateFilter !== '' && dateFilter !== null) {
        if (
          moment().format("DD_MM_YYYY") ===
          moment(dateFilter).format("DD_MM_YYYY")
        ) {
          // console.log(dateFilter)
          dateFilter = moment(dateFilter).format("DD_MM_YYYY HH:mm");
        } else {
          dateFilter = moment
            (dateFilter)
            .startOf("day")
            .format("DD_MM_YYYY HH:mm");
        }
      }
      this.isDateFilterEnabled = true

      queryParams = { date: dateFilter }
    }
    else {
      this.isDateFilterEnabled = false
    }

    if (this.searchForm.value.search) {
      queryParams = { ...queryParams, search: this.searchForm.value.search }
      isFilterEnabled = true
    }
    if (this.searchForm.value.language) {
      queryParams = { ...queryParams, language: this.searchForm.value.language }
      isFilterEnabled = true
    }
    if (this.searchForm.value.specialization) {
      queryParams = { ...queryParams, specialization: this.searchForm.value.specialization }
      isFilterEnabled = true
    }
    if (this.searchForm.value.slot) {
      queryParams = { ...queryParams, slot: this.searchForm.value.slot }
      isFilterEnabled = true
    }
    if (this.isQueryParams && !isFilterEnabled) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
        replaceUrl: true,
      });
      this.isShimmerLoader = true;
      this.getCounsellors(false)
    }
    if (isFilterEnabled) {
      // queryParams = {
      //   ...queryParams,
      //   sp: this.skip, lt: this.perPage
      // }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        replaceUrl: true,
      });
      this.getCounsellors(true)
    }
  }
  doResetFilter() {
    ;
    this.searchForm.controls.search.setValue('')
    this.searchForm.controls.date.setValue('')
    this.searchForm.controls.slot.setValue('')
    this.searchForm.controls.specialization.setValue('')
    this.searchForm.controls.language.setValue('')
    this.isQueryParams = false
    this.isOldDate = false
    this.queryStringDate = ''
    this.isDateFilterEnabled = false
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
    this.isShimmerLoader = true;
    this.getCounsellors(true)
  }
  getCounsellorsDebounce(isSearch) {
    ;
    if (this.searchForm.value.date) {
      this.isDateFilterEnabled = true
    }
    this.getCounsellors(isSearch)
  }
  getCounsellors(isSearch) {
    ;
    this.skip = (this.pageNo - 1) * this.perPage;
    let searchTerm = this.searchForm.value.search;
    let dateFilter = this.searchForm.value.date;
    //add utc 
    let currentDate = moment().utc().format('DD_MM_YYYY HH:mm')
    if (dateFilter !== '' && dateFilter !== null) {
      let formatDate = this.queryStringDate ? moment(this.queryStringDate).format("DD_MM_YYYY") : false
      if (this.queryStringDate && formatDate && formatDate == moment(dateFilter).format("DD_MM_YYYY")) {
        if (moment(dateFilter).format("DD_MM_YYYY") == moment().format("DD_MM_YYYY")) {
          dateFilter = currentDate
        }
        else {
          dateFilter = moment(this.queryStringDate).format("DD_MM_YYYY HH:mm")
        }
      }
      else if (
        moment().format("DD_MM_YYYY") ===
        moment(dateFilter).format("DD_MM_YYYY")
      ) {
        dateFilter = currentDate
        // dateFilter = moment(dateFilter).format("DD_MM_YYYY HH:mm");
      } else {
        dateFilter = moment
          (dateFilter)
          .startOf("day")
          .format("DD_MM_YYYY HH:mm");
      }
    }

    if (dateFilter === null) {
      dateFilter = ''
    }

    if (isSearch) {

      this.skip = 0;
      this.pageNo = 1;
      this.pages = [1];
      // if (this.skip > 0) {
      //   this.isLoading = true;
      // }
      this.isShimmerLoader = true;

      this.therapists = [];
    }
    let slotFilter = '';
    if (this.searchForm.value.slot !== '') {
      let slotTime = this.searchForm.value.slot.split(' ')[0]
      let dateObj = moment().startOf('day')
      dateObj.set({
        hour: slotTime
      })
      let isAm = this.searchForm.value.slot.split(' ')[1] === 'AM' ? true : false
      if (!isAm) {
        dateObj.add(12, 'hour')
      }
      slotFilter = dateObj.utc().format('HH:mm')
    }
    this.counsellorService
      .counsellorPagin(
        this.skip,
        this.perPage,
        searchTerm,
        dateFilter,
        slotFilter,
        currentDate,
        this.searchForm.value.language,
        this.searchForm.value.specialization
      )
      .subscribe((data) => {
        this.isLoading = false;
        this.isShimmerLoader = false;

        data["result"].forEach((therapist) => {
          if (therapist.specializeareas) {
            therapist.specializeareas = therapist.specializeareas.map(item => item.name)
          }
          therapist.specializeareasMore = []
          therapist.showMoreToggle = false;
          if (therapist.specializeareas) {
            if (therapist.specializeareas.length > 5) {
              therapist.specializeareasMore = therapist.specializeareas.splice(5, 5 + therapist.specializeareas.length)
              therapist.specializeareas = therapist.specializeareas.splice(0, 5)
            }
          }
          if (therapist.nextAvailableAt) {
            let utcDate = moment.utc(
              therapist.nextAvailableAt,
              "DD_MM_YYYY HH:mm"
            );
            therapist.nextAvailableAt = momentTz
              .tz(utcDate, this.userTimezone)
              .format("MMM D, YYYY h:mm A");
          }
        });
        if (isSearch) {
          this.therapists = data["result"];
        } else {
          let therapistsTemp = [...this.therapists]
          let key = "_id";
          therapistsTemp = therapistsTemp.concat(data["result"]);
          this.therapists = [...new Map(therapistsTemp.map(item =>
            [item[key], item])).values()];
        }

        // if (this.therapists.length > 0) {
        //   this.therapists.forEach((therapist) => {
        //     let specializeAreasArray = [];
        //     if (therapist.specializeareas) {
        //       therapist.specializeareas.forEach((element) => {
        //         specializeAreasArray.push(element?.name);
        //       });
        //       therapist.specializeareas = specializeAreasArray;
        //     }
        //   });
        // }
        this.totalCount = data["totalCount"];
        let pageCount = Math.ceil(this.totalCount / 6);
        if (pageCount != this.pages.length) {
          for (let i = 0; i < pageCount - 1; i++) {
            if (this.pages.indexOf(i + 2)) {
              this.pages.push(i + 2);
            }
          }
        }
        this.checkPage(isSearch);
      }, (err) => {
        this.isShimmerLoader = false;
      });
  }

  toggleShowMore(index) {
    ;
    this.therapists[index]["showMoreToggle"] = !this.therapists[index]["showMoreToggle"]
  }

  bookNow(therapistId) {
    ;
    if (this.authService.getIsAuth()) {
      this.router.navigate(["../view-counsellor-calendar/" + therapistId]);
    } else {
      this.router.navigateByUrl("/sign-up?therapist=" + therapistId);
    }
  }

  getRecommendation() {
    ;
    if (clevertap) {
      clevertap.event.push("Get Recommendation Clicked")
    }
  }

  openChat() {
    // ;
    // let chatElem = document.getElementById("zsiq_agtpic");
    // chatElem.click();
  }
}
