<div class="wrapPadding" (click)="closenavbar()">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="bodyPageWrap">
        <div style="display: flex; align-items: center; margin-bottom: 20px">
            <span
                ><a
                    routerLink="../profile"
                    style="
                        font-size: 16px;
                        color: #445e93;
                        cursor: pointer;
                        text-decoration: underline;
                    "
                    >Profile</a
                ></span
            >
            <span style="font-size: 16px">&nbsp;>&nbsp;</span>
            <span
                ><a
                    routerLink="../view-calendar"
                    style="
                        font-size: 16px;
                        color: #445e93;
                        cursor: pointer;
                        text-decoration: underline;
                    "
                    >Calendar</a
                ></span
            >
            <span style="font-size: 16px">&nbsp;>&nbsp;</span>
            <span style="font-size: 16px; color: #808080">Holiday</span>
        </div>
        <div>
            <div>
                <div class="formRound">
                    <form [formGroup]="holidaysForm" name="holidaysForm">
                        <div class="sec-padd-top">
                            <div class="sectionHeading">
                                <h3>Going on a holiday</h3>
                                <hr />
                            </div>
                            <div class="lineHeight">
                                If you plan to take a break from the sessions or
                                wish to go on a holiday, you can do so by
                                selecting the date below. Once done you will not
                                be matched for the selected duration.
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <br />
                                    <p>Select Dates from range</p>
                                </div>
                                <div class="col-lg-4">
                                    <p>Start Date</p>
                                    <input
                                        type="text"
                                        placeholder=""
                                        class="form-control"
                                        bsDatepicker
                                        [bsConfig]="{
                                            dateInputFormat: 'DD MMM Y'
                                        }"
                                        formControlName="startDate"
                                        placeholder="Start Date"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <p>End Date</p>
                                    <input
                                        type="text"
                                        placeholder=""
                                        class="form-control"
                                        bsDatepicker
                                        [bsConfig]="{
                                            dateInputFormat: 'DD MMM Y'
                                        }"
                                        formControlName="endDate"
                                        placeholder="End Date"
                                    />
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-lg-4">
                                    <p>Remark</p>
                                </div>
                                <div class="col-lg-8">
                                    <div>
                                        <input
                                            type="text"
                                            id="reason"
                                            name="reason"
                                            placeholder=""
                                            class="form-control"
                                            formControlName="reason"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <button
                                        class="thm-2"
                                        (click)="formSubmission()"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <br />
                    <div class="bottomPadding">List of holidays</div>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Start From</th>
                                    <th>End From</th>
                                    <th class="remarks">Remarks</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody class="tableScroll">
                                <tr *ngFor="let u of holidaysArray">
                                    <td>
                                        {{ u.startDate | date : 'dd MMM yyyy' }}
                                    </td>
                                    <td>
                                        {{ u.endDate | date : 'dd MMM yyyy' }}
                                    </td>
                                    <td class="remarks">{{ u.reason }}</td>
                                    <td>
                                        <i
                                            (click)="removeObjFromArray(u)"
                                            class="fa fa-trash cursorPointer"
                                        ></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
