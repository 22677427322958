import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.css']
})
export class WhatsappChatComponent implements OnInit {
  message="Hi, I need more details on how I can use the pleasecoach platform.";

  constructor() { }

  ngOnInit(): void {
  }

}
