<div class="topMenuMargin" (click)="closenavbar()">
    <div class="d-flex align-items-center flex-column">
        <div class="heading-text" style="margin-top: 50px">
            What Our Users Say
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <ngx-slick-carousel
                    class="carousel"
                    #slickModal2="slick-carousel"
                    [config]="testimonialsSlideConfig"
                >
                    <div
                        class="carousel-card"
                        *ngFor="let item of userReviews"
                        ngxSlickItem
                    >
                        <div class="sub-text">
                            <b>{{ item.name }}</b>
                        </div>
                        <div class="small-text" style="margin-top: 6px">
                            {{ item.work }}
                        </div>
                        <div class="rating" style="margin-top: 30px"></div>
                        <div
                            class="sub-text card-content-width"
                            style="margin-top: 30px"
                        >
                            {{ item.text }}
                        </div>
                        <div class="quote"></div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center flex-column">
        <div class="heading-text">Free Mental Health Tests</div>
        <div class="health-container d-flex">
            <div id="categoryScroll">
                <ul *ngIf="freeReports?.length > 0">
                    <li
                        *ngFor="let item of freeReports; let inx = index"
                        class="cursorPointer"
                    >
                        <a>
                            <img
                                class="freeReportListImg"
                                src="{{ item.image }}"
                                alt=""
                                routerLink="../{{ item.route }}"
                            />
                        </a>
                        <p
                            class="report-description"
                            [ngClass]="{
                                showLess: !item.showMore,
                                showMore: item.showMore
                            }"
                        >
                            {{ item.desc }}
                        </p>
                        <p
                            *ngIf="!item.showMore"
                            class="showTxt"
                            (click)="showMoreToggle(inx)"
                        >
                            Show More
                        </p>
                        <p
                            *ngIf="item.showMore"
                            class="showTxt"
                            (click)="showMoreToggle(inx)"
                        >
                            Show Less
                        </p>
                        <button
                            class="orange-button"
                            routerLink="../{{ item.route }}"
                        >
                            {{ item.name }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="boxRound subsBox">
                <div class="secondaryHeading">
                    <h2>
                        The latest mental health news and tips, delivered to
                        your inbox weekly.
                    </h2>
                    <hr />
                </div>
                <div class="subsEmail formRound1">
                    <form [formGroup]="subsForm" (ngSubmit)="onSubmit()">
                        <div class="d-flex">
                            <input
                                type="text"
                                placeholder="Email"
                                formControlName="email"
                                class="form-control"
                            />
                            <button
                                type="submit"
                                class="buttonTheme1 subsButton"
                            >
                                Subscribe
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
