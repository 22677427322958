import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { TherapistAuthService } from "../therapist-auth/therapist-auth.service";
import { io } from "socket.io-client";

const userBackendUrl = environment.apiUrl + "/therapist/chat/";
@Injectable({
  providedIn: "root",
})
export class TherapistChatService {
  socket: any;
  readonly url = environment.socketUrl;
  readonly socketPath = environment.socketPath;
  private socketEvent = new Subject<Object>();
  constructor(private http: HttpClient, private therapistAuthService: TherapistAuthService) { }

  initializeSocket() {
    let authToken = this.therapistAuthService.getToken()
    this.socket = io(this.url, {
      path: this.socketPath,
      auth: {
        token: authToken,
        role: "therapist"
      },
      upgrade: false,
      transports: ['polling'],

    })
    this.socket.on("receive message", (data) => {
      this.socketEvent.next({ event: 'receive', data })
    })
    this.socket.on("new channel", (data) => {
      window.alert('new channel data')
      console.log('new channel data', data)
    })
  }
  joinMultipleRoom(roomArr) {
    this.socket.emit("join-rooms", roomArr, "therapist")
  }
  joinRoom(roomname) {
    this.socket.emit("join-room", roomname)
  }
  disconnectSocket() {
    if (this.socket) this.socket.disconnect();
  }

  subsribeRooms(rooms) {
    rooms.forEach(room => {
      // console.log(room.channelName)
      this.socket.emit("join-room", room.channelName)
    })
    this.socket.on("recieve message", (data) => {
      // console.log('receive message', data)
      this.socketEvent.next(data)
    })
    this.socket.on("disconnect", (reason) => {
      // window.alert("disconnected" + reason)
    })
  }
  sendMessage(data: any, cb) {
    this.socket.emit("send message:therapist", data, cb)
  }
  sendTypingEvent(data: any, cb) {
    this.socket.emit("send message:therapist", data, cb)
  }
  getEventsObject() {
    return this.socketEvent.asObservable();
  }

  sendNewMessage(messageObj) {
    return this.http.post(userBackendUrl + 'new-message', messageObj)
  }

  getMessages(channelId, skip, limit) {
    return this.http.get(`${userBackendUrl}message-pagin?channelName=${channelId}&skip=${skip}&limit=${limit}`)
  }

  getChatToken() {
    return this.http.get<any>(userBackendUrl + "getToken");
  }

  getChats(searchString) {
    return this.http.get<any>(userBackendUrl + "getChats?searchString=" + searchString);
  }
  sendAttachment(imgData, errorCallback): Observable<any> {
    return this.http.post(userBackendUrl + "uploadAttachment", imgData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(catchError((error: HttpErrorResponse) => {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      errorCallback(error.error.message)
      return throwError(() => {
        return errorMessage;
      });
    }
    ))
  }

  getDownloadUrl(key) {
    return this.http.get(userBackendUrl + key);
  }

  updateLastMessageAt(id, data) {
    return this.http.put(userBackendUrl + 'last-message-at/' + id, data)
  }

  updateLastMessage(id, data) {
    return this.http.put(userBackendUrl + 'last-message/' + id, data)
  }
}
