<div class="therapistsWrapper" (click)="closenavbar()">
    <div class="container">
        <div class="midTextWrapper">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="secondaryHeading">
                        <h2>Meet Our Counselling Therapists</h2>
                        <hr />
                        <p>
                            Meet the highly qualified team of some of the best
                            names in psychology who deliver improved well-being
                            to you.
                        </p>
                        <a
                            routerLink="/our-counselors"
                            class="buttonTheme1"
                            style="cursor: pointer; color: white"
                            >View All</a
                        >
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div class="topRowTBox">
            <div class="row">
                <div class="col-md-6">
                    <a
                        routerLink="/community-talk-therapists-detail/626a2057069e10227bba1971"
                        style="color: #021744"
                    >
                        <div class="therapistBox">
                            <div class="aboutTBox">
                                Aashita Khanna, MPhil in Clinical Psychology
                                (RCI Licensed)
                            </div>
                            <div class="tBoxIcon">
                                <img
                                    src="../../../../assets/images/counseling-therapy-psychologists/aashita-home-bubble.webp"
                                    alt="Please Coach online counselor psychologist sakina palitanawala"
                                    class="img-fluid"
                                    draggable="false"
                                />
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="therapistBox rightBox">
                        <a
                            routerLink="/community-talk-therapists-detail/607fcf80a14fe9519e363e4c"
                            style="color: #021744; display: block !important"
                        >
                            <div class="aboutTBox">
                                Dr. V S Ananthakrishnan, MBBS, MD (Psychiatry)
                            </div>
                            <div class="tBoxIcon">
                                <img
                                    src="../../../../assets/images/counseling-therapy-psychologists/anathakrishna.jpeg"
                                    alt="Please Coach online counselor psychologist V S Ananthakrishnan"
                                    class="img-fluid"
                                    draggable="false"
                                />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomRowTBox">
            <div class="row">
                <div class="col-md-6">
                    <a
                        routerLink="/community-talk-therapists-detail/603b643a37e19c36ed87dfa4"
                        style="color: #021744"
                    >
                        <div class="therapistBox">
                            <div class="aboutTBox">
                                Puja Roy , Msc in Health Psychology (gold
                                medalist)
                            </div>
                            <div class="tBoxIcon">
                                <img
                                    src="../../../../assets/images/counseling-therapy-psychologists/puja-roy.jpeg"
                                    alt="Please Coach online counselor psychologist puja roy"
                                    class="img-fluid"
                                    draggable="false"
                                />
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-6">
                    <a
                        routerLink="/community-talk-therapists-detail/623824accaf201116c09f77d"
                        style="color: #021744"
                    >
                        <div class="therapistBox rightBox">
                            <div class="aboutTBox">
                                Guraksha Pabreja, MA in Clinical Psychology
                                (Counselling Practices)
                            </div>
                            <div class="tBoxIcon">
                                <img
                                    src="../../../../assets/images/counseling-therapy-psychologists/guraksha-home-bubble.webp"
                                    alt="Please Coach online counselor psychologist angelina david"
                                    class="img-fluid"
                                    draggable="false"
                                />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
