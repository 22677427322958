<div class="paddingDisclaimerpage" (click)="closenavbar()">
    <div class="container">
        <div class="pageHeading">
            <h2>Disclaimer</h2>
            <hr />
            <h3>Please Coach Private Limited</h3>
            <h4>Last Updated: 8th June 2020</h4>
        </div>
        <div class="contentClass">
            <ol>
                <li class="li-styling">
                    Please Coach is not in the business of providing psychiatric
                    care or treatment for severe mental illnesses including but
                    not limited suicidal thoughts.
                </li>
                <li class="li-styling">
                    The advice or information provided by counsellors via the
                    website is provided for informational purposes only and
                    cannot be considered a substitute for examination by a
                    doctor or other mental health professional. You are advised
                    strongly against relying solely on, or make decisions based
                    solely on advice provided by any counsellor.
                </li>
                <li class="li-styling">
                    You understand and agree that although a Counsellor may be a
                    qualified clinical or counselling psychologist or other
                    mental health professional. Please Coach disclaims any and
                    all liability for any consultation and therapy services
                    rendered by a Counsellor to you through the Website. You
                    further acknowledge and agree that you take full
                    responsibility for the decision to access a Expert through
                    the Website and to continue to interact with such
                    individual(s), and that the role of Please Coach is strictly
                    limited to providing access to such Counsellors to you.
                </li>
                <li class="li-styling">
                    The website is not a suicide helpline platform. If you are
                    considering or contemplating suicide or feel that you are a
                    danger to yourself or to others, you may discontinue use of
                    the services immediately at your discretion and please
                    notify appropriate police or emergency medical personnel.
                    You may find contacts of such emergency services here
                </li>
            </ol>
        </div>
    </div>
</div>
