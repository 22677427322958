import { Component, OnInit, ViewChild } from "@angular/core";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { SubscriptionService } from "../../subscription.service";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import "magnific-popup";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { faCommentAlt, faVideo, faLongArrowAltRight, faStar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/auth/auth.service";


declare var document: any;
SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  templateUrl: "./design.component.html",
  styleUrls: ["./design.component.scss"],
})

export class DesignComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2') slickModal2: SlickCarouselComponent;
  @ViewChild('slickModal3') slickModal3: SlickCarouselComponent;
  imageUrl = environment.apiUrl;
  safeVideoAlsoLike = [];
  slideConfig = {
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
    ],
  };

  therapistsData = [
    {
      name: "Ritam Dubey",
      title: "MA in Counselling Psychology",
      fileName: "Ritam.webp",
      id: "626a1d73069e10227bba193e"
    },
    {
      name: "Guraksha Pabreja",
      title: "MA in Clinical Psychology (Counselling Practices)",
      fileName: "Guraksha.webp",
      id: "623824accaf201116c09f77d"
    },
    {
      name: "Divyanshi Lohiya",
      title: "MA in Clinical Psychology",
      fileName: "Divyanshi.webp",
      id: "6226e6366328d211150feeae"
    },
    {
      name: "Geetha Krishna",
      title: "Certification in Counselling, Interpersonal Communication & Listening Skills",
      fileName: "Geetha.webp",
      id: "61555433c75c9e3dee864f3f"
    },
    {
      name: "Sakshi Verma",
      title: "MA in Psychology",
      fileName: "Sakshi.webp",
      id: "627e22d3f6ca4779774772aa"
    },
    {
      name: "Syed Urooj",
      title: "MA in Applied Psychology",
      fileName: "Syed.webp",
      id: "6244146dcaf201116c0aaa15"
    },
    {
      name: "Yogendra Singh",
      title: "MBBS & MD in Psychiatry",
      fileName: "Yogendra.webp",
      id: "626d1237f9fda928240cee4f"
    }
  ]


  innerWidth: number;
  itemsPerSlide = 3;
  mobileBreakpoint = 360;
  isLoggedIn: Boolean = false;
  videosAlsoLike = []


  youMayAlsoLikeConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        }
      }
    ]
  }



  constructor(
    private _sanitizer: DomSanitizer, private sidenavService: SideNavBarService, private authService: AuthService) { }
  ngOnInit(): void {
    this.isLoggedIn = this.authService.getIsAuth();
    this.adjustsItemsPerSlide();
    this.videosAlsoLike.forEach((url) => {
      this.safeVideoAlsoLike.push(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    })
    // $(".timeline-wrapper .timeline-content-item > span").on("click", function(e){
    //   $(".timeline-wrapper .timeline-content-item.active").removeClass("active");
    //   $(this).parent().addClass("active");
    // });


    // (function () {
    //   "use strict";

    //   // define variables
    //   var items = document.querySelectorAll(".timeline li");

    //   function isElementInViewport(el) {
    //     var rect = el.getBoundingClientRect();
    //     return (
    //       rect.top >= 0 &&
    //       rect.left >= 0 &&
    //       rect.bottom <=
    //         (window.innerHeight || document.documentElement.clientHeight) &&
    //       rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    //     );
    //   }

    //   function callbackFunc() {
    //     for (var i = 0; i < items.length; i++) {
    //       if (isElementInViewport(items[i])) {
    //         items[i].classList.add("in-view");
    //       }
    //     }
    //   }

    //   // listen for events
    //   window.addEventListener("load", callbackFunc);
    //   window.addEventListener("resize", callbackFunc);
    //   window.addEventListener("click", callbackFunc);
    // })();

  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }

  private adjustsItemsPerSlide() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.itemsPerSlide = 1;
    } else {
      this.itemsPerSlide = 3;
    }
  }
  initAnimations() {
    const options = { rootMargin: "0px 0px -100px 0px" }
    const faders = document.querySelectorAll(".fade-in")
    const sliders = document.querySelectorAll(".slide-in")
    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add('appear');
          appearOnScroll.unobserve(entry.target)
        }
      })
    }, options)

    faders.forEach(fader => {
      appearOnScroll.observe(fader)
    })
    sliders.forEach(slider => {
      appearOnScroll.observe(slider)
    })
  }

  closeFaq(id) {
    if (id == "question1") {
      document.getElementById("question2").style.visibility = "hidden";
      document.getElementById("question2").style.paddingTop = "0px";
      document.getElementById("question2").style.paddingBottom = "0px";
      document.getElementById("question2").style.paddingLeft = "0px";
      document.getElementById("question2").style.paddingRight = "0px";
      document.getElementById("question2").style.transition = "none";

      document.getElementById("question3").style.visibility = "hidden";
      document.getElementById("question3").style.paddingTop = "0px";
      document.getElementById("question3").style.paddingBottom = "0px";
      document.getElementById("question3").style.paddingLeft = "0px";
      document.getElementById("question3").style.paddingRight = "0px";
      document.getElementById("question3").style.transition = "none"

      document.getElementById("question4").style.visibility = "hidden";
      document.getElementById("question4").style.paddingTop = "0px";
      document.getElementById("question4").style.paddingBottom = "0px";
      document.getElementById("question4").style.paddingLeft = "0px";
      document.getElementById("question4").style.paddingRight = "0px";
      document.getElementById("question4").style.transition = "none"

      if (document.getElementById("question1").style.visibility == "visible") {
        document.getElementById("question1").style.visibility = "hidden";
        document.getElementById("question1").style.paddingTop = "0px";
        document.getElementById("question1").style.paddingBottom = "0px";
        document.getElementById("question1").style.paddingLeft = "0px";
        document.getElementById("question1").style.paddingRight = "0px";
        document.getElementById("question1").style.transition = "none";
      }

      else if (document.getElementById("question1").style.visibility !== "visible") {
        document.getElementById("question1").style.visibility = "visible";
        document.getElementById("question1").style.paddingTop = "80px";
        document.getElementById("question1").style.paddingBottom = "50px";
        document.getElementById("question1").style.paddingLeft = "79px";
        document.getElementById("question1").style.paddingRight = "78px";
        document.getElementById("question1").style.transition = "none";
      }
    }

    else if (id == "question2") {
      document.getElementById("question1").style.visibility = "hidden";
      document.getElementById("question1").style.paddingTop = "0px";
      document.getElementById("question1").style.paddingBottom = "0px";
      document.getElementById("question1").style.paddingLeft = "0px";
      document.getElementById("question1").style.paddingRight = "0px";
      document.getElementById("question1").style.transition = "none";

      document.getElementById("question3").style.visibility = "hidden";
      document.getElementById("question3").style.paddingTop = "0px";
      document.getElementById("question3").style.paddingBottom = "0px";
      document.getElementById("question3").style.paddingLeft = "0px";
      document.getElementById("question3").style.paddingRight = "0px";
      document.getElementById("question3").style.transition = "none"

      document.getElementById("question4").style.visibility = "hidden";
      document.getElementById("question4").style.paddingTop = "0px";
      document.getElementById("question4").style.paddingBottom = "0px";
      document.getElementById("question4").style.paddingLeft = "0px";
      document.getElementById("question4").style.paddingRight = "0px";
      document.getElementById("question4").style.transition = "none"

      if (document.getElementById("question2").style.visibility == "visible") {
        document.getElementById("question2").style.visibility = "hidden";
        document.getElementById("question2").style.paddingTop = "0px";
        document.getElementById("question2").style.paddingBottom = "0px";
        document.getElementById("question2").style.paddingLeft = "0px";
        document.getElementById("question2").style.paddingRight = "0px";
        document.getElementById("question2").style.transition = "none";
      }
      else if (document.getElementById("question2").style.visibility !== "visible") {
        document.getElementById("question2").style.visibility = "visible";
        document.getElementById("question2").style.paddingTop = "80px";
        document.getElementById("question2").style.paddingBottom = "50px";
        document.getElementById("question2").style.paddingLeft = "79px";
        document.getElementById("question2").style.paddingRight = "78px";
        document.getElementById("question2").style.transition = "none";
      }
    }

    else if (id == "question3") {
      document.getElementById("question1").style.visibility = "hidden";
      document.getElementById("question1").style.paddingTop = "0px";
      document.getElementById("question1").style.paddingBottom = "0px";
      document.getElementById("question1").style.paddingLeft = "0px";
      document.getElementById("question1").style.paddingRight = "0px";
      document.getElementById("question1").style.transition = "none";

      document.getElementById("question2").style.visibility = "hidden";
      document.getElementById("question2").style.paddingTop = "0px";
      document.getElementById("question2").style.paddingBottom = "0px";
      document.getElementById("question2").style.paddingLeft = "0px";
      document.getElementById("question2").style.paddingRight = "0px";
      document.getElementById("question2").style.transition = "none"

      document.getElementById("question4").style.visibility = "hidden";
      document.getElementById("question4").style.paddingTop = "0px";
      document.getElementById("question4").style.paddingBottom = "0px";
      document.getElementById("question4").style.paddingLeft = "0px";
      document.getElementById("question4").style.paddingRight = "0px";
      document.getElementById("question4").style.transition = "none"

      if (document.getElementById("question3").style.visibility == "visible") {
        document.getElementById("question3").style.visibility = "hidden";
        document.getElementById("question3").style.paddingTop = "0px";
        document.getElementById("question3").style.paddingBottom = "0px";
        document.getElementById("question3").style.paddingLeft = "0px";
        document.getElementById("question3").style.paddingRight = "0px";
        document.getElementById("question3").style.transition = "none";
      }
      else if (document.getElementById("question3").style.visibility !== "visible") {
        document.getElementById("question3").style.visibility = "visible";
        document.getElementById("question3").style.paddingTop = "80px";
        document.getElementById("question3").style.paddingBottom = "50px";
        document.getElementById("question3").style.paddingLeft = "79px";
        document.getElementById("question3").style.paddingRight = "78px";
        document.getElementById("question3").style.transition = "none";
      }
    }

    else if (id == "question4") {
      document.getElementById("question1").style.visibility = "hidden";
      document.getElementById("question1").style.paddingTop = "0px";
      document.getElementById("question1").style.paddingBottom = "0px";
      document.getElementById("question1").style.paddingLeft = "0px";
      document.getElementById("question1").style.paddingRight = "0px";
      document.getElementById("question1").style.transition = "none";

      document.getElementById("question2").style.visibility = "hidden";
      document.getElementById("question2").style.paddingTop = "0px";
      document.getElementById("question2").style.paddingBottom = "0px";
      document.getElementById("question2").style.paddingLeft = "0px";
      document.getElementById("question2").style.paddingRight = "0px";
      document.getElementById("question2").style.transition = "none"

      document.getElementById("question3").style.paddingTop = "0px";
      document.getElementById("question3").style.paddingBottom = "0px";
      document.getElementById("question3").style.visibility = "hidden";
      document.getElementById("question3").style.paddingLeft = "0px";
      document.getElementById("question3").style.paddingRight = "0px";
      document.getElementById("question3").style.transition = "none";

      if (document.getElementById("question4").style.visibility == "visible") {
        document.getElementById("question4").style.visibility = "hidden";
        document.getElementById("question4").style.paddingTop = "0px";
        document.getElementById("question4").style.paddingBottom = "0px";
        document.getElementById("question4").style.paddingLeft = "0px";
        document.getElementById("question4").style.paddingRight = "0px";
        document.getElementById("question4").style.transition = "none";
      }
      else if (document.getElementById("question4").style.visibility !== "visible") {
        document.getElementById("question4").style.visibility = "visible";
        document.getElementById("question4").style.paddingTop = "80px";
        document.getElementById("question4").style.paddingBottom = "50px";
        document.getElementById("question4").style.paddingLeft = "79px";
        document.getElementById("question4").style.paddingRight = "78px";
        document.getElementById("question4").style.transition = "none";
      }
    }
  }

  closeOtherCheckBox(id) {
    switch (id) {
      case 'collapsible1':
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        break;
      case 'collapsible2':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible3');
        this.closeCheckBox('collapsible4');
        break;
      case 'collapsible3':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible4');
        break;
      case 'collapsible4':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        break;
      case 'collapsible4':
        this.closeCheckBox('collapsible1');
        this.closeCheckBox('collapsible2');
        this.closeCheckBox('collapsible3');
        break;
      default:
        break;
    }
  }

  closeCheckBox(id: string) {
    document.getElementById(id)['checked'] = false
  }


  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }
  // closenavbar() {
  //   this.sidenavService.closesidenav();
  // }
  nextSlideTestimonials() {
    this.slickModal1.slickNext();
  }
  prevSlideTestimonials() {
    this.slickModal1.slickPrev();
  }
  nextSlideBlog() {
    this.slickModal2.slickNext();
  }
  prevSlideBlog() {
    this.slickModal2.slickPrev();
  }

}
