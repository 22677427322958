import { Component, OnInit } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistProfileService } from "../therapist-profile.service";

@Component({
  selector: "app-reports-new",
  templateUrl: "./reports-new.component.html",
  styleUrls: ["./reports-new.component.css"],
})
export class ReportsNewComponent implements OnInit {
  reportNews = null;
  averageRating = 0;
  doReportShimmer = true;
  doActiveClients = true;
  constructor(
    private therapistService: TherapistProfileService,
    private sidenavService: SideNavBarService
  ) { }
  ngOnInit(): void {
    this.therapistService.getReports().subscribe((data) => {
      this.reportNews = data;
      if (this.reportNews['PleasecoachAverage']) {
        this.reportNews['PleasecoachAverage'] = this.reportNews['PleasecoachAverage'].toFixed(2)
      }
      if (!data["highestRating"] && !data["highestRating"]) {
        this.averageRating = +((data["highestRating"] + data["lowestRating"]) / 2).toFixed(2);
      }
      this.doReportShimmer = false;
      this.doActiveClients = false;
    });
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
