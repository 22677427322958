import { Component, OnInit } from "@angular/core";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-meet-therapists",
  templateUrl: "./meet-therapists.component.html",
  styleUrls: ["./meet-therapists.component.css"],
})
export class MeetTherapistsComponent implements OnInit {
  constructor(private sidenavService: SideNavBarService) {}

  ngOnInit(): void {}
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
