import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import * as moment from "moment";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { MoodJournalService } from "../mood-journal.service";
import clevertap from "clevertap-web-sdk";


@Component({
  selector: "app-mood-check-in",
  templateUrl: "./mood-check-in.component.html",
  styleUrls: ["./mood-check-in.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MoodCheckInComponent implements OnInit {
  events = [];
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    headerToolbar: {
      center: "title prev next",
      start: "",
      end: "",
    },
    events: [],
    datesSet: this.handleDates.bind(this),
    showNonCurrentDates: false,
    eventBackgroundColor: "transparent",
    eventTextColor: "#6d9566",
    eventBorderColor: "transparent",
  };

  constructor(
    private confirmationService: ConfirmationDialogService,
    private moodService: MoodJournalService,
    private alertService: BootstrapAlertService
  ) {}
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  ngOnInit(): void {}

  formSubmit2(color, feeling) {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.render();
    // let addEvent = calendarApi.addEvent({
    //   date: new Date(),
    //   allDay: true,
    //   color: color
    // })
    // console.log(addEvent)
    // calendarApi.next()
    let moodObj = {
      mood: feeling,
      color: color,
      date: moment().format("YYYY-MM-DD"),
    };
    this.confirmationService
      .confirm(
        "Are you sure?",
        "Are you sure you want to continue?",
        "Yes",
        "No"
      )
      .subscribe((res) => {
        if (res) {
          this.moodService
            .moodcheckin(moodObj, moment().format("YYYY-MM-DD"))
            .subscribe((res) => {
              if (res) {
                let isExists = false;
                if(clevertap){
                  clevertap.event.push("Mood Checked In", {
                    "Feeling" : moodObj.mood
                  })
                }
                this.events.forEach((event, index) => {
                  let startMoment = moment(event.id, "YYYY-MM-DD");
                  let now = moment();
                  if (startMoment.isSame(now, "day")) {
                    isExists = true;
                    this.events[index] = color;
                  }
                });
                if (!isExists) {
                  calendarApi.addEvent({
                    id: moment().format("YYYY-MM-DD"),
                    date: new Date(),
                    allDay: true,
                    color: color,
                    className: ["cal-eventClass"]
                  });
                } else {
                  let events = calendarApi.getEventById(
                    moment().format("YYYY-MM-DD")
                  );
                  if (events) {
                    events.setProp("color", color);
                  }
                }
                this.alertService.alert(
                  new BootstrapAlert(
                    "Mood updated successfully for today!",
                    "alert-success"
                  )
                );
              }
            });
        }
      });
  }

  handleDates(args: any) {
    this.moodService
      .getMoodCheckins({
        startDate: args.start,
        endDate: args.end,
      })
      .subscribe((res) => {
        this.events = [];
        res["result"].forEach((element) => {
          this.events = this.events.concat({
            color: element.color,
            id: element.date,
            start: moment(element.date, "YYYY-MM-DD")["_d"],
            allDay: true,
            className: ["cal-eventClass"],
          });
        });
        this.events.push({
          id: "",
          title: "",
          start: new Date(),
          allDay: true,
          className: ["cal-todayClass"],
        });
        this.calendarOptions.events = this.events;
      });
  }
}
