<div class="secPadding">
    <div class="container-fluid">
        <div class="secondaryHeading">
            <h2>Welcome to Please Coach</h2>
            <hr />
        </div>
        <div class="queWrap">
            <div class="welcomeText">
                <h4>
                    Please answer the following quick questions & we will match
                    you to the perfect counselor that suits your needs!
                </h4>
            </div>
            <div class="formRound">
                <form [formGroup]="questionsForm" name="userForm">
                    <div class="eachOptionsDivTag extraCssforcategory">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="insideFormDivTags">
                                    <h3>What are you seeking help for?</h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [items]="specializeareas1"
                                            formControlName="specializeArea"
                                            [(ngModel)]="
                                                selectedOptionspecializeareas
                                            "
                                            [multiple]="true"
                                            [selectableGroup]="true"
                                            [closeOnSelect]="false"
                                            [selectableGroup]="true"
                                            [searchable]="false"
                                            (change)="
                                                onSpecializeAreaChanged($event)
                                            "
                                        >
                                        </ng-select>
                                    </div>
                                    <p
                                        style="
                                            color: red;
                                            text-align: left;
                                            margin-left: 20px;
                                            margin-top: -17px;
                                        "
                                        *ngIf="
                                            questionsForm.controls
                                                .specializeArea.value == ''
                                        "
                                    >
                                        This field is required
                                    </p>
                                    <div
                                        class="insideFormDivTags"
                                        *ngIf="showSpecificArea"
                                    >
                                        <input
                                            class="form-control col-xl-9"
                                            type="text"
                                            formControlName="specifyNeed"
                                            placeholder="Type your issue here..."
                                        />
                                        <button
                                            class="buttonTheme2 selectFromOptions"
                                            *ngIf="showSpecificArea"
                                            (click)="showOptions()"
                                        >
                                            Select from options
                                        </button>
                                    </div>
                                </div>
                                <div class="insideFormDivTags">
                                    <h3>
                                        What is your preferred medium for the
                                        session?
                                    </h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [items]="preferredMediumOptions"
                                            [searchable]="false"
                                            formControlName="preferredMedium"
                                        >
                                        </ng-select>
                                    </div>
                                    <p
                                        style="
                                            color: red;
                                            text-align: left;
                                            margin-left: 20px;
                                            margin-top: -17px;
                                        "
                                        *ngIf="
                                            questionsForm.controls
                                                .preferredMedium.errors
                                                ?.required
                                        "
                                    >
                                        Preferred medium is required
                                    </p>
                                </div>
                                <div class="insideFormDivTags">
                                    <h3>
                                        Do you have a gender preference for your
                                        therapist?
                                    </h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [items]="therapistgenderpreference"
                                            [searchable]="false"
                                            formControlName="therapistgenderpreference"
                                        ></ng-select>
                                    </div>
                                    <p
                                        style="
                                            color: red;
                                            text-align: left;
                                            margin-left: 20px;
                                            margin-top: -17px;
                                        "
                                        *ngIf="
                                            questionsForm.controls
                                                .therapistgenderpreference
                                                .errors?.required
                                        "
                                    >
                                        Therapist gender preference is required
                                    </p>
                                </div>
                                <div class="insideFormDivTags">
                                    <h3>
                                        Do you speak any languages, other than
                                        English? If yes, please select the
                                        language and your proficiency level?
                                    </h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [items]="language"
                                            (change)="addLanguageToArray()"
                                            [searchable]="false"
                                            formControlName="language"
                                        ></ng-select>
                                        <div class="divTagClassCss"></div>
                                        <table>
                                            <tr>
                                                <th>Language</th>
                                                <th>Native</th>
                                                <th>Fluent</th>
                                                <th>Average</th>
                                            </tr>

                                            <tr
                                                *ngFor="
                                                    let language of selectedLanguageArray;
                                                    let idx = index;
                                                    trackBy: indexTracker
                                                "
                                            >
                                                <td>
                                                    {{ language.language }}
                                                </td>
                                                <td>
                                                    <input
                                                        name="{{
                                                            language.language
                                                        }}"
                                                        [checked]="
                                                            language.fluency ==
                                                            'Native'
                                                        "
                                                        [(ngModel)]="
                                                            selectedLanguageArray[
                                                                idx
                                                            ].fluency
                                                        "
                                                        [ngModelOptions]="{
                                                            standalone: true
                                                        }"
                                                        type="radio"
                                                        value="Native"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="{{
                                                            language.language
                                                        }}"
                                                        [checked]="
                                                            language.fluency ==
                                                            'Fluent'
                                                        "
                                                        [(ngModel)]="
                                                            selectedLanguageArray[
                                                                idx
                                                            ].fluency
                                                        "
                                                        [ngModelOptions]="{
                                                            standalone: true
                                                        }"
                                                        type="radio"
                                                        value="Fluent"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="{{
                                                            language.language
                                                        }}"
                                                        [checked]="
                                                            language.fluency ==
                                                            'Average'
                                                        "
                                                        [(ngModel)]="
                                                            selectedLanguageArray[
                                                                idx
                                                            ].fluency
                                                        "
                                                        [ngModelOptions]="{
                                                            standalone: true
                                                        }"
                                                        type="radio"
                                                        value="Average"
                                                    />
                                                </td>
                                                <td>
                                                    <i
                                                        class="fa fa-times"
                                                        (click)="
                                                            removeLanguageFromArray(
                                                                language.language
                                                            )
                                                        "
                                                        aria-hidden="true"
                                                    ></i>
                                                </td>
                                            </tr>
                                            <tr></tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="insideFormDivTags">
                                    <h3>
                                        When do you want to schedule your first
                                        session?
                                    </h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [items]="firstsessionAt"
                                            [searchable]="false"
                                            formControlName="firstsessionAt"
                                        >
                                        </ng-select>
                                    </div>
                                    <p
                                        style="
                                            color: red;
                                            text-align: left;
                                            margin-left: 20px;
                                            margin-top: -17px;
                                        "
                                        *ngIf="
                                            questionsForm.controls
                                                .firstsessionAt.errors?.required
                                        "
                                    >
                                        First session at is required
                                    </p>
                                </div>
                                <div class="insideFormDivTags">
                                    <h3>
                                        What is your preferred time for session?
                                    </h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [searchable]="false"
                                            [items]="TimeSlott"
                                            formControlName="TimeSlott"
                                        ></ng-select>
                                    </div>
                                    <p
                                        style="
                                            color: red;
                                            text-align: left;
                                            margin-left: 20px;
                                            margin-top: -17px;
                                        "
                                        *ngIf="
                                            questionsForm.controls.TimeSlott
                                                .errors?.required
                                        "
                                    >
                                        Time slot is required
                                    </p>
                                </div>

                                <div
                                    class="insideFormDivTags"
                                    *ngIf="questionsType == 'child'"
                                >
                                    <h3>How are you related to the child?</h3>
                                    <div>
                                        <ng-select
                                            class="form-control table"
                                            [searchable]="false"
                                            [items]="relatesToChild"
                                            formControlName="relatedToChild"
                                        >
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submitbtnn text-center">
                            <button
                                [style.opacity]="
                                    !questionsForm.controls.TimeSlott.errors
                                        ?.required &&
                                    !questionsForm.controls.firstsessionAt
                                        .errors?.required &&
                                    !questionsForm.controls
                                        .therapistgenderpreference.errors
                                        ?.required &&
                                    !questionsForm.controls.preferredMedium
                                        .errors?.required &&
                                    questionsForm.controls.specializeArea
                                        .value != ''
                                        ? '1'
                                        : '0.5'
                                "
                                [style.cursor]="
                                    !questionsForm.controls.TimeSlott.errors
                                        ?.required &&
                                    !questionsForm.controls.firstsessionAt
                                        .errors?.required &&
                                    !questionsForm.controls
                                        .therapistgenderpreference.errors
                                        ?.required &&
                                    !questionsForm.controls.preferredMedium
                                        .errors?.required &&
                                    questionsForm.controls.specializeArea
                                        .value != ''
                                        ? 'pointer'
                                        : 'not-allowed'
                                "
                                (click)="onSubmit()"
                                class="buttonTheme1"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
