import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ContactService } from "../../contact.service";
import { COMMON_DATA } from "src/app/shared/common";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SeoMetaService } from "src/app/seo-meta.service";

@Component({
  selector: "app-sign-up-demo",
  templateUrl: "./sign-up-demo.component.html",
  styleUrls: ["./sign-up-demo.component.css"],
})
export class SignUpDemoComponent implements OnInit {
  message = "";
  signUpDemoForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  timeSlots = COMMON_DATA.timeSlotsInSignUp;
  userCountryCode = {
    countryCode: "in",
    dialCode: "91"
  };
  noOfEmployeeOptions = COMMON_DATA.noOfEmployeeOptions
  constructor(
    private sidenavService: SideNavBarService,
    private confirmationService: ConfirmationDialogService,
    private corporateService: ContactService,
    private alertService: BootstrapAlertService,
    private metaSeo: SeoMetaService
  ) { }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    this.metaSeo.updateTitle("Online Counseling | PleaseCoach");
    this.metaSeo.updateMetaInfo("Higher productivity through happier employees. Become an employer of choice, go beyond traditional benefits. Enquire today.", "Corporate wellness, corporate mental health, mental health initiatives, mental wellness for business")
    this.signUpDemoForm = new FormGroup({
      name: new FormControl("", Validators.required),
      designation: new FormControl("", [
        Validators.required,
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      companyName: new FormControl("", Validators.required),
      noOfEmployee: new FormControl(null, Validators.required),
      department: new FormControl("", Validators.required),
      officeEmailId: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
      preferredDate: new FormControl(null, Validators.required),
      preferredTime: new FormControl(null, Validators.required),
    });
  }

  checkError(valid) {
    if (!valid) {
      this.signUpDemoForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.signUpDemoForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }








  onSubmit() {
    if (this.signUpDemoForm.invalid) {
      return;
    }
    this.confirmationService
      .confirm(
        "Are you sure you want to request for demo?",
        "",
        "Yes",
        "Cancel"
      )
      .subscribe((res) => {
        if (res) {
          this.corporateService
            .requestCorporateDemo(this.signUpDemoForm.value)
            .subscribe((res) => {
              if (res) {
                this.alertService.alert(
                  new BootstrapAlert(
                    "We will get in touch with you soon!",
                    "alert-success"
                  )
                );
                this.signUpDemoForm.reset();
              }
            });
        }
      });
  }
}
