<div (click)="closenavbar()">
    <div class="pleasecoachMessagepart">
        <div class="container">
            <div class="row rowPaddingbox">
                <div class="col-md-7">
                    <img
                        src="../../../assets/images/shapHow.svg"
                        alt="Not loading"
                        style="width: 100%"
                    />
                    <div class="centered">
                        <h3 class="insideH3tag">
                            <b
                                >Please Coach literally means “happiness”. It
                                was conceived with the mission to make the world
                                a happier place.</b
                            >
                        </h3>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-3 alignmentOfbox">
                    <div class="wrapperOfImage">
                        <img
                            src="../../../../assets/images/about-us/pleasecoach/about-pleasecoach-counseling-therapy.svg"
                            class="img-fluid-top"
                            draggable="false"
                            alt="about Please Coach online counseling therapy platform"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contentWrapper sectionPaddingbox">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="mainContentbox">
                        <h3>Thought That Started It</h3>
                        <hr />
                        <p>
                            In all human history, we have never lived better in
                            terms of creature comforts, medical care,
                            connectivity tools, human rights and world peace.
                            However, never, have we been so devoid of close-knit
                            relationships, human to human connection and deep
                            immersive experiences. The bane of our existence
                            today is that as our physical existence has steadily
                            improved, our mental existence has not been able to
                            keep pace.
                        </p>
                        <p>
                            Our flight or fight mechanisms and tribal
                            orientation evolved to survive the Savannah – not
                            the metropolitan life. Rat race, sensory overload
                            and emotional isolation wreak havoc on our mental
                            health. While longer life spans and physically
                            comfortable lives are being focused on in the world,
                            we want to optimise for enhanced mental wellbeing
                            for maximum people.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="alignmentOfImage">
                        <img
                            src="../../../../assets/images/about-us/pleasecoach/thought-that-started-pleasecoach.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="Please Coach online counseling therapy platform start up idea"
                        />
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="mainContentbox">
                        <h3>Problems That We Want To Solve</h3>
                        <hr />
                        <p>
                            Lack of Awareness – mental health is not synonymous
                            with labelled psychiatric illnesses. Feeling lonely,
                            empty, frustrated, overwhelmed and unhappy without a
                            reason are also mental health issues that can be
                            resolved with talk therapy.
                        </p>
                        <p>
                            Unstructured Ecosystem – system does not emphasize
                            enough on the potential benefits of talk therapy. No
                            platform is able connect enough trained counsellors
                            to all the people in need of therapy.
                        </p>
                        <p>
                            Lack of Access – affordable talk therapy in a safe
                            environment is not available everywhere.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="alignmentOfImage">
                        <img
                            src="../../../../assets/images/about-us/pleasecoach/pleasecoach-online-counseling-therapy-solution.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="Please Coach online counseling therapy app and platform solution for counseling therapy needs"
                        />
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="mainContentbox">
                        <h3>Solution That We Have Designed</h3>
                        <hr />
                        <p>
                            State of the art platform – Schedule and connect on
                            high quality video sessions with your therapist from
                            the comfort of your home. Get 24*7 chat access,
                            should you feel the need to reach out.
                        </p>
                        <p>
                            Best in class therapists – Get access to a pool of
                            top psychology experts that are vetted by a 3-step
                            rigorous selection process.
                        </p>
                        <p>
                            Flexibility – Therapy is available to you anytime,
                            anywhere and on any device.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="alignmentOfImage">
                        <img
                            class="xyz"
                            src="../../../../assets/images/pleasecoach-mobile-app-web-platform.png"
                            class="img-fluid"
                            draggable="false"
                            alt="Please Coach online counseling therapy app and website"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sectionPaddingbox">
        <div class="container">
            <div class="sectionHeading">
                <h2>Team Behind It</h2>
                <hr />
            </div>
            <div class="sectionTeamWrapper">
                <h2>Our Advisors</h2>
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="teamContentsgroup">
                            <div class="teamImageWrap"></div>
                            <a
                                (click)="openModal(); currentSlide(1)"
                                class="thm-2"
                            ></a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="teamContentsgroup">
                            <div class="teamImageWrap"></div>
                            <a
                                (click)="openModal(); currentSlide(4)"
                                class="thm-2"
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sectionTeamWrapper">
                <h2>Management</h2>
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="teamContentsgroup">
                            <div class="teamImageWrap"></div>
                            <a
                                (click)="openModal(); currentSlide(3)"
                                class="thm-2"
                            ></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6">
                        <div class="teamContentsgroup">
                            <div class="teamImageWrap"></div>
                            <a
                                (click)="openModal(); currentSlide(2)"
                                class="thm-2"
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="myModal" class="modal" style="z-index: 22222222222; display: none">
        <span class="close cursor" (click)="closeModal()">&times;</span>
        <div class="modal-content">
            <div class="mySlides padding-20" (click)="currentSlide(1)">
                <div class="captionContainer">
                    <h3 class="h3name">Dr. XYZ</h3>
                    <p class="pcontent">
                        Dr. XYZ has been a practicing clinical psychologist
                        since 1972 with experience across Seth G.S. Medical
                        College, K.E.M. Hospital and P.D. Hinduja Hospital. In
                        her nearly 50 years of experience she has been
                        instrumental in starting and advising several
                        institutions like Astitva, Asha Niketan, Vishwas &
                        Swanand. She cofounded the Institute for Psychological
                        Health in 1990. Dr. Thatte has more than 30 publications
                        in international journals & has worked for Indian
                        Council of Medical Research and National Institute of
                        Mental Health, Washington.
                    </p>
                </div>
            </div>
            <div class="mySlides padding-20" (click)="currentSlide(2)">
                <div class="captionContainer">
                    <h3 class="h3name"></h3>
                    <p class="pcontent"></p>
                </div>
            </div>
            <div class="mySlides padding-20" (click)="currentSlide(3)">
                <div class="captionContainer">
                    <h3 class="h3name"></h3>
                    <p class="pcontent"></p>
                </div>
            </div>
            <div class="mySlides padding-20" (click)="currentSlide(4)">
                <div class="captionContainer">
                    <h3 class="h3name"></h3>
                    <p class="pcontent"></p>
                </div>
            </div>
        </div>
    </div>
</div>
