import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { BootstrapAlertService } from "../../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { auth } from 'firebase/app';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { faCommentAlt, faVideo, faLongArrowAltRight, faStar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: 'app-new-landing',
  templateUrl: './new-landing.component.html',
  styleUrls: ['./new-landing.component.css']
})
export class NewLandingComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faStar = faStar;
  faVideo = faVideo;
  isLoading = false;
  faCommentAlt = faCommentAlt;
  faLongArrowAltRight = faLongArrowAltRight;
  isShowPassword: Boolean = false;
  userCountryCode = {
    countryCode: "in",
    dialCode: "91"
  };
  signUpForm = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
    ]),
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
    ]),
    password: new FormControl("", [
      Validators.required,
      Validators.pattern(
        "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$"
      ),
    ]),
  });
  slideConfig = {
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    dots: true,
    arrows: false,
    // autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
    ],
  };

  testimonialsSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
    ]
  }

  userReviews = [
    {
      text: "Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.",
      name: "Sana Javed",
      work: "Software Engineer",
    },
    {
      name: "Naina Kashyap",
      work: "Mumbai",
      text: "When I finally held my baby in my arms after 9 months of waiting and expecting, I didn’t really feel flooded with love or attachment. To the contrary I felt overwhelmed and despondent. When I reached out to pleasecoach, I was full of guilt and shame for feeling this way. My pleasecoach therapist helped me understand that a biochemical shift in my brain was causing my despair. Within a few sessions she pulled me out of this really dark place & helped me find joy in my motherhood."
    },
    {
      text: "I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
      name: "Puja Mittal",
      work: "Homemaker",
    },
    {
      text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at pleasecoach who made helped me understand & treat my depression.",
      name: "Vikas Arora",
      work: "CFO",
    },
    {
      text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
      name: "Aishwarya Hegde",
      work: "Student",
    },
  ];
  therapistsData = [
    {
      name: "Puja Roy",
      title: "M.Sc in Health Psychology (Gold Medalist)",
      fileName: "pura-roy-slide.webp",
      id: "603b643a37e19c36ed87dfa4"
    },
    {
      name: "Sadaf Mir",
      title: "Master\'s in Clinical Psychology",
      fileName: "sadaf-mir-slide.webp",
      id: "619378a71192ee11e85d1d28"
    },
    {
      name: "Sakina Palitanawala",
      title: "MA in Clinical Psychology",
      fileName: "sakina-slide.webp",
      id: "60d5712353481e07848c98f6"
    },
    {
      name: "Dr. V S Ananthakrishnan",
      title: "MBBS, MD (Psychiatry)",
      fileName: "anantha-krishnan-slide.webp",
      id: "607fcf80a14fe9519e363e4c"
    },
    {
      name: "Zoha Merchant",
      title: "M.Phil. in Psychology",
      fileName: "zoha-slide.webp",
      id: "61b837471ff9c716c12706e3"
    }
  ]




  constructor(public authService: AuthService,
    private router: ActivatedRoute,
    private sidenavService: SideNavBarService,
    private route: Router,
    public afAuth: AngularFireAuth,
    private alertService: BootstrapAlertService) { }

  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  ngOnInit(): void {
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 4000)
  }


  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }

  comingSoon() {
    this.alertService.alert(
      new BootstrapAlert(
        "Coming soon for mobile devices! Stay Tuned! 🤩",
        "alert-success"
      )
    );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  nextSlideTestimonials() {
    this.slickModal1.slickNext();
  }
  prevSlideTestimonials() {
    this.slickModal1.slickPrev();
  }

  loadGTMCid() {
    if (window["dataLayer"]) {
      let dataLayer = window["dataLayer"];
      dataLayer.forEach((data) => {
        if (data["cid"]) {
          localStorage.setItem("GTMcid", data["cid"]);
        }
      });
    }
  }

  onSubmit() {
    if (this.signUpForm.controls.phone.status === "INVALID") {
      this.alertService.alert(
        new BootstrapAlert("Please enter a valid phone number", "alert-danger")
      )
      return;
    }
    let userData = this.getFormControls();
    this.authService.registerUser(
      userData.name.value,
      userData.email.value,
      userData.phone.value,
      userData.password.value,
      this.userCountryCode, () => this.stopLoader()
    );
  }
  stopLoader() {
    this.isLoading = false
  }

  checkError(valid) {
    if (!valid) {
      this.signUpForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.signUpForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  showPasswordToggle() {
    this.isShowPassword = !this.isShowPassword;
  }

  getFormControls() {
    return this.signUpForm.controls;
  }

  loginWithGoogle() {
    return this.AuthLogin(new auth.GoogleAuthProvider(), 'Google');
  }

  loginWithFacebook() {
    return this.AuthLogin(new auth.FacebookAuthProvider(), 'Facebook');
  }

  AuthLogin(provider, social) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result) => {
        let idToken = await result.user.getIdToken();
        this.authService.googleAuth({
          token: idToken,
          social: social
        })
      }).catch((error) => {
      })
  }

}
