<div (click)="closenavbar()" class="topMenuMargin">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <strong
                ><i class="fa fa-exclamation-triangle"></i> Disclamer: </strong
            >This tool should be used for screening and monitoring symptom
            severity and cannot replace a clinical assessment and diagnosis. You
            are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <h3>
            Getting to know yourself is the first step towards a mentally
            healthier life.
        </h3>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <p>
                The BFI-S measures 5 personality traits. Your score on each of
                these traits is mentioned as follows:
            </p>
            <p><strong>Neuroticism:</strong> {{ subScore1 }}</p>
            <p><strong>Extraversion:</strong> {{ subScore2 }}</p>
            <p><strong>Openness to experience:</strong> {{ subScore3 }}</p>
            <p><strong>Agreeableness:</strong> {{ subScore4 }}</p>
            <p><strong>Conscientiousness:</strong> {{ subScore5 }}</p>
            <p>
                <span class="scrollTxt" (click)="scrollToElem('target')"
                    >Detailed Analysis</span
                >
            </p>
        </div>
    </div>
    <div class="container about-scores">
        <div class="mt-4 mb-4">
            <p>
                It sure can be overwhelming to see such a detailed analysis of
                your report. You can contact Please Coach and take steps for
                yourself if you are seeking any type of mental health care.
            </p>
        </div>
    </div>
    <div class="container about-scores" id="target">
        <div class="">
            <h5>Detailed Analysis</h5>
            <p class="mb-3">
                <strong>Neuroticism:</strong> It describes the tendency to have
                a negative attitude towards self, others, and life in general.
                The possible range of scores on this subscale is 3 to 21, where
                3 indicates low neuroticism, and 21 indicates high neuroticism.
                A high score on this sub-scale indicates feeling vulnerable or
                insecure. People often experience mood swings and struggle to
                deal with difficult situations. A low score indicates a more
                optimistic outlook toward the future with a stable mood and less
                worry.
            </p>
            <p class="mb-3">
                <strong>Extraversion:</strong> It describes the energy one has
                in social situations. The possible range of scores on this
                subscale is 3 to 21, where 3 indicates low extraversion, and 21
                indicates high extraversion. A high score indicates that you
                like being social and going out often. People are extremely
                energetic, extroverted, and can make friends easily. A low score
                indicates introverted behaviors including difficulty making
                friends or being in social gatherings. Difficulty making new
                friends and being more on the reserved side of the spectrum.
            </p>
            <p class="mb-3">
                <strong>Openness to experience:</strong> It refers to a sense of
                adventure and excitement toward the world. The possible range of
                scores on this subscale is 3 to 21, where 3 indicates low
                openness, and 21 indicates high openness. A high score indicates
                curiosity and creativity. People enjoy trying new things and
                have a good imagination. A low score indicates a more
                traditional approach to thinking. People in this category avoid
                change and prefer to do things in a routine.
            </p>
            <p class="mb-3">
                <strong>Agreeableness:</strong> It refers to the tendency to
                keep things flowing in a hassle-free manner. The possible range
                of scores on this subscale is 3 to 21, where 3 indicates low
                agreeableness, and 21 indicates high agreeableness. A high score
                indicates a helping nature with care and concern for people
                around. A low score indicates a stubborn attitude and people in
                this category are often considered to be selfish with less
                compassion for others.
            </p>
            <p class="mb-3">
                <strong>Conscientiousness:</strong> It means the ability to be
                cautious, and careful towards intricate details. The possible
                range of scores on this subscale is 3 to 21, where 3 indicates
                low conscientiousness, and 21 indicates high conscientiousness.
                A high score indicates a goal-oriented and persistent person
                with a strong drive to keep things in order. A low score
                indicates less organized people who often finish work last
                minute and take things as they come.
            </p>
            <p>
                This is a general explanation for each of the personality traits
                measured by the BFI-S along with interpretations of high and low
                scores. If you feel like any of these personality traits is
                hindering your lifestyle and you need to work on it, Please
                Coach can help you. We have some of the best mental health
                professionals who can help you understand yourself better.
            </p>
        </div>
    </div>
    <div class="container">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
</div>
