import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { COMMON_DATA } from "src/app/shared/common";
import clevertap from "clevertap-web-sdk";

import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-financial-stress-test',
  templateUrl: './financial-stress-test.component.html',
  styleUrls: ['./financial-stress-test.component.css']
})
export class FinancialStressTestComponent implements OnInit {

  freeReportForm: FormGroup;
  userForm2: FormGroup;
  hideModal = true;
  firstPart: boolean = true;
  secondPart: boolean = false;
  currentIndex = 0;
  answers = COMMON_DATA.financial_stress_option;
  questions = COMMON_DATA.financial_stress_question;
  progressPercentage = 0;
  userCountryCode = {
    countryCode: localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "in",
    dialCode: localStorage.getItem("dialCode") ? localStorage.getItem("dialCode") : "91",
  };
  value: number;
  currentFormControlName = 'queNo1';
  options: Options = {
    floor: 1,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true,
    showSelectionBar: true,
    stepsArray: [
      {value: 1, legend: 'Overwhelming stress'},
      {value: 2},
      {value: 3},
      {value: 4, legend: 'High stress'},
      {value: 5},
      {value: 6},
      {value: 7, legend: 'Low stress'},
      {value: 8},
      {value: 9},
      {value: 10, legend: 'No stress at all'}
    ]
  };

  constructor(
    private reportService: FreeReportService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) { }

  ngOnInit() {
    // console.log(this.value);
    this.initForms();
    this.initForms2();
    if (clevertap) {
      clevertap.event.push("Free Report Started", {
        "Report Name": "Financial Stress",
      })
    }
  }
  checkError(valid) {
    if (!valid) {
      this.userForm2.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  previousPage() {
    this.currentIndex--;
  }
  currentIndexInc() {
    this.currentIndex++;
    console.log(this.freeReportForm.value);
    this.currentFormControlName = 'queNo2'
    // console.log(this.freeReportForm)
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction1 = function () {
    this.firstPart = true;
    this.secondPart = false;
  };
  showModal() {
    if (this.freeReportForm.invalid) {
      return;
    }
    this.hideModal = false;
  }

  onSubmit() {
    if (this.userForm2.invalid) {
      return;
    }
    let ansObj = {
      questionsAttempted: this.freeReportForm.value,
      ...this.userForm2.value,
      countryCode: this.userCountryCode
    };
    console.log("ansObj",ansObj);
    clevertap.profile.push({
      "Site": {
        "Name": this.userForm2.value.fullname,
        "Email": this.userForm2.value.email,
        "Phone": "+" + this.userCountryCode.dialCode + this.userForm2.value.phone
      }
    })
    this.reportService.generateReport(ansObj, "financial-stress").subscribe((data) => {
      this.hideModal = true;
      this.router.navigate([
        "../free-report/financial-stress-test-results/" +
        data["freeReportId"],
      ]);
    });
  }

  initForms() {
    let group = {};
    this.questions.forEach((input_template, index) => {
      group[input_template["label"]] = new FormControl("", Validators.required);
    });
    console.log(this.questions)
    this.freeReportForm = new FormGroup(group);
    console.log(this.freeReportForm.controls)
    this.freeReportForm.valueChanges.subscribe((data) => {
      let answers = 0;
      Object.values(data).forEach((value) => {
        console.log(value);
        if (value !== '') {
          answers++;
        }
      })
      console.log("total answer",this.answers)
      this.progressPercentage = (answers / Object.values(data).length) * 100;
    })
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  initForms2() {
    this.userForm2 = new FormGroup({
      email: new FormControl(localStorage.getItem("email") ? localStorage.getItem("email") : "", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl(localStorage.getItem("phone") ? localStorage.getItem("phone") : "", Validators.required),
      fullname: new FormControl(localStorage.getItem("name") ? localStorage.getItem("name") : "", Validators.required),
    });
  }
  indexTracker(index: number, value: any) {
    return index;
  }
  

}
