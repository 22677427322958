<div class="pageWrap" (click)="closenavbar()">
    <div *ngIf="doReportShimmer; else displayNoneMainShimmer">
        <ngx-skeleton-loader
            appearance="line"
            [theme]="{
                'border-radius': '12px',
                height: '250px',
                display: 'flex !important',
                'margin-top': '3px',
                'margin-right': '10px'
            }"
        ></ngx-skeleton-loader>
    </div>
    <ng-template #displayNoneMainShimmer>
        <div class="welcomeBoxWrap">
            <div class="contentBoxWrap">
                <h3>You Are On A Roll</h3>
                <p>Believe us, Numbers do not lie.</p>
                <div class="sessionButton">
                    <a routerLink="../sessions-report" class="thm-2"
                        >Access Sessions Report</a
                    >
                </div>
            </div>
            <div class="welcomeImage">
                <img
                    src="../../../assets/images/notes.svg"
                    class="img-fluid"
                    draggable="false"
                    alt=""
                />
            </div>
        </div>
    </ng-template>

    <div class="sectionPadding-top">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div *ngIf="doReportShimmer; else displayNoneShimmer">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '200px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneShimmer>
                    <div class="repostBoxWrap">
                        <h2>{{ reportNews?.clientsTillDate }}</h2>
                        <p>Total Clients Served</p>
                    </div>
                </ng-template>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div *ngIf="doReportShimmer; else displayNoneActiveShimmer">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '200px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneActiveShimmer>
                    <div class="repostBoxWrap">
                        <h2>{{ reportNews?.activeClients }}</h2>
                        <p>Active Clients</p>
                    </div>
                </ng-template>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div *ngIf="doReportShimmer; else displayNoneEarningsShimmer">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '200px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneEarningsShimmer>
                    <div class="repostBoxWrap">
                        <h3>{{ reportNews?.totalEarning }}</h3>
                        <p>Total Earnings So Far</p>
                    </div></ng-template
                >
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div *ngIf="doReportShimmer; else displayNoneHoursShimmer">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '200px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneHoursShimmer>
                    <div class="repostBoxWrap">
                        <h2>{{ reportNews?.sessionsDone }}</h2>
                        <p>Total Hours Spent</p>
                    </div></ng-template
                >
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div *ngIf="doReportShimmer; else displayNoneFirstShimmer">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '200px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneFirstShimmer>
                    <div class="repostBoxWrap">
                        <h4>
                            {{
                                reportNews?.firstCompletedSession?.startDate
                                    | date : 'dd-MM-yyyy'
                            }}
                        </h4>
                        <p>First Session Date</p>
                    </div></ng-template
                >
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div
                    *ngIf="
                        doReportShimmer;
                        else displayNoneaverageRatingShimmer
                    "
                >
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '200px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneaverageRatingShimmer>
                    <div class="repostBoxWrap">
                        <h2 *ngIf="reportNews?.averageRating; else elsePart">
                            {{ averageRating }}
                        </h2>
                        <ng-template #elsePart><h2>-</h2></ng-template>
                        <p>Average Rating</p>
                    </div></ng-template
                >
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div
                    *ngIf="
                        doReportShimmer;
                        else displayNonehighestRatingShimmer
                    "
                >
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '100px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNonehighestRatingShimmer>
                    <div class="repostAverageScore bg-orange">
                        <h4>Highest Rating</h4>
                        <h3>{{ reportNews?.highestRating }}</h3>
                    </div></ng-template
                >
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div
                    *ngIf="doReportShimmer; else displayNonelowestRatingShimmer"
                >
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '100px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNonelowestRatingShimmer>
                    <div class="repostAverageScore bg-orange">
                        <h4>Lowest Rating</h4>
                        <h3>{{ reportNews?.lowestRating }}</h3>
                    </div></ng-template
                >
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div *ngIf="doReportShimmer; else displayNoneAverageShimmer">
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '100px',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #displayNoneAverageShimmer>
                    <div class="repostAverageScore bg-blue">
                        <h4>Please Coach Average</h4>
                        <h3>{{ reportNews?.PleasecoachAverage }}</h3>
                    </div></ng-template
                >
            </div>
        </div>
    </div>
</div>
