<main (click)="closenavbar()" class="topMenuMargin">
  <section class="single-counsellor-section" *ngIf="isProfileActive">
    <div class="mainContainerHolder">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4 col-lg-3 text-center">
            <div class="counsellor-img-wrapper">
              <img
                src="{{ therapist?.imageURL }}"
                alt="{{ therapist?.firstname }} {{ therapist?.lastname }}"
                onerror="this.src='../../../../assets/images/user.png';"
                class="w-100"
              />
              <div class="single-counsellor-info text-center">
                <h2 class="single-counsellor-name text-capitalize">
                  {{ therapist?.firstname }} {{ therapist?.lastname }}
                </h2>
                <p
                  class="single-counsellor-title"
                  *ngIf="
                    therapist?.jobqualification == 'Other (Please Mention)';
                    else elsepart
                  "
                >
                  {{ therapist?.jobqualificationOtherOptionAnswer }}
                </p>
                <ng-template #elsepart>
                  <p class="single-counsellor-title">
                    {{ therapist?.jobqualification }}
                  </p>
                </ng-template>
                <div class="single-counsellor-contact-icons">
                  <fa-icon *ngIf="therapist?.videoTherapy" [icon]="faVideo"></fa-icon>
                  <a *ngIf="therapist?.audioTherapy"><i class="fa fa-microphone"></i></a>
                  <fa-icon *ngIf="therapist?.chatTherapy" [icon]="faCommentAlt"></fa-icon>
                </div>
              </div>
            </div>
            <a
              (click)="viewCalendar()"
              class="primaryBtn book-btn text-capitalize mt-md-5"
              >Book Session</a
            >
          </div>
          <div class="col-md-8 col-lg-9 mt-5 mt-md-0">
            <div class="row mb-0 mb-md-4 ms-lg-4 ms-md-1 ms-0">
              <div class="col-md-6">
                <div class="counsellor-data d-flex align-items-start">
                  <img
                    src="../../../../assets/images/graduation-cap-icon.svg"
                    alt="graduation cap icon"
                  />
                  <div class="data-info ms-4">
                    <p class="data-title">Qualification</p>
                    <span
                      class="data-desc text-capitalize"
                      *ngIf="
                        therapist?.jobqualification == 'Other (Please Mention)';
                        else elsepart
                      "
                      >{{ therapist?.jobqualificationOtherOptionAnswer }}</span
                    >
                    <ng-template #elsepart>
                      <span class="data-desc text-capitalize">
                        {{ therapist?.jobqualification }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="counsellor-data d-flex align-items-start">
                  <img
                    src="../../../../assets/images/language-icon.svg"
                    alt="language icon"
                  />
                  <div class="data-info ms-4">
                    <p class="data-title">Languages Spoken</p>
                    <span class="data-desc text-capitalize">{{
                      therapist?.selectedLanguageArray.join(", ")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-0 mb-md-4 ms-lg-4 ms-md-1 ms-0">
              <div class="col-md-6">
                <div class="counsellor-data d-flex align-items-start">
                  <img
                    src="../../../../assets/images/star-icon.svg"
                    alt="star icon"
                  />
                  <div class="data-info ms-4">
                    <p class="data-title">Specialization</p>
                    <span
                      class="data-desc text-capitalize"
                      *ngIf="therapist?.specializeareas"
                      >{{ therapist?.specializeareas.join(", ") }}</span
                    >
                    <span
                      class="data-desc text-capitalize"
                      *ngIf="!therapist?.specializeareas"
                      >-</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="counsellor-data d-flex align-items-start">
                  <img
                    src="../../../../assets/images/profile-icon.svg"
                    alt="next available at"
                  />
                  <div class="data-info ms-4">
                    <p class="data-title">Therapeutic Expertise</p>
                    <span
                      class="data-desc text-capitalize"
                      *ngIf="therapist?.therapeuticExperties"
                      >{{ therapist?.therapeuticExperties.join(", ") }}</span
                    >
                    <span
                      class="data-desc text-capitalize"
                      *ngIf="!therapist?.therapeuticExperties"
                      >-</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-0 mb-md-4 ms-lg-4 ms-md-1 ms-0">
              <div class="col-md-6">
                <div class="counsellor-data d-flex align-items-start">
                  <img
                    src="../../../../assets/images/next-available-at.svg"
                    alt="next available at"
                  />
                  <div class="data-info ms-4">
                    <p class="data-title">Next Available at</p>
                    <span class="data-desc text-capitalize">{{
                      nextAvailableAt
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ms-lg-5">
              <div class="col-md-12">
                <div class="counsellor-about-wrapper">
                  <h2 class="counsellor-about-title mb-4">About</h2>
                  <p class="counsellor-about-text">
                    {{ therapist?.AboutPerson }}
                  </p>
                </div>
                <div
                  class="counsellor-review-wrapper"
                  *ngIf="reviewsData.length > 0"
                >
                  <h2 class="counsellor-review-title">Reviews</h2>
                  <div
                    class="review d-flex align-items-start mt-3"
                    *ngFor="let review of reviewsData"
                  >
                    <div class="review-info w-100 ms-4">
                      <div
                        class="name-date-star-wrapper d-md-flex d-block"
                        [attr.data-value]="[review?.initials]"
                      >
                        <div
                          class="user-name-date-wrapper d-flex"
                          *ngIf="review?.initials"
                        >
                          <a class="user-name me-4">{{ review?.username }}</a>
                          <!-- customer Name -->
                          <span class="date">{{
                            review?.addedOn | date: "mediumDate"
                          }}</span>
                        </div>
                      </div>
                      <p class="review-text">
                        {{ review?.review }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 d-flex justify-content-center">
                    <div class="pagination" *ngIf="showNext && !isLoading">
                      <button
                        class="page-btn nextBtnCss"
                        (click)="nextPage()"
                        *ngIf="showNext"
                      >
                        Load More Reviews
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center loadingSpinner"
                    *ngIf="isLoading"
                  >
                    <div
                      class="spinner-border"
                      style="width: 2rem; height: 2rem"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="deactive-profile" *ngIf="!isProfileActive">
    <div class="message-holder">
      <h2>Ooppss!😔 Looks like this profile is not active anymore.</h2>
      <button class="buttonTheme1 viewAll" routerLink="/our-counselors">
        View All Counselors
      </button>
    </div>
  </section>
</main>
