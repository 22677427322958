<div>
  <div class="secPadding">
    <div class="container">
      <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
      <div class="secondaryHeading">
        <h2>Get Started</h2>
        <hr />
      </div>
      <div class="frgtPassContainer justify-content-center">
        <div class="formRound">
          <form [formGroup]="campaignForm" class="formClass">
            <input
              type="text"
              placeholder="Name*"
              class="col-lg-7"
              formControlName="name"
              [ngClass]="{
                'has-error':
                  !campaignForm.controls.name.valid &&
                  campaignForm.controls.name.touched,
                'has-success':
                  campaignForm.controls.name.valid &&
                  campaignForm.controls.name.touched
              }"
            />
            <div
              *ngIf="
                campaignForm.controls.name.errors?.required &&
                campaignForm.controls.name.touched
              "
              class="errorContainer"
            >
              <p>Name is required</p>
            </div>
            <input
              type="text"
              placeholder="Email*"
              class="col-lg-7"
              formControlName="email"
              [ngClass]="{
                'has-error':
                  !campaignForm.controls.email.valid &&
                  campaignForm.controls.email.touched,
                'has-success':
                  campaignForm.controls.email.valid &&
                  campaignForm.controls.email.touched
              }"
            />
            <div
              *ngIf="
                campaignForm.controls.email.errors?.required &&
                campaignForm.controls.email.touched
              "
              class="errorContainer"
            >
              <p>Email is required</p>
            </div>
            <div
              *ngIf="
                campaignForm.controls.email.errors?.pattern &&
                campaignForm.controls.email.touched
              "
              class="errorContainer"
            >
              <p>Email is invalid</p>
            </div>
            <div class="col-lg-7">
              <div class="phoneInputHolder">
                <input
                  type="text"
                  id="phoneInput"
                  formControlName="phone"
                  class="phoneControl"
                  [ngClass]="{
                    'has-error': campaignForm.controls.phone.errors?.invalidPhone
                  }"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                />
              </div>
            </div>
            <div
              class="errorContainer"
              *ngIf="campaignForm.controls.phone.errors?.invalidPhone"
            >
              <p>Please enter a valid phone number.</p>
            </div>
            <div
              *ngIf="
                campaignForm.controls.phone.errors?.required &&
                campaignForm.controls.phone.touched
              "
              class="errorContainer"
            >
              <p>Phone number is required</p>
            </div>
            <div
              *ngIf="
                campaignForm.controls.phone.errors?.pattern &&
                campaignForm.controls.phone.touched
              "
              class="errorContainer"
            >
              <p>Phone number is invalid</p>
            </div>
            <div class="col-lg-7">
              <ng-select
              [items]="states"
              [(ngModel)]="selectedState"
              [closeOnSelect]="true"
              [ngModelOptions]="{standalone: true}"
            ></ng-select>
            </div>
            <div class="errorContainer" *ngIf="campaignForm.invalid">
              <p>All the fields with * are required.</p>
            </div>
            <div class="col-lg-7 couponMainHolder" *ngIf="isCouponShow">
              <div class="couponHolder">
                <input
                  type="text"
                  placeholder="Coupon(if any)"
                  formControlName="coupon"
                />
                <button
                  *ngIf="campaignForm.value.coupon !== ''"
                  class="buttonTheme1"
                  (click)="checkCoupon()"
                >
                  Apply
                </button>
              </div>
            </div>
            <div class="selectAPlan" *ngIf="!paramPlan">
              <h2>Choose your plan :</h2>
            </div>
            <div class="plansHolder" *ngIf="!paramPlan">
              <div id="scroll">
                <ul *ngIf="plans?.length > 0">
                  <li
                    *ngFor="let plan of plans; let inx = index"
                    [ngClass]="{ borderColor: plan.isSelected }"
                    (click)="selectPlan(inx)"
                  >
                    <div class="priceBox">
                      <p *ngIf="plan.discountedPrice">
                        <strong>{{ plan.discountedPrice }} </strong>INR
                      </p>
                      <p
                        [ngClass]="{
                          strikeThrough: plan.discountedPrice ? true : false
                        }"
                      >
                        <strong>{{ plan.price }} </strong>INR
                      </p>
                    </div>
                    <a>
                      <img
                        src="../../../assets/images/campaign/{{ plan.key }}.jpg"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="paramPlan" *ngIf="paramPlan">
              <div class="priceBox">
                <p *ngIf="selectedPlan.discountedPrice">
                  <strong>{{ selectedPlan.discountedPrice }} </strong>INR
                </p>
                <p
                  [ngClass]="{
                    strikeThrough: selectedPlan.discountedPrice ? true : false
                  }"
                >
                  <strong>{{ selectedPlan.price }} </strong>INR
                </p>
              </div>
              <a>
                <img
                  src="../../../assets/images/campaign/{{
                    selectedPlan.key
                  }}.jpg"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="text-center submitBtn">
              <button class="buttonTheme1" (click)="initPayment()">
                Continue
              </button>
            </div>
            <div class="agreeTxt">
              <p>
                By signing up, you agree to our
                <a
                  >Terms Of Service</a
                >
                and acknowledge that you have read our
                <a
                  >Privacy Policy</a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="faqHolder">
    <div class="faqHeading">
      <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>How many sessions do I need?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                What you need is extremely specific to your own needs. You can
                vent out & feel better in one session, however, we recommend a
                minimum of 3 sessions for anyone to experience meaningful change
                in their thoughts & behaviour.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div class="faqQue">
                <h4>What if I don’t want to do a video session?</h4>
              </div>
            </div>
            <div class="flip-card-back">
              <p>
                You can choose your preferred medium for the session – we give
                all options for your 45 minutes of session – video, audio or
                chat.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>Is there an EMI option?</h4>
            </div>
            <div class="flip-card-back">
              <p>Yes, you just have to choose it while checking out.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h4>When can I take the session?</h4>
            </div>
            <div class="flip-card-back">
              <p>
                Whenever you want! We have continuous appointments available
                from 6 AM IST to 2 AM IST.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="confuCont">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 confusedCss">
          <p>
            Still confused?🤔
            <button class="buttonTheme1" (click)="chatWithUs()">
              Chat with Us
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="processingScreen" *ngIf="isPaymentProcessing">
    <div class="overlay-content">
      <div class="spinner-border loadingSpin" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <p>
        Please wait while we are processing your payment. Please do not press
        back or navigate away from this page.
      </p>
    </div>
  </div>
</div>
