import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { environment } from "src/environments/environment";
import { BlogService } from "../../blog.service";
import { DiscussionForumService } from "../../discussion-forum.service";
import { SubscriptionService } from "../../subscription.service";
import { DomSanitizer } from '@angular/platform-browser';
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Router } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import clevertap from "clevertap-web-sdk";
import { debounce } from "lodash";
import { SeoMetaService } from "src/app/seo-meta.service";

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: "app-pleasecoach-blog",
  templateUrl: "./pleasecoach-blog.component.html",
  styleUrls: ["./pleasecoach-blog.component.css"]
})
export class PleasecoachBlogComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2') slickModal2: SlickCarouselComponent;
  @ViewChild('slickModal3') slickModal3: SlickCarouselComponent;
  @ViewChild('slickModal4') slickModal4: SlickCarouselComponent;

  categories = [];
  isShimmerLoader = true;
  featuredBlog;
  imageUrl = environment.apiUrl;
  latestBlogs = [];
  categoryFilter = '';
  testimonials = [
    {
      name: "Eren Jaegar",
      text: "You are not supposed to read this. I dont why you are still reading this!",
      rating: 1,
    },
    {
      name: "Eren Jaegar",
      text: "You are not supposed to read this. I dont why you are still reading this!",
      rating: 4,
    },
    {
      name: "Eren Jaegar",
      text: "You are not supposed to read this. I dont why you are still reading this!",
      rating: 3,
    },
    {
      name: "Eren Jaegar",
      text: "You are not supposed to read this. I dont why you are still reading this!",
      rating: 1,
    },
    {
      name: "Eren Jaegar",
      text: "You are not supposed to read this. I dont why you are still reading this!",
      rating: 5,
    },
  ];
  subsForm = new FormGroup({
    email: new FormControl("", Validators.required),
  });
  blogImageUrl = '';
  topCategories = [];
  freeReports = [
    {
      name: 'Anxiety',
      image: '/assets/images/anxiety-test.jpg',
      route: 'free-report/anxiety-test',
      desc: 'The Generalized Anxiety Disorder Scale-7 (GAD-7) is a self-rated scale developed by Spitzer and colleagues as a screening tool and severity indicator for GAD. It is easily scored and helps in diagnosis of GAD.',
      showMore: false,
    },
    {
      name: 'Depression',
      image: '/assets/images/depression-test.jpg',
      route: 'free-report/depression-test',
      showMore: false,
      desc: 'The Patient Health Questionnaire (PHQ)-9 is the major depressive disorder (MDD) module of the full PHQ. It is used to provisionally diagnose depression and grade severity of symptoms. It quantifies depression symptoms and monitors severity.'
    },
    {
      name: 'Adverse Childhood',
      image: '/assets/images/ACE.jpg',
      route: 'free-report/adverse-childhood-experiences-questionnaire',
      showMore: false,
      desc: 'Adverse Childhood Experiences (ACEs) are the number of traumatic experiences a person had in their early to late childhood. The ACE Questionnaire is built to determine the level of risk for various mental health conditions due to adverse experiences in early childhood.'
    },
    {
      name: 'Anger',
      image: '/assets/images/Anger.jpg',
      route: 'free-report/angry-test',
      showMore: false,
      desc: 'The Clinical Anger Scale (CAS) is used by healthcare professionals to estimate the degree of Clinical Anger (Chronic and pervasive anger). Clinical anger differs from the common emotion in terms of the distress caused to the individual and the time period for which the person has experienced the emotion.'
    },
    {
      name: 'OCD',
      image: '/assets/images/OCD.jpg',
      route: 'free-report/obsessive-compulsive-disorder-test',
      showMore: false,
      desc: 'Yale-Brown Obsessive-Compulsive Scale (Y-BOCS) is a test to rate the severity of obsessive–compulsive disorder (OCD) symptoms. This scale, which measures obsessions separately from compulsions, specifically measures the severity of symptoms of obsessive–compulsive disorder.'
    },
    {
      name: 'PTSD',
      image: '/assets/images/PTSD Test.jpg',
      route: 'free-report/post-traumatic-stress-disorder-test',
      showMore: false,
      desc: 'PCL-5 Questionnaire for is used to assess the degree and severity of Post-Traumatic Stress disorder using the 20 symptoms listed for the same in the Diagnostic Statistics Manual-5 (DSM-5). This questionnaire asks about trauma events that happened to you directly, something you witnessed, or something you learned happened to a close family member or close friend. '
    },
    {
      name: 'Prolonged Grief',
      image: '/assets/images/GriefImage.webp',
      route: 'free-report/grief-test',
      showMore: false,
      desc: 'The International Prolonged Grief Disorder Scale (IPGDS) is a self-report test created by the WHO ICD-11 Working Group, to diagnose Prolonged Grief Disorder. It is characterized by a feeling of longing and excessive preoccupation with the deceased and has a very particular set of symptoms. '
    },
    {
      name: 'ADHD',
      image: '/assets/images/ADHD.webp',
      route: 'free-report/adhd-test',
      showMore: false,
      desc: 'The Adult Self-Report Scale (ASRS) Screener is a test developed by World Health Organization (WHO) to help you recognize the signs and symptoms of adult ADHD. ADHD is not a “one size fits all” disorder and many factors must be considered before a definitive diagnosis is made and an appropriate treatment is found.'
    }
    // {
    //   name : 'Obsessive Compulsive Disorder Test',
    //   image : '/assets/images/OCD.jpg',
    //   route : 'obsessive-compulsive-disorder-test'
    // },
    // {
    //   name : 'Post Traumatic Stress Disorder (PTSD)',
    //   image : '/assets/images/PTSD Test.jpg',
    //   route : 'post-traumatic-stress-disorder-test'
    // }
  ]
  freeExercises = ['#', '#'];
  expertTalks = ['#', '#', "#"];
  videosAlsoLike = ["#", "#", "#", "#"]
  safeExerciseUrls = [];
  safeExpertUrls = [];
  safeVideoAlsoLike = [];
  userReviews = [{
    text: 'Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.',
    name: 'Sana Javed',
    work: 'Software Engineer'
  }, {
    text: 'I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.',
    name: 'Puja Mittal',
    work: "Homemaker"
  }, {
    text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot and it worked out well.",
    name: "Vikas Arora",
    work: "CFO"
  }, {
    text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
    name: "Aishwarya Hegde",
    work: "Student"
  }]
  testimonialsSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
    ]
  }

  expertSpeaksConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
  }

  youMayAlsoLikeConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        }
      }
    ]
  }
  innerWidth: number;
  itemsPerSlide = 3;
  mobileBreakpoint = 360;
  query = new FormControl("");
  constructor(
    private sidenavService: SideNavBarService,
    private discussionFormService: DiscussionForumService,
    private subscriptionService: SubscriptionService,
    private alertService: BootstrapAlertService,
    private blogService: BlogService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private metaSeo: SeoMetaService
  ) {
    this.onSearch = debounce(this.onSearch, 800);
  }

  // shimmer
  fakeArray(length: number): Array<any> {
    if (length >= 0) {
      return new Array(length);
    }
  }

  ngOnInit(): void {
    this.metaSeo.updateMetaInfo(
      "The most convenient way to access counseling & therapy - anytime, anywhere, any device. Connect with the best in class psychologists over chat or video sessions. Get matched now.",
      "Online counseling, online therapy, psychologists near me, clinical psychologist, psychologist, counselor, therapist, talk therapy, counseling app, therapy app"
    );
    this.adjustsItemsPerSlide();
    this.discussionFormService.getCategories().subscribe((data) => {
      this.categories = data["category"];
      this.topCategories = this.categories
    });
    // this.blogService.getFeaturedBlog().subscribe((res) => {
    //   this.isShimmerLoader = false;
    //   this.featuredBlog = res;
    // })
    // this.blogService.getLatestBlogs().subscribe((res) => {
    //   this.latestBlogs = res;
    // })
    this.freeExercises.forEach((url) => {
      this.safeExerciseUrls.push(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    })
    this.expertTalks.forEach((url) => {
      this.safeExpertUrls.push(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    })
    this.videosAlsoLike.forEach((url) => {
      this.safeVideoAlsoLike.push(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    })

  }

  onSearch() {
    if (this.query.value.trim() == "" && this.categoryFilter == null) { return }
    if (this.query.value.trim() != "" || this.categoryFilter != "") {
      this.router.navigate(['../all-blogs'], {
        queryParams: {
          search: this.query.value.trim(), category: this.categoryFilter
        }
      })
      this.isShimmerLoader = false;
    }
  }

  private adjustsItemsPerSlide() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.itemsPerSlide = 1;
    } else {
      this.itemsPerSlide = 3;
    }
  }


  closenavbar() {
    this.sidenavService.closesidenav();
  }
  getFormControls() {
    return this.subsForm.controls;
  }

  showMoreToggle(index) {
    this.freeReports[index].showMore = !this.freeReports[index].showMore
  }

  onSubmit() {
    this.subscriptionService
      .subscribe(this.subsForm.value.email)
      .subscribe((res) => {
        if (res) {
          clevertap.event.push("Email Subscribed");
          this.alertService.alert(
            new BootstrapAlert(
              "You have successfully subscribed!",
              "alert-success"
            )
          );
          this.subsForm.reset();
        }
      });
  }
}
