<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <strong>
                <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong
            >This tool should be used for screening and monitoring symptom
            severity and cannot replace a clinical assessment and diagnosis. You
            are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <br />
        <div class="report-user">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p class="mt-3"><strong>Your Score:</strong></p>
            <p>Your total score is {{ socialAnxietyScore }}</p>
            <p class="mt-2"><strong>Your Result:</strong></p>
            <p>
                Your responses indicate
                <span
                    >{{ socialAnxietyPhrase }}.
                    <span class="scrollTxt" (click)="scrollToElem('target')"
                        >Detailed Analysis</span
                    >
                </span>
            </p>
        </div>
    </div>

    <div class="container graph-section mt-3">
        <div>
            <div *ngIf="socialAnxietyScore">
                <p
                    class="mb-3"
                    *ngIf="socialAnxietyScore > 0 && socialAnxietyScore <= 29"
                >
                    Your results may not be a sign of concern, but read on to
                    find out a more detailed analysis of your score as well as a
                    few suggestions from Please Coach that you can always use to
                    improve your lifestyle.
                </p>

                <p
                    class="mb-3"
                    *ngIf="socialAnxietyScore >= 30 && socialAnxietyScore <= 64"
                >
                    Your results may be slightly concerning, but do not worry
                    too much - Please Coach has got you covered. We offer a
                    variety of services that you can access at any time,
                    anywhere.
                </p>
                <p class="mb-3" *ngIf="socialAnxietyScore >= 65">
                    Seeing the report can be overwhelming, but Please Coach has
                    got you covered. We offer a variety of services that you can
                    access at any time, anywhere.
                </p>
            </div>

            <div class="report-user">
                <h3>Understanding Your Social Anxiety Assessment</h3>
            </div>
            <div class="graph-right">
                <img
                    src="../../../assets/images/free-report-graphs/social-anxiety.png"
                />
            </div>
        </div>
    </div>

    <div class="container scores-section mb-4">
        <div class="about-scores report-user">
            <h5>Your Score Interpretation</h5>
            <p class="mb-3">
                The total range of scores is 0 to 144, where 0 indicates ‘no
                social anxiety’ and 144 indicates ‘severely high social
                anxiety’.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="socialAnxietyScore > 0 && socialAnxietyScore <= 29"
            >
                A score range of 0 to 29 indicates no social anxiety. This is a
                good state to be in as it helps you be healthy in all spheres of
                your life.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="socialAnxietyScore >= 30 && socialAnxietyScore <= 64"
            >
                A score ranging from mild to moderate anxiety can have symptoms
                with moderate intensity which can affect one’s social and
                interpersonal life. This can have overwhelming effects on your
                life and is a difficult phase to live in. You can try dealing
                with it on your own using certain mindfulness practices but if
                it seems unbearable, you can always get in touch with a mental
                health professional who can help you with everything in a
                systematic manner. Please Coach can help you with that.
            </p>
            <p class="para_scores mb-3" *ngIf="socialAnxietyScore >= 65">
                A score ranging from marked social anxiety to very severe
                anxiety is very distressing for the person experiencing it. You
                might be struggling a lot in all spheres of life which might be
                an additional source of stress, anxiety and depression for you.
                This phase of life is difficult but you have Please Coach to
                help you. It is better to seek help than to suffer alone with no
                hope in sight.
            </p>
        </div>
    </div>

    <!-- TODO: Add CSS -->
    <div class="container about-scores">
        <div class="">
            <h5 class="mb-3">Detailed Analysis</h5>
            <p class="mb-3">
                This self-report measure of social anxiety is made up of 4
                factors. They are - interaction with strangers, speaking in
                public or interaction with public authorities, eating/drinking
                in front of strangers, working/writing/talking by phone in front
                of other people. These can be highly uncomfortable for people
                with social anxiety. They may either endure this situation with
                extreme discomfort or avoid the situation completely.
            </p>
            <p class="mb-3">
                You may get in touch with Please Coach to seek help and get the
                best service possible!
            </p>
        </div>
    </div>
    <div class="container">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
    <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Please Coach disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
</div>
