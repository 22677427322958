<div class="bodyPageWrap">
    <div style="display: flex; align-items: center; margin-bottom: 20px">
        <span
            ><a
                routerLink="../helpdesk"
                style="
                    font-size: 16px;
                    color: #445e93;
                    cursor: pointer;
                    text-decoration: underline;
                "
                >Helpdesk</a
            ></span
        >
        <span style="font-size: 16px">&nbsp;>&nbsp;</span>
        <span style="font-size: 16px; color: #808080"
            >Benefits Related FAQ's</span
        >
    </div>
    <div>
        <div class="frequentHeadWrap">
            <div class="row">
                <div class="col-md-12">
                    <div class="sectionHeading">
                        <h2>Benefits Related FAQ's</h2>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentQuestionBox">
            <div class="row">
                <div id="1" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>
                            Why should I work on Please Coach and not start my
                            own practice or take up full time employment?
                        </h4>
                        <p class="ptagCss">
                            Please Coach takes care of getting you new clients
                            so that you can focus on your core competence –
                            therapy. New client acquisition is both an expensive
                            and effort intensive process. We handle that for
                            your entirely.
                        </p>
                        <p class="ptagCss">
                            You incur zero overheads to run your practice, at no
                            cost you can acquire and serve clients.
                        </p>
                        <p class="ptagCss">
                            You work on your own terms – choose how much you
                            want to work and exactly when.
                        </p>
                        <p class="ptagCss">
                            Start earning right away – no gestation period and
                            very high earning potential.
                        </p>
                    </div>
                </div>
                <div id="2" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>Is there a therapist referral policy?</h4>
                        <p class="ptagCss">
                            Yes. If you refer a therapist to Please Coach and
                            she gets hired, you get paid a referral bonus after
                            their first 10 sessions.
                        </p>
                    </div>
                </div>
                <div id="3" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>
                            Can I route my own clients to the Please Coach
                            portal?
                        </h4>
                        <p class="ptagCss">
                            Yes. In such a case you are charged just for the
                            platform depending on the number of people you
                            refer. You can get in touch with your counsellor
                            partner to discuss this in detail.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentHeadWrap">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <h3>
                            Have More Questions?
                            <span class="spanCss"
                                >(Access following heads)</span
                            >
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentQuestionBox">
            <div class="row">
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../contract-related-faq"
                                >Contract Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../talk-therapy-faq"
                                >Talk therapy Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../payment-related-faq"
                                >Payment Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../platform-related-faq"
                                >Platform Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../account-related-faq"
                                >Account Related</a
                            ></b
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
