<div style="display: flex; align-items: center; margin-bottom: 20px">
    <span
        ><a
            routerLink="../helpdesk"
            style="
                font-size: 16px;
                color: #445e93;
                cursor: pointer;
                text-decoration: underline;
            "
            >Helpdesk</a
        ></span
    >
    <span style="font-size: 16px">&nbsp;>&nbsp;</span>
    <span style="font-size: 16px; color: #808080">View Old Requests</span>
</div>
<div class="radioButtonwrap">
    <div class="filterCss">
        <input
            type="radio"
            name="userdetails"
            value="open"
            [checked]="true"
            (click)="onFilterRequests('OPEN')"
        />
        <span>Open Tickets</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <input
            type="radio"
            name="userdetails"
            value="closed"
            (click)="onFilterRequests('CLOSED')"
        />
        <span>Closed Tickets</span>
    </div>
</div>

<div *ngIf="isRequestLoading; else dataLoded">
    <div class="row">
        <div class="col-md-6">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '100%',
                    height: '430px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
        <div class="col-md-6">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '100%',
                    height: '430px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
        <div class="col-md-6">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '100%',
                    height: '430px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
        <div class="col-md-6">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '100%',
                    height: '430px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
    </div>
</div>
<ng-template #dataLoded>
    <div class="row">
        <div
            class="detailsGroup d-flex flex-wrap"
            *ngFor="let i of filteredTickets"
        >
            <div class="row">
                <div class="detailsDivtag">
                    <b>Ticket Number</b>
                </div>
                <div class="detailFetchTag">
                    {{ i.ticketNumber }}
                </div>
            </div>
            <div class="row">
                <div class="detailsDivtag">
                    <b>Category</b>
                </div>
                <div class="detailFetchTag">
                    {{ i.grievance }}
                </div>
            </div>
            <div class="row">
                <div class="detailsDivtag">
                    <b>Status</b>
                </div>
                <div class="detailFetchTag">
                    {{ i.status }}
                </div>
            </div>
            <div class="row">
                <div class="detailsDivtag">
                    <b>Date Created</b>
                </div>
                <div class="detailFetchTag">
                    {{ i.createdAt | date : 'dd/MMM/yyyy' }}
                </div>
            </div>
            <div class="row">
                <div class="detailsDivtag">
                    <b>Description</b>
                </div>
                <div class="detailFetchTag newellipse">{{ i.description }}</div>
            </div>
            <div class="row extarDivTagCss">
                <div class="divTagStyle">
                    <a class="thm-2" (click)="redirectTo(i._id)">View</a>
                </div>
            </div>
        </div>
        <div
            class="d-flex filterCss"
            *ngIf="!isLoading && filteredTickets.length == 0"
        >
            <p>There are no {{ currentFilter }} tickets right now.</p>
        </div>
    </div>
</ng-template>
<!-- <div class="pageInWrap">
    <ul uib-pagination="uib-pagination" next-text="&#62;" last-text="&#x25BA;&#124;" first-text="&#124;&#x25C4;" previous-text="&#60;" ng-click="pageChange();" total-items="totalItems" ng-model="pageNo" max-size="maxSize" class="pagination-sm" boundary-links="true" num-pages="numPages"></ul>
</div> -->
