<div class="bodyPageWrap">
  <div>
    <div *ngIf="isShimmerLoading; else BoxLoading">
      <ngx-skeleton-loader
        appearance="line"
        [theme]="{
          'border-radius': '15px',
          height: '200px',
          width: '100%'
        }"
      ></ngx-skeleton-loader>
    </div>
    <ng-template #BoxLoading>
      <div class="welcomeBoxWrap">
        <div class="contentBoxWrap">
          <h3>{{ textoverbanner }}</h3>
        </div>
        <div class="welcomeImageWrap">
          <img
            src="../../../assets/images/3744798.svg"
            class="img-fluid"
            draggable="false"
            alt=""
          />
        </div>
      </div>
    </ng-template>

    <div class="sectionPadding-top">
      <div class="sessionFilterWrap">
        <div *ngIf="isShimmerLoading; else categoryLoading">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '15px',
              height: '30px',
              width: '40%'
            }"
          ></ngx-skeleton-loader>
        </div>

        <ng-template #categoryLoading>
          <ul style="display: flex; flex-direction: row;">
            <li (click)="setCategory('SCHEDULED')">
              <label class="radio">
                <input type="radio" value="SCHEDULED" name="session" checked />
                <span>Upcoming Sessions</span>
              </label>
            </li>
            <li (click)="setCategory('COMPLETED')">
              <label class="radio">
                <input type="radio" name="session" value="COMPLETED" />
                <span>Completed Sessions</span>
              </label>
            </li>
          </ul>
        </ng-template>
      </div>
      <div>
        <div *ngIf="isShimmerLoading; else LoadingDone">
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '330px',
              width: '30%'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '330px',
              width: '30%',
              'margin-left': '20px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '330px',
              width: '30%',
              'margin-left': '20px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '330px',
              width: '30%'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '330px',
              width: '30%',
              'margin-left': '20px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            appearance="line"
            [theme]="{
              'border-radius': '5px',
              height: '330px',
              width: '30%',
              'margin-left': '20px'
            }"
          ></ngx-skeleton-loader>
        </div>

        <ng-template #LoadingDone>
          <div class="row">
            <div class="col-xl-4 col-sm-6" *ngFor="let ses of items">
              <div class="sessionBlockWrap">
                <div class="sessionImageBox">
                  <!-- <img
                  src="/images/{{ ses.userId.image }}"
                  draggable="false"
                  alt="img"
                  class="img-fluid"
                /> -->
                  <img
                    src="{{ imageUrl }}/{{ ses?.therapistId?.imageUrl }}"
                    draggable="false"
                    alt="img"
                    class="img-fluid"
                  />
                </div>
                <div class="connectSectionWrap">
                  <h3>
                    {{ ses?.therapistId?.name }}
                    {{ ses?.therapistId?.lastname }}
                  </h3>
                  <div class="viewProfile">
                    <a
                      routerLink="/community-talk-therapists-detail/{{
                        ses?.therapistId?._id
                      }}"
                      >View Profile</a
                    >
                  </div>
                  <p><strong>Session No. -</strong> {{ ses?.SessionNo }}</p>
                  <p>
                    <strong>{{ ses.sessionDay }} -</strong>
                    {{ ses.sessionDate }}
                  </p>
                  <p>
                    <strong>Time -</strong>
                    {{ ses.sessionStartTime }} to
                    {{ ses.sessionEndTime }}
                  </p>
                  <div
                    *ngIf="
                      ses?.status == 'COMPLETED' ||
                      ses?.status == 'MISSED_USER' ||
                      ses?.status == 'MISSED_THERAPIST'
                    "
                  >
                    <p *ngIf="ses?.ratingId?.rating > 0">
                      <strong>Rating -</strong>

                      <span>
                        <i
                          class="fontCss fa"
                          [ngClass]="{
                            'fa-star-o': ses.ratingId.rating < 1,
                            'fa-star': ses.ratingId.rating > 0
                          }"
                        ></i>
                        <i
                          class="fontCss fa"
                          [ngClass]="{
                            'fa-star-o': ses.ratingId.rating < 1,
                            'fa-star': ses.ratingId.rating > 0
                          }"
                        ></i>
                        <i
                          class="starCss fa"
                          [ngClass]="{
                            'fa-star-o': ses.ratingId.rating < 1,
                            'fa-star': ses.ratingId.rating > 0
                          }"
                        ></i>
                        <i
                          class="starCss fa"
                          [ngClass]="{
                            'fa-star-o': ses.ratingId.rating < 1,
                            'fa-star': ses.ratingId.rating > 0
                          }"
                        ></i>
                        <i
                          class="starCss fa"
                          [ngClass]="{
                            'fa-star-o': ses.ratingId.rating < 1,
                            'fa-star': ses.ratingId.rating > 0
                          }"
                        ></i>
                      </span>
                    </p>
                    <p *ngIf="!ses?.ratingId?.rating">
                      <strong>Rating -</strong>
                      <span>
                        <i class="fontCss" class="fa fa-star-o"></i>
                        <i class="fontCss" class="fa fa-star-o"></i>
                        <i class="fontCss" class="fa fa-star-o"></i>
                        <i class="fontCss" class="fa fa-star-o"></i>
                        <i class="fontCss" class="fa fa-star-o"></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="joinButtonWrap">
                  <a
                    (click)="redirectToV3(ses?._id)"
                    *ngIf="ses.status == 'SCHEDULED' || ses.status == 'STARTED'"
                    class="thm-2"
                    >Join Session</a
                  >
                </div>
                <div class="cancelReportBox">
                  <div
                    class="spinner-border divTagExtra"
                    *ngIf="
                      isCancelSessionLoading.status &&
                      isCancelSessionLoading.id == ses?._id
                    "
                    style="width: 2rem; height: 2rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <a
                    *ngIf="
                      ses?.status == 'SCHEDULED' &&
                      isCancelSessionLoading.status === false
                    "
                    class="divTagExtra"
                    (click)="cancelSession(ses)"
                    >Cancel</a
                  >
                  <a
                    routerLink="../give-rating/{{ ses?._id }}"
                    *ngIf="ses?.status == 'COMPLETED'"
                    >Give Rating</a
                  >
                  <a
                    [routerLink]="['../requestform']"
                    [queryParams]="{ report: 'therapist', sessionId: ses?._id }"
                    class="divTagExtra"
                    *ngIf="
                      ses?.status == 'COMPLETED' ||
                      ses?.status == 'MISSED_USER' ||
                      ses?.status == 'MISSED_THERAPIST'
                    "
                    >Report</a
                  >
                </div>
              </div>
            </div>
            <div
              class="noSessions d-flex"
              *ngIf="!isShimmerLoading && items.length == 0"
            >
              <h5>No sessions available for this category.</h5>
            </div>

            <div *ngIf="isLoading" class="d-flex justify-content-center">
              <!-- <div
                class="spinner-border"
                style="width: 2rem; height: 2rem"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div> -->
              <ng-lottie
                height="150px"
                [options]="options"
                (animationCreated)="animationCreated($event)"
              ></ng-lottie>
              <h5 *ngIf="isLoading" class="loadingText">
                Please wait while we're loading...
              </h5>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="text-center">
        <button *ngIf="showNext" (click)="nextPage()" class="buttonTheme1">
          Load Older Sessions
        </button>
      </div>
    </div>
  </div>
</div>
