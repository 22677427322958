import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { TherapistAuthService } from "src/app/therapist-auth/therapist-auth.service";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { Editor, toHTML } from "ngx-editor";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { DiscussionForumService } from "src/app/user/discussion-forum.service";
import { environment } from "src/environments/environment";
import { TherapistDiscussionFourmService } from "src/app/therapist/therapist-discussion-fourm.service";
import { SeoMetaService } from "src/app/seo-meta.service";
import { ProfileService } from "src/app/user/profile.service";
import clevertap from "clevertap-web-sdk";

@Component({
  selector: "app-discussion-forum",
  templateUrl: "./discussion-forum.component.html",
  styleUrls: ["./discussion-forum.component.css"],
})
export class DiscussionForumComponent implements OnInit {
  faCommentAlt = faCommentAlt;
  categories = [{ name: "All Categories", _id: "" }];
  items = [];
  pageNo = 1;
  pages = [1];
  showNext = false;
  totalCount = 0;
  paginObject = {};
  selectedCategory = "";
  isLoading = true;
  hideModal = true;
  editor: Editor;
  discussionId = null;
  userId = null;
  therapistId = null;
  imageUrl = environment.apiUrl;
  isLoggedIn = false;
  selected = ["OBC", "Anxiety", "Panic Attacks"];
  discussionForm = new FormGroup({
    topic: new FormControl("", Validators.required),
    tags: new FormControl("", Validators.required),
    description: new FormControl("", Validators.required),
    isAnonymous: new FormControl(false),
  });
  userLoggedin: Boolean;
  isDashboardRedirect: Boolean;
  constructor(
    private discussionService: DiscussionForumService,
    private therapistDiscussionService: TherapistDiscussionFourmService,
    private userAuthService: AuthService,
    private userService: ProfileService,
    private therapistAuthService: TherapistAuthService,
    private router: Router,
    private sidenavService: SideNavBarService,
    private actRoute: ActivatedRoute,
    private alertService: BootstrapAlertService,
    private metaSeo: SeoMetaService
  ) { }
  ngOnInit(): void {
    this.editor = new Editor();
    this.metaSeo.updateTitle("Online Counseling | PleaseCoach");
    this.metaSeo.updateMetaInfo(
      "Engage with India's top community on mental wellness. Ask questions & comment anonymously. Connect with India's top psychologists & get answers for your queries.",
      "discussion forum, mental health topics, ask a psychologist, mental health community"
    );
    this.paginObject = {
      page: this.pageNo,
      perPage: 10,
    };
    this.discussionService.getCategories().subscribe((data) => {
      this.categories = this.categories.concat(data["category"]);
    });
    this.getDiscussions();
    this.userId = localStorage.getItem("userId");
    this.therapistId = localStorage.getItem("therapistId");
    if (
      this.userAuthService.getIsAuth() ||
      this.therapistAuthService.getIsAuth()
    ) {
      this.isLoggedIn = true;
    }
    this.userLoggedin = this.userAuthService.getIsAuth();
    if (this.userLoggedin) {
      this.userService.getBookingAvailable().subscribe((res) => {
        let plans = res["bookingAvailable"];
        this.isDashboardRedirect = plans > 0;
      });
    }
  }
  onSubmit() {
    if (this.discussionForm.invalid) {
      return;
    }
    let formData = this.discussionForm.value;
    if (formData.description) {
      formData.description = toHTML(formData.description);
    }
    if (this.userAuthService.getIsAuth()) {
      this.discussionService.createDiscussion(formData).subscribe((data) => {
        if (data) {
          clevertap.event.push("Discussion Started")
          this.getDiscussions();
          this.alertService.alert(
            new BootstrapAlert(
              "Discussion was created successfully",
              "alert-success"
            )
          );
        }
      });
    } else if (this.therapistAuthService.getIsAuth()) {
      this.therapistDiscussionService
        .createDiscussion(formData)
        .subscribe((data) => {
          if (data) {
            this.getDiscussions();
            this.alertService.alert(
              new BootstrapAlert(
                "Discussion was created successfully",
                "alert-success"
              )
            );
          }
        });
    }
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  toggleLike(id) {
    if (this.userAuthService.getIsAuth()) {
      this.discussionService.toggleLikeOnDiscussion(id).subscribe((res) => {
        this.changeIsLiked(id);
      });
    }
    if (this.therapistAuthService.getIsAuth()) {
      this.therapistDiscussionService
        .toggleLikeOnDiscussion(id)
        .subscribe((res) => {
          this.changeIsLiked(id);
        });
    }
    if (!this.isLoggedIn) {
      this.alertService.alert(
        new BootstrapAlert(
          "Please log in first to like discussion forum.",
          "alert-warning"
        )
      );
    }
  }
  changeIsLiked(id) {
    this.items.forEach((item, index) => {
      if (item._id == id) {
        this.items[index]["isLiked"] = !this.items[index]["isLiked"];
        if (this.items[index]["isLiked"]) {
          item.likes++;
        } else {
          item.likes--;
        }
      }
    });
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }
  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    this.pageNo = this.pageNo + 1;
    this.paginObject["page"]++;
    this.getDiscussions();
    this.checkPage();
  }
  setPage(pageNo) {
    this.pageNo = pageNo;
    this.paginObject["page"] = this.pageNo;
    this.getDiscussions();
  }
  getDiscussions() {
    this.discussionService.getForums(this.paginObject).subscribe((data) => {
      this.items = this.items.concat(data["result"]);
      this.items.forEach((item, index1) => {
        this.items[index1]["isLiked"] = false;
        if (this.userAuthService.getIsAuth()) {
          item.likedBy.forEach((like) => {
            if (this.userId == like) {
              this.items[index1]["isLiked"] = true;
            }
          });
        }
        if (this.therapistAuthService.getIsAuth()) {
          item.likedBy.forEach((like) => {
            if (this.therapistId == like) {
              this.items[index1]["isLiked"] = true;
            }
          });
        }
      });
      this.isLoading = false;
      this.totalCount = data["totalCount"];
      let pageCount = Math.ceil(this.totalCount / 10);
      if (pageCount != this.pages.length) {
        for (let i = 0; i < pageCount - 1; i++) {
          if (this.pages.indexOf(i + 2)) {
            this.pages.push(i + 2);
          }
        }
      }
      this.checkPage();
    });
  }
  setCategory(id) {
    this.items = [];
    if (id == "") {
      this.selectedCategory = "";
      delete this.paginObject["tags"];
      this.getDiscussions();
    } else {
      this.selectedCategory = id;
      this.paginObject["tags"] = id;
      this.getDiscussions();
    }
  }
  askQuestion() {
    if (this.userAuthService.getIsAuth()) {
      this.hideModal = false;
      this.discussionForm.reset();
    } else if (this.therapistAuthService.getIsAuth()) {
      this.hideModal = false;
      this.discussionForm.reset();
    } else {
      this.router.navigateByUrl("/login?redirect=user-dashboard/userdiscussionform");
    }
  }

  async copyUrl(val) {
    try {
      let shareUrl = String("https://example.com" + val._id)
      let shareTopic = String(val.topic)
      let shareData = {
        url: shareUrl,
        title: shareTopic,
        text: 'Join this discussion at pleasecoach!'
      }
      let resultShare = await navigator.share(shareData)
    } catch (error) {
      if (error !== 'AbortError: Share canceled') {
        let selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = environment.base_url + "/view-discussion/" + val._id;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this.alertService.alert(
          new BootstrapAlert(
            "Successfully copied url to clipboard!",
            "alert-success"
          )
        );
      }
    }
  }
  indexTracker(index: number, value: any) {
    return index;
  }
}
