<link
    href="https://netdna.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    rel="stylesheet"
/>
<div style="min-height: 100%; margin: 0 auto">
    <div>
        <div
            class="row app-one"
            *ngIf="chatShimmer || chatThreads.length > 0 || isSearch"
        >
            <div
                class="noPadding displayX p-absolute"
                [ngClass]="{ displayShow: isChatListOpen }"
            >
                <div class="col-lg-3 col-sm-12 side">
                    <div class="side-one">
                        <div *ngIf="chatShimmer; else text">
                            <ngx-skeleton-loader
                                appearance="line"
                                [theme]="{
                                    'border-radius': '5px',
                                    height: '57px',
                                    width: '90%',
                                    border: '1px solid white',
                                    'margin-left': '25px'
                                }"
                            >
                            </ngx-skeleton-loader>
                        </div>

                        <ng-template #text>
                            <p
                                class="heading"
                                style="font-size: 16px; padding: 14px 30px"
                            >
                                All Conversations
                            </p>
                        </ng-template>

                        <div *ngIf="chatShimmer; else search">
                            <ngx-skeleton-loader
                                appearance="line"
                                [theme]="{
                                    'border-radius': '5px',
                                    height: '40px',
                                    width: '90%',
                                    border: '1px solid white',
                                    'margin-left': '25px'
                                }"
                            >
                            </ngx-skeleton-loader>
                        </div>

                        <ng-template #search>
                            <div class="row searchBox">
                                <div class="col-sm-12 searchBox-inner">
                                    <div class="form-group has-feedback">
                                        <input
                                            id="searchText"
                                            type="text"
                                            class="form-control"
                                            name="searchText"
                                            placeholder="Search"
                                            (input)="
                                                onSearchChange(
                                                    $event.target.value
                                                )
                                            "
                                        />
                                        <span
                                            class="glyphicon glyphicon-search form-control-feedback"
                                            style="width: 34px"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div *ngIf="chatShimmer; else sidebarData">
                            <ngx-skeleton-loader
                                appearance="line"
                                count="5"
                                [theme]="{
                                    'border-radius': '5px',
                                    height: '57px',
                                    width: '90%',
                                    border: '1px solid white',
                                    'margin-left': '25px'
                                }"
                            >
                            </ngx-skeleton-loader>
                        </div>

                        <ng-template #sidebarData>
                            <div
                                class="row sideBar"
                                *ngIf="
                                    chatThreads.length > 0;
                                    else noSearchresults
                                "
                            >
                                <div
                                    class="row sideBar-body"
                                    *ngFor="
                                        let chat of chatThreads;
                                        let idx = index
                                    "
                                    (click)="changeThread(chat, idx)"
                                    title="{{ chat?.lastMessage?.message }} {{
                                        chat?.lastMessage?.attachment?.name
                                    }}"
                                    [style.background]="
                                        selectedThreadData?.userId?.name ===
                                        chat?.userId?.name
                                            ? '#f2f2f2'
                                            : '#ffffff'
                                    "
                                >
                                    <div>
                                        <div
                                            class="col-sm-3 col-xs-3 sideBar-avatar"
                                        >
                                            <div class="avatar-icon">
                                                <img
                                                    src="{{ imageUrl }}/{{
                                                        chat?.userId?.imageUrl
                                                    }}"
                                                    onerror="this.src='../../../../assets/images/user.png';"
                                                />
                                                <span
                                                    class="onlineStatus"
                                                    *ngIf="
                                                        chat?.userId
                                                            ?.isOnline === true
                                                    "
                                                ></span>
                                                <span
                                                    class="offlineStatus"
                                                    *ngIf="
                                                        chat?.userId
                                                            ?.isOnline === false
                                                    "
                                                ></span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-sm-9 col-xs-9 sideBar-main"
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-sm-8 col-xs-8 sideBar-name"
                                                >
                                                    <div
                                                        class="chatThreadHolder"
                                                    >
                                                        <div
                                                            class="nameStatusHolder"
                                                        >
                                                            <!-- <span class="onlineStatus" *ngIf="chat?.userId?.isOnline"></span> -->
                                                            <!-- <span class="offlineStatus" *ngIf="!chat?.userId?.isOnline"></span> -->
                                                            <span
                                                                class="name-meta"
                                                                >{{
                                                                    chat?.userId
                                                                        ?.name
                                                                }}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="lastMessage"
                                                        *ngIf="
                                                            chat?.lastMessage
                                                                ?.isTyping &&
                                                                chat
                                                                    ?.lastMessage
                                                                    ?.isTyping ==
                                                                    true;
                                                            else lastmessagestatus
                                                        "
                                                    >
                                                        <span
                                                            class="last-meta-typing"
                                                            [style.color]="
                                                                green
                                                            "
                                                            *ngIf="
                                                                chat
                                                                    ?.lastMessage
                                                                    ?.isTyping &&
                                                                chat
                                                                    ?.lastMessage
                                                                    ?.isTyping ==
                                                                    true
                                                            "
                                                            >Typing...
                                                        </span>
                                                    </div>
                                                    <ng-template
                                                        #lastmessagestatus
                                                    >
                                                        <div
                                                            class="lastMessage"
                                                        >
                                                            <span
                                                                class="last-meta"
                                                                [style.color]="
                                                                    chat
                                                                        ?.lastMessage
                                                                        ?.isUnread ==
                                                                    true
                                                                        ? 'black'
                                                                        : 'gray'
                                                                "
                                                                *ngIf="
                                                                    chat
                                                                        ?.lastMessage
                                                                        ?.message
                                                                "
                                                                >{{
                                                                    chat
                                                                        ?.lastMessage
                                                                        ?.message
                                                                }}
                                                            </span>
                                                            <span
                                                                class="last-meta"
                                                                [style.color]="
                                                                    chat
                                                                        ?.lastMessage
                                                                        ?.isUnread ==
                                                                    true
                                                                        ? 'black'
                                                                        : 'gray'
                                                                "
                                                                *ngIf="
                                                                    chat
                                                                        ?.lastMessage
                                                                        ?.attachment
                                                                "
                                                                >Attachment -
                                                                {{
                                                                    chat
                                                                        ?.lastMessage
                                                                        ?.attachment
                                                                        ?.name
                                                                }}</span
                                                            >
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div
                                                    class="col-sm-4 col-xs-4 pull-right sideBar-time"
                                                    *ngIf="chat.lastMessageAt"
                                                >
                                                    <span
                                                        class="time-meta pull-right"
                                                    >
                                                        {{
                                                            chat.lastMessageAt
                                                                | dateFromNow
                                                        }}
                                                    </span>
                                                    <span
                                                        class="unread-message-icon"
                                                        *ngIf="
                                                            chat?.lastMessage
                                                                ?.isUnread
                                                        "
                                                    >
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #noSearchresults>
                                <div
                                    *ngIf="chatThreads.length == 0 && isSearch"
                                    class="no-chats"
                                >
                                    <div>
                                        <img
                                            src="assets/images/chat_icon.svg"
                                            height="150"
                                            width="150"
                                            alt=""
                                        />
                                    </div>
                                    <p>No chats found with your query.</p>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>

                    <div class="side-two">
                        <div class="row newMessage-heading">
                            <div class="row newMessage-main">
                                <div class="col-sm-2 col-xs-2 newMessage-back">
                                    <i
                                        class="fa fa-arrow-left"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                                <div
                                    class="col-sm-10 col-xs-10 newMessage-title"
                                >
                                    New Chat
                                </div>
                            </div>
                        </div>

                        <div class="row composeBox">
                            <div class="col-sm-12 composeBox-inner">
                                <div class="form-group has-feedback">
                                    <input
                                        id="composeText"
                                        type="text"
                                        class="form-control"
                                        name="searchText"
                                        placeholder="Search People"
                                    />
                                    <span
                                        class="glyphicon glyphicon-search form-control-feedback"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="noPadding p-absolute displayY displayShow"
                [ngClass]="{ chatOpen: isChatOpen, displayShow: isChatOpen }"
            >
                <div
                    class="col-lg-9 col-md-12 col-sm-12 col-xs-12 conversation"
                >
                    <div *ngIf="chatShimmer; else heading">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '6rem',
                                width: '121rem',
                                border: '1px solid white'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>

                    <ng-template #heading>
                        <div class="row heading">
                            <i
                                class="fa fa-angle-left fa-2x mobileView"
                                style="cursor: pointer; font-size: 30px"
                                (click)="closeChat()"
                                aria-hidden="true"
                            ></i>
                            <div
                                class="heading-avatar"
                                (click)="openTherapistProfile()"
                                title="Click for open Profile"
                            >
                                <div class="heading-avatar-icon">
                                    <a>
                                        <img
                                            src="{{ imageUrl }}/{{
                                                selectedThreadData?.userId
                                                    ?.imageUrl
                                            }}"
                                            onerror="this.src='../../../../assets/images/user.png';"
                                    /></a>
                                    <span
                                        class="onlineStatus-chat"
                                        *ngIf="
                                            selectedThreadData?.therapistId
                                                ?.isOnline === true
                                        "
                                    ></span>
                                </div>
                            </div>
                            <div
                                class="heading-name"
                                (click)="openTherapistProfile()"
                                title="Click for open Profile"
                            >
                                <a class="heading-name-meta"
                                    >{{ selectedThreadData?.userId?.name }}
                                    {{ chat?.userId?.isOnline }}
                                </a>
                                <br />
                                <span
                                    class="heading-online"
                                    *ngIf="
                                        selectedThreadData?.lastMessage
                                            ?.isTyping
                                    "
                                    >Typing...</span
                                >
                                <!-- <span class="heading-online">Online</span> -->
                            </div>
                            <!-- <div class="col-sm-1 col-xs-1  heading-dot pull-right">
        </div> -->
                        </div>
                    </ng-template>
                    <div *ngIf="chatShimmer; else message">
                        <div *ngFor="let item of UserLoader(5)">
                            <div style="display: flex; flex-direction: row">
                                <ngx-skeleton-loader
                                    appearance="line"
                                    [theme]="{
                                        'border-radius': '12px',
                                        height: '6rem',
                                        width: '30rem',
                                        display: 'flex !important',

                                        'margin-top': '3px',
                                        'margin-left': '10px',
                                        'margin-right': '10px'
                                    }"
                                ></ngx-skeleton-loader>
                            </div>
                            <div
                                style="
                                    display: flex;
                                    flex-direction: row-reverse;
                                "
                            >
                                <ngx-skeleton-loader
                                    appearance="line"
                                    [theme]="{
                                        'border-radius': '12px',
                                        height: '6rem',
                                        width: '30rem',
                                        display: 'flex !important',

                                        'margin-top': '3px',
                                        'margin-left': '10px',
                                        'margin-right': '10px'
                                    }"
                                ></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <ng-template #message>
                        <div
                            class="row message"
                            [class]="isProfileOpen ? 'col-md-9' : 'col-md-12'"
                            id="chatContainerId"
                        >
                            <div class="row message-previous">
                                <div class="col-sm-12 previous">
                                    <div
                                        *ngIf="
                                            !isShowMoreMessageLoader &&
                                            isShowMoreMessageButtonEnabled &&
                                            !isChatMessageLoading
                                        "
                                    >
                                        <a (click)="doShowMoreMessage()">
                                            Show Previous Message!
                                        </a>
                                    </div>
                                    <div *ngIf="isShowMoreMessageLoader">
                                        Fetching...
                                    </div>
                                </div>
                            </div>

                            <div
                                class="row message-body"
                                *ngFor="
                                    let message of newMessages;
                                    let idx = index
                                "
                                id="chatContainerId"
                            >
                                <div
                                    class="col-sm-12"
                                    [ngClass]="
                                        message.sentBy === 'therapist'
                                            ? 'message-main-sender'
                                            : 'message-main-receiver'
                                    "
                                    [id]="
                                        idx === newMessages.length - 1
                                            ? 'last-message'
                                            : ''
                                    "
                                >
                                    <div
                                        *ngIf="message.message"
                                        [ngClass]="
                                            message.sentBy === 'therapist'
                                                ? 'sender'
                                                : 'receiver'
                                        "
                                    >
                                        <div
                                            class="message-text"
                                            *ngIf="message.message"
                                        >
                                            <p
                                                [innerHTML]="
                                                    replaceURLs(message.message)
                                                "
                                            ></p>
                                        </div>
                                        <span
                                            class="message-time pull-right"
                                            [id]="
                                                idx === newMessages.length - 1
                                                    ? 'lastMessageId'
                                                    : ''
                                            "
                                        >
                                            {{ message.time }}
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="!message.message"
                                        [ngClass]="{
                                            sender:
                                                message.sentBy === 'therapist',
                                            receiver:
                                                message.sentBy !== 'therapist',
                                            sendImg:
                                                message.attachment
                                                    .attachmentType === 'image'
                                        }"
                                    >
                                        <div
                                            *ngIf="
                                                checkViewSource(
                                                    message.attachment.name ||
                                                        message.attachment
                                                            .fileName
                                                )
                                            "
                                            title="Click to preview"
                                        >
                                            <img
                                                src="{{ imgUrl }}/thumbnails/{{
                                                    message.attachment
                                                        .thumbnailKey
                                                }}"
                                                (click)="
                                                    showHighQualityImage(
                                                        message.attachment.key,
                                                        message.attachment.key,
                                                        message.attachment
                                                            .name ||
                                                            message.attachment
                                                                .fileName
                                                    )
                                                "
                                                style="
                                                    object-fit: cover;
                                                    height: 42vh;
                                                    width: 11vw;
                                                    cursor: zoom-in;
                                                "
                                                (load)="scrollOnImageLoad(idx)"
                                            />
                                            <div class="imgShadow"></div>
                                            <div
                                                class="msgTime"
                                                [id]="
                                                    idx ===
                                                    newMessages.length - 1
                                                        ? 'lastMessageId'
                                                        : ''
                                                "
                                            >
                                                {{ message.time }}
                                            </div>
                                        </div>

                                        <div
                                            *ngIf="
                                                !checkViewSource(
                                                    message.attachment.name ||
                                                        message.attachment
                                                            .fileName
                                                )
                                            "
                                        >
                                            <div
                                                style="
                                                    display: flex;
                                                    flex-direction: row;
                                                "
                                            >
                                                <img
                                                    src="{{
                                                        checkExtension(
                                                            message.attachment
                                                                .name ||
                                                                message
                                                                    .attachment
                                                                    .fileName
                                                        )
                                                    }}"
                                                    class="img-fluid"
                                                    style="
                                                        width: 50px;
                                                        padding-bottom: 5px;
                                                    "
                                                    alt="demo-landscape"
                                                />
                                                <div
                                                    style="
                                                        display: flex;
                                                        flex-direction: column;
                                                        justify-content: center;
                                                        width: 150px;
                                                    "
                                                >
                                                    <a
                                                        style="
                                                            margin-left: 10px;
                                                            text-decoration: underline;
                                                            cursor: pointer;
                                                        "
                                                        (click)="
                                                            downloadAttachment(
                                                                message
                                                                    .attachment
                                                                    .key,
                                                                message
                                                                    .attachment
                                                                    .name ||
                                                                    message
                                                                        .attachment
                                                                        .fileName
                                                            )
                                                        "
                                                        >{{
                                                            message.attachment
                                                                .name ||
                                                                message
                                                                    .attachment
                                                                    .fileName
                                                        }}</a
                                                    >
                                                    <div
                                                        style="
                                                            display: flex;
                                                            flex-direction: row;
                                                        "
                                                    >
                                                        <a
                                                            style="
                                                                margin-left: 10px;
                                                                text-decoration: underline;
                                                                cursor: pointer;
                                                            "
                                                            (click)="
                                                                downloadAttachment(
                                                                    message
                                                                        .attachment
                                                                        .key,
                                                                    message
                                                                        .attachment
                                                                        .name ||
                                                                        message
                                                                            .attachment
                                                                            .fileName
                                                                )
                                                            "
                                                            >{{
                                                                message
                                                                    .attachment
                                                                    .size
                                                            }}</a
                                                        >
                                                        <span
                                                            style="
                                                                display: flex;
                                                                align-items: center;
                                                            "
                                                            ><i
                                                                class="fa fa-solid fa-circle"
                                                                style="
                                                                    font-size: 6px;
                                                                    display: flex;
                                                                    margin-top: 3px;
                                                                    margin-left: 5px;
                                                                "
                                                            ></i
                                                        ></span>
                                                        <a
                                                            style="
                                                                margin-left: 10px;
                                                                text-decoration: underline;
                                                                cursor: pointer;
                                                                color: inherit !important;
                                                            "
                                                            (click)="
                                                                downloadAttachment(
                                                                    message
                                                                        .attachment
                                                                        .key,
                                                                    message
                                                                        .attachment
                                                                        .name ||
                                                                        message
                                                                            .attachment
                                                                            .fileName
                                                                )
                                                            "
                                                            *ngIf="
                                                                !isDownloading[
                                                                    message
                                                                        .attachment
                                                                        .key +
                                                                        '-chatbar'
                                                                ]?.status
                                                            "
                                                            >Download</a
                                                        >
                                                        <div
                                                            *ngIf="
                                                                isDownloading &&
                                                                isDownloading[
                                                                    message
                                                                        .attachment
                                                                        .key +
                                                                        '-chatbar'
                                                                ]?.status
                                                            "
                                                            class=""
                                                            style="
                                                                display: flex;
                                                                justify-content: center;
                                                            "
                                                        >
                                                            <div
                                                                class="spinner-border"
                                                                style="
                                                                    width: 1.5rem;
                                                                    height: 1.5rem;
                                                                    margin-left: 20px;
                                                                "
                                                                role="status"
                                                            >
                                                                <span
                                                                    class="sr-only"
                                                                    >Loading...</span
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style="
                                                        display: flex;
                                                        align-items: flex-end;
                                                        margin-left: 10px;
                                                    "
                                                    [id]="
                                                        idx ===
                                                        newMessages.length - 1
                                                            ? 'lastMessageId'
                                                            : ''
                                                    "
                                                >
                                                    {{ message.time }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="scrollerDiv"></div>
                            <div
                                class="row message-body typingAnimation"
                                *ngIf="isRemoteTyping"
                            >
                                <div class="col-sm-12 message-main-receiver">
                                    <div class="receiver appearZoomIn">
                                        <div class="message-text">
                                            <p>typing...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <div *ngIf="chatShimmer; else replyMessage">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '57px',
                                width: '121rem',
                                border: '1px solid white'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <ng-template #replyMessage>
                        <div
                            class="row reply"
                            [class]="
                                isProfileOpen
                                    ? 'col-lg-9 col-md-8 col-sm-8'
                                    : 'col-md-12'
                            "
                        >
                            <!-- <div class="col-sm-1 col-xs-1 reply-emojis">
              <i class="fa fa-smile-o fa-2x"></i>
            </div> -->
                            <div
                                class="col-lg-10 col-md-9 col-sm-8 col-xs-6 col-8 reply-main"
                                *ngIf="selectedAttachment === null"
                            >
                                <input
                                    type="text"
                                    placeholder="Type your message here…"
                                    [formControl]="newMessage"
                                    style="outline: none"
                                    autofocus
                                    (keyup.enter)="sendNewMessage()"
                                    (keyup)="userTyping($event)"
                                />
                            </div>

                            <div
                                class="col-lg-11 col-md-9 col-sm-8 col-xs-6 col-10 fileAttachmentBox"
                                *ngIf="selectedAttachment !== null"
                            >
                                <div
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 40px;
                                    "
                                >
                                    <div
                                        style="
                                            display: flex;
                                            width: 100%;
                                            flex-direction: row;
                                        "
                                    >
                                        <p>{{ selectedAttachment?.name }}</p>
                                        <i
                                            *ngIf="
                                                selectedAttachment != null &&
                                                isSendingMessage
                                            "
                                            class="fa fa-times fa-2x cancelAttachment"
                                            (click)="cancelAttachment()"
                                            aria-hidden="true"
                                        ></i>
                                    </div>

                                    <div
                                        class="progress"
                                        *ngIf="isUploadingAttachment"
                                    >
                                        <div
                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            aria-valuenow="{{ progress }}"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            [style.width]="progress + '%'"
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-lg-1 col-md-2 col-sm-2 col-xs-2"
                                style="display: inline-flex"
                            >
                                <span
                                    class="addAttch"
                                    *ngIf="selectedAttachment === null"
                                >
                                    <label id="insertFile">
                                        <input
                                            type="file"
                                            name="fileData"
                                            id="fileData"
                                            (change)="addAttachment($event)"
                                        />
                                        <span
                                            ><i
                                                class="fa fa-paperclip fa-2x"
                                            ></i
                                        ></span>
                                    </label>
                                </span>
                                <span
                                    class="reply-send"
                                    *ngIf="!isUploadingAttachment"
                                    (click)="sendNewMessage()"
                                    [style.opacity]="
                                        newMessage.valid ||
                                        selectedAttachment !== null
                                            ? '1'
                                            : '.5'
                                    "
                                    [style.cursor]="
                                        newMessage.valid ||
                                        selectedAttachment !== null
                                            ? 'pointer'
                                            : 'not-allowed'
                                    "
                                >
                                    <i class="fa fa-paper-plane fa-2x"></i>
                                </span>
                                <div
                                    *ngIf="isUploadingAttachment"
                                    style="
                                        display: flex;
                                        align-items: center;
                                        margin-left: 4px;
                                        margin-top: 15px;
                                        font-size: 16px;
                                    "
                                >
                                    {{ progress }} %
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div
            class="container-fluid no-chats"
            *ngIf="chatThreads.length == 0 && !isLoading && !isSearch"
        >
            <div>
                <div class="row">
                    <div
                        class="col-12"
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        "
                    >
                        <div>
                            <img
                                src="assets/images/chat_icon.svg"
                                height="150"
                                width="150"
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        class="col-12"
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        "
                    >
                        <p>
                            There are no messages in this conversation... Be the
                            first one to message here!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="noPadding p-absolute displayZ"
        [ngClass]="{ profileOpen: isProfileOpen, displayShow: isProfileOpen }"
    >
        <div class="wrapper" style="display: contents">
            <div
                class="sidebar col-lg-2 col-sm-12 col-xs-12"
                [style.display]="isProfileOpen ? 'block' : 'none'"
            >
                <div class="therapistContainer newBorder">
                    <div class="heading-dot pull-right">
                        <a *ngIf="isProfileOpen">
                            <i
                                class="fa fa-times fa-2x pull-right"
                                (click)="closeTherapistProfile()"
                            ></i>
                        </a>
                    </div>

                    <div class="thrpstImgCont">
                        <img
                            *ngIf="
                                selectedThreadData?.userId?.imageUrl;
                                else nothereapistimage
                            "
                            src="{{ imageUrl }}/{{
                                selectedThreadData?.userId?.imageUrl
                            }}"
                            onerror="this.src='../../../../assets/images/user.png';"
                            class="img-fluid"
                            style="
                                margin-left: 50px;
                                margin-top: 60px;
                                border-radius: 50%;
                            "
                        />

                        <ng-template #nothereapistimage>
                            <img
                                src="assets/images/user_none.png"
                                class="img-fluid"
                                alt=""
                            />
                        </ng-template>

                        <div class="thrpstInfo">
                            <div class="thrpstName">
                                <h3>{{ selectedThreadData?.userId?.name }}</h3>

                                <!-- <p *ngIf="
                selectedThreadData?.userId?.jobqualification !=
                'Other (Please Mention)'
              "> {{ selectedThreadData?.userId?.jobqualification }}</p> -->

                                <div class="thrpstBtns">
                                    <button
                                        class="buttonTheme1"
                                        style="height: 50px"
                                        routerLink="../../user-profile/{{
                                            selectedUserData?._id
                                        }}"
                                    >
                                        View Profile
                                    </button>
                                    <!-- <button class="buttonTheme2">Go to calendar</button> -->
                                </div>
                                <hr />

                                <div class="attachMents" style="height: 40px">
                                    <div class="attchText">
                                        <h3
                                            style="
                                                justify-content: space-between;
                                            "
                                        >
                                            Attachments
                                        </h3>
                                        <a
                                            (click)="toggleSeeAllAttachments()"
                                            *ngIf="
                                                chatThreads[selectedChatIndex]
                                                    ?.attachments.length > 6
                                            "
                                            style="
                                                color: inherit;
                                                text-decoration: none;
                                                cursor: pointer;
                                            "
                                        >
                                            See All
                                            <i
                                                class="fa fa-sort-down"
                                                [ngClass]="{
                                                    rotateIcon:
                                                        !seeAllAttachments
                                                }"
                                            >
                                            </i
                                        ></a>
                                    </div>
                                </div>

                                <div
                                    class="attchHolder"
                                    [style.height]="
                                        seeAllAttachments ? '100%' : '250px'
                                    "
                                    [style.overflow]="
                                        seeAllAttachments ? 'initial' : 'hidden'
                                    "
                                >
                                    <div
                                        class="chatAttch"
                                        *ngFor="
                                            let attachment of chatThreads[
                                                selectedChatIndex
                                            ]?.attachments
                                        "
                                    >
                                        <span *ngIf="attachment.isShown">
                                            <div
                                                *ngIf="
                                                    attachment?.name
                                                        ?.split('.')
                                                        .pop() == 'png' ||
                                                        attachment?.fileName
                                                            ?.split('.')
                                                            .pop() == 'png' ||
                                                        attachment?.name
                                                            ?.split('.')
                                                            .pop() == 'jpg' ||
                                                        attachment?.fileName
                                                            ?.split('.')
                                                            .pop() == 'jpg' ||
                                                        attachment?.name
                                                            ?.split('.')
                                                            .pop() == 'svg' ||
                                                        attachment?.fileName
                                                            ?.split('.')
                                                            .pop() == 'svg' ||
                                                        attachment?.name
                                                            ?.split('.')
                                                            .pop() == 'jpeg' ||
                                                        attachment?.fileName
                                                            ?.split('.')
                                                            .pop() == 'jpeg';
                                                    else docImg
                                                "
                                            >
                                                <img
                                                    *ngIf="
                                                        attachment.thumbnailImage
                                                    "
                                                    src="{{
                                                        attachment.thumbnailImage
                                                    }}"
                                                    onerror="this.src='../../../assets/images/attach_file_black_48dp.svg';"
                                                    style="
                                                        height: 100%;
                                                        max-height: 50px;
                                                        cursor: pointer !important;
                                                    "
                                                    class="img-fluid zoom-img"
                                                    title="{{
                                                        isDownloading[
                                                            attachment.key +
                                                                '-sidebar' ||
                                                                attachment.filePath +
                                                                    '-sidebar'
                                                        ]?.status
                                                            ? 'Downloading'
                                                            : 'Click here to Download'
                                                    }} "
                                                    (click)="
                                                        downloadAttachment(
                                                            attachment.key ||
                                                                attachment.filePath,
                                                            attachment.name ||
                                                                attachment.fileName,
                                                            true
                                                        )
                                                    "
                                                />
                                            </div>
                                            <ng-template #docImg>
                                                <img
                                                    *ngIf="
                                                        !attachment.thumbnailImageKey
                                                    "
                                                    src="{{
                                                        checkExtension(
                                                            attachment.name ||
                                                                attachment.fileName
                                                        )
                                                    }}"
                                                    style="
                                                        height: 100%;
                                                        max-height: 50px;
                                                        cursor: pointer !important;
                                                    "
                                                    class="img-fluid"
                                                    title="{{
                                                        isDownloading[
                                                            attachment.key +
                                                                '-sidebar' ||
                                                                attachment.filePath +
                                                                    '-sidebar'
                                                        ]?.status
                                                            ? 'Downloading'
                                                            : 'Click here to Download'
                                                    }} "
                                                    (click)="
                                                        downloadAttachment(
                                                            attachment.key ||
                                                                attachment.filePath,
                                                            attachment.name ||
                                                                attachment.fileName,
                                                            true
                                                        )
                                                    "
                                                />
                                            </ng-template>
                                            <div style="display: flex">
                                                <p
                                                    style="
                                                        font-size: 12px;
                                                        cursor: pointer;
                                                    "
                                                    title="{{
                                                        isDownloading[
                                                            attachment.key +
                                                                '-sidebar' ||
                                                                attachment.filePath +
                                                                    '-sidebar'
                                                        ]?.status
                                                            ? 'Downloading'
                                                            : 'Click here to Download'
                                                    }} "
                                                    (click)="
                                                        downloadAttachment(
                                                            attachment.key ||
                                                                attachment.filePath,
                                                            attachment.name ||
                                                                attachment.fileName,
                                                            true
                                                        )
                                                    "
                                                >
                                                    {{
                                                        attachment.name ||
                                                            attachment.fileName
                                                    }}
                                                </p>

                                                <div
                                                    *ngIf="
                                                        isDownloading &&
                                                        isDownloading[
                                                            attachment.key +
                                                                '-sidebar' ||
                                                                attachment.filePath +
                                                                    '-sidebar'
                                                        ]?.status
                                                    "
                                                    class=""
                                                    title="Downloading"
                                                    style="
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                    "
                                                >
                                                    <div
                                                        class="spinner-border"
                                                        style="
                                                            width: 1rem;
                                                            height: 1rem;
                                                            margin-left: 2px;
                                                        "
                                                        role="status"
                                                    >
                                                        <span class="sr-only"
                                                            >Loading...</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        [style.display]="showModal ? 'flex' : 'none'"
        class="modal"
        id="imagemodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
    >
        <div (click)="hide()" style="width: 100%; cursor: zoom-out">
            <div class="modal-dialog" style="height: 100%; width: 100%">
                <div class="modal-content" style="height: 100%; width: 100%">
                    <div class="modal-body" style="height: 100%; width: 100%">
                        <div
                            *ngIf="isShowHighQualityImageLoader"
                            style="
                                height: 90vh;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <div
                                class="spinner-border text-light"
                                style="
                                    width: 5rem;
                                    height: 5rem;
                                    margin-left: 55px;
                                    margin-top: 23px;
                                "
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <img
                            *ngIf="!isShowHighQualityImageLoader"
                            src="{{ showModalImg }}"
                            id="imagepreview"
                            style="
                                height: 90%;
                                max-height: 650px;
                                width: 90%;
                                object-fit: scale-down;
                                max-width: 900px;
                                cursor: pointer;
                                /* min-width: 600px; */
                            "
                        />
                    </div>
                </div>
            </div>
        </div>

        <span
            style="
                color: white;
                position: absolute;
                top: 10px;
                right: 50px;
                font-size: 24px;
                border: none;
                background: none;
                cursor: pointer;
            "
            (click)="downloadAttachment(downloadFilePath, downloadFileName)"
            title="download"
            ><img
                src="../../../assets/images/download (1).png"
                style="width: 20px"
            />
        </span>

        <button
            type="button"
            class="btn btn-default"
            style="
                color: white;
                position: absolute;
                top: 0;
                right: 0;
                font-size: 32px;
                border: none;
                background: none;
            "
            data-dismiss="modal"
            (click)="hide()"
            title="close"
        >
            &times;
        </button>
    </div>
</div>
