<div class="headerContainer">
    <app-therapist-header></app-therapist-header>
</div>
<div class="pageWrapper sideNav">
    <div
        class="leftSideBarWrapper mobileNav"
        *ngIf="isHeaderActions"
        [ngClass]="{ showSideNav: isSideNav }"
    >
        <div class="sideBarBox">
            <div class="sideBarpleasecoachLogo">
                <a routerLink="">
                    <img
                        src="../../../assets/images/PleaseCoach_Logo_white.png"
                        draggable="false"
                        alt=""
                        class="img-fluid"
                    />
                </a>
            </div>
            <div class="sideBarMenu">
                <ul>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="dashboard" (click)="closeSidenav()"
                            >Dashboard</a
                        >
                    </li>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="profile" (click)="closeSidenav()"
                            >Profile</a
                        >
                        <a
                            routerLink="changepassword"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="view-calendar"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="recurring-schedule"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="therapist-calender"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                    </li>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="sessions" (click)="closeSidenav()"
                            >Sessions</a
                        >
                        <a
                            routerLink="join-session"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                    </li>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="my-chats" (click)="closeSidenav()"
                            >My Chats</a
                        >
                    </li>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="client-notes" (click)="closeSidenav()"
                            >Client Notes</a
                        >
                        <a
                            routerLink="client-notes-view"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="client-notes-edit"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="create-client-note"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                    </li>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="discussion" (click)="closeSidenav()"
                            >Discussion Forum</a
                        >
                        <a
                            routerLink="therapistDiscussionForum"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="answerDiscussion"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                    </li>
                    <li class="list" routerLinkActive="active">
                        <a routerLink="helpdesk" (click)="closeSidenav()"
                            >Helpdesk</a
                        >
                        <a
                            routerLink="contract-related-faq"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="talk-therapy-faq"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="payment-related-faq"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="platform-related-faq"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="account-related-faq"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="benefits-related-faq"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="viewRequest"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                        <a
                            routerLink="viewsingleticket"
                            style="display: none"
                            (click)="closeSidenav()"
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sideBarBottomImage">
            <img
                src="../../../assets/images/sidebarFoot.svg"
                class="img-fluid"
                draggable="false"
                alt=""
            />
        </div>
    </div>
    <div class="rightSideBodyWrapper">
        <div class="headWrapper">
            <div class="row justify-content-between">
                <div class="col-12">
                    <div class="headMenuWrapper">
                        <ul *ngIf="isHeaderActions">
                            <li>
                                <a routerLink="notifications"
                                    ><img
                                        src="../../../assets/images/bell.png"
                                        style="
                                            z-index: 999999999;
                                            cursor: pointer;
                                        "
                                        draggable="false"
                                        class="img-fluid"
                                    /><span class="bellCircle">{{
                                        newNotifications
                                    }}</span></a
                                >
                            </li>
                            <li (click)="logOut()">
                                <a style="cursor: pointer"
                                    ><img
                                        src="../../../assets/images/logout.svg"
                                        draggable="false"
                                        style="
                                            z-index: 999999999;
                                            cursor: pointer;
                                        "
                                        class="img-fluid"
                                /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="bodyPageWrapper"
            [ngClass]="{
                dashboardNoPadding: isDashboard,
                chatTabCss: isChatTab
            }"
            (click)="closeSidenav()"
        >
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
