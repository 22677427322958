import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ContactService } from "../guest/contact.service";
import { BootstrapAlertService } from "../shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "../shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { SideNavBarService } from "../side-nav-bar.service";
import clevertap from "clevertap-web-sdk";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  constructor(
    private sidenavService: SideNavBarService,
    private callbackService: ContactService,
    private alertService: BootstrapAlertService
  ) {}
  callbackForm = new FormGroup({
    phone: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
    ]),
  });
  userCountryCode = {
    countryCode: "in",
    dialCode: "91"
  };
  ngOnInit(): void {}

  closenavbar() {
    this.sidenavService.closesidenav();
  }

  checkError(valid) {
    if (!valid) {
      this.callbackForm.controls["phone"].setErrors({ 'invalidPhone': true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.callbackForm.patchValue({
      phone: phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }

  onSubmit() {
    if (this.callbackForm.invalid) {
      this.alertService.alert(
        new BootstrapAlert(
          "Please enter a valid phone number",
          "alert-danger"
        )
      );
      return;
    } else {
      this.callbackService
        .requestCallback({ phone :  "+" +this.userCountryCode.dialCode +this.callbackForm.value.phone})
        .subscribe((res) => {
          if (res) {
            clevertap.event.push("Callback Requested")
            this.alertService.alert(
              new BootstrapAlert(
                "Thank You for getting in touch with us. Someone from our team will be in touch with you shortly.",
                "alert-success"
              )
            );
            this.callbackForm.reset();
          }
        });
    }
  }
}
