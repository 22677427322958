import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { OurCounsellorsService } from "src/app/guest/our-counsellors.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { environment } from "src/environments/environment";
import { BookingService } from "../booking.service";
import clevertap from "clevertap-web-sdk";
import { AuthService } from "src/app/auth/auth.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';



declare global {
  interface Window {
    Tawk_API: any;
  }
}
@Component({
  selector: "app-our-recommendation",
  templateUrl: "./our-recommendation.component.html",
  styleUrls: ["./our-recommendation.component.css"],
})
export class OurRecommendationComponent implements OnInit {
  recommendedTherpist = [];
  planName;
  msgfornext = " ";
  nextAvailableAt = null;
  result;
  isLoading = true;
  imageUrl = environment.apiUrl;
  userTimezone = "Asia/Calcutta";

  constructor(
    private actRoute: ActivatedRoute,
    private bookingService: BookingService,
    private router: Router,
    private alertService: BootstrapAlertService,
    private counselorService: OurCounsellorsService,
    private authService: AuthService
  ) { }
  options: AnimationOptions = {
    path: '/assets/loader.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  ngOnInit(): void {
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 2000)
    this.actRoute.queryParamMap.subscribe((query) => {
      if (query.get("plan")) {
        this.planName = query.get("plan");
      }
    });
    this.respectivePlans();
    if (localStorage.getItem("userTzcode")) {
      this.userTimezone = localStorage.getItem("userTzcode");
    } else {
      this.userTimezone = momentTz.tz.guess();
    }
    this.recommendedTherpist = this.setIsSelected(this.recommendedTherpist);
  }
  respectivePlans() {
    this.bookingService.getRecommendation().subscribe((data) => {
      this.recommendedTherpist = data["result"];
      this.isLoading = false;
      if (data['slots']) {
        if (data['slots'].length > 0) {
          this.recommendedTherpist.forEach((therapist) => {
            data['slots'].forEach((therapistSlot) => {
              if (therapist._id === therapistSlot.therapistId) {
                let dateObj = therapistSlot['date'] && therapistSlot['slot'] ? moment.utc(therapistSlot['date'] + ' ' + therapistSlot['slot'], 'DD_MM_YYYY HH:mm') : false
                therapist.nextAvailableAtStr = dateObj ? momentTz.tz(dateObj, this.userTimezone).format('MMM D, YYYY h:mm A') : false
              }
            })
            if (therapist.specializeareas) {
              therapist.specializeareas = therapist.specializeareas.map(item => item.name)
            }
            therapist.specializeareasMore = []
            therapist.showMoreToggle = false;
            if (therapist.specializeareas) {
              if (therapist.specializeareas.length > 5) {
                therapist.specializeareasMore = therapist.specializeareas.splice(5, 5 + therapist.specializeareas.length)
                therapist.specializeareas = therapist.specializeareas.splice(0, 5)
              }
            }
          })
        }
      }
      // this.counselorService.getNextAvailableAt().subscribe((res) => {
      //   this.nextAvailableAt = res;
      //   this.recommendedTherpist.forEach((therapist) => {
      //     this.nextAvailableAt.nextSlot.forEach((slot) => {
      //       if (therapist._id == slot.therapistId) {
      //         therapist.nextAvailableAt = moment(slot.date, "DD_MM_YYYY");
      //         therapist.nextAvailableAt.set({
      //           hour: slot.slot.split(":")[0],
      //           minute: slot.slot.split(":")[1],
      //         });
      //         therapist.nextAvailableAtStr = momentTz(therapist.nextAvailableAt, 'Asia/Calcutta').format('MMM D, YYYY h:mm A')
      //       }
      //     });
      //   });
      // });
    });
  }

  toggleShowMore(index) {
    this.recommendedTherpist[index]["showMoreToggle"] = !this.recommendedTherpist[index]["showMoreToggle"]
  }
  setIsSelected(array) {
    array.forEach((therapist, index1) => {
      array[index1]["isSelected"] = false;
    });
    return array;
  }
  selectedTherapist(id) {
    this.setIsSelected(this.recommendedTherpist);
    this.result = this.recommendedTherpist.find(({ _id }) => _id == id);
    this.result["isSelected"] = true;
  }
  indexTracker(index: number, value: any) {
    return index;
  }
  nextFunction() {
    if (this.result) {
      this.router.navigate(["../view-counsellor-calendar/" + this.result._id]);
    } else {
      this.alertService.alert(
        new BootstrapAlert("Please select a therapist first", "alert-danger")
      );
    }
  }
  openChat() {
    // let chatElem = document.getElementById("zsiq_agtpic");
    // chatElem.click();
  }
  viewProfile(therapist) {
    if (clevertap) {
      clevertap.event.push("Recommended Counsellor Viewed", {
        "Therapist Name": therapist.firstname + " " + therapist.lastname
      })
    }
  }
  bookNow(therapist) {
    if (clevertap) {
      clevertap.event.push("Recommended Counsellor Book Now Clicked", {
        "Therapist Name": therapist.firstname + " " + therapist.last
      })
    }
    if (this.authService.getIsAuth()) {
      this.router.navigate(["../view-counsellor-calendar/" + therapist._id]);
    } else {
      this.router.navigateByUrl("/sign-up?therapist=" + therapist._id);
    }
  }
}
