import { Component, OnInit } from "@angular/core";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Router } from "@angular/router";

SwiperCore.use([Pagination, Navigation, Autoplay]);
@Component({
  selector: "app-ad-campaign-home",
  templateUrl: "./ad-campaign-home.component.html",
  styleUrls: ["./ad-campaign-home.component.css"],
})
export class AdCampaignHomeComponent implements OnInit {
  userReviews = [
    {
      text: "Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.",
      name: "Sana Javed",
      work: "Software Engineer",
    },
    {
      text: "I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
      name: "Puja Mittal",
      work: "Homemaker",
    },
    {
      text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at pleasecoach who made helped me understand & treat my depression.",
      name: "Vikas Arora",
      work: "CFO",
    },
    {
      text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
      name: "Aishwarya Hegde",
      work: "Student",
    },
  ];
  ourCounsellors = [
    {
      name: "Dr. V S Ananthakrishnan",
      qualification: "MBBS, MD (Psychiatry)",
      specialization:
        "Sexual Dysfunction, Masturbation, Anxiety, OCD, Depression, Marital Discord",
      src: "ananthakrishnan-new.png",
    },
    {
      name: "Geetha Krishna",
      qualification:
        "Certification in Counselling, Interpersonal Communication & Listening Skills",
      specialization:
        " Stress, Anxiety, Feeling Low, Confusion, Relationship Issues, Marital Discord, Family Conflicts, Relationship Issues ",
      src : "geetha-new.jpg"
    },
    {
      name : "Nandhini S",
      qualification : "MSc. in Counselling Psychology",
      specialization : "Relationship Issues, Anxiety, Stress, Low Self Esteem, Body Dysmorphic Disorder, Career Related Concerns, Self- Image Issues",
      src : "nandhini-new.png"
    },
    {
      name : "Priya Nambiar",
      qualification : "Certification in Counselling, Interpersonal & Listening skills Training",
      specialization : "Stress, Anxiety, Feeling Low, Confusion, Relationship Issues, Grief",
      src: "priya-new.jpg"
    },
    {
      name : "Sahana V",
      qualification : "MSc in Clinical Psychology",
      specialization : "Stress, Anxiety, Depression, Psychotic Disorder, Schizophrenia, Personality Disorder, Sleep Disorder, Mood Disorder",
      src : "sahana-new.jpg"
    },
    {
      name : "Shiny Shrivastava",
      qualification : "MA in Psychology",
      specialization : "Anxiety, Depression, Stress, Trauma, Grief, Relationship Issues, Self- Image Issues",
      src: "shiny-new.png"
    },
    {
      name : "Spandana Reddy",
      qualification : "MA in Counselling Psychology",
      specialization : "Anxiety, Stress, Depression, Adolescent Issues, Relationship Issues, Family Therapy, Self- Image Issues, Depression, Self-Improvement, Mood Disorder",
      src : "spandana-new.png"
    },
    {
      name : "Srimoyee Roy",
      qualification : "Masters in psychology",
      specialization : "Anxiety, Stress, Depression, Relationship Issues, Low Confidence, Self- Image Issues",
      src : "srimoyee-new.png"
    },
    {
      name : "Srishti Bhattacharya",
      qualification : "Masters in Social Work (Counselling)",
      specialization : "Anxiety, Stress, Relationship Issues, Trauma, Depression, Self- Image Issues, Binge Eating",
      src: "srishti-new.png"
    },
    {
      name : "Zufeen Khan",
      qualification: "MA in Clinical Psychology",
      specialization : "Anxiety, Stress, Trauma, OCD, Personality Disorders, Grief, Self - Image Issues, Relationship Issues, Schizophrenia, Neurodevelopmental Disorders",
      src : "zufeen-new.jpg"
    }
  ];
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 4000);
  }

  chatWithUs() {
    // window["$zoho"].salesiq.floatbutton.visible("show");
    // let chatElem = document.getElementById("zsiq_agtpic");
    // chatElem.click();
  }
}
