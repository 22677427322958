<div style="display: flex; align-items: center; margin-bottom: 20px">
    <span
        ><a
            routerLink="../helpdesk"
            style="
                font-size: 16px;
                color: #445e93;
                cursor: pointer;
                text-decoration: underline;
            "
            >Helpdesk</a
        ></span
    >
    <span style="font-size: 16px">&nbsp;>&nbsp;</span>
    <span
        ><a
            routerLink="../viewRequest"
            style="
                font-size: 16px;
                color: #445e93;
                cursor: pointer;
                text-decoration: underline;
            "
            >View Old Requests</a
        ></span
    >
    <span style="font-size: 16px">&nbsp;>&nbsp;</span>
    <span style="font-size: 16px; color: #808080"
        >View {{ userData?.grievance }} Requests</span
    >
</div>

<div *ngIf="isRequestLoading; else dataLoded">
    <div class="row">
        <div class="col-md-12">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '100%',
                    height: '430px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
        <div class="col-md-12">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    width: '100%',
                    height: '130px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
        <div class="col-md-12" style="text-align: center">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '50px',
                    width: '25%',
                    height: '50px',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>
    </div>
</div>
<ng-template #dataLoded>
    <div>
        <div class="discussionGroup d-flex flex-wrap">
            <div class="disscussionContentWrap">
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Ticket Number</b>
                    </div>
                    <div class="detailFetchTag">
                        {{ userData?.ticketNumber }}
                    </div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Category</b>
                    </div>
                    <div class="detailFetchTag">{{ userData?.grievance }}</div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Subject</b>
                    </div>
                    <div class="detailFetchTag">{{ userData?.subject }}</div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Status</b>
                    </div>
                    <div class="detailFetchTag">{{ userData?.status }}</div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Date Created</b>
                    </div>
                    <div class="detailFetchTag">
                        {{ userData?.createdAt | date : 'dd/MMM/yyyy' }}
                    </div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Description</b>
                    </div>
                    <div class="detailFetchTag description">
                        {{ userData?.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let i of userDataAnswers">
        <div class="discussionGroup d-flex flex-wrap">
            <div class="disscussionContentWrap">
                <!-- <div class="col-md-12"></div> -->
                <div class="row" *ngIf="i.AdminId">
                    <div class="eachDivBlock">
                        <b>Replied By</b>
                    </div>
                    <div class="detailFetchTag">
                        {{ i?.AdminId?.name }} (Support Team)
                    </div>
                </div>
                <div class="row" *ngIf="i.therapistId">
                    <div class="eachDivBlock">
                        <b>Replied By</b>
                    </div>
                    <div class="detailFetchTag">
                        {{ i?.therapistId?.firstname }}
                    </div>
                </div>
                <div class="row">
                    <div class="eachDivBlock"><b>Replied by you</b></div>
                    <div class="detailFetchTag"></div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Date Created</b>
                    </div>
                    <div class="detailFetchTag">
                        {{ i?.createdAt | date : 'dd/MMM/yyyy' }}
                    </div>
                </div>
                <div class="row">
                    <div class="eachDivBlock">
                        <b>Response</b>
                    </div>
                    <div class="detailFetchTag description">
                        {{ i?.response }}
                    </div>
                </div>
                <div class="row extradivTagCss" *ngIf="i?.image">
                    <a href="/images/{{ i?.image }}">
                        <div>
                            <input
                                class="form-control extraInputCss"
                                type="text"
                                placeholder="ScreenShot {{ i?.image }}"
                            />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="userData?.status == 'closed'">
        <p>
            Your Ticket has been marked as closed. Respond below and it will be
            reopen
        </p>
    </div>
    <div class="formRound">
        <div>
            <form [formGroup]="replyForm">
                <textarea
                    class="form-control discussionGroup textareaCss"
                    rows="5"
                    formControlName="response"
                    placeholder="If you are not satisfied. Respond here."
                    [ngClass]="{
                        'has-error':
                            !replyForm.controls.response.valid &&
                            replyForm.controls.response.touched,
                        'has-success':
                            replyForm.controls.response.valid &&
                            replyForm.controls.response.touched
                    }"
                ></textarea>
                <div
                    *ngIf="
                        replyForm.controls.response.errors?.required &&
                        replyForm.controls.response.touched
                    "
                    class="errorContainer"
                >
                    <p>Response is required to submit</p>
                </div>
            </form>
            <div class="col-lg-6 text-alignCss">
                <!-- <div
        ngf-drop="uploadFiles($files)"
        ngf-select="uploadFiles($files)"
        class="chostExt"
        ngf-select
        ng-class="{
          'bg-default': !image,
          'bg-danger': userForm.image.$error.pattern,
          'bg-success': !userForm.image.$error.pattern && image
        }"
        ng-model="image"
        name="image"
        ngf-drag-over-class="'dragover'"
        ngf-valid-only="true"
        ngf-invalid-class="bg-danger"
        ngf-multiple="false"
        ngf-pattern="'image/*'"
      >
        Upload Attachments If any (*jpg,*.png,*jpeg) &nbsp;&nbsp;
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </div> -->
                <!-- <div ng-if="userForm.image.$error.pattern">Invalid Format</div>
        <ul>
          <li ng-repeat="f in ff" style="font: smaller">
            {{ f.name }}
            {{ f.$errorParam }}
          </li>
          <li ng-repeat="f in errFiles" style="font: smaller">
            {{ f.name }}
            {{ f.$error }}
            {{ f.$errorParam }}
          </li>
        </ul> -->
            </div>
        </div>
        <div class="Css wrapper">
            <div *ngIf="isLoading" class="d-flex justify-content-center">
                <div
                    class="spinner-border"
                    style="width: 2rem; height: 2rem; margin-top: 23px"
                    role="status"
                >
                    <span class="sr-only">Loading...</span>
                </div>
                <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
            </div>
            <button
                (click)="onSubmit()"
                [disabled]="replyForm.invalid"
                class="thm-2"
                *ngIf="!isLoading"
            >
                Submit Reply
            </button>
        </div>
    </div>
</ng-template>
