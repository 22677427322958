<div class="bodyPageWrap">
    <div style="display: flex; align-items: center; margin-bottom: 20px">
        <span
            ><a
                routerLink="../helpdesk"
                style="
                    font-size: 16px;
                    color: #445e93;
                    cursor: pointer;
                    text-decoration: underline;
                "
                >Helpdesk</a
            ></span
        >
        <span style="font-size: 16px">&nbsp;>&nbsp;</span>
        <span style="font-size: 16px; color: #808080">Contract related</span>
    </div>
    <div>
        <div class="frequentHeadWrap">
            <div class="row">
                <div class="col-md-12">
                    <div class="sectionHeading">
                        <h2>Contract Related FAQ's</h2>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentQuestionBox">
            <div class="row">
                <div id="1" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>
                            Can I practice counselling outside Please Coach?
                        </h4>
                        <p class="ptagCss">
                            Yes. You can run your private practice or employment
                            along with Please Coach. However, you will be
                            signing a non-compete contract with Please Coach –
                            WILL NOT POACH clients via Please Coach and INTERACT
                            WITH Please Coach CLIENTS ONLY ON THE PLATFORM. Any
                            attempt to lure clients acquired via Please Coach
                            off the platform will invite legal action.
                        </p>
                    </div>
                </div>
                <div id="2" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>
                            What is the client interaction protocol to be
                            followed on Please Coach?
                        </h4>
                        <p class="ptagCss">
                            Firstly, You will be signing a non-compete contract
                            with Please Coach – WILL NOT POACH clients via
                            Please Coach and INTERACT WITH Please Coach CLIENTS
                            ONLY ON THE PLATFORM. Any attempt to lure clients
                            acquired via Please Coach off the platform will
                            invite legal action.
                        </p>
                        <p class="ptagCss">
                            Secondly, you are expected to maintain the decorum
                            of a therapist and patient relationship in all your
                            client interactions.
                        </p>
                        <p class="ptagCss">
                            Finally, you are expected to deliver on the promise
                            of enhanced wellbeing. While we do not expect you to
                            be available at a client’s beck and all, you are
                            expected to respond to chat messages 2-3 times a day
                            outside the scheduled sessions.
                        </p>
                    </div>
                </div>
                <div id="3" class="col-lg-12 col-md-6 col-sm-6">
                    <div class="questionWrap">
                        <h4>
                            What is the type of contract I sign with Please
                            Coach?
                        </h4>
                        <p class="ptagCss">
                            You will have a freelancing contract with Please
                            Coach and not be on our rolls full time. Either
                            party can terminate the contract with 15 days of
                            notice.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentHeadWrap">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <h3>
                            Have More Questions?
                            <span class="spanCss"
                                >(Access following heads)</span
                            >
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="frequentQuestionBox">
            <div class="row">
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../talk-therapy-faq"
                                >Talk therapy Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../payment-related-faq"
                                >Payment Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../platform-related-faq"
                                >Platform Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../account-related-faq"
                                >Account Related</a
                            ></b
                        >
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="questionWrapper">
                        <b
                            ><a routerLink="../benefits-related-faq"
                                >Benefits Related</a
                            ></b
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
