<div class="secPadding" (click)="closenavbar()">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="container">
        <div class="secondaryHeading">
            <h2>Log In</h2>
            <hr />
        </div>
        <div class="signUpLine">
            <h3>New to Please Coach ? <a routerLink="/sign-up">Sign Up</a></h3>
        </div>
        <div class="row align-items-center">
            <div class="col-md-6 borderInRight">
                <div class="loginForm formRound">
                    <form
                        [formGroup]="loginForm"
                        (ngSubmit)="onSubmit()"
                        name="loginForm"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                name="email"
                                id="email"
                                formControlName="email"
                                placeholder="Email"
                                (change)="trimInput()"
                                class="form-control"
                                [ngClass]="{
                                    'has-error':
                                        !loginForm.controls.email.valid &&
                                        loginForm.controls.email.touched,
                                    'has-success':
                                        loginForm.controls.email.valid &&
                                        loginForm.controls.email.touched
                                }"
                            />
                            <div
                                *ngIf="
                                    loginForm.controls.email.errors?.required &&
                                    loginForm.controls.email.touched
                                "
                                class="errorContainer"
                            >
                                <p>Email is required</p>
                            </div>
                            <div
                                *ngIf="
                                    loginForm.controls.email.errors?.pattern &&
                                    loginForm.controls.email.touched
                                "
                                class="errorContainer"
                            >
                                <p>Email is invalid</p>
                            </div>
                        </div>
                        <div class="input-group">
                            <input
                                [type]="isShowPassword ? 'text' : 'password'"
                                name="password"
                                id="password"
                                formControlName="password"
                                placeholder="Password"
                                class="form-control"
                                [ngClass]="{
                                    'has-error':
                                        !loginForm.controls.password.valid &&
                                        loginForm.controls.password.touched,
                                    'has-success':
                                        loginForm.controls.password.valid &&
                                        loginForm.controls.password.touched
                                }"
                            />
                            <span class="eyeIcon">
                                <i
                                    class="fa"
                                    [ngClass]="{
                                        'fa-eye-slash': !isShowPassword,
                                        'fa-eye': isShowPassword
                                    }"
                                    (click)="showPasswordToggle()"
                                ></i>
                            </span>
                        </div>
                        <div
                            *ngIf="
                                loginForm.controls.password.errors?.required &&
                                loginForm.controls.password.touched
                            "
                            class="errorContainer"
                        >
                            <p>Password is required</p>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                    style="width: 100%; margin-left: -5px"
                                >
                                    <div class="col-6">
                                        <div class="checkBox">
                                            <input
                                                type="checkbox"
                                                name="rememberMe"
                                                id="rememberMe"
                                            />
                                            <span>Remember Me</span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="forgotPassWrap">
                                            <a routerLink="../forgot-password"
                                                >Forgot Password ?</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div
                                *ngIf="isLoading"
                                class="d-flex justify-content-start"
                            >
                                <div
                                    class="spinner-border"
                                    style="
                                        width: 2rem;
                                        height: 2rem;
                                        margin-left: 55px;
                                        margin-top: 23px;
                                    "
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <!-- <ng-lottie
                height="130px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
                            </div>
                            <button
                                *ngIf="!isLoading"
                                class="buttonTheme1"
                                type="submit"
                                [disabled]="!loginForm.valid"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="socialLoginWrap">
                    <ul>
                        <li #loginRef>
                            <a (click)="loginWithGoogle()">
                                <img
                                    src="../../../assets/images/google.png"
                                    class="img-fluid"
                                    draggable="false"
                                    alt="social login google"
                                />
                                <span style="margin-right: 18px"
                                    >Log In with Google</span
                                >
                            </a>
                        </li>
                        <li>
                            <a (click)="loginWithFacebook()">
                                <img
                                    src="../../../assets/images/facebook.png"
                                    class="img-fluid"
                                    draggable="false"
                                    alt="social login facebook"
                                />
                                <span>Log In with Facebook</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
