<div class="sectionPadding" (click)="closenavbar()">
    <div class="container">
        <div class="sectionHeading">
            <h2>Become a Therapist on Please Coach</h2>
            <hr />
        </div>
        <div class="becomeContentBox formRound" *ngIf="firstPart">
            <form
                [formGroup]="becometherapistForm1"
                name="becometherapistForm1"
            >
                <!-- part1 -->
                <div>
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="First Name *"
                                    formControlName="firstname"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-error':
                                            !becometherapistForm1.controls
                                                .firstname.valid &&
                                            becometherapistForm1.controls
                                                .firstname.touched,
                                        'has-success':
                                            becometherapistForm1.controls
                                                .firstname.valid &&
                                            becometherapistForm1.controls
                                                .firstname.touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.firstname
                                            .errors?.required &&
                                        becometherapistForm1.controls.firstname
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Firstname is required</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="Last Name *"
                                    formControlName="lastname"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-error':
                                            !becometherapistForm1.controls
                                                .lastname.valid &&
                                            becometherapistForm1.controls
                                                .lastname.touched,
                                        'has-success':
                                            becometherapistForm1.controls
                                                .lastname.valid &&
                                            becometherapistForm1.controls
                                                .lastname.touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.lastname
                                            .errors?.required &&
                                        becometherapistForm1.controls.lastname
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Lastname is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="Email *"
                                    formControlName="email"
                                    name="email"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-error':
                                            !becometherapistForm1.controls.email
                                                .valid &&
                                            becometherapistForm1.controls.email
                                                .touched,
                                        'has-success':
                                            becometherapistForm1.controls.email
                                                .valid &&
                                            becometherapistForm1.controls.email
                                                .touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.email
                                            .errors?.required &&
                                        becometherapistForm1.controls.email
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Email is required</p>
                                </div>
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.email
                                            .errors?.pattern &&
                                        becometherapistForm1.controls.email
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Email is invalid</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="Contact No. *"
                                    formControlName="phone"
                                    class="form-control"
                                    name="phone"
                                    [ngClass]="{
                                        'has-error':
                                            !becometherapistForm1.controls.phone
                                                .valid &&
                                            becometherapistForm1.controls.phone
                                                .touched,
                                        'has-success':
                                            becometherapistForm1.controls.phone
                                                .valid &&
                                            becometherapistForm1.controls.phone
                                                .touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.phone
                                            .errors?.required &&
                                        becometherapistForm1.controls.phone
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Contact No. is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input
                                    type="text"
                                    placeholder="Location"
                                    formControlName="location"
                                    name="location"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <input
                                    type="password"
                                    placeholder="Enter Password *"
                                    formControlName="password"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-error':
                                            !becometherapistForm1.controls
                                                .password.valid &&
                                            becometherapistForm1.controls
                                                .password.touched,
                                        'has-success':
                                            becometherapistForm1.controls
                                                .password.valid &&
                                            becometherapistForm1.controls
                                                .password.touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.password
                                            .errors?.required &&
                                        becometherapistForm1.controls.password
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Password is required</p>
                                </div>
                                <div
                                    *ngIf="
                                        becometherapistForm1.controls.password
                                            .errors?.pattern &&
                                        becometherapistForm1.controls.password
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>
                                        Password must be minimum 8 characters, 1
                                        lower case letter, 1 number and 1
                                        special character
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="submitButtonClass text-center">
                        <button
                            class="thm-2"
                            [disabled]="!becometherapistForm1?.valid"
                            (click)="ShowHide()"
                        >
                            Next
                        </button>
                    </div>
                    <div class="signupConditionClass">
                        By signing up, you agree to our
                        <a
                            routerLink="/online-counseling-psychologists/terms-of-service"
                            >Terms of Use</a
                        >
                        <br />
                        and acknowledge that you have read our
                        <a
                            routerLink="/online-counseling-psychologists/privacy-policy"
                            >Privacy Policy</a
                        >
                    </div>
                </div>
            </form>
        </div>
        <div class="becomeContentBox formRound" *ngIf="secondPart">
            <form
                [formGroup]="becometherapistForm2"
                name="becometherapistForm2"
            >
                <!-- part2 -->
                <div class="col-md-12">
                    <div class="selectGroupContent extraCss">
                        <h3>How old are you?</h3>
                        <div>
                            <div class="table">
                                <ng-select
                                    class="form-control"
                                    formControlName="age"
                                    [items]="ages"
                                    [ngClass]="{
                                        'has-error':
                                            !becometherapistForm2.controls.age
                                                .valid &&
                                            becometherapistForm2.controls.age
                                                .touched,
                                        'has-success':
                                            becometherapistForm2.controls.age
                                                .valid &&
                                            becometherapistForm2.controls.age
                                                .touched
                                    }"
                                ></ng-select>
                                <div
                                    *ngIf="
                                        becometherapistForm2.controls.age.errors
                                            ?.required &&
                                        becometherapistForm2.controls.age
                                            .touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Age is required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>What is your gender?</h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                formControlName="gender"
                                [items]="gender"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm2.controls.gender
                                            .valid &&
                                        becometherapistForm2.controls.gender
                                            .touched,
                                    'has-success':
                                        becometherapistForm2.controls.gender
                                            .valid &&
                                        becometherapistForm2.controls.gender
                                            .touched
                                }"
                            ></ng-select>
                            <div
                                *ngIf="
                                    becometherapistForm2.controls.gender.errors
                                        ?.required &&
                                    becometherapistForm2.controls.gender.touched
                                "
                                class="errorContainer"
                            >
                                <p>Gender is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            What is your most relevant qualification for this
                            job?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                formControlName="jobqualification"
                                [items]="jobqualification"
                                [(ngModel)]="selected"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm2.controls
                                            .jobqualification.valid &&
                                        becometherapistForm2.controls
                                            .jobqualification.touched,
                                    'has-success':
                                        becometherapistForm2.controls
                                            .jobqualification.valid &&
                                        becometherapistForm2.controls
                                            .jobqualification.touched
                                }"
                            ></ng-select>
                            <div
                                *ngIf="
                                    becometherapistForm2.controls
                                        .jobqualification.errors?.required &&
                                    becometherapistForm2.controls
                                        .jobqualification.touched
                                "
                                class="errorContainer"
                            >
                                <p>Job Qualification is required</p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="selectGroupContent"
                        *ngIf="selected == 'Other (Please Mention)'"
                    >
                        <h3>Name other :</h3>
                        <input
                            class="form-control Qualification"
                            type="text"
                            formControlName="jobqualificationOtherOptionAnswer"
                            placeholder="Other Qualification*"
                        />
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            How much actual counselling work experience do you
                            have?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                formControlName="counsellingexperience"
                                [items]="counsellingexperience"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm2.controls
                                            .counsellingexperience.valid &&
                                        becometherapistForm2.controls
                                            .counsellingexperience.touched,
                                    'has-success':
                                        becometherapistForm2.controls
                                            .counsellingexperience.valid &&
                                        becometherapistForm2.controls
                                            .counsellingexperience.touched
                                }"
                            ></ng-select>
                            <div
                                *ngIf="
                                    becometherapistForm2.controls
                                        .counsellingexperience.errors
                                        ?.required &&
                                    becometherapistForm2.controls
                                        .counsellingexperience.touched
                                "
                                class="errorContainer"
                            >
                                <p>This field is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            How many hours can you commit to counselling on
                            Please Coach per week?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                formControlName="counsellingPleasecoachPerWeek"
                                [items]="counsellingPleasecoachPerWeek"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm2.controls
                                            .counsellingPleasecoachPerWeek.valid &&
                                        becometherapistForm2.controls
                                            .counsellingPleasecoachPerWeek.touched,
                                    'has-success':
                                        becometherapistForm2.controls
                                            .counsellingPleasecoachPerWeek.valid &&
                                        becometherapistForm2.controls
                                            .counsellingPleasecoachPerWeek.touched
                                }"
                            ></ng-select>
                            <div
                                *ngIf="
                                    becometherapistForm2.controls
                                        .counsellingPleasecoachPerWeek.errors
                                        ?.required &&
                                    becometherapistForm2.controls
                                        .counsellingPleasecoachPerWeek.touched
                                "
                                class="errorContainer"
                            >
                                <p>This field is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            Would you be working elsewhere apart from practicing
                            via Please Coach?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                formControlName="WorkingElseWhereApartPleasecoach"
                                [items]="WorkingElseWhereApartPleasecoach"
                                [(ngModel)]="selectedOption"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm2.controls
                                            .WorkingElseWhereApartPleasecoach
                                            .valid &&
                                        becometherapistForm2.controls
                                            .WorkingElseWhereApartPleasecoach
                                            .touched,
                                    'has-success':
                                        becometherapistForm2.controls
                                            .WorkingElseWhereApartPleasecoach
                                            .valid &&
                                        becometherapistForm2.controls
                                            .WorkingElseWhereApartPleasecoach
                                            .touched
                                }"
                            ></ng-select>
                            <div
                                *ngIf="
                                    becometherapistForm2.controls
                                        .WorkingElseWhereApartPleasecoach.errors
                                        ?.required &&
                                    becometherapistForm2.controls
                                        .WorkingElseWhereApartPleasecoach.touched
                                "
                                class="errorContainer"
                            >
                                <p>This field is required</p>
                            </div>
                            <p>
                                Please note that we are fine with your other
                                engagements as long as you do not poach
                                customers from Please Coach.
                            </p>
                        </div>
                    </div>
                    <div
                        class="selectGroupContent"
                        *ngIf="
                            selectedOption ==
                            'Yes, I will continue with my employment as a counsellor (please mention employer)'
                        "
                    >
                        <input
                            class="form-control"
                            type="text"
                            formControlName="WorkingElseWhereApartPleasecoachOtherEmployer"
                            placeholder=" Other Employer *"
                        />
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            Are there any specific areas that you specialise in?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                (change)="addSpecializeAreaToArray()"
                                formControlName="specializeareas"
                                [items]="specializeareas1"
                            ></ng-select>
                        </div>
                    </div>
                    <div style="padding-top: 10px"></div>
                    <table>
                        <tr *ngFor="let i of selectedSpecializeAreas">
                            <td>
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true"
                                ></i>
                            </td>
                            <td>{{ i }}</td>
                            <td (click)="removeSpecializeAreaFromArray(i)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </table>
                    <div class="selectGroupContent">
                        <h3>What therapy techniques are you experienced in?</h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                (change)="addtherapeuticExperties()"
                                formControlName="therapeuticExperties"
                                [items]="therapeuticExpertiesOptions"
                            ></ng-select>
                        </div>
                    </div>
                    <div style="padding-top: 10px"></div>
                    <table>
                        <tr *ngFor="let i of selectedtherapeuticExperties">
                            <td>
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true"
                                ></i>
                            </td>
                            <td>{{ i }}</td>
                            <td
                                (click)="removeTherapeuticExpertiesFromArray(i)"
                            >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </table>
                    <div style="padding-top: 20px"></div>
                    <div class="selectGroupContent">
                        <h3>Describe Yourself</h3>
                        <div>
                            <textarea
                                class="form-group col-md-12"
                                type="text"
                                placeholder="Describe yourself in 40-50 words *"
                                style="
                                    background: #f6f8f9;
                                    border: 1px solid #f6f8f9;
                                    border-radius: 25px;
                                    padding: 10px;
                                "
                                formControlName="AboutPerson"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm2.controls
                                            .AboutPerson.valid &&
                                        becometherapistForm2.controls
                                            .AboutPerson.touched,
                                    'has-success':
                                        becometherapistForm2.controls
                                            .AboutPerson.valid &&
                                        becometherapistForm2.controls
                                            .AboutPerson.touched
                                }"
                                rows="5"
                            ></textarea>
                            <div
                                *ngIf="
                                    becometherapistForm2.controls.AboutPerson
                                        .errors?.required &&
                                    becometherapistForm2.controls.AboutPerson
                                        .touched
                                "
                                class="errorContainer"
                            >
                                <p>This field is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="theypisubmit text-center">
                        <button (click)="showold2part()" class="thm-2">
                            Back
                        </button>
                        <button
                            class="thm-2"
                            [disabled]="!becometherapistForm2?.valid"
                            (click)="ShowHideForNextPart()"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="becomeContentBox formRound" *ngIf="thirdPart">
            <form
                [formGroup]="becometherapistForm3"
                name="becometherapistForm3"
            >
                <!-- part3 -->
                <div>
                    <div class="selectGroupContent">
                        <h3>
                            How would you rate your fluency in English and other
                            languages?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                [items]="language"
                                (change)="addLanguageToArray()"
                                formControlName="language"
                            ></ng-select>
                            <table>
                                <tr>
                                    <th>Language</th>
                                    <th>Native</th>
                                    <th>Fluent</th>
                                    <th>Average</th>
                                </tr>

                                <tr
                                    *ngFor="
                                        let language of selectedLanguageArray;
                                        let idx = index;
                                        trackBy: indexTracker
                                    "
                                >
                                    <td>
                                        {{ language.language }}
                                    </td>
                                    <td>
                                        <input
                                            name="{{ language.language }}"
                                            [checked]="
                                                language.fluency == 'Native'
                                            "
                                            [(ngModel)]="
                                                selectedLanguageArray[idx]
                                                    .fluency
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            type="radio"
                                            value="Native"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            name="{{ language.language }}"
                                            [checked]="
                                                language.fluency == 'Fluent'
                                            "
                                            [(ngModel)]="
                                                selectedLanguageArray[idx]
                                                    .fluency
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            type="radio"
                                            value="Fluent"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            name="{{ language.language }}"
                                            [checked]="
                                                language.fluency == 'Average'
                                            "
                                            [(ngModel)]="
                                                selectedLanguageArray[idx]
                                                    .fluency
                                            "
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            type="radio"
                                            value="Average"
                                        />
                                    </td>
                                    <td>
                                        <i
                                            class="fa fa-times"
                                            (click)="
                                                removeLanguageFromArray(
                                                    language.language
                                                )
                                            "
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                </tr>
                                <tr></tr>
                            </table>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            Do you have the required infrastructure for a video
                            call?
                        </h3>
                        <div>
                            <input
                                type="checkbox"
                                formControlName="RequiredInfrastructureForVideoCallDevice"
                            />
                            Device(laptop/smartphone/desktop with webcam)
                            <br />
                            <input
                                type="checkbox"
                                formControlName="RequiredInfrastructureForVideoCallInternet"
                            />
                            High speed Internet
                            <br />
                            <input
                                type="checkbox"
                                formControlName="RequiredInfrastructureForVideoCallEnvironment"
                            />
                            Conducive environment(quiet personal spot)
                            <br />
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>How do you offer therapy?</h3>
                        <div>
                            <input
                                type="checkbox"
                                formControlName="audioTherapy"
                            />
                            Audio
                            <br />
                            <input
                                type="checkbox"
                                formControlName="videoTherapy"
                            />
                            Video
                            <br />
                            <input
                                type="checkbox"
                                formControlName="chatTherapy"
                            />
                            Chat
                            <br />
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>
                            Are you comfortable checking and responding to chat
                            messages and email 2-3 times a day apart from your
                            scheduled calls?
                        </h3>
                        <div>
                            <ng-select
                                class="form-control table"
                                formControlName="ChatMessage"
                                [items]="ChatMessage"
                                [ngClass]="{
                                    'has-error':
                                        !becometherapistForm3.controls
                                            .ChatMessage.valid &&
                                        becometherapistForm3.controls
                                            .ChatMessage.touched,
                                    'has-success':
                                        becometherapistForm3.controls
                                            .ChatMessage.valid &&
                                        becometherapistForm3.controls
                                            .ChatMessage.touched
                                }"
                            ></ng-select>
                            <div
                                *ngIf="
                                    becometherapistForm3.controls.ChatMessage
                                        .errors?.required &&
                                    becometherapistForm3.controls.ChatMessage
                                        .touched
                                "
                                class="errorContainer"
                            >
                                <p>This field is required</p>
                            </div>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>What is your preferred time for a session?</h3>
                        <div>
                            <table>
                                <tr>
                                    <th>Time Slot</th>
                                    <th>Time Zone</th>
                                </tr>
                                <tr style="background-color: white">
                                    <td>
                                        <ng-select
                                            class="form-control table"
                                            formControlName="TimeSlott"
                                            [items]="TimeSlott"
                                            [ngClass]="{
                                                'has-error':
                                                    !becometherapistForm3
                                                        .controls.TimeSlott
                                                        .valid &&
                                                    becometherapistForm3
                                                        .controls.TimeSlott
                                                        .touched,
                                                'has-success':
                                                    becometherapistForm3
                                                        .controls.TimeSlott
                                                        .valid &&
                                                    becometherapistForm3
                                                        .controls.TimeSlott
                                                        .touched
                                            }"
                                        ></ng-select>
                                        <div
                                            *ngIf="
                                                becometherapistForm3.controls
                                                    .TimeSlott.errors
                                                    ?.required &&
                                                becometherapistForm3.controls
                                                    .TimeSlott.touched
                                            "
                                            class="errorContainer"
                                        >
                                            <p>This field is required</p>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-select
                                            class="form-control table"
                                            formControlName="TimeZone"
                                            [items]="TimeZone"
                                        ></ng-select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="selectGroupContent">
                        <h3>Upload your resume: *</h3>
                        <div *ngIf="uploadResumeObj === null">
                            <input
                                type="file"
                                class="form-control"
                                name="files"
                                (change)="uploadResume($event)"
                            />
                        </div>
                        <div
                            *ngIf="uploadResumeObj !== null"
                            class="documentUpHolder"
                        >
                            <p>{{ uploadResumeObj.fileName }}</p>
                            <button
                                class="uploadDocBtn"
                                (click)="showUploadBox()"
                            >
                                Cancel
                            </button>
                        </div>
                        <div *ngIf="showUploadWarning" class="errorContainer">
                            <p>Resume is required</p>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-12 buttonCsspart3"
                    style="display: flex; justify-content: center"
                >
                    <button
                        type="submit"
                        [style.display]="showBack ? 'block' : 'none'"
                        class="thm-2 text-center"
                        (click)="showold3part()"
                    >
                        Back
                    </button>
                    <div *ngIf="isLoading" class="">
                        <div
                            class="spinner-border"
                            style="
                                width: 2rem;
                                height: 2rem;
                                margin-left: 55px;
                                margin-top: 23px;
                            "
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                        <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
                    </div>
                    <button
                        type="submit"
                        *ngIf="!isLoading"
                        (click)="onSubmit()"
                        class="thm-2 text-center"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
