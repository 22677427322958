import { AfterViewInit, Component, OnInit } from "@angular/core";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.css"],
})
export class PrivacyPolicyComponent implements OnInit, AfterViewInit {
  constructor(private sidenavService: SideNavBarService, private metaSeo: SeoMetaService) { }

  ngOnInit(): void {
    this.metaSeo.updateTitle("Online Counseling | PleaseCoach");
    window.onload = (event) => {
      this.hideSalesIQ();
    }
  }

  ngAfterViewInit() {
    this.hideSalesIQ();
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }
  hideSalesIQ() {
    window["$zoho"].salesiq.ready = function () {
      window["$zoho"].salesiq.floatbutton.visible("hide");
    };
    window.onload = (event) => {
      window["kiwi"]["hide"]();
    };
  }
}
