import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/app/auth/auth.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import { SubscriptionService } from "../../subscription.service";
import { BootstrapAlertService } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert.service";
import { BootstrapAlert } from "src/app/shared/ng-bootstrap-alert/bootstrap-alert";
import { ProfileService } from "src/app/user/profile.service";
import { BlogService } from "../../blog.service";
import { environment } from "src/environments/environment";
import { SeoMetaService } from "src/app/seo-meta.service";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { faCommentAlt, faVideo, faLongArrowAltRight, faStar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Router } from "@angular/router";
import clevertap from "clevertap-web-sdk";


SwiperCore.use([Pagination, Navigation, Autoplay]);
@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit {
  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2') slickModal2: SlickCarouselComponent;
  faCheckSquare = faCheckSquare;
  faCommentAlt = faCommentAlt;
  faVideo = faVideo;
  faLongArrowAltRight = faLongArrowAltRight;
  faStar = faStar;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  isLoggedin: boolean;
  isDashboardRedirect: boolean;
  latestBlogs = [];
  imageUrl = environment.apiUrl;
  userReviews = [
    {
      text: "Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.",
      name: "Sana Javed",
      work: "Software Engineer",
    },
    {
      name: "Naina Kashyap",
      work: "Mumbai",
      text: "When I finally held my baby in my arms after 9 months of waiting and expecting, I didn’t really feel flooded with love or attachment. To the contrary I felt overwhelmed and despondent. When I reached out to pleasecoach, I was full of guilt and shame for feeling this way. My pleasecoach therapist helped me understand that a biochemical shift in my brain was causing my despair. Within a few sessions she pulled me out of this really dark place & helped me find joy in my motherhood."
    },
    {
      text: "I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
      name: "Puja Mittal",
      work: "Homemaker",
    },
    {
      text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at pleasecoach who made helped me understand & treat my depression.",
      name: "Vikas Arora",
      work: "CFO",
    },
    {
      text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
      name: "Aishwarya Hegde",
      work: "Student",
    },
  ];

  slideConfig = {
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
    ],
  };

  testimonialsSlideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      },
    ]
  }

  therapistsData = [
    {
      name: "Puja Roy",
      title: "M.Sc in Health Psychology (Gold Medalist)",
      fileName: "pura-roy-slide.webp",
      id: "603b643a37e19c36ed87dfa4"
    },
    {
      name: "Sadaf Mir",
      title: "Master\'s in Clinical Psychology",
      fileName: "sadaf-mir-slide.webp",
      id: "619378a71192ee11e85d1d28"
    },
    {
      name: "Ritam Dubey",
      title: "MA in Counselling Psychology",
      fileName: "ritam-slide-image.webp",
      id: "626a1d73069e10227bba193e"
    },
    {
      name: "Dr. V S Ananthakrishnan",
      title: "MBBS, MD (Psychiatry)",
      fileName: "anantha-krishnan-slide.webp",
      id: "607fcf80a14fe9519e363e4c"
    },
    {
      name: "Zoha Merchant",
      title: "M.Phil. in Psychology",
      fileName: "zoha-slide.webp",
      id: "61b837471ff9c716c12706e3"
    }
  ]
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  imageObject: Array<object> = [{
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    title: 'Hummingbirds are amazing creatures'
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
  }, {
    video: 'https://youtu.be/tYa6OLQHrEc',
    posterImage: 'https://img.youtube.com/vi/tYa6OLQHrEc/hqdefault.jpg',
    title: 'Youtube example one with title.'
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    title: 'Most beautiful birds in the world flying.'
  }];
  constructor(
    private titleServe: Title,
    private userAuthService: AuthService,
    private userService: ProfileService,
    private sidenavService: SideNavBarService,
    private subscriptionService: SubscriptionService,
    private alertService: BootstrapAlertService,
    private blogService: BlogService,
    private metaSeo: SeoMetaService,
    private router: Router,
  ) { }
  subsForm = new FormGroup({
    email: new FormControl("", Validators.required),
  });

  ngOnInit(): void {
    this.metaSeo.updateTitle(
      "Online Counseling | PleaseCoach"
    );
    this.metaSeo.updateMetaInfo(
      "The most convenient way to access counseling & therapy - anytime, anywhere, any device. Connect with the best in class psychologists over chat or video sessions. Get matched now.",
      "Online counseling, online therapy, psychologists near me, clinical psychologist, psychologist, counselor, therapist, talk therapy, counseling app, therapy app"
    );
    this.initAnimations();
    this.isLoggedin = this.userAuthService.getIsAuth();
    if (this.isLoggedin) {
      this.userService.getBookingAvailable().subscribe((res) => {
        let plans = res["bookingAvailable"];
        this.isDashboardRedirect = plans > 0;
      });
    }
    this.blogService.getBlogsForHomePage().subscribe((res) => {
      this.latestBlogs = res;
    });
  }

  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  getStarted() {
    if (clevertap) {
      clevertap.event.push("Get Started - Main Banner")
    }
  }

  getFreereport() {
    if (clevertap) {
      clevertap.event.push("Free Report - Homepage")
    }
  }

  getAllCounsellors() {
    if (clevertap) {
      clevertap.event.push("View All Counsellors - Homepage")
    }
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }

  navigateToOurCounsellors() {
    this.router.navigate(['our-counselors'])
  }

  initAnimations() {
    const options = { rootMargin: "0px 0px -100px 0px" }
    const faders = document.querySelectorAll(".fade-in")
    const sliders = document.querySelectorAll(".slide-in")
    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add('appear');
          appearOnScroll.unobserve(entry.target)
        }
      })
    }, options)

    faders.forEach(fader => {
      appearOnScroll.observe(fader)
    })
    sliders.forEach(slider => {
      appearOnScroll.observe(slider)
    })
  }

  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  nextSlideTestimonials() {
    this.slickModal1.slickNext();
  }
  prevSlideTestimonials() {
    this.slickModal1.slickPrev();
  }
  nextSlideBlog() {
    this.slickModal2.slickNext();
  }
  prevSlideBlog() {
    this.slickModal2.slickPrev();
  }

  onSwiper(event) { }

  onSlideChange() { }

  openChat() {
    // let chatElem = document.getElementById("zsiq_agtpic");
    // chatElem.click();
  }

  getFormControls() {
    return this.subsForm.controls;
  }

  comingSoon() {
    this.alertService.alert(
      new BootstrapAlert(
        "Coming soon for mobile devices! Stay Tuned! 🤩",
        "alert-success"
      )
    );
  }

  onSubmit() {
    this.subscriptionService
      .subscribe(this.subsForm.value.email)
      .subscribe((res) => {
        if (res) {
          clevertap.event.push("Email Subscribed");
          this.alertService.alert(
            new BootstrapAlert(
              "You have successfully subscribed!",
              "alert-success"
            )
          );
          this.subsForm.reset();
        }
      });
  }
}
