import { TherapistVideoService } from '../therapist-video.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Zoom } from 'swiper';
import { environment } from 'src/environments/environment';
import { SideNavBarService } from 'src/app/side-nav-bar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingService } from '../booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { Meta } from '@angular/platform-browser';

import {
  BootstrapAlert,
  BootstrapAlertService,
} from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';

interface IMeetingDetailsResponse {
  id: string | number;
  password: string;
  zakToken: string;
  signatureToken: string;
}
interface ITherapistDetails {
  firstname: string;
  lastname: string;
  zoomEmail: string;
  _id: string;
}
@Component({
  selector: 'app-session-join',
  templateUrl: './session-join.component.html',
  styleUrls: ['./session-join.component.css']
})

export class SessionJoinComponent implements OnInit {
  isJoiningRoom = false;
  meetingDetails: IMeetingDetailsResponse = {
    id: '',
    password: '',
    signatureToken: '',
    zakToken: ''
  };
  responsiveRoute = ""
  ZoomMtg
  mobileScreen = false
  MobileText: String;
  leaveUrl = environment.base_url + '/therapist-dashboard/sessions'
  roomName = "";
  room = null;
  videoToken;
  therapistDetails: ITherapistDetails = {
    firstname: '',
    lastname: '',
    zoomEmail: '',
    _id: '',
  }
  isAudio: Boolean = true;
  isVideo: Boolean = true;
  isLocalAudio: Boolean = true;
  isLocalVideo: Boolean;
  isFullScreen: Boolean = false;
  isRemoteJoined = false;
  mediaDevices = [];
  localVideoTrack;
  localAudioTrack;
  localTracks = null;
  isLoading = true;
  mics = [];
  cams = [];
  remoteAudioMuted = false;
  remoteVideoMuted = false;
  status = "";
  remainingMinutes = null;
  sessionData = null;
  joinDate = null;
  remainingTimeText = "";
  imageUrl = environment.apiUrl;
  audioLevel = 0;
  isMeetingFetchLoader = false;
  isMobile = false;
  isMicAvailable = true;
  isCamAvailable = true;
  isFirstTime = true;
  isConnecting = false;
  deviceInfo = null;
  constructor(
    private videoService: TherapistVideoService,
    private sideNavService: SideNavBarService,
    private bookingService: BookingService,
    private actRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private confirmationService: ConfirmationDialogService,
    private router: Router,
    private alertService: BootstrapAlertService, private meta: Meta
  ) {

  }

  initializeLibrary() {
    import('@zoomus/websdk').then(({ ZoomMtg }) => {
      this.ZoomMtg = ZoomMtg
      ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.0/lib', '/av');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      // loads language files, also passes any error messages to the ui
      this.isJoiningRoom = true
      this.ZoomMtg.i18n.load('en-US');
      this.ZoomMtg.i18n.reload('en-US');

      document.getElementById('zmmtg-root').style.display = 'none'
      document.getElementById('zmmtg-root').style.backgroundColor = 'transparent'
      document.getElementById('zmmtg-root').style.overflow = 'scroll'


      this.initMeeting()
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    document.getElementById('zmmtg-root').style.display = 'none'
    document.getElementById('zmmtg-root').style.backgroundColor = 'transparent'
    document.getElementById('zmmtg-root').style.overflow = 'scroll'
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.actRoute.queryParamMap.subscribe(
      (params) => {
        let leftSession = params.get("leftSession");
        if (leftSession == "true") {
          this.status = "CALL_ENDED"
        }
        this.roomName = params.get("sessionId");
        this.responsiveRoute = environment.deepLinkTherapist + params.get("sessionId");
        this.leaveUrl = environment.base_url + '/therapist-dashboard/session-join?sessionId=' + this.roomName + "&leftSession=true"
        this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Page Loaded - Therapist' }).subscribe((res) => {
        });
        this.isLoading = true;
        this.bookingService
          .getSession(this.roomName)
          .subscribe(async (data) => {
            this.sessionData = data["booking"];
            if (data["booking"]['therapistId']) {
              this.therapistDetails = {
                firstname: data["booking"]['therapistId'].firstname,
                lastname: data["booking"]['therapistId'].lastname,
                zoomEmail: data["booking"]['therapistId'].zoomEmail,
                _id: data["booking"]['therapistId']._id,
              }
            }
            if (data["booking"].meeting) {
              this.meetingDetails.id = data["booking"].meeting.id
              this.meetingDetails.password = data["booking"].meeting?.pwd || ""
              this.meetingDetails.signatureToken = data["booking"].meeting?.signatureToken
              this.meetingDetails.zakToken = data["booking"].meeting?.zakToken
            }
            let startDate = moment(this.sessionData.startDate);
            let timeDiff = this.bookingService.calcTimeDiff(
              startDate["_d"],
              new Date()
            );

            let timeDiffKey = Object.keys(timeDiff);
            timeDiffKey.forEach((key) => {
              if (timeDiff[key] > 0) {
                if (timeDiff[key] > 1) {
                  this.remainingTimeText += timeDiff[key] + " " + key + " ";
                } else {
                  this.remainingTimeText +=
                    timeDiff[key] + " " + key.slice(0, -1) + " ";
                }
              }
            });

            let now = moment(new Date());
            this.remainingMinutes = moment.duration(startDate.diff(now));
            this.remainingMinutes = this.remainingMinutes.asMinutes();
            this.joinDate = new Date(this.sessionData.startDate);
            this.joinDate.setMinutes(this.joinDate.getMinutes() - 10);
            if (this.status == "CALL_ENDED") {
              this.isLoading = false;
            }
            else if (this.remainingMinutes <= -90) {
              this.isLoading = false;
              this.status = "PAST_MEETING";
              setTimeout(() => {
                this.router.navigateByUrl("therapist-dashboard/sessions");
              }, 3000)

            }
            else if (this.remainingMinutes <= 30) {
              this.status = "ABOUT_TO_START";
              this.isLoading = false
              this.doHandleMeetingInit()

            } else {
              this.isLoading = false;
              this.status = "NOT_STARTED";
            }
          });
      },
      (error) => {
        if (error.status === 404) {
          this.status = "NOT_FOUND";
        }
      }
    );




  }
  isMeetingAccessible(endDate, startDate, defaultMargin = 30) {
    try {
      let endDateMoment = moment(endDate)
      let addedMargin = endDateMoment.add(defaultMargin, 'minutes')
      let startDateMoment = moment(startDate)
      let now = moment(new Date());
      return now.isBetween(startDateMoment, addedMargin)

    }
    catch (e) {
      return false
    }


  }

  doHandleMeetingInit() {
    this.isLoading = false;

    if (this.deviceService.os.toLocaleLowerCase().includes('ios')) {

      this.MobileText = "App Store";
      this.mobileScreen = true;

    }
    else if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {

      this.MobileText = "Play Store";
      this.mobileScreen = true;

    } else {
      
      if (this.meetingDetails.id &&
        this.meetingDetails.password &&
        this.meetingDetails.signatureToken &&
        this.meetingDetails.zakToken) {

        // this.initializeLibrary()
        this.mobileScreen = false;

      }
      else {
        this.status = "ABOUT_TO_START";
        this.isLoading = false
        // this.doGetMeetingDetails()
      }

    }
  }

  doGetMeetingDetails() {
    // get meeting details service 
    this.isMeetingFetchLoader = true
    this.bookingService
      .joinSession(this.roomName)
      .subscribe(async (data) => {
        this.isLoading = false
        this.isMeetingFetchLoader = true
        
        if (data["booking"].meeting && data["booking"].meeting.id && data["booking"].meeting.pwd && data["booking"].meeting.signatureToken && data["booking"].meeting.zakToken) {
          this.meetingDetails.id = data["booking"].meeting.id
          this.meetingDetails.password = data["booking"].meeting?.pwd || ""
          this.meetingDetails.signatureToken = data["booking"].meeting?.signatureToken
          this.meetingDetails.zakToken = data["booking"].meeting?.zakToken
          this.initializeLibrary()
        }
        else {
          this.confirmationService
            .confirm(
              "No slot available",
              "There has been error while creating meeting please contact support. Please try again",
              "ok",
              "Contact Support"
            )
            .subscribe((res) => {
              if (res) {
                
              }
            });
        }
      }, error => {
        this.isMeetingFetchLoader = false
        this.confirmationService
          .confirm(
            "No slot available",
            error.error.message,
            "ok",
            "Contact Support"
          )
          .subscribe((res) => {
            if (res) {
              
            }
          });
      })

    // if no meeting detals show popup
  }
  initMeeting() {
    console.log('init meeting--------------')
    this.ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      screenShare: true,
      showMeetingHeader: false,
      disableInvite: true,
      disableRecord: true,
      isSupportChat: true,
      audioPanelAlwaysOpen: false,
      debug: false,
      meetingInfo: [],
      success: (success) => {
        console.log('init meeting-sucess--------------')
        this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Zoom init - Therapist' }).subscribe((res) => { })

        if (this.deviceService.os.includes('ios') || this.deviceService.os.includes('iOS')) {
          document.getElementById('media-preview-tooltip-container').style.position = 'absolute'
          document.getElementById('media-preview-tooltip-container').style.left = '50px'
        }
        if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {
          this.meta.updateTag(
            { name: 'viewport', content: "width=device-width, initial-scale=1" },
            'name=viewport'
          )
          document.getElementById('content_container').style.height = '80vh'
          document.getElementById('content_container').style.width = '50vh'
          document.getElementById('media-preview-tooltip-container').style.position = 'absolute'
          document.getElementById('media-preview-tooltip-container').style.left = '50px'
        }
        this.ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
          // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
          if (data.meetingStatus == 1) {
            console.log('Connecting')
            this.sideNavService.hideHeaderActions()
            console.log('Connecting--hide')
          }
          if (data.meetingStatus == 2) {
            console.log('--connected')
            this.sideNavService.hideHeaderActions()
          } if (data.meetingStatus == 3) {
            console.log('--disconnected')
            this.sideNavService.hideHeaderActions()
          }
          if (data.meetingStatus == 4) {
            console.log('--reconnecting')
            this.sideNavService.hideHeaderActions()
          }
        });
        this.ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
          // console.log(data, 'onUserJoin');
        });

        // only support meeting
        this.ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
          // console.log(data, 'onUserLeave');
        });

        this.ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
          console.log(data, 'onUserIsInWaitingRoom');
        });
        this.startMeeting()

      },
      error: (error) => {
        // open sidenav if error comes
        this.sideNavService.showHeaderActions()
        // console.log(error)
      }
    })
  }
  startMeeting() {
    try {
      console.log('start meeting--------------')

      let zoomRoot = document.getElementById('zmmtg-root')
      zoomRoot.style.display = 'block'
      if (this.deviceService.isDesktop()) {
        document.getElementById('zmmtg-root').style.paddingLeft = '8rem'
        document.getElementById('zmmtg-root').style.overflow = 'hidden'
      }
      if (this.deviceService?.isMobile()) {
        if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {
          zoomRoot.className += ' android'
          this.meta.updateTag(
            { name: 'viewport', content: "width=device-width, initial-scale=0.1" },
            'name=viewport'
          )
          // document.getElementById('zmmtg-root').style.paddingLeft = '8rem'
        }
        this.sideNavService.hideHeaderActions()

      }
      console.log('start meeting - - JOINING--------------')
      this.ZoomMtg.join({
        meetingNumber: this.meetingDetails.id.toString(),
        sdkKey: environment.zoomSdkKey,
        userName: this.therapistDetails.firstname + " " + this.therapistDetails.lastname,
        passWord: this.meetingDetails.password,
        zak: this.meetingDetails.zakToken,
        signature: this.meetingDetails.signatureToken,
        success: (success) => {
          this.ZoomMtg.showJoinAudioFunction({
            show: false
          });
          this.videoService
            .setTherapistStarted(this.roomName)
            .subscribe((res) => { });
          if (this.deviceService.isMobile()) {
            if (this.deviceService.os.includes('Android') || this.deviceService.os.includes('android')) {
              this.meta.updateTag(
                { name: 'viewport', content: "width=device-width, initial-scale=0.1" },
                'name=viewport'
              )
            }
          }
          this.sideNavService.hideHeaderActions()
          console.log('start meeting - - JOINING-success--------------')
        },
        error: (error) => {
          console.log('start meeting - - something went wrong while JOINING---------------')
          this.sideNavService.showHeaderActions()
        }
      })
    }
    catch (e) {
      this.sideNavService.showHeaderActions()
    }
  }
  joinCall() {
    if (this.status === "ABOUT_TO_START") {
      if (this.mics.length === 0 || this.cams.length === 0) {
        if (this.mics.length === 0 && this.cams.length === 0) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like you don't have mic and camera connected. Therapist won't be see and hear you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
        if (this.mics.length === 0 && this.cams.length > 0) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like you don't have mic connected. Therapist won't be able to hear you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
        if (this.mics.length > 0 && this.cams.length === 0) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like you don't have camera connected. Therapist won't be able to see you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
      } else {
        this.checkJoin();
      }
    } else if (this.status === "NOT_STARTED") {
      this.checkJoin();
    }
  }


  checkJoin() {
    if (this.status == "CALL_ENDED") {
      this.status = ""
      this.router.navigateByUrl("therapist-dashboard/session-join?sessionId=" + this.roomName);
    }
    else if (this.status == "ABOUT_TO_START") {
      if(window["$zoho"]){
        if(window["$zoho"].salesiq){
          window["$zoho"].salesiq.floatbutton.visible("hide");
        }
      }
      if(window["kiwi"]){
        window["kiwi"]["hide"]();
      }
      this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Starting - Therapist' }).subscribe((res) => { })
      this.doGetMeetingDetails()
    } else if (this.status == "NOT_STARTED") {
      let startDate = moment(this.sessionData.startDate);
      let now = moment(new Date());
      this.remainingMinutes = moment.duration(startDate.diff(now));
      this.remainingMinutes = this.remainingMinutes.asMinutes();
      if (this.remainingMinutes < 10) {
        this.status = "ABOUT_TO_START";
      } else {
        this.alertService.alert(
          new BootstrapAlert(
            "You can join session when 30 minutes are remaining",
            "alert-warning"
          )
        );
      }
    }
  }
  openLink() {
    window.open(this.responsiveRoute, '_blank');
  }
  ngOnDestroy() {
    this.sideNavService.showHeaderActions()
    window.location.reload();
    this.meta.updateTag(
      { name: 'viewport', content: "width=device-width, initial-scale=1" },
      'name=viewport'
    )

  }

}
