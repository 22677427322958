import { Component, OnInit } from "@angular/core";
import { SeoMetaService } from "src/app/seo-meta.service";
import { SideNavBarService } from "src/app/side-nav-bar.service";

@Component({
  selector: "app-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.css"],
})
export class DisclaimerComponent implements OnInit {
  constructor(private sidenavService: SideNavBarService, private metaSeo: SeoMetaService) { }

  ngOnInit(): void {
    this.metaSeo.updateTitle("Online Counseling | PleaseCoach")
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
}
