import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SideNavBarService {
  private sidenavbarListener = new Subject<boolean>();
  private sideNavAndHeader = new Subject<boolean>();
  isSideNav = false;
  constructor() { }
  sideNavBarListener() {
    return this.sidenavbarListener.asObservable();
  }
  dashboardsideNavAndHeader() {
    return this.sideNavAndHeader.asObservable();
  }
  toggleValue() {
    this.isSideNav = !this.isSideNav;
    this.sidenavbarListener.next(this.isSideNav);
  }
  opensidenav() {
    this.sidenavbarListener.next(true);
  }
  closesidenav() {
    this.sidenavbarListener.next(false);
  }
  hideHeaderActions() {
    this.sideNavAndHeader.next(false)
  }
  showHeaderActions() {
    this.sideNavAndHeader.next(true)
  }
}
