<div class="formRound">
    <div style="display: flex; align-items: center; margin-bottom: 20px">
        <span
            ><a
                routerLink="../discussion"
                style="
                    font-size: 16px;
                    color: #445e93;
                    cursor: pointer;
                    text-decoration: underline;
                "
                >Discussion</a
            ></span
        >
        <span style="font-size: 16px">&nbsp;>&nbsp;</span>
        <span style="font-size: 16px; color: #808080">Ask Anonymously</span>
    </div>
    <form [formGroup]="discussionForm" name="userForm">
        <div class="eachOptionsDivTag">
            <h3>What is Your Discussion About?</h3>
            <div class="sectionWrap">
                <input
                    type="text"
                    id="topic"
                    name="topic"
                    formControlName="topic"
                    class="form-control"
                    placeholder="Enter Topic of your choice"
                    required="required"
                    [ngClass]="{
                        'has-error':
                            !discussionForm.controls.topic.valid &&
                            discussionForm.controls.topic.touched,
                        'has-success':
                            discussionForm.controls.topic.valid &&
                            discussionForm.controls.topic.touched
                    }"
                />
                <div
                    *ngIf="
                        discussionForm.controls.topic.errors?.required &&
                        discussionForm.controls.topic.touched
                    "
                    class="errorContainer"
                >
                    <p>Topic is required</p>
                </div>
            </div>
        </div>
        <div class="eachOptionsDivTag extraCssforcategory">
            <div class="row">
                <div class="col-md-12">
                    <div class="insideFormDivTags">
                        <h3>Category</h3>
                        <ng-select
                            formControlName="tags"
                            class="form-control"
                            [multiple]="true"
                            [selectableGroup]="true"
                            [closeOnSelect]="false"
                            [selectableGroup]="true"
                            [ngClass]="{
                                'has-error':
                                    !discussionForm.controls.tags.valid &&
                                    discussionForm.controls.tags.touched,
                                'has-success':
                                    discussionForm.controls.tags.valid &&
                                    discussionForm.controls.tags.touched
                            }"
                        >
                            <ng-option
                                *ngFor="let category of categoryitems"
                                [value]="category._id"
                                >{{ category.name }}</ng-option
                            >
                        </ng-select>
                        <div
                            *ngIf="
                                discussionForm.controls.tags.errors?.required &&
                                discussionForm.controls.tags.touched
                            "
                            class="errorContainer"
                        >
                            <p>Please select minium one tag</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eachOptionsDivTag">
                <h3>Describe Your Topic</h3>
                <div class="sectionWrap editor">
                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                    <ngx-editor
                        [editor]="editor"
                        [placeholder]="'Type here...'"
                        formControlName="description"
                        [ngClass]="{
                            'has-error':
                                !discussionForm.controls.description.valid &&
                                discussionForm.controls.description.touched,
                            'has-success':
                                discussionForm.controls.description.valid &&
                                discussionForm.controls.description.touched
                        }"
                    ></ngx-editor>
                </div>
                <div
                    *ngIf="
                        discussionForm.controls.description.errors?.required &&
                        discussionForm.controls.description.touched
                    "
                    class="errorContainer"
                >
                    <p>Description is required</p>
                </div>
            </div>
            <div class="submitbtnn text-center">
                <div class="anonymousBox" [formGroup]="discussionForm">
                    <input type="checkbox" formControlName="isAnonymous" />
                    Post Anonymously
                </div>
                <div *ngIf="isLoading" class="d-flex justify-content-center">
                    <!-- <div
            class="spinner-border"
            style="
              width: 2rem;
              height: 2rem;
              margin-top: 23px;
            "
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div> -->
                    <ng-lottie
                        height="150px"
                        [options]="options"
                        (animationCreated)="animationCreated($event)"
                    ></ng-lottie>
                </div>

                <button (click)="onSubmit()" class="thm-2" *ngIf="!isLoading">
                    Start Discussion
                </button>
            </div>
        </div>
    </form>
</div>
