import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { COMMON_DATA } from "src/app/shared/common";
import { SideNavBarService } from "src/app/side-nav-bar.service";
  
@Component({
  selector: "app-anxiety-test",
  templateUrl: "./anxiety-test.component.html",
  styleUrls: ["./anxiety-test.component.css"],
})
export class AnxietyTestComponent implements OnInit {
  freeReportForm: FormGroup;
  userForm2: FormGroup;
  hideModal = true;
  firstPart: boolean = true;
  secondPart: boolean = false;
  currentIndex = 0;
  answers = COMMON_DATA.anixety_options;
  questions = COMMON_DATA.anixety_questions;
  progressPercentage = 0;
  userCountryCode = {
    countryCode:  localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "in",
    dialCode: localStorage.getItem("dialCode") ? localStorage.getItem("dialCode") : "91",
  };
  constructor(
    private reportService: FreeReportService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) {}
  checkError(valid) {
    if(!valid){
      this.userForm2.controls["phone"].setErrors({ 'invalidPhone' : true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.userForm2.patchValue({
      phone : phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  previousPage() {
    this.currentIndex--;
  }
  currentIndexInc() {
    this.currentIndex++;
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction1 = function () {
    this.firstPart = true;
    this.secondPart = false;
  };
  ngOnInit() {
    this.loadGTMCid();
    window.onload = (event) => {
      this.loadGTMCid();
    }
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  loadGTMCid(){
    if (window["dataLayer"]) {
      let dataLayer = window["dataLayer"];
      dataLayer.forEach((data) => {
        if (data["cid"]) {
          localStorage.setItem("GTMcid", data["cid"]);
        }
      });
    }
  }
  initForms2() {
    this.userForm2 = new FormGroup({
      email: new FormControl(localStorage.getItem("email") ? localStorage.getItem("email") : "", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl(localStorage.getItem("phone") ? localStorage.getItem("phone") : "", Validators.required),
      fullname: new FormControl(localStorage.getItem("name") ? localStorage.getItem("name") : "", Validators.required),
    });
  }
  indexTracker(index: number, value: any) {
    return index;
  }
}
