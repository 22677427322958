import { Component, OnInit } from '@angular/core';
import { SeoMetaService } from 'src/app/seo-meta.service';
import { SideNavBarService } from 'src/app/side-nav-bar.service';

@Component({
  selector: 'app-ad-campaign',
  templateUrl: './ad-campaign.component.html',
  styleUrls: ['./ad-campaign.component.css']
})
export class AdCampaignComponent implements OnInit {
  isLoggedin: boolean = false;
  isDashboardRedirect: boolean = false;
  innerWidth : number;
  itemsPerSlide = 3;
  mobileBreakpoint = 360
  userReviews = [
    {
      name : "Nakul Sinha",
      work : "New Delhi",
      text : "When my wife and I decided to start a family, it was not all hearts and rainbows. There was so much anxiety about what was to come and doubt about it being the right decision. Pre-natal doctor appointments and check ups really took a toll. The counsellor at pleasecoach helped us navigate this difficult phase. She not only put our minds at ease but also addressed all our insecurities. We were so much better prepared for parenthood with these counselling sessions."
    },
    {
      name : "Naina Kashyap",
      work : "Mumbai",
      text : "When I finally held my baby in my arms after 9 months of waiting and expecting, I didn’t really feel flooded with love or attachment. To the contrary I felt overwhelmed and despondent. When I reached out to pleasecoach, I was full of guilt and shame for feeling this way. My pleasecoach therapist helped me understand that a biochemical shift in my brain was causing my despair. Within a few sessions she pulled me out of this really dark place & helped me find joy in my motherhood."
    },
    {
      name : "Karishma Patni",
      work : "Bangalore",
      text : "I felt ready to be a mother when I became one. I had braced myself for everything & found most solutions on the online mom boards. What I did not expect was losing myself, I jumped 4 dress sizes and could not bear to look at myself in the mirror. This self-hatred & toxic body image marred the motherhood joy. I developed an eating disorder. My pleasecoach therapist helped me accept my reality & develop body positivity. With her help, I started my journey to regain my health. Lost 20 pounds and found mental peace that helped me become a better mother. "
    },
    {
    text: 'Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.',
    name: 'Sana Javed',
    work: 'Software Engineer'
  },{
    text : 'I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.',
    name : 'Puja Mittal',
    work : "Homemaker"
  }, {
    text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at pleasecoach who made helped me understand & treat my depression.",
    name: "Vikas Arora",
    work: "CFO"
  }, {
    text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
    name: "Aishwarya Hegde",
    work : "Student"
  }]
  constructor(private sidenavService: SideNavBarService, private seoService: SeoMetaService) { }

  ngOnInit(): void {
    this.adjustsItemsPerSlide();
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 4000)
    this.seoService.updateTitle("Online Counseling | PleaseCoach")
  }

  closenavbar() {
    this.sidenavService.closesidenav();
  }
  
  private adjustsItemsPerSlide() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.itemsPerSlide = 1;
    } else {
      this.itemsPerSlide = 3;
    }
  }

  chatWithUs(){
  //   window["$zoho"].salesiq.floatbutton.visible("show");
  //   let chatElem = document.getElementById('zsiq_agtpic')
	// 	chatElem.click();
  }

}
