<div class="sectionPadding">
    <div class="container-fluid">
        <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
        <div class="sectionHeading">
            <h2>Choose Your Therapist</h2>
            <hr />
            <p>Based on your answers we have the following recommendations</p>
        </div>
        <div
            *ngIf="isLoading"
            class="d-flex justify-content-center loadingSpinner"
        >
            <!-- <div
        class="spinner-border"
        style="width: 2rem; height: 2rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div> -->
            <ng-lottie
                height="150px"
                [options]="options"
                (animationCreated)="animationCreated($event)"
            ></ng-lottie>
            <h3 class="loadingText">&nbsp; Generating recommendations...</h3>
        </div>
        <div class="container-fluid">
            <div class="row mt-md-4 mt-lg-5">
                <div
                    class="col-md-6 col-lg-4"
                    *ngFor="let i of recommendedTherpist; let inx = index"
                >
                    <div class="counselling-therapist">
                        <div class="counselling-therapist-profile">
                            <img
                                src="{{ i?.imageURL }}"
                                alt="{{ i?.firstname }}"
                                class="counsellor-therapist-img"
                                onerror="this.src='../../../../assets/images/user.png'"
                            />
                            <div class="counselling-therapist-info">
                                <h3 class="counselling-therapist-name">
                                    {{ i?.firstname }} {{ i?.lastname }}
                                </h3>
                                <p
                                    class="counselling-therapist-title"
                                    *ngIf="
                                        i?.jobqualification ==
                                            'Other (Please Mention)';
                                        else elsepart
                                    "
                                >
                                    {{ i?.jobqualificationOtherOptionAnswer }}
                                </p>
                                <ng-template #elsepart>
                                    <p class="counselling-therapist-title">
                                        {{ i?.jobqualification }}
                                    </p>
                                </ng-template>
                            </div>
                        </div>
                        <div class="counselling-therapist-detail mt-4">
                            <img
                                src="../../../../assets/images/star-icon.svg"
                                alt="star icon"
                            />
                            <div class="detail-content">
                                <h4 class="details-title">Specialization</h4>
                                <p
                                    class="details-desc"
                                    *ngIf="i?.specializeareas !== null"
                                >
                                    {{ i?.specializeareas?.join(', ') }}
                                    <span
                                        *ngIf="
                                            !i.showMoreToggle &&
                                            i?.specializeareasMore?.length > 0
                                        "
                                        >...
                                    </span>
                                    <span
                                        *ngIf="
                                            i?.specializeareasMore?.length > 0
                                        "
                                    >
                                        <span
                                            class="readMoreSection"
                                            [ngClass]="{
                                                showReadMoreSec:
                                                    i.showMoreToggle
                                            }"
                                            >{{
                                                i?.specializeareasMore.join(
                                                    ', '
                                                )
                                            }}</span
                                        >
                                        <a
                                            class="readMoreToggle"
                                            *ngIf="!i.showMoreToggle"
                                            (click)="toggleShowMore(inx)"
                                            >Show More</a
                                        ><a
                                            *ngIf="i.showMoreToggle"
                                            class="readMoreToggle"
                                            (click)="toggleShowMore(inx)"
                                            >Show Less</a
                                        ></span
                                    >
                                </p>
                                <p
                                    class="details-desc"
                                    *ngIf="i?.specializeareas === null"
                                >
                                    -
                                </p>
                            </div>
                        </div>
                        <div class="counselling-therapist-detail mt-2">
                            <img
                                src="../../../../assets/images/next-available-at.svg"
                                alt="available icon"
                            />
                            <div class="detail-content">
                                <h4 class="details-title">Next Available at</h4>
                                <p
                                    class="details-desc"
                                    *ngIf="i?.nextAvailableAtStr"
                                >
                                    {{ i?.nextAvailableAtStr }}
                                </p>
                                <p
                                    class="details-desc"
                                    *ngIf="!i?.nextAvailableAtStr"
                                >
                                    -
                                </p>
                            </div>
                        </div>
                        <div class="therapist-btns">
                            <a
                                routerLink="../community-talk-therapists-detail/{{
                                    i?._id
                                }}"
                                class="therapist-btn profile-btn"
                                (click)="viewProfile(i)"
                            >
                                View Profile</a
                            >
                            <a
                                (click)="bookNow(i)"
                                class="therapist-btn therapist-book-btn"
                                >Book Session</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentWrap" *ngIf="!isLoading">
            <div class="selectContent paddingStyle">
                <h4>
                    Select a therapist of your choice and proceed to book a
                    session from the calendar 📆
                </h4>
            </div>
            <div class="selectContent msgfornext" *ngIf="msgfornext">
                {{ msgfornext }}
            </div>
            <div class="reselectContent">
                <a routerLink="/user-dashboard/dashboard" class="thm-3"
                    >Choose Later</a
                >
                <a routerLink="/our-counselors" class="thm-2">View All</a>
                <a class="thm-3" (click)="openChat()"
                    >Chat with Please Coach Support</a
                >
            </div>
        </div>
    </div>
</div>
