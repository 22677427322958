import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const counsellorUrl = environment.apiUrl + "/guest/our-counsellors";
@Injectable({
  providedIn: "root",
})
export class OurCounsellorsService {
  constructor(private http: HttpClient) { }

  counsellorPagin(skip, limit, searchTerm, date, slot, currentDate, language, specialization) {
    return this.http.get<any>(
      counsellorUrl + "/counsellorsPagin?skip=" + skip + "&limit=" + limit + "&search=" + searchTerm + "&date=" + date + "&slot=" + slot + "&currentDate=" + currentDate + "&language=" + language + "&specialization=" + specialization
    );
  }

  getNextAvailableAt() {
    return this.http.get<any>(counsellorUrl + "/nextsession")
  }

  getNextAvailableAtById(id) {
    return this.http.get<any>(counsellorUrl + "/nextsession/" + id)
  }

  getCounsellorById(id) {
    return this.http.get<any>(counsellorUrl + "/" + id);
  }

  getReviews(id, pageNo) {
    return this.http.get<any>(counsellorUrl + "/v2/remarks/" + id + "?pageNo=" + pageNo)
  }
}
