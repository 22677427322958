import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AboutUsComponent } from "./home/about-us/about-us.component";
import { ContactUsComponent } from "./home/contact-us/contact-us.component";
import { HomePageComponent } from "./home/home-page/home-page.component";
import { HowItWorksComponent } from "./home/how-it-works/how-it-works.component";
import { ChildCounsellingComponent } from "./home/plans-and-pricing/child-counselling/child-counselling.component";
import { CouplesCounsellingComponent } from "./home/plans-and-pricing/couples-counselling/couples-counselling.component";
import { GeneralCounsellingComponent } from "./home/plans-and-pricing/general-counselling/general-counselling.component";
import { PsychiatricCounsellingComponent } from "./home/plans-and-pricing/psychiatric-counselling/psychiatric-counselling.component";
import { DisclaimerComponent } from "./legal-stuff/disclaimer/disclaimer.component";
import { PrivacyPolicyComponent } from "./legal-stuff/privacy-policy/privacy-policy.component";
import { TermsComponent } from "./legal-stuff/terms/terms.component";
import { ForCorporatesComponent } from "./quick-links/for-corporates/for-corporates.component";
import { ForCounselorsComponent } from "./quick-links/for-counselors/for-counselors.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from "@angular/cdk/layout";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "../app-routing.module";
import { PlansAndPricingComponent } from "./home/plans-and-pricing/plans-and-pricing/plans-and-pricing.component";
import { PleasecoachBlogComponent } from "./home/pleasecoach-blog/pleasecoach-blog.component";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CommunityTalkTherapistsComponent } from "./home/community-talk-therapists/community-talk-therapists.component";
import { BecomeTherapistComponent } from "./quick-links/become-therapist/become-therapist.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { SignUpDemoComponent } from "./quick-links/sign-up-demo/sign-up-demo.component";
import { ScheduleTherapistsDetailComponent } from "./home/schedule-therapists-detail/schedule-therapists-detail.component";
import { DiscussionForumComponent } from "./home/discussion-forum/discussion-forum.component";
import { SharedModule } from "../shared/shared.module";
import { ViewDiscussionComponent } from "./home/view-discussion/view-discussion.component";
import { BlogViewComponent } from "./home/blog-view/blog-view.component";
import { MeetTherapistsComponent } from "./common/meet-therapists/meet-therapists.component";
// import { FreeReportComponent } from "./home/free-report/free-report.component";
import { FinalResultComponent } from "./home/final-result/final-result.component";
import { DepressionTestResultsComponent } from "./home/depression-test-results/depression-test-results.component";
import { AnxietyTestResultsComponent } from "./home/anxiety-test-results/anxiety-test-results.component";
import { AdverseChildhoodExperiencesTestResultsComponent } from "./home/adverse-childhood-experiences-test-results/adverse-childhood-experiences-test-results.component";
import { AngryTestResultsComponent } from "./home/angry-test-results/angry-test-results.component";
import { ObsessiveCompulsiveDisorderTestResultsComponent } from "./home/obsessive-compulsive-disorder-test-results/obsessive-compulsive-disorder-test-results.component";
import { PostTraumaticStressDisorderTestResultsComponent } from "./home/post-traumatic-stress-disorder-test-results/post-traumatic-stress-disorder-test-results.component";
import { BootstrapAlertModule } from "../shared/ng-bootstrap-alert/bootstrap-alert.module";
import { NgxEditorModule } from "ngx-editor";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { BlogListComponent } from './home/blog-list/blog-list.component';
import { RedirectComponent } from './home/redirect/redirect.component';
import { AdCampaignComponent } from "./ad-campaign/ad-campaign.component";
import { AdCampaignSignupComponent } from './ad-campaign-signup/ad-campaign-signup.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SwiperModule } from 'swiper/angular';
import { AdCampaignHomeComponent } from './ad-campaign-home/ad-campaign-home.component';
import { AdCampaignCounsellorsComponent } from './ad-campaign-counsellors/ad-campaign-counsellors.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TestIntegrationsComponent } from './test-integrations/test-integrations.component';
import { AdhdTestResultsComponent } from './home/adhd-test-results/adhd-test-results.component';
import { GriefTestResultsComponent } from './home/grief-test-results/grief-test-results.component';
import { NgImageFullscreenViewModule } from "ng-image-fullscreen-view";
import { NewLandingComponent } from './new-landing/new-landing.component';
import { AnxietyHamiltionTestResultsComponent } from './home/anxiety-hamiltion-test-results/anxiety-hamiltion-test-results.component';
import { RelationshipTestResultsComponent } from './home/relationship-test-results/relationship-test-results.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ChildAdhdTestResultsComponent } from "./home/child-adhd-test-results/child-adhd-test-results.component";
import { DesignComponent } from "./home/design/design.component";
import { NewLandingPageComponent } from './new-landing-page/new-landing-page.component';
import { PersonalityAssessmentTestResultsComponent } from './home/personality-assessment-test-results/personality-assessment-test-results.component';
import { FinancialStressTestResultsComponent } from './home/financial-stress-test-results/financial-stress-test-results.component';
import { SelfEsteemTestResultsComponent } from './home/self-esteem-test-results/self-esteem-test-results.component';
import { SatisfactionWithLifeTestResultsComponent } from './home/satisfaction-with-life-test-results/satisfaction-with-life-test-results.component';
import { NarcissismTestResultsComponent } from './home/narcissism-test-results/narcissism-test-results.component';
import { LonelinessTestResultsComponent } from './home/loneliness-test-results/loneliness-test-results.component';
import { EatingDisordersTestResultsComponent } from './home/eating-disorders-test-results/eating-disorders-test-results.component';
import { WorkLifeBalanceTestResultsComponent } from './home/work-life-balance-test-results/work-life-balance-test-results.component';
import { AcademicStressTestResultsComponent } from './home/academic-stress-test-results/academic-stress-test-results.component';
import { PsychologicalDistressTestResultsComponent } from './home/psychological-distress-test-results/psychological-distress-test-results.component';
import { CaregiverStressTestResultsComponent } from './home/caregiver-stress-test-results/caregiver-stress-test-results.component';
import { SocialAnxietyTestResultsComponent } from "./home/social-anxiety-test-results/social-anxiety-test-results.component";
import { EmailUnverifiedComponent } from './email-unverified/email-unverified.component';
import { WellnessHubComponent } from './home/wellness-hub/wellness-hub.component';
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AboutUsComponent,
    ContactUsComponent,
    HomePageComponent,
    HowItWorksComponent,
    ChildCounsellingComponent,
    CouplesCounsellingComponent,
    GeneralCounsellingComponent,
    PsychiatricCounsellingComponent,
    DisclaimerComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    ForCorporatesComponent,
    ForCounselorsComponent,
    PlansAndPricingComponent,
    PleasecoachBlogComponent,
    CommunityTalkTherapistsComponent,
    BecomeTherapistComponent,
    SignUpDemoComponent,
    ScheduleTherapistsDetailComponent,
    DiscussionForumComponent,
    ViewDiscussionComponent,
    BlogViewComponent,
    MeetTherapistsComponent,
    // FreeReportComponent,
    FinalResultComponent,
    DepressionTestResultsComponent,
    AnxietyTestResultsComponent,
    AdverseChildhoodExperiencesTestResultsComponent,
    AngryTestResultsComponent,
    ObsessiveCompulsiveDisorderTestResultsComponent,
    PostTraumaticStressDisorderTestResultsComponent,
    VerifyemailComponent,
    BlogListComponent,
    AdCampaignComponent,
    RedirectComponent,
    AdCampaignSignupComponent,
    ThankYouComponent,
    AdCampaignHomeComponent,
    AdCampaignCounsellorsComponent,
    TestIntegrationsComponent,
    AdhdTestResultsComponent,
    ChildAdhdTestResultsComponent,
    GriefTestResultsComponent,
    NewLandingComponent,
    AnxietyHamiltionTestResultsComponent,
    RelationshipTestResultsComponent,
    DesignComponent,
    NewLandingPageComponent,
    PersonalityAssessmentTestResultsComponent,
    FinancialStressTestResultsComponent,
    SelfEsteemTestResultsComponent,
    SatisfactionWithLifeTestResultsComponent,
    NarcissismTestResultsComponent,
    LonelinessTestResultsComponent,
    EatingDisordersTestResultsComponent,
    WorkLifeBalanceTestResultsComponent,
    AcademicStressTestResultsComponent,
    PsychologicalDistressTestResultsComponent,
    CaregiverStressTestResultsComponent,
    SocialAnxietyTestResultsComponent,
    EmailUnverifiedComponent,
    WellnessHubComponent
  ],
  imports: [
    NgxSkeletonLoaderModule.forRoot({ theme: { opacity: '0.9' } }),
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    BootstrapAlertModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgbModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    FormsModule,
    NgxEditorModule,
    SwiperModule,
    Ng2TelInputModule,
    SlickCarouselModule,
    NgImageFullscreenViewModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
})
export class GuestModule { };
