<div (click)="closeNavBar()">

  <div class="container">
    <div class="hero-container">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h3 class="section-inner-title">Happiness Happens In The Mind</h3>
          <p class="section-inner-desc">
            Talk to experts who understand the mind. Try a session at
            <b>&#8377;&nbsp; 399 </b> <s>999</s>
          </p>
          <div class="center">
            <a *ngIf="!isLoggedIn" [routerLink]="['/sign-up']" [queryParams]="{plan : 'plan_a1'}" class="primaryBtn">Start Now</a>
            <a *ngIf="isLoggedIn" class="primaryBtn" routerLink="/order-summary/plan_a1">Start Now</a>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
          <div class="center">
            <img
              src="../../../../assets/images/happy.png"
              alt="Happy image"
              class="happy-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <div class="personal-plan d-flex">
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 100%"
    >
      <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12" style="padding: 0px;">
          <div class="personal-plan-text">
            Get Your Free Personalized  Wellness plan
          </div>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center"
          style="padding: 0px; display:flex; align-items: center; justify-content: center"
        >
          <a
            routerLink=""
            class="primaryBtn"
            style="height: 55px; background-color: white; color: #262626"
            >Start Now</a
          >
        </div>
      </div>
    </div>
  </div> -->
  
  <div class="container" style="margin-top: 120px">
    <h2 class="section-title md-subpleasecoachText">We Have You Covered</h2>
    <div class="row fade-in">
      <div class="col-12">
        <div class="mental-health-tag-wrapper">
          <a class="tag">Anxiety</a>
          <a class="tag">OCD</a>
          <a class="tag">Relationships</a>
          <a class="tag">ADHD</a>
          <a class="tag">Sleep</a>
          <a class="tag">Life direction</a>
          <a class="tag">Grief & Loss</a>
          <a class="tag">Psychiatric illnesss</a>
          <a class="tag">Couple</a>
          <a class="tag">LGBTQIA+</a>
          <a class="tag">Bipolar</a>
          <a class="tag">Depression</a>
          <a class="tag">Habit Formation</a>
          <a class="tag">Stress</a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div>
        <div class="specialBtn fade-in">
          <a routerLink="/our-counselors" class="primaryBtn">Get Started</a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container">
    <h2 class="section-title2 md-subpleasecoachText">Online Consultation</h2>
    <p class="min">45 minutes</p>
    <div class="icons mt-3" style="font-size: 20px">
      <i class="fa fa-video-camera fa-lg"></i>
      <i class="fa fa-phone fa-lg"></i>
      <i class="fa fa-comment fa-lg"></i>
    </div>
  
    <div class="row mt-5">
      <figure class="col-md-12 col-lg-4 col-sm-12">
        <img src="./assets/images/validity icon.svg" />
        <figcaption>
          <p>Lifetime validity</p>
        </figcaption>
      </figure>
      <figure class="col-md-12 col-lg-4 col-sm-12">
        <img src="./assets/images/chat icon.svg" />
        <figcaption>
          <p>Chat between sessions</p>
        </figcaption>
      </figure>
      <figure class="col-md-12 col-lg-4 col-sm-12">
        <img src="./assets/images/schedule icon.svg" />
        <figcaption>
          <p>Schedule anytime</p>
        </figcaption>
      </figure>
    </div>
  
    <div class="container" style="margin: 120px 0px 60px 0px">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)"
      >
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <div class="planBox">
            <div class="planContWrap">
              <h2 class="MainTitle">{{ slide.MainTitle }}</h2>
              <div class="planIcon">
                <img
                  src="{{ slide.featuredImage }}"
                  class="img-fluid"
                  draggable="false"
                  alt=""
                />
              </div>
              <div class="planConts">
                <h5>{{ slide.sessionsCount }} Sessions</h5>
                <div class="iconsHolder">
                  <div class="icons2 md-icons">
                    <div><i class="fa fa-video-camera fa-lg"></i></div>
                    <div><i class="fa fa-phone fa-lg"></i></div>
                    <div><i class="fa fa-comment fa-lg"></i></div>
                  </div>
                </div>
              </div>
              <div class="planConts">
                <h3 class="staticText">What to Expect ?</h3>
                <p class="titleDesc">
                  {{ slide.titleDesc }}
                </p>
              </div>
              <div class="planPrice">
                <span class="price"
                  >INR
                  <b>{{ slide.pricing }}</b>
                </span>
              </div>
              <div class="planBuy">
                <a
                  *ngIf="isLoggedIn"
                  class="buttonTheme1"
                  style="box-shadow: 0px 3px 18px #00000029"
                  id="select-plan"
                  routerLink="/order-summary/{{slide.planId}}"
                  >Select Plan</a
                >
                <a
                *ngIf="!isLoggedIn"
                class="buttonTheme1"
                style="box-shadow: 0px 3px 18px #00000029"
                id="select-plan"
                [routerLink]="['/sign-up/']"
                [queryParams]="{plan: slide.planId}"
                >Select Plan</a
              >
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  
  <div class="container" id="access">
    <div class="row" style="margin-top: 180px">
      <h3 class="md-subpleasecoachText accessText">
        ACCESS FREE SELF HELP TOOLKIT WITH <br />
        EVERY LONG-TERM PLAN
      </h3>
    </div>
    <div class="row">
      <div style="width: 100%; text-align: center; margin-top: 30px">
        <a routerLink="/order-summary/plan_a1" *ngIf="isLoggedIn" class="primaryBtn" id="access-btn"
          >Start Now</a
        >
        <a [routerLink]="['/sign-up']" [queryParams]="{plan : 'plan_a1'}" *ngIf="!isLoggedIn" class="primaryBtn" id="access-btn"
        >Start Now</a
      >
      </div>
    </div>
  </div>
  
  <div class="d-flex align-items-center flex-column mt-5 mt-64">
    <div class="md-subpleasecoachText accessText">
      <b>FREQUENTLY ASKED QUESTIONS</b>
    </div>
    <div class="orange-seperator"></div>
      <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible1')"> <input id="collapsible1" class="toggle" type="checkbox"> <label for="collapsible1"
        class="lbl-toggle">
        <h4>Do I really need therapy?</h4>
      </label>
      <div class="collapsible-content">
        <div class="content-inner">
          <p> The only qualification to be eligible for therapy is to have a mind. Anyone living in the 21 st century grapples with a heightened level of systemic stress and anxiety and could use help. Therapy is not only for clinical illnesses but also to improve general well-being. </p>
        </div>
      </div>
    </div>
  
    <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible2')"> <input id="collapsible2" class="toggle" type="checkbox"> <label for="collapsible2"
        class="lbl-toggle">
        <h4>How many sessions do I need?</h4>
      </label>
      <div class="collapsible-content">
        <div class="content-inner">
          <p>The number of sessions depends on your issue and the therapist’s approach. The first session generally involves case history taking during which the therapist gains an overall idea about your life and triggers. We would recommend you take a minimum of 3 sessions to get adapted to the experience of therapy in order to witness improvements in your lifestyle then. </p>
        </div>
      </div>
    </div>
    <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible3')"> <input id="collapsible3" class="toggle" type="checkbox"> <label for="collapsible3"
        class="lbl-toggle">
        <h4>What if I don’t want to do a video session?</h4>
      </label>
      <div class="collapsible-content">
        <div class="content-inner">
          <p> You can choose your preferred medium for the session – we give all options for your 45 minutes of session – video, audio or chat. </p>
        </div>
      </div>
    </div>
    <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible4')"> <input id="collapsible4" class="toggle" type="checkbox"> <label for="collapsible4"
        class="lbl-toggle">
        <h4>Is there an EMI option?</h4>
      </label>
      <div class="collapsible-content">
        <div class="content-inner">
          <p> Yes, you just have to choose it while checking out. </p>
        </div>
      </div>
    </div>
    <div class="wrap-collabsible" (click)="closeOtherCheckBox('collapsible5')"> <input id="collapsible5" class="toggle" type="checkbox"> <label for="collapsible5"
      class="lbl-toggle">
      <h4>When can I take the session?</h4>
    </label>
    <div class="collapsible-content">
      <div class="content-inner">
        <p> Whenever you want! We have continuous appointments available from 6 AM IST to midnight. </p>
      </div>
    </div>
  </div>
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 mt-5">
            <h3 class="more-therapy">Basics of therapy</h3>
            <p class="therapy-details">
              Therapy is a two-way process involving a therapist and a client.
              Therapy is also known as psychotherapy or counselling and these
              terms are often used interchangeably. Therapy aims to transform a
              person into their best possible version. In the best possible
              version, it means a healthier state of both, the mind and body.
              Therapy is used for various issues like anxiety, depression, stress,
              body image issues, confidence problems, relationship issues, psycho-
              somatic complaints and more. For severe mental health conditions
              like schizophrenia, bipolar disorder, borderline personality
              disorder, psychosis and more, therapy is used in combination with
              medications. The treatment of severe conditions can include a
              combination of medications along with family counselling, social
              skills training, and individual therapy. The purpose of this is that
              while medications ease the symptoms, therapy helps bring people back
              into their social life for better global functioning. Like every
              individual is different right from their DNA, their process of
              therapy also differs from person to person. Therapists make sure to
              make customized plans for their clients with various conditions. The
              process of therapy is a journey for both the client as well as the
              therapy as initially, it is about rapport building and trust. It
              helps people learn a lot about themselves, their patterns of
              behaviour, their coping mechanisms, negative thoughts and more. In
              the process of therapy, each of these is aimed to be transformed
              into healthier versions to have a cumulative effect on the overall
              health. It takes an active effort on both ends to see results and it
              does work. There are different kinds of therapies that therapists
              are trained with, and they use what’s best for the client and their
              condition.<br /><br />
              Even with everything going well, one can think of taking up therapy
              to keep a check on their mental health. A trigger doesn&#39;t need
              to facilitate the idea of therapy. We might not realize this but
              there could be some unhealthy thoughts or behaviours that we might
              be indulging in. With the pandemic and lockdown, the need for better
              mental health was highlighted. In light of this, the concept of
              online therapy also emerged, and it has its pros and cons. Online
              therapy is convenient in many ways including time and travelling.
              The issue of confidentiality and privacy remains the same,
              irrespective of whether it is an online or an offline session.
              Therapists are supposed to create a safe, judgement-free environment
              with empathetic listening and genuine concern for the client. This
              is ethically supposed to be done regardless of the mode of the
              session. To sum up, therapy is a journey worth taking!
            </p>
          </div>
          <div class="col-md-6 col-sm-12 mt-5">
            <h3 class="more-therapy">
              Difference between therapy and counselling
            </h3>
            <p class="therapy-details">
              There is no difference between the terms ‘Therapy’ and
              ‘Counselling’. These terms are used interchangeably quite often with
              no difference in interpretation as long as it involves a therapist
              or a psychologist. Counselling is also used under the title of
              Career counselling which helps a student understand their aptitude
              for the selection of their academic course.
            </p>
          </div>
          <div class="col-md-5 col-sm-12 mt-5">
            <h3 class="more-therapy">Therapy structure and duration</h3>
            <p class="therapy-details">
              The number of sessions depends on the severity of the presenting
              problem. The goal of therapy is to make the client independent in
              leading a mentally healthier life. For this purpose, one session a
              week is conducted for a minimum of 3 months (12 to 16 sessions).
              This is not a benchmark. The number of sessions varies depending on
              many factors. It also depends on how the client is working on
              themselves post the therapy session which makes a difference. Each
              therapy session makes a difference in the client’s life, however
              small it might be. Such minute differences compile to push the
              client into a healthier lifestyle.
            </p>
          </div>
          <div class="col-md-6 col-sm-12 mt-5">
            <h3 class="more-therapy">When to take therapy</h3>
            <p class="therapy-details">
              If you have ever found yourself asking if you need therapy, you may
              try going for it. Additionally, if you have been noticing a sudden
              change in your sleep, appetite, and overall being, you might
              consider therapy. A disruption in your daily life routine as
              compared to how you were functioning previously could act as another
              indicator. One may also experience symptoms like constant anxiety,
              negative thoughts, overthinking, procrastination, fatigue,
              exhaustion and sadness. In totality, if you experience a change in
              your lifestyle and mindset at the negative end of the spectrum,
              therapy would be an ideal choice.
            </p>
          </div>
          <div class="col-md-6 col-sm-12 mt-5">
            <h3 class="more-therapy">Different mental health professionals</h3>
            <p class="therapy-details">
              Both psychologists and psychiatrists come under the umbrella of
              mental health professionals. A psychologist is a professional who
              has completed a master’s or PhD in psychology and focuses on the
              therapeutic approach. Their main tools include different kinds of
              therapies like Cognitive behavioral therapy, psychoanalysis,
              person-centered therapy, behavioral therapy, cognitive therapy, etc.
              They use the concept of talk therapy. A psychiatrist is a doctor who
              does MBBS followed by an MD in psychiatry. Psychiatrists can
              prescribe medications to people experiencing severe mental health
              conditions. Generally, a combination of psychotherapy and
              medications works best.
            </p>
          </div>
          <div
            class="col-md-6 col-sm-12"
            style="margin-top: 50px; margin-bottom: 80px"
          >
            <h3 class="more-therapy">
              Difference in friendly advice and therapy
            </h3>
            <p class="therapy-details">
              Friends are very different from therapists. The might listen to you
              and give advice but talking to a therapist will help gain
              professional advice from someone trained in the field. Therapists
              are taught various skills like active listening, empathy,
              genuineness, unconditional positive regard, non-judgmental attitude,
              self-disclosure, interpretive skills and more. They come with all
              these skills into a session which is not possible with a friend. The
              main difference between talking to a therapist and a friend is a
              friend will listen but will give advice based on what they would
              have done in that position. On the other hand, a therapist will
              listen to everything that one is going through patiently and help
              identify the best possible outcome in that situation. Along with
              this, a therapist will also use custom-tailored therapy techniques
              to develop a healthier mindset. This mindset will help one deal with
              any problem that they encounter independently which does not seem to
              be possible with a friend. This sums up why a therapist is a better
              option when it comes to working on a problem.
            </p>
          </div>
          <div class="col-md-6 col-sm-12 cont" style="margin-bottom: 80px">
            <h3 class="more-therapy">Insight into therapy sessions</h3>
            <p class="therapy-details">
              Therapy sessions mostly lie between 45 minutes to an hour. In these
              sessions, a therapist acts as a facilitator wherein the client is
              the main focus. The clients come in with the problem which is heard
              by the therapist using skills like active listening, empathy, and a
              non- judgmental attitude. The first session is generally a case
              history session in which the therapist tries to understand the
              client as a whole person and as an individual in all spheres of
              life. The mental status of the client is also understood using
              certain on-point questions and the observational skills of the
              therapist. Following this, a session is conducted each week in which
              the client gives the therapist an update on everything that has
              happened in the previous week. Healthier skills are built in each
              session where the client works on their problems as examples.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
