<div class="boxWrapper" (click)="closenavbar()">
  <div class="boxWrapper-list">
    <div class="boxWrapper-list-items-plans">
      <div class="expertsBox ltBlueBg">
        <div class="expertsBoxImageContainer">
          <img
            class="img-fluid"
            src="../../../assets/images/websitehome-generalcounselling.png"
            draggable="false"
            alt="connect with best counseling therapy psychologists"
          />
        </div>
        <div class="expertsBoxTextContainer">
          <h5>General Counselling</h5>
          <ul>
            <li>Stress, anxiety, depression & other issues.</li>
            <li>Self improvement.</li>
            <li>All mental health issues.</li>
          </ul>
        </div>
      </div>
      <div class="text-center">
        <button
          class="tryNowButton buttonTheme1"
          routerLink="../general-counselling-plans"
          (click)="tryNow()"
        >
          Buy Now
        </button>
      </div>
    </div>
    <div class="boxWrapper-list-items-plans">
      <div class="expertsBox ltOrangeBg">
        <div class="expertsBoxImageContainer">
          <img
            class="img-fluid"
            src="../../../assets/images/websitehome-couplecounselling.png"
            draggable="false"
            alt="connect with best counseling therapy psychologists"
          />
        </div>
        <div class="expertsBoxTextContainer">
          <h5>Couple Counselling</h5>
          <ul>
            <li>Relationship issues.</li>
            <li>Pre-marital counselling.</li>
            <li>Intimacy & Sexual Issues.</li>
          </ul>
        </div>
      </div>
      <div class="text-center">
        <button
          class="tryNowButton buttonTheme1"
          routerLink="../couple-counselling-plans"
          (click)="tryNow()"
        >
          Buy Now
        </button>
      </div>
    </div>
    <div class="boxWrapper-list-items-plans">
      <div class="expertsBox ltBlueBg">
        <div class="expertsBoxImageContainer">
          <img
            class="img-fluid"
            src="../../../assets/images/websitehome-childcounselling.png"
            draggable="false"
            alt="connect with best counseling therapy psychologists"
          />
        </div>
        <div class="expertsBoxTextContainer">
          <h5>Child Counselling</h5>
          <ul>
            <li>Neuro-development disorder (ADHD, Autism etc).</li>
            <li>Conduct disorders.</li>
            <li>Self-harm.</li>
          </ul>
        </div>
      </div>
      <div class="text-center">
        <button
          class="tryNowButton buttonTheme1"
          routerLink="../child-counselling-plans"
          (click)="tryNow()"
        >
          Buy Now
        </button>
      </div>
    </div>
    <div class="boxWrapper-list-items-plans">
      <div class="expertsBox ltOrangeBg">
        <div class="expertsBoxImageContainer">
          <img
            class="img-fluid"
            src="../../../assets/images/websitehome-psychiatricconsultation.png"
            draggable="false"
            alt="connect with best counseling therapy psychologists"
          />
        </div>
        <div class="expertsBoxTextContainer">
          <h5>Psychiatric Consultation</h5>
          <ul>
            <li>Psychiatric diagnosis & medication.</li>
            <li>Sexual Issues.</li>
            <li>Sleep Disorders.</li>
          </ul>
        </div>
      </div>
      <div class="text-center">
        <button
          class="tryNowButton buttonTheme1"
          routerLink="../psychiatric-counsultation-plans"
          (click)="tryNow()"
        >
          Buy Now
        </button>
      </div>
    </div>
  </div>
  <div class="plansTip">
    Sessions do not expire & have lifetime validity. <br />
    Once bought you can schedule it at your convenience.
  </div>
  <div class="plansTip">
    Couldn't find the plan you are looking for? <br />
    No worries, please drop us a call on
    <a href="tel:+91xxxxxxxxxx">+91xxxxxxxxxx</a>, and we'll help you out.
  </div>
</div>
