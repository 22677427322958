<div (click)="closenavbar()">
    <div class="contactWrapper sectionPaddingbox">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="contactform formRound">
                        <div class="sectionHeading">
                            <h2>Don't Wonder. Ask Away.</h2>
                            <hr />
                        </div>
                        <form
                            [formGroup]="contactform"
                            (ngSubmit)="onSubmit()"
                            name="contactform"
                        >
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    formControlName="name"
                                    placeholder="Name *"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-error':
                                            !contactform.controls.name.valid &&
                                            contactform.controls.name.touched,
                                        'has-success':
                                            contactform.controls.name.valid &&
                                            contactform.controls.name.touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        contactform.controls.name.errors
                                            ?.required &&
                                        contactform.controls.name.touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Name is required</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    formControlName="email"
                                    placeholder="Email *"
                                    class="form-control"
                                    [ngClass]="{
                                        'has-error':
                                            !contactform.controls.email.valid &&
                                            contactform.controls.email.touched,
                                        'has-success':
                                            contactform.controls.email.valid &&
                                            contactform.controls.email.touched
                                    }"
                                />
                                <div
                                    *ngIf="
                                        contactform.controls.email.errors
                                            ?.required &&
                                        contactform.controls.email.touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Email is required</p>
                                </div>
                                <div
                                    *ngIf="
                                        contactform.controls.email.errors
                                            ?.pattern &&
                                        contactform.controls.email.touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Email is invalid</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="phoneHolder">
                                    <input
                                        type="tel"
                                        id="phone"
                                        formControlName="phone"
                                        name="phone"
                                        class="form-control phoneControl"
                                        [ngClass]="{
                                            'has-error':
                                                contactform.controls.phone
                                                    .errors?.invalidPhone ||
                                                (contactform.controls.phone
                                                    .errors?.required &&
                                                    contactform.controls.phone
                                                        .touched)
                                        }"
                                        ng2TelInput
                                        (hasError)="checkError($event)"
                                        (ng2TelOutput)="getNumber($event)"
                                        (intlTelInputObject)="
                                            telInputObject($event)
                                        "
                                        (countryChange)="
                                            onCountryChange($event)
                                        "
                                        [ng2TelInputOptions]="{
                                            initialCountry:
                                                userCountryCode.countryCode
                                        }"
                                    />
                                </div>
                                <div
                                    class="errorContainer"
                                    *ngIf="
                                        contactform.controls.phone.errors
                                            ?.invalidPhone
                                    "
                                >
                                    <p>Please enter a valid phone number.</p>
                                </div>
                                <div
                                    *ngIf="
                                        contactform.controls.phone.errors
                                            ?.required &&
                                        contactform.controls.phone.touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Phone number is required</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <textarea
                                    rows="5"
                                    class="form-control query"
                                    name="query"
                                    id="query"
                                    placeholder="Query *"
                                    formControlName="message"
                                    [ngClass]="{
                                        'has-error':
                                            !contactform.controls.message
                                                .valid &&
                                            contactform.controls.message
                                                .touched,
                                        'has-success':
                                            contactform.controls.message
                                                .valid &&
                                            contactform.controls.message.touched
                                    }"
                                ></textarea>
                                <div
                                    *ngIf="
                                        contactform.controls.message.errors
                                            ?.required &&
                                        contactform.controls.message.touched
                                    "
                                    class="errorContainer"
                                >
                                    <p>Query is required</p>
                                </div>
                            </div>

                            <div class="form-group submitButton text-center">
                                <button
                                    class="buttonTheme1"
                                    type="submit"
                                    [disabled]="!contactform.valid"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-6 top">
                    <div>
                        <img
                            src="../../../../assets/images/contact/contact-form-pleasecoach.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="contact form queries, Please Coach online counseling therapy"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sectionPaddingbox-bottom">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <div class="contactImagebox">
                        <img
                            src="../../../../assets/images/contact/contact-number-pleasecoach.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="Please Coach contact number phone 8764152996"
                        />
                        <h3>Phone</h3>
                        <p>+91 XXXXXXXXXX</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contactImagebox">
                        <img
                            src="../../../../assets/images/contact/email-pleasecoach.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="contact Please Coach on email, hola@Pleasacoach.care"
                        />
                        <h3>Email</h3>
                        <p>hola@pleasecoach.care</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contactImagebox">
                        <img
                            src="../../../../assets/images/contact/pleasecoach-corporate-headquarters-address.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="Please Coach online counseling therapy office headquarters address ac"
                        />
                        <h3>Address</h3>
                        <p>XYZ,Near XY Press ABC 111111</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
