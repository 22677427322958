<div class="paddingPrivacypage" (click)="closenavbar()">
    <div class="container">
        <div class="pageHeading">
            <h2>Privacy Policy</h2>
            <hr />
            <h3>Please Coach Private Limited</h3>
            <h4>Last Updated: 8th June 2020</h4>
        </div>
        <div class="contentClass">
            <h3>CONTEXT</h3>
            <p>
                <a href="https://web.pleasecoach.com/">
                    https://web.pleasecoach.com/
                </a>
                is owned by Please Coach (registered as Please Coach Priviate Limited).This Privacy Policy explains how
                Please Coach (registered as: Please Coach Private
                Limited), hereafter Please Coach, collects, processes, uses,
                shares, and protects information about you. It also tells you
                how you can access and update your information and make certain
                choicesabout how your information is used.
            </p>
            <p>
                Sensitive Personal Data or Information or “SDPI” of a person
                includes any personal information such as a) password, b)
                financial information – bank account, debit card, credit card or
                any other payment information c) physical and mental health
                history, d) sexual orientation, e) medical history, f)
                biometric, g) any details related to the above points. Any
                publicly available information is does not come under SDPI.
            </p>
            <p>
                The Privacy Policy covers both “online” (e.g., web and mobile
                services, including any websitesoperated by us such as
                https://www.felcity.care/ or Mobile Application, however
                accessed and/orused, whether via personal computers, mobile
                devices or otherwise) and “offline” (e.g., collectionof data
                through mailings, telephone, or in person) activities owned,
                operated, provided, or madeavailable by the Company. This
                Privacy Policy also applies to your use of interactive features
                or downloads that: <br />
                (i) we own or control;<br />
                (ii) are available through the Services; or <br />
                (iii) interact with the Servicesand post or incorporate this
                Privacy Policy.
            </p>
            <p>
                BY ACCEPTING THE CUSTOMER TERMS AND CONDITIONS, YOU AGREE TO THE
                TERMSOF THIS PRIVACY POLICY. Please review the following
                carefully so that you understand ourprivacy practices. If you do
                not agree to this Privacy Policy, do not accept the Customer
                Terms of Service or use our Services. This Privacy Policy is
                incorporated by reference into theTerms of Service.
            </p>
            <p>
                If you have questions about this Privacy Policy, please contact
                us through email address providedon our website and/ or Mobile
                Application.
            </p>
            <h3>INFORMATION THAT WE COLLECT</h3>
            <ol>
                <li class="li-styling">
                    Information you provide to us: We may ask you to provide us
                    with SPDI. We may collect this informationthrough various
                    means and in various places through the Services, including
                    account registration, forms, contact us forms, or when you
                    otherwise interact with us. When you sign up to use
                    theServices, you create a user profile. We shall ask you to
                    provide only such SPDI which is for lawful purpose connected
                    with our Services and necessary to be collected by us
                    forsuch purpose.The current data fields that might be
                    requested for are: Name, Email, Mobile number, Password,
                    Gender, Age, professional, mental health related
                    information, language spoken, sexual orientation.
                </li>
                <li class="li-styling">
                    Information we collect as you access and use our services:

                    <p class="ptag-styling">
                        <b>a) </b> In addition to any Protected Information or
                        other information that you choose to submit to us, weand
                        our TPSP may use a variety of technologies that
                        automatically (or passively) collect certaininformation
                        whenever you visit or interact with the Services (“Usage
                        Information”). This UsageInformation may include the
                        browser that you are using, the URL that referred you to
                        our Services, all of the areas within our Services that
                        you visit, and the time of day, among other information.
                    </p>
                    <p class="ptag-styling">
                        <b>b) </b> Inaddition, we collect your Device Identifier
                        for your Device. A Device Identifier is a number that
                        isautomatically assigned to your Device used to access
                        the Services, and our computers identifyyour Device by
                        its Device Identifier.In case of interaction via
                        customer service, Please Coach may record calls for
                        quality and training purposes.
                    </p>
                    <p class="ptag-styling">
                        <b>c) </b> Usage Information may be collected using a
                        cookie. If you do not want information to be
                        collectedthrough the use of cookies, your browser allows
                        you to deny or accept the use of cookies. Cookiescan be
                        disabled or controlled by setting a preference within
                        your web browser or on your Device.If you choose to
                        disable cookies or flash cookies on your Device, some
                        features of the Servicesmay not function properly or may
                        not be able to customize the delivery of information to
                        you. TheCompany cannot control the use of cookies (or
                        the resulting information) by third partiesand useof
                        third party cookies is not covered by our Privacy
                        Policy.
                    </p>
                </li>
                <li class="li-styling">
                    Information third parties provide about you: We may, from
                    time to time, supplement the information we collect about
                    you through our websiteor Mobile Application or Services
                    with outside records from third parties.
                </li>
                <li class="li-styling">
                    Information collected by mobile applications

                    <p class="ptag-styling">
                        <b>a) </b> Our Services are also provided through the
                        Mobile Application. We may collect and usetechnical data
                        and related information, including but not limited to,
                        technical information about yourdevice, system and
                        application software, and peripherals, that is gathered
                        periodically to facilitatethe provision of software
                        updates, product support and other services to you (if
                        any) related to the Mobile Application.
                    </p>
                    <p class="ptag-styling">
                        <b>b) </b> When you use any of our Mobile Applications,
                        the Mobile Application may automatically collectand
                        store some or all of the following information from your
                        mobile device (“Mobile DeviceInformation”), in addition
                        to the Device Information, including without limitation:
                    </p>

                    <p class="ptag-styling">
                        • Your preferred language and country site (if
                        applicable)
                    </p>
                    <p class="ptag-styling">
                        • The manufacturer and model of your mobile device
                    </p>
                    <p class="ptag-styling">• Your mobile operating system</p>
                    <p class="ptag-styling">
                        • The type of mobile internet browsers you are using
                    </p>
                    <p class="ptag-styling">• Your geolocation</p>
                    <p class="ptag-styling">
                        • Information about how you interact with the Mobile
                        Application and any of our web sites to whichthe Mobile
                        Application links, such as how many times you use a
                        specific part of the MobileApplication over a given time
                        period, the amount of time you spend using the Mobile
                        Application,how often you use the Mobile Application,
                        actions you take in the Mobile Application and how
                        youengage with the Mobile Application
                    </p>
                    <p class="ptag-styling">
                        • Information to allow us to personalize the services
                        and content available through the MobileApplication
                    </p>
                    <p class="ptag-styling">
                        • Data from SMS/ text messages upon receiving Device
                        permissions for the purposes of <br />
                        (i) issuingand receiving one time passwords and other
                        device verification, and <br />
                        (ii) automatically fillingverification details during
                        financial transactions, either through us or a
                        third-party service provider,in accordance with
                        applicable law. We do not share or transfer SMS/ text
                        message data to anythird party other than as provided
                        under this Privacy Policy.
                    </p>
                </li>
            </ol>
            <h3>USE OF THE COLLECTED INFORMATION</h3>
            <ol>
                <li class="li-styling">
                    Our primary goal in collecting your information is to
                    provide you with an enhanced experiencewhen using the
                    Services. We use your SDPI in order to match you to the
                    counsellor best suited to your needs.
                </li>
                <li class="li-styling">
                    Your mental health related data is shared with the
                    counsellor with your consent so that the counsellor can
                    effectively cater to your needs
                </li>
                <li class="li-styling">
                    We use your email id and mobile number to send you reminders
                    about upcoming sessions and other information related to
                    your account. We might also use it for marketing and
                    promotional purposes.
                </li>
                <li class="li-styling">
                    We use your Internet Protocol (IP) address to help diagnose
                    problems with our computer server,and to administer our web
                    site(s). Your IP address is used to help identify you but
                    contains nopersonal information about you.
                </li>
                <li class="li-styling">
                    We will send you strictly service-related announcements on
                    rare occasions when it is necessaryto do so. For instance,
                    if our Services are temporarily suspended for maintenance,
                    we might sendyou an email.
                </li>
                <li class="li-styling">
                    All information (and copies thereof) collected by Please
                    Coach, including without limitation SPDI, Usage information
                    and other information related to your access and use of the
                    services offered by Please Coach, may be retained by Please
                    Coach for such period as necessary, including but not
                    limited to, for purposes such as compliance with statutory
                    or legal obligations, tax laws and potential evidentiary
                    purposes and for other reasonable purposes such as to
                    implement, administer, and manage your access and use of our
                    services, or resolution of any disputes.
                </li>
                <li class="li-styling">
                    To ensure a seamless experience at the Website for you and
                    to ensure your maximum benefit and comfort, we use or may
                    use the data collected through cookies, log file, device
                    identifiers, location data and clear gifs information to:
                    (a) remember information so that you will not have to
                    re-enter it during your visit or the next time you visit the
                    site; (b) provide custom, personalized content and
                    information, including advertising; (c) provide and monitor
                    the effectiveness of our Services; (d) monitor aggregate
                    metrics such as total number of visitors, traffic, usage,
                    and demographic patterns on our website and our Services;
                    (e) diagnose or fix technology problems; and (f) otherwise
                    to plan for and enhance our service.
                </li>
                <li class="li-styling">
                    We may use the information collected from you for targeted
                    advertising. This involves usinginformation collected on
                    your web or mobile browsing behaviour such as the pages
                    youhave visited or the searches you have made. This
                    information is then used to select whichadvertisements
                    should be displayed to you on websites other than our
                    website(s). The information collected is only linked to an
                    anonymous cookie ID (alphanumeric number);it does not
                    include any information that could be linked back to you,
                    such asname, address or credit card number.
                </li>
                <li class="li-styling">
                    We use certain third-party analytics tools to help us
                    measure traffic and usage trends for the Services. These
                    tools collect information, which is not personal or
                    sensitive in nature sent by your device or our Services,
                    including the web pages you visit, add-ons, and other
                    information that assists us in improving the Services. We
                    collect and use this analytics information with analytics
                    information from other Users in the form of anonymised logs,
                    so that it cannot reasonably be used to identify any
                    particular individual User.
                </li>
            </ol>
            <h3>CONFIDENTIALITY</h3>
            <ol>
                <li class="li-styling">
                    Any information shared by you during personal consultation
                    (“Confidential Information”) shall be excluded from the
                    definition of Personal Information and shall not be
                    collected/used in the same manner as Personal Information as
                    mentioned above.
                </li>
                <li class="li-styling">
                    Your Confidential Information shall not be disclosed or
                    shared by Please Coach, its employees, its agents, or any
                    third-party contractors, including the counsellors; either
                    orally or in writing; except only in the following
                    circumstances:
                    <p class="ptag-styling">
                        <b>a) </b> If Please Coach believes that there is a
                        significant/ real/ imminent threat or risk to your
                        health,safety or life or to the health, safety or life
                        of any other person or the public.
                    </p>
                    <p class="ptag-styling">
                        <b>b) </b> If Please Coach is required to share such
                        Confidential Information to any authority by law; and
                    </p>
                    <p class="ptag-styling">
                        <b>c) </b> If you have claimed a health care insurance
                        and such Confidential Information is required by the
                        insurer.
                    </p>
                </li>
            </ol>
            <h3>SHARING OF THE COLLECTED INFORMATION</h3>
            <ol>
                <li class="li-styling">
                    We may share Personal Information (including but not limited
                    to, information from cookies, log files, device identifiers,
                    location data, and usage data) with businesses that are
                    legally part of the same group of companies that Please
                    Coach is part of, or that become part of that group (“
                    Affiliates”).Our Affiliates may only use this information to
                    help provide, understand, and improve the Service (including
                    by providing analytics) and Affiliates’ own services
                    (including by providing you with better and more relevant
                    experiences).
                </li>
                <li class="li-styling">
                    We also may share your Personal Information as well as
                    information from tools like cookies, log files, and device
                    identifiers and location data, with third-party
                    organizations that help us provide the Services to you. Our
                    service providers will only be given access to your
                    information as is reasonably necessary to provide the
                    Services.All the Service Providers and third parties engaged
                    by Please Coach shall be bound by adequate confidentiality
                    terms to protect the Confidential Information and the
                    Personal Information of the User so that such information is
                    not used in any manner it is not intended to be used.
                </li>
                <li class="li-styling">
                    While sharing any information with any third party, we will
                    remove parts of data that can identify you and share only
                    anonymized data with other parties. We may also combine your
                    information with other information in a way that it is no
                    longer associated with you and share that anonymised
                    aggregated information which cannot be traced back to you.
                </li>
                <li class="li-styling">
                    Please note that only Personal Information or content that
                    you voluntarily disclose for posting to the Website, or post
                    to any social media account owned and operated by Please
                    Coach becomes available to the public. No other information
                    provided by you is shared with the public. Once you have
                    shared such information or made it public, the same may be
                    re-shared by others, re-posted by Please Coach, or used by
                    Please Coach for the purposes of analysis, research or
                    studies.
                </li>
                <li class="li-styling">
                    Please Coach shall not ordinarily share the sensitive
                    Personal Information of a User without their prior consent.
                    You agree that Please Coach may share sensitive Personal
                    Information of a User with any third party without obtaining
                    the prior consent of the User in the following limited
                    circumstances:
                    <p class="ptag-styling">
                        <b>a)</b> When it is requested or required by law or by
                        any court or governmental agency or authority to
                        disclose, for the purpose of verification of identity,
                        or for the prevention, detection, investigation
                        including cyber incidents, or for prosecution and
                        punishment of offences. These disclosures are made in
                        good faith and belief that such disclosure is reasonably
                        necessary for enforcing the Terms of Use, this Privacy
                        Policy, or for complying with the applicable laws and
                        regulations.
                    </p>
                    <p class="ptag-styling">
                        <b>b)</b> Please Coach proposes to share such
                        information within its group companies and officers and
                        employees of such group companies for the purpose of
                        processing personal information on its behalf. Please
                        Coach also ensures that the recipients of such
                        information agree to process such information based on
                        our instructions and in compliance with this Privacy
                        Policy and any other appropriate confidentiality and
                        security measures.
                    </p>
                </li>
            </ol>
            <h3>THIRD PARTY CONTENT AND LINKS TO THIRD PARTY SERVICES</h3>

            <p>
                The Services may contain content that is supplied by a third
                party, and those third parties maycollect website usage
                information and your Device Identifier when web pages from any
                online ormobile Services are served to your browser. In
                addition, when you are using the Services, youmay be directed to
                other sites or applications that are operated and controlled by
                third parties thatwe do not control. We are not responsible for
                the privacy practices employed by any of these thirdparties. For
                example, if you click on a banner advertisement, the click may
                take you away fromone of our websites onto a different web site.
                These other web sites may send their own cookiesto you,
                independently collect data or solicit Protected Information and
                may or may not have theirown published privacy policies. We
                encourage you to note when you leave our Services and toread the
                privacy statements of all thirdparty web sites or applications
                before submitting any SPDI to third parties.
            </p>
            <h3>SOCIAL MEDIA FEATURES AND WIDGETS</h3>
            <p>
                Our online and mobile Services may include social media
                features, such as the Facebook Likebutton, and widgets such as a
                “Share This” button, or interactive mini-programs that run on
                ouronline and mobile Services. These features may collect your
                IP address, photograph, which pageyou are visiting on our online
                or mobile Servicesand may set a cookie to enable the feature
                tofunction properly. Social media features and widgets are
                either hosted by a third party or hosteddirectly on our online
                Services. Your interactions with these features and widgets are
                governed bythe privacy policy of the company providing them.
            </p>
            <h3>SECURITY</h3>
            <ol>
                <li class="li-styling">
                    The Protected Information and Usage Information we collect
                    is securely stored within ourdatabases, and we use standard,
                    industry-wide, commercially reasonable security practices
                    suchas encryption, firewalls and SSL (Secure Socket Layers)
                    for protecting your information. However,as effective as
                    encryption technology is, no security system is
                    impenetrable. We cannot guarantee that such information will
                    never be accessed, used, or released in a manner that is
                    inconsistent with this policy. We expressly disclaim any
                    representation or warranty, whether express or implied, with
                    respect to ensuring, guaranteeing, or otherwise offering any
                    definitive promise of security in connection with your
                    Information.
                </li>
                <li class="li-styling">
                    The User is solely responsible for maintaining
                    confidentiality of the User’s password and User
                    identification. The User shall be solely responsible for all
                    activities and transmission performed by the User through
                    his User identification. Please Coach assumes no
                    responsibility or liability for their improper use of
                    information relating to such usage of credit cards and/or
                    debit cards by the User, whether online or off-line.
                </li>
                <li class="li-styling">
                    If you are located outside India and choose to provide
                    information to us from there, Please Coach will transfer and
                    keep your information in India. Your submission of
                    information represents your consent to this transfer.
                </li>
            </ol>
            <h3>CHANGE OF INFORMATION AND CANCELLATION OF ACCOUNT</h3>
            <ol>
                <li class="li-styling">
                    You are responsible for maintaining the accuracy of the
                    information you submit to us, such asyour contact
                    information provided as part of account registration. If
                    your SPDI changes, or if you no longer desire our Services,
                    you may correct, delete inaccuracies, or amendinformation by
                    making the change on our user profile page or by contacting
                    usthrough email address mentioned on our website or Mobile
                    Application. We will make goodfaith efforts to make
                    requested changes in our then active databases as soon as
                    reasonablypracticable.
                </li>
                <li class="li-styling">
                    You may also cancel or modify your communications that you
                    have elected to receive from theServices by following the
                    instructions contained within an e-mail or by logging into
                    your useraccount and changing your communication
                    preferences.
                </li>
                <li class="li-styling">
                    If upon modifying or changing the information earlier
                    provided to Us, we find it difficult to permitaccess to our
                    Services to you due to insufficiency/ inaccuracy of the
                    information, we may, in oursole discretion terminate your
                    access to the Services by providing you a written notice to
                    this effecton your registered email id.
                </li>
                <li class="li-styling">
                    If you wish to cancel your account or request that we no
                    longer use your information to provideyou services, contact
                    us through the contact details on the website. Even after
                    youraccount is terminated, we will retain your SPDI and
                    Usage Information as needed to comply with our legal and
                    regulatoryobligations, resolve disputes, conclude any
                    activities related to cancellation of an account,investigate
                    or prevent fraud and other inappropriate activity, to
                    enforce our agreements, and forother business reason. After
                    a period of time, your data may be anonymized and
                    aggregated, andthen may be held by us as long as necessary
                    for us to provide our Services effectively, but our useof
                    the anonymized data will be solely for analytic purposes.
                </li>
                <li class="li-styling">
                    The right to delete personal data : You have the right to
                    request the deletion of your personal data, subject to
                    certain exceptions. Once we receive your request through
                    email on hola@Pleasacoach.care and confirm your request, we
                    will go ahead & delete (and direct our service providers to
                    delete) your personal information from records, unless an
                    exception applies.
                </li>
            </ol>
            <p>
                Please Coach may change or amend this Privacy Policy from time
                to time to incorporate necessary future changes. Please Coach’s
                use of User Data shall be in adherence with consistent with the
                provisions of the relevant privacy policy under which the
                information was collected, regardless of new or conflicting
                provisions in an updated Privacy Policy.
            </p>
        </div>
    </div>
</div>
