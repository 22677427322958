import { Component, OnInit } from "@angular/core";
import { ProfileService } from "../profile.service";

@Component({
  selector: "app-view-old-plans",
  templateUrl: "./view-old-plans.component.html",
  styleUrls: ["./view-old-plans.component.css"],
})
export class ViewOldPlansComponent implements OnInit {
  oldPlans = [];
  filteredResults = [];
  NoPlans = false;
  selectedCategory = "ACTIVE";
  isShimmerLoading = true;
  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.isShimmerLoading = true;
    this.profileService.getPurchasedPlans().subscribe((data) => {
      if (data['plans']['plans'].length > 0) {
        this.oldPlans = data['plans']['plans'].sort((a, b) => {
          return new Date(b.boughtOn).getTime() - new Date(a.boughtOn).getTime();
        })
      }
      this.setCategory('ACTIVE');
      this.isShimmerLoading = false;
    })
  }

  setCategory(type) {
    if (type == 'ACTIVE') {
      this.filteredResults = this.oldPlans.filter(plans => plans.sessions > 0 || plans.chatDaysAvailable > 0)
      if (this.filteredResults.length == 0) {
        this.NoPlans = true
      }
    } else {
      this.filteredResults = this.oldPlans.filter(plan => plan.session == 0 && plan.chatDaysAvailable == 0)
      if (this.filteredResults.length == 0) {
        this.NoPlans = true
      }
    }
  }
}
