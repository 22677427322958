<div (click)="closenavbar()">
    <div>
        <div class="logoHolderCus">
            <img
                src="../../../assets/images/logo.png"
                class="img-fluid"
                alt=""
            />
        </div>
        <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
        <div class="homePageBannerWrap">
            <div class="BannerImageHolder pcView">
                <img
                    src="../../../assets/images/conversation-heads-banner.jpg"
                    draggable="false"
                    class="img-fluid"
                    alt="Please Coachmental health conversation with counselor therapist concept"
                />
            </div>
            <div class="BannerImageHolder mobileView">
                <img
                    src="../../../assets/images/campaign/new-square.png"
                    draggable="false"
                    alt="Please Coachmental health conversation with counselor therapist concept"
                />
            </div>
            <div class="bannerTextHolder">
                <h3>Talk. Resolve. Heal.</h3>
                <h1>Online counselling therapy with top psychologists</h1>
            </div>
        </div>
    </div>
    <div class="blogMainHolder">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <h2>Pregnancy can be tough</h2>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-md-7">
                    <p>
                        Stepping into motherhood makes an individual experience
                        numerous emotions, all at once.
                    </p>
                    <p>
                        &nbsp;The journey is full of fluctuating affectivities,
                        new lifestyle changes, and adapting to new bodily
                        transitions and variations. The news brings out a lot of
                        joy mixed with "fear" and "loss".
                    </p>
                    <p>
                        &nbsp;The mother tends to feel hopeful but alongside the
                        experience brings out negative and unhealthy emotions
                        too and a pattern of fearfulness, pre-occupation of
                        thoughts, self-doubt, body image issues might come into
                        the picture which results in depressive episodes and
                        generation of anxiety.
                    </p>
                    <p>
                        &nbsp;It is essential for the mother to be insightful
                        about what changes are normal and what isn&rsquo;t. She
                        needs to be knowledgeable about what needs to be
                        addressed and what requires professional help, with
                        respect to her physical as well as mental health.
                    </p>
                </div>
                <div class="col-md-5">
                    <img
                        src="../../../assets/images/campaign/blog-image-new.jpg"
                        class="img-fluid blogImgNew"
                        alt=""
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p>
                        &nbsp;There are a number of changes that a women&rsquo;s
                        body undergoes during pregnancy which directly leads to
                        influence in hormonal regulation, usually resulting in
                        mood fluctuations, distress, irritability, fatigue, etc.
                        These symptoms usually increase over the course of
                        pregnancy, giving the mother both physical as well as
                        mental exhaustion, hence it becomes very vital for her
                        to learn the management of how to deal with such changes
                        and symptoms. Focus on the mental health aspects during
                        this phase of life should be a priority for both the
                        parents.
                    </p>
                    <p>
                        Prenatal counselling, in times like these, comes in
                        handy. It prepares the mother for such changes and makes
                        her develop a balance. It is seen that women tend to go
                        through extreme body image issues during pregnancy and
                        they start developing a sense of hate towards their
                        physical appearances, resulting in lowering of their
                        self-esteem and their confidence levels, prenatal
                        counseling helps the individual to overcome these
                        irrational and negative beliefs. An appointment with a
                        mental health specialist in your schedule can help you
                        adapt to these new lifestyle changes better, it will
                        enhance the quality of your life and will help you
                        foster healthy behavioral patterns.
                    </p>
                    <p>
                        &nbsp;Pregnancy can lead to distress even for those
                        individuals who are usually very calm and relaxed, being
                        stressed can directly take a dig on you and your baby,
                        hence to have a management of all the factors that
                        influence your mental well being, a constant check-up
                        with your psychologist is very important. Learning the
                        skill set to deal with your fluctuating moods, talking
                        about your thoughts can be extremely helpful in times
                        like these.
                    </p>
                    <p>
                        &nbsp;The expectations bar is set very high on the
                        mother from her own end and as well as by the society,
                        she is expected to maintain good physical and mental
                        health and is time and time again reminded to be strong,
                        resilient, and adaptive to change, these exceptions
                        usually put the mother under a lot of pressure and
                        worry, resulting into anxious behavior and flattening of
                        affectivity/mood that can, in turn, lead to depression,
                        if not put into consideration and worked upon. There are
                        a lot of mothers who get prone to depression and anxiety
                        disorders during their prenatal and postpartum phases,
                        therefore mental health counseling in these periods has
                        to be a top priory. It not only makes you adaptive to
                        new changes, but it also prepares you.&nbsp; For the.
                        upcoming events and stages. It helps you relieve the
                        stress levels, makes you insightful, and helps you
                        acquire behavioral management skills.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center flex-column">
        <div class="heading-text" style="margin-top: 0px">
            What Our Users Say
        </div>
        <div class="carousel-container d-flex">
            <div class="owl-carousel">
                <carousel
                    id="carousel"
                    [itemsPerSlide]="itemsPerSlide"
                    [interval]="5000"
                    [showIndicators]="false"
                    class="wide-carousel"
                    [noWrap]="false"
                    [singleSlideOffset]="true"
                    [isAnimated]="true"
                >
                    <slide
                        class="carousel-card"
                        *ngFor="let item of userReviews"
                    >
                        <div class="sub-text">
                            <b>{{ item.name }}</b>
                        </div>
                        <div class="small-text" style="margin-top: 6px">
                            {{ item.work }}
                        </div>
                        <div class="rating" style="margin-top: 30px"></div>
                        <div
                            class="sub-text card-content-width"
                            style="margin-top: 30px"
                        >
                            {{ item.text }}
                        </div>
                        <div class="quote"></div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
    <div class="ecosystemHolder">
        <div class="ecoWrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="ecoIndHolder">
                            <img
                                src="../../../assets/images/campaign/trust-picture1.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="ecoIndHolder resSecCss">
                            <img
                                src="../../../assets/images/campaign/trust-picture2.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="ecoIndHolder resSecCss">
                            <img
                                src="../../../assets/images/campaign/trust-picture3.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boxRoundCircle howHeading">
            <div class="secondaryHeading">
                <h2>How It Works</h2>
                <hr />
                <p>
                    The most simple & convenient way to access talk therapy -
                    anytime, anywhere, any device.
                </p>
            </div>
        </div>
    </div>
    <div class="howItWorks">
        <div class="bannerimageCamp pcView">
            <img
                src="../../../assets/images/campaign/how-it-works-desktop.jpg"
                draggable="false"
                class="img-fluid"
                alt="Please Coachmental health conversation with counselor therapist concept"
            />
        </div>
        <div class="bannerimageCamp mobileView">
            <img
                src="../../../assets/images/campaign/how-it-works-phone.jpg"
                draggable="false"
                alt="Please Coachmental health conversation with counselor therapist concept"
                class="img-fluid"
            />
        </div>
    </div>
    <div class="getStartedBtn getstartedHolder">
        <button class="buttonTheme2" (click)="chatWithUs()">
            Chat with Us
        </button>
        <button routerLink="../new-signup" class="buttonTheme1">
            Get Started
        </button>
    </div>
</div>
