<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
        <p class="">
            <strong>Disclamer: </strong>This tool is intended to be a screening
            tool and to monitor the likelihood that someone has ADHD. It cannot
            replace a clinical assessment and diagnosis. You are advised to
            share these results with a mental health professional for a better
            understanding.
        </p>
        <div class="report-user mt-3">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
            <br />
        </div>
        <ng-container *ngIf="report['reportType'] == 'adhd'">
            <div class="report-user">
                <p>
                    A score >= 2 on 4 out of 6 questions indicates presence of
                    adult ADHD.
                </p>
                <p>
                    <strong
                        >Your score was >= 2 in {{ highScoreCount }} out of 6
                        questions.</strong
                    >
                </p>
                <p>
                    This indicates
                    <span *ngIf="highScoreCount >= 2">presence</span>
                    <span *ngIf="highScoreCount < 2">absence</span> of Adult
                    ADHD.
                </p>
                <p>
                    This screening test is a symptoms checklist for adult ADHD
                    and not a diagnostic test. A full assessment and potential
                    diagnosis of ADHD cannot be done using an online test or
                    during 1 therapy session. A thorough evaluation usually
                    takes more than one session and must be done by a
                    professional who is trained in ADHD.
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="report['reportType'] == 'adhd-adult'">
            <div class="report-user mt-4">
                <p><strong>Your score: </strong></p>
                <p>{{ adhdScore }}</p>
                <p><strong>Your Assessment: </strong></p>
                <p>
                    Your responses indicate that you are {{ adhdFinal }}
                    <span class="scrollTxt" (click)="scrollToElem('target')"
                        >Detailed Analysis</span
                    >
                </p>
            </div>
        </ng-container>
    </div>
    <br />

    <div
        class="container graph-section mt-3"
        *ngIf="report['reportType'] == 'adhd-adult'"
    >
        <div>
            <div>
                <p class="mb-3">
                    Seeing the report can be overwhelming, but Please Coach has
                    got you covered. We offer a variety of services that you can
                    access at any time, anywhere.
                </p>
            </div>
            <h5>Understanding Your ADHD Assessment</h5>
        </div>
    </div>

    <div class="container scores-section">
        <table class="table table-hover" *ngIf="report['reportType'] == 'adhd'">
            <tr>
                <th class="text-center">Questions</th>
                <th>Answer/Score</th>
            </tr>

            <ng-container *ngFor="let question of questions; let i = index">
                <tr>
                    <td>{{ question['questionText'] }}</td>
                    <td class="text-center">{{ answersArray[i] }}</td>
                </tr>
            </ng-container>
        </table>
        <div class="about-scores" *ngIf="report['reportType'] == 'adhd-adult'">
            <p class="para_scores mb-3">
                A score of 0 to 16 indicates no likelihood of having ADHD. This
                means that you do not have ADHD, no chances of having it. In
                case you are still experiencing symptoms, it is recommended for
                you to visit a licensed professional who can diagnose you and
                help you with the treatment.
            </p>
            <p class="para_scores mb-3">
                A score of 17 to 23 indicates a likelihood of having ADHD. This
                means that you might have ADHD but to be sure, it is advised to
                get a proper diagnostic test. With the test that you just did,
                you got screened for chances of having ADHD. A mental health
                professional can guide you best into the next steps for the
                same. It can feel overwhelming but don’t worry, Please Coach is
                here to help. We have some of the best psychiatrists, clinical
                psychologists and therapists who can help you.
            </p>
            <p class="para_scores mb-3" id="target">
                A score of 24 and above increases the likelihood of having ADHD
                to a high level. It feels confusing at the moment but you must
                take this screening report to a psychiatrist or a clinical
                psychologist who can help you best in this time of need. You
                need not worry about that since Please Coach has got you
                covered.
            </p>
        </div>
        <div
            class="container about-scores"
            *ngIf="report['reportType'] == 'adhd-adult'"
        >
            <div class="">
                <h5 class="mb-3 mt-4">Detailed Analysis</h5>
                <p class="mb-3">
                    ASRS is a screening tool for ADHD. This is made up of 2
                    components: Inattentive Presentation and
                    Hyperactive/Impulsive Presentation
                </p>
                <p class="mb-3">
                    <strong>Inattentive Presentation </strong>means lack of
                    focus and concentration. Getting easily distracted by random
                    stimuli and an inability to organize tasks and activities.
                    There might also be a complaint of losing items often. This
                    subscale also involves the lack of listening skills and
                    attention. Your score on this subscale is
                    {{ inattentiveness }} where the maximum score is 24 and the
                    minimum score is 0.
                </p>
                <p class="mb-3">
                    <strong>Hyperactive/Impulsive Presentation </strong>means
                    the need for constant movement. Activities like fidgeting,
                    squirming and being unable to sit in a place are common.
                    Constantly talking at a very high speed with high motor
                    skills. Your score on this subscale is {{ hyperactivity }},
                    where the maximum score is 48 and the minimum score is 0.
                </p>
                <h5>Note:</h5>
                <p>
                    This screening test is a symptoms checklist for adult ADHD
                    and not a diagnostic test. A full assessment and potential
                    diagnosis of ADHD cannot be done using an online test or
                    during 1 therapy session. A thorough evaluation usually
                    takes more than one session and must be done by a
                    professional who is trained in ADHD.
                </p>
            </div>
        </div>
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
    <!-- <div class="disclaimer-section">
    <div class="head-disclaimer">
      <h3>Disclaimer</h3>
      <p>
        This screening test is a symptoms checklist for adult ADHD and not a diagnostic test. A full assessment and potential diagnosis of ADHD cannot be done using an online test or during 1 therapy session. A thorough evaluation usually takes more than one session and must be done by a professional who is trained in ADHD. 
      </p>
    </div>
  </div> -->
    <br />
</div>
