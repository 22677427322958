import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AdCampaignService } from "../ad-campaign.service";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: "app-ad-campaign-counsellors",
  templateUrl: "./ad-campaign-counsellors.component.html",
  styleUrls: ["./ad-campaign-counsellors.component.css"],
})
export class AdCampaignCounsellorsComponent implements OnInit {
  therapists = [];
  pageNo = 1;
  pages = [1];
  showNext = false;
  totalCount = 0;
  isLoading = true;
  skip = 0;
  perPage = 6;
  languages = [
    "Hindi",
    "English",
    "Bengali",
    "Tamil",
    "Kannada",
    "Telugu",
    "Gujarati",
    "Punjabi",
    "Malayalam",
    "Marathi",
    "All"
  ]
  selectedLanguage = "";
  userReviews = [
    {
      text: "Therapy with pleasecoach gave me a new lease of life. I was at the point of ending things & within a matter 21 days I felt like I got my life back. I am so grateful for the timely help and unconditional support from pleasecoach.",
      name: "Sana Javed",
      work: "Software Engineer",
    },
    {
      text: "I was unsure about starting therapy when I reached out to pleasecoach. Their personalized therapy plans, 24/7 support & absolute flexibility in terms of choosing time or therapist got me to enrol. I have learnt to manage my anxiety so well. My panic attacks are a thing of distant past now.",
      name: "Puja Mittal",
      work: "Homemaker",
    },
    {
      text: "pleasecoach has helped me put more life in my years. I used to always feel low, had lost interest in everything despite having a perfect life. I could not understand what I was going through. Thanks to a friend who highly recommended pleasecoach to me, I gave it a shot. I feel so grateful to the psychologist & psychiatrist at pleasecoach who made helped me understand & treat my depression.",
      name: "Vikas Arora",
      work: "CFO",
    },
    {
      text: "College was not what I expected. I felt so marginalised, had lost all confidence & could not cope with the academic stress & my strained relationship. My therapist at pleasecoach helped me find my self esteem & confidence. She also helped me with effective coping mechanisms for my stress & relationship issues.",
      name: "Aishwarya Hegde",
      work: "Student",
    },
  ];
  constructor(private adCampaignService: AdCampaignService, private actRoute: ActivatedRoute, private router : Router) {}

  
  options: AnimationOptions = {
      path: '/assets/loader.json',
    };
  
    animationCreated(animationItem: AnimationItem): void {
      console.log(animationItem);
    }
  ngOnInit(): void {
    this.actRoute.queryParamMap.subscribe((param) =>{
      if(param.get("language")){
        this.selectedLanguage = param.get("language");
      }
    })
    this.getCounsellors(false);
    setTimeout(() => {
      window["$zoho"].salesiq.floatbutton.visible("hide");
      window["kiwi"]["hide"]();
    }, 4000);
  }

  chatWithUs() {
    // window["$zoho"].salesiq.floatbutton.visible("show");
    // let chatElem = document.getElementById("zsiq_agtpic");
    // chatElem.click();
  }

  checkPage() {
    if (this.pageNo === this.pages[this.pages.length - 1]) {
      this.showNext = false;
    } else {
      this.showNext = true;
    }
  }

  nextPage() {
    if (this.pageNo === this.pages[-1]) {
      return;
    }
    this.isLoading = true;
    this.pageNo = this.pageNo + 1;
    this.getCounsellors(false);
    this.checkPage();
  }

  getCounsellors(isSearch){
    this.skip = (this.pageNo - 1) * this.perPage;
    if (isSearch) {
      this.skip = 0;
      this.pageNo = 1;
      this.pages = [1];
      this.isLoading = true;
      this.therapists = [];
    }
    this.adCampaignService.getCounsellors(this.skip, this.perPage, this.selectedLanguage).subscribe((res) => {
      this.isLoading = false;
      res["result"].forEach((therapist) => {
        if(therapist.specializeareas){
          therapist.specializeareas = therapist.specializeareas.map(item => item.name)
        }
      });
      if (isSearch) {
        this.therapists = res["result"];
      } else {
        let therapistsTemp = [...this.therapists]
        let key = "_id";
        therapistsTemp = therapistsTemp.concat(res["result"]);
        this.therapists = [...new Map(therapistsTemp.map(item =>
          [item[key], item])).values()];
      }
      this.totalCount = res["totalCount"];
      let pageCount = Math.ceil(this.totalCount / 6);
      if (pageCount != this.pages.length) {
        for (let i = 0; i < pageCount - 1; i++) {
          if (this.pages.indexOf(i + 2)) {
            this.pages.push(i + 2);
          }
        }
      }
      this.checkPage();
    })
  }

  selectLanguage(language){
    if(language === "All"){
      this.selectedLanguage = '';
    } else {
      this.selectedLanguage = language;
    }
    this.isLoading = true;
    this.getCounsellors(true);
  }
}
