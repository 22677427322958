import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FreeReportService } from "src/app/guest/free-report.service";
import { COMMON_DATA } from "src/app/shared/common";
import { SideNavBarService } from "src/app/side-nav-bar.service";
import clevertap from "clevertap-web-sdk";


@Component({
  selector: "app-angry-test",
  templateUrl: "./angry-test.component.html",
  styleUrls: ["./angry-test.component.css"],
})
export class AngryTestComponent implements OnInit {
  freeReportForm: FormGroup;
  userForm2: FormGroup;
  hideModal = true;
  firstPart: boolean = true;
  secondPart: boolean = false;
  currentIndex = 0;
  answers = COMMON_DATA.angry_options;
  questions = COMMON_DATA.angry_questions;
  progressPercentage = 0;
  userCountryCode = {
    countryCode:  localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "in",
    dialCode: localStorage.getItem("dialCode") ? localStorage.getItem("dialCode") : "91",
  };

  constructor(
    private reportService: FreeReportService,
    private router: Router,
    private sidenavService: SideNavBarService
  ) {}

  ngOnInit() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.initForms();
    this.initForms2();
    if(clevertap){
      clevertap.event.push("Free Report Started", {
        "Report Name" : "Clinical Anger"
      })
    }
  }
  checkError(valid) {
    if(!valid){
      this.userForm2.controls["phone"].setErrors({ 'invalidPhone' : true })
    }
  }

  getNumber(event) {
    let phoneNew = String(event).substring(this.userCountryCode.dialCode.length + 1);
    this.userForm2.patchValue({
      phone : phoneNew
    })
  }

  telInputObject(event) {
    // console.log(event);
    // event.setCountry("id")
  }

  onCountryChange(event) {
    this.userCountryCode.countryCode = event.iso2;
    this.userCountryCode.dialCode = event.dialCode;
  }
  previousPage() {
    this.currentIndex--;
  }
  currentIndexInc() {
    this.currentIndex++;
  }
  ShowHide = function () {
    this.firstPart = false;
    this.secondPart = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  instruction1 = function () {
    this.firstPart = true;
    this.secondPart = false;
  };
  showModal() {
    if (this.freeReportForm.invalid) {
      return;
    }
    this.hideModal = false;
  }

  onSubmit() {
    if(this.userForm2.invalid){
      return;
    }
    let ansObj = {
      questionsAttempted: this.freeReportForm.value,
      ...this.userForm2.value,
      countryCode : this.userCountryCode
    };
    clevertap.profile.push({
      "Site" : {
        "Name" : this.userForm2.value.fullname,
        "Email" : this.userForm2.value.email,
        "Phone" : "+" + this.userCountryCode.dialCode + this.userForm2.value.phone
      }
    })
    this.reportService.generateReport(ansObj, "anger").subscribe((data) => {
      this.hideModal = true;
      this.router.navigate(["../free-report/angry-test-results/" + data["freeReportId"]]);
    });
  }

  initForms() {
    let group = {};
    this.questions.forEach((input_template, index) => {
      group[input_template["label"]] = new FormControl("", Validators.required);
    });
    this.freeReportForm = new FormGroup(group);
    this.freeReportForm.valueChanges.subscribe((data) => {
      let answers = 0;
      Object.values(data).forEach((value) =>{
        if(value !== ''){
          answers++;
        }
      })
      this.progressPercentage = (answers / Object.values(data).length)*100;
    })
  }
  closenavbar() {
    this.sidenavService.closesidenav();
  }
  initForms2() {
    this.userForm2 = new FormGroup({
      email: new FormControl(localStorage.getItem("email") ? localStorage.getItem("email") : "", [
        Validators.required,
        Validators.pattern("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]"),
      ]),
      phone: new FormControl(localStorage.getItem("phone") ? localStorage.getItem("phone") : "", Validators.required),
      fullname: new FormControl(localStorage.getItem("name") ? localStorage.getItem("name") : "", Validators.required),
    });
  }
  indexTracker(index: number, value: any) {
    return index;
  }
}
