import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ConfirmationDialogService } from "src/app/confirmation-dialog.service";
import {
  BootstrapAlert,
  BootstrapAlertService,
} from "src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert";
import { environment } from "src/environments/environment";
import { BookingService } from "../booking.service";
import { UserVideoService } from "../user-video.service";
import 'zone.js/dist/zone';
import 'zone.js/dist/webapis-rtc-peer-connection';
import { DeviceDetectorService } from 'ngx-device-detector';


const {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack,
} = require("twilio-video");

@Component({
  selector: "app-join-session",
  templateUrl: "./join-session.component.html",
  styleUrls: ["./join-session.component.css"],
})
export class JoinSessionComponent implements OnInit, OnDestroy {
  constructor(
    private videoService: UserVideoService,
    private actRoute: ActivatedRoute,
    private bookingService: BookingService,
    private alertService: BootstrapAlertService,
    private confirmationService: ConfirmationDialogService,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) {
    if (document.addEventListener) {
      document.addEventListener("fullscreenchange", this.exitHandler, false);
      document.addEventListener("mozfullscreenchange", this.exitHandler, false);
      document.addEventListener("MSFullscreenChange", this.exitHandler, false);
      document.addEventListener(
        "webkitfullscreenchange",
        this.exitHandler,
        false
      );
    }
  }
  roomName = "";
  isLoading = true;
  room = null;
  videoToken;
  isAudio: Boolean = true;
  isVideo: Boolean = true;
  isLocalAudio: Boolean = true;
  isLocalVideo: Boolean;
  isFullScreen: Boolean = false;
  isRemoteJoined = false;
  mediaDevices = [];
  localVideoTrack;
  localAudioTrack;
  localTracks = null;
  mics = [];
  cams = [];
  remoteAudioMuted = false;
  remoteVideoMuted = false;
  status = "";
  isConnecting = false
  remainingMinutes = null;
  sessionData = null;
  joinDate = null;
  remainingTimeText = "";
  imageUrl = environment.apiUrl;
  mediaErrors = [
    "NotAllowedError",
    "NotFoundError",
    "NotReadableError",
    "OverconstrainedError",
    "TypeError",
  ];
  // AudioContext = window.AudioContext;
  // audioContext = AudioContext ? new AudioContext() : null;
  audioLevel = 0;
  isMobile = false;
  isMicAvailable = true;
  isCamAvailable = true;
  isFirstTime = true;
  deviceInfo = null;

  @ViewChild("videCallBox") divRef;
  async ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.videoService.getVideoToken().subscribe((videoToken) => {
      this.videoToken = videoToken;
      this.actRoute.queryParamMap.subscribe((params) => {
        this.roomName = params.get("sessionId");
        this.videoService.statsLogger({ deviceInfo: this.deviceInfo, sessionId: this.roomName, eventName: 'Page Loaded - User' }).subscribe((res) => {

        });
        this.isLoading = true;
        this.bookingService.getSession(this.roomName).subscribe(
          async (data) => {
            this.sessionData = data["booking"];
            let startDate = moment(this.sessionData.startDate);
            let timeDiff = this.bookingService.calcTimeDiff(
              startDate["_d"],
              new Date()
            );
            let timeDiffKey = Object.keys(timeDiff);

            this.isLoading = true;
            timeDiffKey.forEach((key) => {
              if (timeDiff[key] > 0) {
                if (timeDiff[key] > 1) {
                  this.remainingTimeText += timeDiff[key] + " " + key + " ";
                } else {
                  this.remainingTimeText +=
                    timeDiff[key] + " " + key.slice(0, -1) + " ";
                }
              }
            });
            let now = moment(new Date());
            this.remainingMinutes = moment.duration(startDate.diff(now));
            this.remainingMinutes = this.remainingMinutes.asMinutes();
            this.joinDate = new Date(this.sessionData.startDate);
            this.joinDate.setMinutes(this.joinDate.getMinutes() - 10);
            if (this.remainingMinutes <= 30) {
              this.status = "ABOUT_TO_START";
              this.initPrejoinMedia();
            } else {
              this.status = "NOT_STARTED";
            }
            this.isLoading = false;
          },
          (error) => {
            if (error.status === 404) {
              this.status = 'NOT_FOUND'
            }
            this.isLoading = false;
          }
        );
      });
    });
  }

  ngOnDestroy() {
    this.endCall();
  }

  async initPrejoinMedia() {
    try {
      this.mediaDevices = await navigator.mediaDevices.enumerateDevices();
      console.log(this.mediaDevices);
      this.mics = this.mediaDevices.filter(
        (device) => device.kind === "audioinput"
      );
      this.cams = this.mediaDevices.filter(
        (device) => device.kind === "videoinput"
      );

      this.isMicAvailable = this.mics.some((device) => { return device.label !== '' })
      this.isCamAvailable = this.cams.some((device) => { return device.label !== '' })

      // If a media device is available or not
      // If cams > 0

      // createLocalVideoTrack().catch((error) => this.handleMediaError(error))

      this.localTracks = await createLocalTracks({
        audio: this.mics.length > 0 && this.isMicAvailable ? true : false,
        video: this.cams.length > 0 && this.isCamAvailable ? true : false,
      }).catch((error) => this.handleMediaError(error));
      if (this.localTracks !== undefined) {
        this.localVideoTrack = this.localTracks.find(
          (track) => track.kind === "video"
        );
        this.localAudioTrack = this.localTracks.find(
          (track) => track.kind === "audio"
        );
        this.videoService.statsLogger({ sessionId: this.roomName, eventName: "Initiated Media - User", isAudioEnabled: this.localVideoTrack ? true : false, isVideoEnabled: this.localVideoTrack ? true : false, deviceInfo: this.deviceInfo }).subscribe((res) => { });
        // this.pollAudioLevel(this.localAudioTrack, (level) => {
        //   this.audioLevel = level * 2;
        // });
        let localMediaContainer = document.getElementById("inputPreview");
        let lvt = this.localVideoTrack.attach(localMediaContainer);
        lvt.style.transform = "scale(-1,1)";
        // if(navigator["userAgentData"]["mobile"]){
        //   document.addEventListener("visibilitychange", async () => {
        //     if(document.visibilityState === "hidden"){
        //       this.localVideoTrack.stop();
        //       this.localVideoTrack.detach();
        //       this.localVideoTrack.disable();
        //       let lclVideoIndex = this.localTracks.findIndex(
        //         (track) => track.kind === "video"
        //       );
        //       if (lclVideoIndex >= 0) {
        //         this.localTracks.splice(lclVideoIndex, 1);
        //       }
        //     } else {
        //       this.localVideoTrack = await createLocalVideoTrack();
        //       this.localTracks.push(this.localVideoTrack);
        //       let localMediaContainer = document.getElementById("inputPreview");
        //       let lvt = this.localVideoTrack.attach(localMediaContainer);
        //       lvt.style.transform = "scale(-1,1)";
        //     }
        //   })
        // }
      }
    } catch (error) {
      // console.log("permission denied", error);
    }
  }

  // rootMeanSquare(samples) {
  //   const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0);
  //   return Math.sqrt(sumSq / samples.length);
  // }

  // async pollAudioLevel(track, onLevelChanged) {
  //   let that = this;
  //   if (!this.audioContext) {
  //     return;
  //   }

  //   // Due to browsers' autoplay policy, the AudioContext is only active after
  //   // the user has interacted with your app, after which the Promise returned
  //   // here is resolved.
  //   await this.audioContext.resume();

  //   // Create an analyser to access the raw audio samples from the microphone.
  //   const analyser = this.audioContext.createAnalyser();
  //   analyser.fftSize = 1024;
  //   analyser.smoothingTimeConstant = 0.5;

  //   // Connect the LocalAudioTrack's media source to the analyser.
  //   const stream = new MediaStream([track.mediaStreamTrack]);
  //   const source = this.audioContext.createMediaStreamSource(stream);
  //   source.connect(analyser);

  //   const samples = new Uint8Array(analyser.frequencyBinCount);
  //   let level = null;

  //   // Periodically calculate the audio level from the captured samples,
  //   // and if changed, call the callback with the new audio level.
  //   requestAnimationFrame(function checkLevel() {
  //     analyser.getByteFrequencyData(samples);
  //     const rms = that.rootMeanSquare(samples);
  //     const log2Rms = rms && Math.log2(rms);

  //     // Audio level ranges from 0 (silence) to 10 (loudest).
  //     const newLevel = Math.ceil((10 * log2Rms) / 8);
  //     if (level !== newLevel) {
  //       level = newLevel;
  //       onLevelChanged(level);
  //     }

  //     // Continue calculating the level only if the audio track is live.
  //     if (track.mediaStreamTrack.readyState === "live") {
  //       requestAnimationFrame(checkLevel);
  //     } else {
  //       requestAnimationFrame(() => onLevelChanged(0));
  //     }
  //   });
  // }

  joinCall() {
    if (this.status === "ABOUT_TO_START") {
      if (!this.isMicAvailable || !this.isCamAvailable) {
        if (!this.isMicAvailable && !this.isCamAvailable) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like pleasecoach doesn't have mic and camera permission. Therapist won't be see and hear you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
        if (!this.isMicAvailable && this.isCamAvailable) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like pleasecoach doesn't have mic permission. Therapist won't be able to hear you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
        if (!this.isCamAvailable && this.isMicAvailable) {
          this.confirmationService
            .confirm(
              "Are you sure?",
              "Seems like pleasecoach doesn't have camera permission. Therapist won't be able to see you. Would you like to join anyways?",
              "Yes",
              "No"
            )
            .subscribe((res) => {
              if (res) {
                this.checkJoin();
              }
            });
        }
      } else {
        this.checkJoin();
      }
    } else if (this.status === "NOT_STARTED") {
      this.checkJoin();
    }
  }

  checkJoin() {
    if (this.status == "ABOUT_TO_START") {
      this.status = "STARTED";
      this.initVideoCall(this.roomName);
      // this.videoService
      //   .setStats(this.roomName, { state: "CALL_STARTED" })
      //   .subscribe((res) => {});
    } else if (this.status == "NOT_STARTED") {
      let startDate = moment(this.sessionData.startDate);
      let now = moment(new Date());
      this.remainingMinutes = moment.duration(startDate.diff(now));
      this.remainingMinutes = this.remainingMinutes.asMinutes();
      if (this.remainingMinutes < 10) {
        this.status = "ABOUT_TO_START";
        const localMediaContainer = document.getElementById("inputPreview");
        this.localVideoTrack.attach(localMediaContainer);
      } else {
        this.alertService.alert(
          new BootstrapAlert(
            "You can join session when 30 minutes are remaining",
            "alert-warning"
          )
        );
      }
    }
  }

  toggleLocalVideo() {
    if (this.isVideo) {
      this.localVideoTrack.disable();
      this.localVideoTrack.stop();
      this.localVideoTrack.detach();
      this.localVideoTrack = null;
      let oldTrackIndex = this.localTracks.findIndex(
        (track) => track.kind === "video"
      );
      if (oldTrackIndex !== -1) {
        this.localTracks.splice(oldTrackIndex, 1);
      }
      // if(this.room !== null){
      //   this.room.localParticipant.unpublishTrack(this.localVideoTrack);
      // }
    } else {
      let oldTrackIndex = this.localTracks.findIndex(
        (track) => track.kind === "video"
      );
      if (oldTrackIndex !== -1) {
        this.localTracks.splice(oldTrackIndex, 1);
      }
      createLocalVideoTrack().then((track) => {
        this.localVideoTrack = track;
        this.localTracks.push(track);
        const localMediaContainer = document.getElementById("inputPreview");
        let lvt = track.attach(localMediaContainer);
        lvt.style.transform = "scale(-1,1)";
        // if(this.room !== null){
        //   console.log('room not null')
        //   this.room.localParticipant.publishTrack(this.localVideoTrack);
        // }
      });
    }
    this.isVideo = !this.isVideo;
  }

  toggleLocalAudio() {
    if (this.isAudio) {
      this.localAudioTrack.disable();
    } else {
      this.localAudioTrack.enable();
    }
    this.isAudio = !this.isAudio;
  }

  handleMediaError(error) {
    console.log("media error recieved", error);
    createLocalAudioTrack((track) => {
      this.localAudioTrack = track;
    }).catch((error) => {
      this.isMicAvailable = false;
    });
    createLocalVideoTrack((track) => {
      this.localVideoTrack = track;
    }).catch((error) => {
      this.isCamAvailable = false;
    });
    this.videoService.statsLogger({ sessionId: this.roomName, eventName: "Media Error - User", text: JSON.stringify(error), deviceInfo: this.deviceInfo }).subscribe((res) => { })
  }

  markAsStarted() {
    // console.log("started");
  }

  async initVideoCall(roomName) {

    console.log("init video call ->>>>.", this.localTracks)
    // pass local audio and video track (depending on their availibility)    
    connect(this.videoToken["token"], {
      name: roomName,
      tracks: this.localTracks ? this.localTracks : [],
      preferredVideoCodecs: ['VP8', 'H264']
    })
      .then(
        (room) => {
          let that = this;
          this.videoService.statsLogger({ sessionId: that.roomName, eventName: "Roon Joined - User", deviceInfo: this.deviceInfo, isAudioEnabled: this.localVideoTrack ? true : false, isVideoEnabled: this.localVideoTrack ? true : false }).subscribe((res) => { })
          //  if(navigator["userAgentData"]["mobile"]){
          //     document.addEventListener("visibilitychange", async () => {
          //       if (document.visibilityState === "hidden") {
          //         this.localVideoTrack.stop();
          //         let lclVideoIndex = this.localTracks.findIndex(
          //           (track) => track.kind === "video"
          //         );
          //         if (lclVideoIndex >= 0) {
          //           this.localTracks.splice(lclVideoIndex, 1);
          //         }
          //         await this.room.localParticipant.unpublishTrack(
          //           this.localVideoTrack
          //         );
          //       } else {
          //         this.localVideoTrack = await createLocalVideoTrack();
          //         this.localTracks.push(this.localVideoTrack);
          //         await this.room.localParticipant.publishTrack(
          //           this.localVideoTrack
          //         );
          //         let localMediaContainer = document.getElementById("inputPreview");
          //         let lvt = this.localVideoTrack.attach(localMediaContainer);
          //         lvt.style.transform = "scale(-1,1)";
          //       }
          //     });
          //   }
          if (this.sessionData.userStartedAt === undefined) {
            this.videoService
              .setUserStartedAt(that.roomName)
              .subscribe((res) => { });
          }

          // local joined
          this.room = room;
          function startTimer(action: string) {
            let jsTimer = undefined;
            if (action === "start" && jsTimer === undefined) {
              jsTimer = setTimeout(
                () =>
                  that.videoService
                    .setSessionAsStarted(that.roomName)
                    .subscribe((res) => { }),
                120000
              );
            } else {
              clearTimeout(jsTimer);
            }
          }
          // createLocalVideoTrack().then((track) => {
          //   this.localVideoTrack = track;
          //   const localMediaContainer =
          //     document.getElementById("inputPreview");
          //   track.attach(localMediaContainer);
          // });

          function handleSubscription(track, participant) {
            if (track.kind === "audio") {
              track.on("disabled", (track) => {
                that.remoteAudioMuted = true;
              });

              track.on("enabled", (track) => {
                that.remoteAudioMuted = false;
              });
            } else if (track.kind === "video") {
              track.on("disabled", (track) => {
                that.remoteVideoMuted = true;
              });

              track.on("enabled", (track) => {
                that.remoteVideoMuted = false;
              });
            }

            track.on("started", () => {
              if (track.kind === "audio") {
                track.attach();
              } else if (track.kind === "video") {
                that.isFirstTime = false;
                let localMediaContainer = document.getElementById("remote");
                localMediaContainer.addEventListener('fullscreenchange', (event) => {
                  if (document.fullscreenElement) {
                    that.isFullScreen = true;
                  } else {
                    that.isFullScreen = false;
                  }
                });
                let lvt = track.attach(localMediaContainer);
                lvt.style.transform = "scale(-1,1)";
              }
            });
          }

          function handleUnsubscription(track, participant) {
            track.detach();
          }

          room.localParticipant.tracks.forEach((publication) => {
            if (publication.track && publication.track.kind == "video") {
              let lvt = publication.track.attach(
                document.getElementById("inputPreview")
              );
              lvt.style.transform = "scale(-1,1)";
            }
          });
          // getting existing participants in the room
          this.room.participants.forEach((participant) => {
            that.isRemoteJoined = true;
            startTimer("start");
            // iterating over participants
            //events on participant tracks
            that.isRemoteJoined = true;
            participant.on('networkQualityLevelChanged', (networkQuality) => {
              // console.log(networkQuality)
            });
            participant.tracks.forEach((publication) => {
              // when a track is published
              publication.on("subscribed", (track) => {
                handleSubscription(track, participant);
              });

              // when a track  is unpublished
              publication.on("unsubscribed", (track) => {
                handleUnsubscription(track, participant);
              });
            });
            // future features like sharing screen and all
            participant.on("trackPublished", (publication) => {
              // console.log("new track", publication);
            });
          });

          // for participants joining later
          room.on("participantConnected", function (participant) {
            that.isRemoteJoined = true;
            that.isFirstTime = true;
            startTimer("start");
            that.isRemoteJoined = true;
            participant.on('networkQualityLevelChanged', (networkQuality) => {
              // console.log(networkQuality)
            });

            participant.tracks.forEach((publication) => {
              publication.on("subscribed", (track) => {
                handleSubscription(track, participant);
              });

              publication.on("unsubscribed", (track) => {
                handleUnsubscription(track, participant);
              });
            });

            participant.on("trackPublished", (publication) => {
              // console.log("publication published", publication);
              // for future features like screen sharing and all
            });
          });

          room.on("participantDisconnected", (participant) => {
            that.isRemoteJoined = false;
            that.isFirstTime = true;
            startTimer("reset");
          });

          room.on("disconnected", function (room, error) {
            if (error) {
              // console.log("Unexpectedly disconnected : ", error.message);
            }
            room.localParticipant.tracks.forEach((publication) => {
              publication.track.stop();
              publication.track.detach();
            });
          });

          room.on("room-ended", (participant) => { });

          // room.participants.forEach((participant) => {
          //   this.isRemoteJoined = true;
          //   participant.tracks.forEach((publication) => {
          //     if (publication.track) {
          //       publication.track.attach(document.getElementById("remote"));
          //     }
          //   });

          //   participant.on("trackSubscribed", (track) => {
          //     track.attach(document.getElementById("remote"));
          //   });
          // });
          // room.on("participantConnected", (participant) => {
          //   this.isRemoteJoined = true;
          //   participant.tracks.forEach((publication) => {
          //     if (publication.isSubscribed) {
          //       const track = publication.track;
          //       track.attach(document.getElementById("remote"));
          //     }
          //   });

          //   participant.on("trackSubscribed", (track) => {
          //     track.attach(document.getElementById("remote"));
          //     console.log('log test')
          //   });
          // });
          // console.log("end");
          // room.on("disconnected", (room) => {
          //   let keys = Array.from(room.localParticipant.videoTracks.keys());
          //   console.log(keys)
          //   let screenStream = room.localParticipant.videoTracks.get(keys[1]);
          //   screenStream.track.stop();
          //   screenStream.unpublish();
          //   console.log("disconnected", room.localParticipant.tracks);
          //   room.localParticipant.tracks.forEach((publication) => {
          //     const attachedElements = publication.track.detach();
          //     attachedElements.forEach((element) => element.remove());
          //     publication.track.stop();
          //     this.localVideoTrack.disable();
          //     this.localVideoTrack.detach();
          //     this.localVideoTrack.stop();
          //     this.localAudioTrack.stop();
          //   });
          // });
        },
        (error) => {
          console.error(`Unable to connect to Room: ${error.message}`);
        }
      )
      .catch((error) => {
        if (this.mediaErrors.includes(error.name)) {
          // Handle media error here.
          this.handleMediaError(error);
        }
      });
  }

  async retryMedia(kind) {
    this.mediaDevices = await navigator.mediaDevices.enumerateDevices();
    this.mics = this.mediaDevices.filter(
      (device) => device.kind === "audioinput"
    );
    this.cams = this.mediaDevices.filter(
      (device) => device.kind === "videoinput"
    );
    if (kind === "audio") {
      if (this.mics.length < 1) {
        this.confirmationService
          .confirm(
            "Something went wrong!",
            "We were not able to detect any mic connected to your device.",
            "Troubleshoot",
            "Close"
          )
          .subscribe((res) => {
            if (res) {
              let url = this.router.serializeUrl(
                this.router.createUrlTree([`user-dashboard/troubleshoot`])
              );
              window.open(url, "_blank");
            }
            return;
          });
      }
      createLocalAudioTrack((track) => {
        this.localAudioTrack = track;
        if (this.localTracks) {
          this.localTracks = this.localTracks.filter(
            (track) => track.kind !== "audio"
          );
          this.localTracks.push(this.localAudioTrack);
        } else {
          this.localTracks = [this.localAudioTrack];
        }
        if (this.room) {
          this.room.localParticipant.publishTrack(this.localAudioTrack);
        }
        this.isMicAvailable = true;
      }).catch((error) => {
        this.confirmationService
          .confirm(
            "Something went wrong",
            "Seems like pleasecoach does not have access to mic",
            "Troubleshoot",
            "Close"
          )
          .subscribe((res) => {
            if (res) {
              let url = this.router.serializeUrl(
                this.router.createUrlTree([`user-dashboard/troubleshoot`])
              );
              window.open(url, "_blank");
            }
          });
      });
    }
    if (kind === "video") {
      if (this.cams.length < 1) {
        this.confirmationService
          .confirm(
            "Something went wrong!",
            "We were not able to detect any camera connected to your device.",
            "Troubleshoot",
            "Close"
          )
          .subscribe((res) => {
            if (res) {
              let url = this.router.serializeUrl(
                this.router.createUrlTree([`user-dashboard/troubleshoot`])
              );
              window.open(url, "_blank");
            }
            return;
          });
      }
      createLocalVideoTrack((track) => {
        this.localVideoTrack = track;
        if (this.localTracks) {
          this.localTracks = this.localTracks.filter(
            (track) => track.kind !== "video"
          );
          this.localTracks.push(this.localVideoTrack);
        } else {
          this.localTracks = [this.localVideoTrack];
        }
        const localMediaContainer = document.getElementById("inputPreview");
        let lvt = track.attach(localMediaContainer);
        lvt.style.transform = "scale(-1,1)";
        if (this.room) {
          this.room.localParticipant.publishTrack(this.localVideoTrack);
        }
        this.isCamAvailable = true;
      }).catch((error) => {
        this.confirmationService
          .confirm(
            "Something went wrong",
            "Seems like pleasecoach does not have access to camera.",
            "Troubleshoot",
            "Close"
          )
          .subscribe((res) => {
            if (res) {
              let url = this.router.serializeUrl(
                this.router.createUrlTree([`user-dashboard/troubleshoot`])
              );
              window.open(url, "_blank");
            }
          });
      });
    }
  }

  audioToggle() {
    if (this.isAudio) {
      this.room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });
    } else {
      this.room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
    }
    this.isAudio = !this.isAudio;
  }

  videoToggle() {
    if (this.isVideo) {
      this.localVideoTrack.disable();
      this.room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });
      // this.room.localParticipant.unpublishTrack(this.localVideoTrack);
    } else {
      if (!this.localVideoTrack) {
        let oldTrackIndex = this.localTracks.findIndex(
          (track) => track.kind === "video"
        );
        if (oldTrackIndex !== -1) {
          this.localTracks.splice(oldTrackIndex, 1);
        }
        createLocalVideoTrack().then((track) => {
          this.localVideoTrack = track;
          this.localTracks.push(track);
          const localMediaContainer = document.getElementById("inputPreview");
          let lvt = track.attach(localMediaContainer);
          lvt.style.transform = "scale(-1,1)";
        });
        this.room.localParticipant.publishTrack(this.localVideoTrack);
      } else {
        this.localVideoTrack.enable();
        this.room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable();
        });
      }
      // let oldTrackIndex = this.localTracks.findIndex(
      //   (track) => track.kind === "video"
      // );
      // if (oldTrackIndex !== -1) {
      //   this.localTracks.splice(oldTrackIndex, 1);
      // }
      // createLocalVideoTrack().then((track) => {
      //   this.localVideoTrack = track;
      //   this.localTracks.push(track);
      //   const localMediaContainer = document.getElementById("inputPreview");
      //   let lvt = track.attach(localMediaContainer);
      //   lvt.style.transform = "scale(-1,1)";
      //   this.room.localParticipant.publishTrack(this.localVideoTrack);
      // });
    }
    this.isVideo = !this.isVideo;
  }

  fullScreenToggle() {
    let videoCallBox = document.getElementById("videCallBox");
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      if (videoCallBox.requestFullscreen) {
        videoCallBox.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  }


  openFullscreen() {
    let videoCallBox = document.getElementById("videCallBox");
    if (videoCallBox.requestFullscreen) {
      videoCallBox.requestFullscreen();
    } else if (videoCallBox['mozRequestFullScreen']) {
      /* Firefox */
      videoCallBox['mozRequestFullScreen()'];
    } else if (videoCallBox['webkitRequestFullscreen']) {
      /* Chrome, Safari and Opera */
      videoCallBox['webkitRequestFullscreen()'];
    } else if (videoCallBox['msRequestFullscreen']) {
      /* IE/Edge */
      videoCallBox['msRequestFullscreen()'];
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    let videoCallBox = document.getElementById("videCallBox");
    if (videoCallBox['exitFullscreen']) {
      videoCallBox['exitFullscreen()'];
    } else if (videoCallBox['mozCancelFullScreen']) {
      /* Firefox */
      videoCallBox['mozCancelFullScreen()'];
    } else if (videoCallBox['webkitExitFullscreen']) {
      /* Chrome, Safari and Opera */
      videoCallBox['webkitExitFullscreen()'];
    } else if (videoCallBox['msExitFullscreen']) {
      /* IE/Edge */
      videoCallBox['msExitFullscreen()'];
    }
  }


  exitHandler() {
    if (!this.isFullScreen) {
      this.isFullScreen = false;
    }
  }

  endCall() {
    if (this.room) {
      this.room.disconnect();
    }
    if (this.localVideoTrack || this.localAudioTrack) {
      if (this.localVideoTrack) {
        this.localVideoTrack.stop();
        this.localVideoTrack.detach();
      }
      if (this.localAudioTrack) {
        this.localAudioTrack.stop();
        this.localVideoTrack.detach();
      }
      if (this.localTracks.length > 0) {
        this.localTracks.forEach((track) => {
          track.stop();
          track.detach();
        });
      }
    }
    this.status = "CALL_ENDED";
    this.videoService.statsLogger({ sessionId: this.roomName, deviceInfo: this.deviceInfo, eventName: "Room Disconnected - User" }).subscribe((res) => { });
  }

  rejoin() {
    this.status = "ABOUT_TO_START";
    this.initPrejoinMedia();
  }
}
