<div (click)="closenavbar()">
  <div class="footerWrap">
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="footerCont">
            <img
              src="../../assets/images/PleaseCoach_Logo_white.png"
              alt="Please Coach"
              draggable="false"
              class="img-fluid"
            />
            <ul class="contactInfo">
              <li>
                <i class="fa fa-map-marker"></i>
                XYZ,Near XY Press <br />
                ABC 111111, India
              </li>
              <li class="phoneCssFix">
                <a href="mailto:hola@pleasecoach.care"
                  ><i class="fa fa-envelope"></i> hola@pleasecoach.care</a
                >
              </li>
              <li class="phoneCssFix">
                <a href="tel:+91xxxxxxxxxx"
                  ><i class="fa fa-phone"></i> +91xxxxxxxxxx</a
                >
              </li>
            </ul>
            <ul class="socialIconCont">
              <li>
                <a target="_blank" href="#"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a target="_blank" href="#"
                  ><i class="fa fa-twitter-square"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="#"><i class="fa fa-instagram"></i></a>
              </li>
              <li>
                <a target="_blank" href="#"><i class="fa fa-youtube"></i></a>
              </li>
              <li>
                <a target="_blank" href="#"><i class="fa fa-pinterest"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-6">
          <div class="quickLinkBox">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a routerLink="/corporates">For Corporates</a>
              </li>
              <li>
                <a
                  routerLink="/counseling-therapy-clinical-psychology-jobs/get-onboard"
                  >For Therapists</a
                >
              </li>
              <li>
                <a routerLink="online-counseling-psychologists/contact-us"
                  >Contact</a
                >
              </li>
              <li>
                <a routerLink="mental-health-community/about-the-company"
                  >About us</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-6">
          <div class="quickLinkBox">
            <h3>Legal Stuff</h3>
            <ul>
              <li>
                <a routerLink="/online-counseling-psychologists/disclaimer"
                  >Disclaimer</a
                >
              </li>
              <li>
                <a routerLink="/online-counseling-psychologists/privacy-policy"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a
                  routerLink="/online-counseling-psychologists/terms-of-service"
                  >Terms Of Service</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-8 col-12">
          <div class="callbackBox">
            <form [formGroup]="callbackForm" (ngSubmit)="onSubmit()">
              <h3>We are here to make sure that you are always happy</h3>
              <div class="callbackInput">
                <input
                  type="text"
                  placeholder="Enter your phone number"
                  class="form-control one"
                  formControlName="phone"
                  ng2TelInput
                  (hasError)="checkError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="{
                    initialCountry: userCountryCode.countryCode
                  }"
                  [ngClass]="{
                    'has-error':
                      !callbackForm.controls.phone.valid &&
                      callbackForm.controls.phone.touched,
                    'has-success':
                      callbackForm.controls.phone.valid &&
                      callbackForm.controls.phone.touched
                  }"
                />
                <a type="submit" class="primaryBtn" (click)="onSubmit()">
                  Request Callback
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footerWarningBox">
    <div class="container">
      If you are in a life-threatening situation – DO NOT use this site. Use
      these
      <a
        target="_blank"
        href="https://en.wikipedia.org/wiki/List_of_suicide_crisis_lines"
        >resources</a
      >
      to get immediate help. <br />
      ©Please Coach. All rights reserved.
    </div>
  </div>
</div>
