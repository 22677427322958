<main (click)="closenavbar()">
    <section class="hero-section">
        <div class="container">
            <div class="hero-text-wrapper">
                <h1 class="hero-title">Talk. Resolve. Heal.</h1>
                <p class="hero-desc">
                    Online Counselling Therapy With Psychologists <br />
                    Anytime, Anywhere, Any device.
                </p>
                <a
                    *ngIf="isLoggedin && isDashboardRedirect"
                    routerLink="user-dashboard"
                    class="primaryBtn getStartedBtn"
                    id="getStartedBanner1"
                    (click)="getStarted()"
                    >Get Started</a
                >
                <a
                    *ngIf="isLoggedin && !isDashboardRedirect"
                    routerLink="online-counseling-psychologists/plans-pricing"
                    class="primaryBtn getStartedBtn"
                    id="getStartedBanner1"
                    (click)="getStarted()"
                    >Get Started</a
                >
                <a
                    *ngIf="!isLoggedin"
                    [routerLink]="['sign-up']"
                    [queryParams]="{
                        redirect:
                            'online-counseling-psychologists/plans-pricing'
                    }"
                    class="primaryBtn getStartedBtn"
                    id="getStartedBanner1"
                    (click)="getStarted()"
                    >Get Started</a
                >
            </div>
        </div>
    </section>
    <section class="counselling-session-section">
        <div class="container">
            <h2 class="section-title title-with-line">Choose Help.</h2>
            <hr class="hrDivider" />
            <div class="row">
                <div class="col-md-12 col-lg-6 text-center slider-col">
                    <div class="counselorHolder fade-in">
                        <ngx-slick-carousel
                            class="counsellor-slider me-md-3"
                            #slickModal="slick-carousel"
                            [config]="slideConfig"
                            (init)="slickInit($event)"
                            (breakpoint)="breakpoint($event)"
                            (afterChange)="afterChange($event)"
                            (beforeChange)="beforeChange($event)"
                        >
                            <div
                                class="item sldie"
                                ngxSlickItem
                                *ngFor="let therapist of therapistsData"
                                routerLink="../community-talk-therapists-detail/{{
                                    therapist.id
                                }}"
                            >
                                <img
                                    src="../../../../assets/images/homepage-slides/{{
                                        therapist.fileName
                                    }}"
                                    alt="{{ therapist.name }}"
                                    class="slider-img w-100 borderRadius"
                                />
                                <div class="slider-info">
                                    <h3 class="counsellor-name">
                                        {{ therapist.name }}
                                    </h3>
                                    <p class="counsellor-title">
                                        {{ therapist.title }}
                                    </p>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                        <div class="zIndexForBtn">
                            <a
                                class="primaryBtn counselorBtn"
                                routerLink="our-counselors"
                                (click)="getAllCounsellors()"
                                >View All Counselors</a
                            >
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="counsellor-session-text ms-md-3">
                        <h3 class="section-inner-title firstSectionMargin">
                            Counselling Therapy Sessions With Licensed &
                            Verified Experts
                        </h3>
                        <p class="section-inner-desc">
                            Highly qualified team of some of the best names in
                            psychology who deliver improved well-being to you.
                            Carefully vetted through a rigorous selection
                            process. Trained and experienced in all
                            psychotherapy techniques.
                        </p>
                        <div class="session-icons-wrapper d-flex">
                            <div class="session from-left slide-in">
                                <fa-icon [icon]="faVideo"></fa-icon>
                                <span class="session-name d-block"
                                    >Video Session</span
                                >
                            </div>
                            <div class="session fade-in">
                                <i class="fa fa-microphone"></i>
                                <span class="session-name d-block"
                                    >Audio Session</span
                                >
                            </div>
                            <div class="session from-right slide-in">
                                <fa-icon [icon]="faCommentAlt"></fa-icon>
                                <span class="session-name d-block"
                                    >Chat Session</span
                                >
                            </div>
                        </div>
                        <p class="tag-line">
                            English And All Regional Indian Languages
                        </p>
                        <p class="tag-line">100% Private & Secure Platform</p>
                        <p class="tag-line">24/7 Support</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="mental-health-section">
        <div class="custom-shape-divider-top-1639975762">
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
            >
                <path
                    d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                    class="shape-fill"
                ></path>
            </svg>
        </div>
        <div class="container">
            <h2 class="section-title">
                Specialized Help For Your Mental Health
            </h2>
            <div class="row fade-in">
                <div class="col-12">
                    <div class="mental-health-tag-wrapper">
                        <a class="tag">Anxiety & Stress</a>
                        <a class="tag">Depression</a>
                        <a class="tag">Relationship Issues</a>
                        <a class="tag">OCD</a>
                        <a class="tag">Bipolar Disorder</a>
                        <a class="tag">Psychosis</a>
                        <a class="tag">Grief & Loss</a>
                        <a class="tag">Trauma</a>
                        <a class="tag">Couple Therapy</a>
                        <a class="tag">LGBTQIA+</a>
                        <a class="tag">ADHD & Teenage Issues</a>
                        <a class="tag">Sexual Issues</a>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-3">
                    <div class="specialBtn fade-in">
                        <a
                            *ngIf="isLoggedin && isDashboardRedirect"
                            routerLink="user-dashboard"
                            class="primaryBtn"
                            id="getStartedBanner2"
                            >Get Started</a
                        >
                        <a
                            *ngIf="isLoggedin && !isDashboardRedirect"
                            routerLink="online-counseling-psychologists/plans-pricing"
                            class="primaryBtn"
                            id="getStartedBanner2"
                            >Get Started</a
                        >
                        <a
                            *ngIf="!isLoggedin"
                            id="getStartedBanner2"
                            [routerLink]="['sign-up']"
                            [queryParams]="{
                                redirect:
                                    'online-counseling-psychologists/plans-pricing'
                            }"
                            class="primaryBtn"
                            >Get Started</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="report-section">
        <div class="custom-shape-divider-top-1639980888">
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
            >
                <path
                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                    class="shape-fill"
                ></path>
            </svg>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-4 order-2 from-left slide-in">
                    <img
                        appLazyLoad
                        src="../../../../assets/images/free_report_asset.webp"
                        alt="Test image"
                        class="test-img"
                    />
                </div>
                <div
                    class="col-md-7 order-1 d-flex align-items-center from-right slide-in"
                >
                    <div class="report-text-content ms-md-5">
                        <h3 class="section-inner-title">
                            Take a Free Mental Health Test Today! Know How You
                            Feel.
                        </h3>
                        <p class="section-inner-desc reportSectionTxt">
                            Scientifically validated standard assessments -
                            quickest way to determine if you are suffering from
                            symptoms of any mental health disorder.
                        </p>
                        <a
                            routerLink="free-report"
                            class="primaryBtn report-btn"
                            (click)="getFreereport()"
                            >Get a Free Report</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="connect-section">
        <div class="custom-shape-divider-top-1639980888">
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
            >
                <path
                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                    class="shape-fill"
                ></path>
            </svg>
        </div>
        <div class="container">
            <div class="row">
                <div
                    class="col-md-6 col-lg-5 d-flex align-items-center text-center text-md-start"
                >
                    <div class="connect-text-content">
                        <h3 class="section-inner-title">
                            Have Questions? Please CoachSupport Has You Covered.
                        </h3>
                        <p class="section-inner-desc reportSectionTxt">
                            Get help in choosing the right counselling therapist
                            - specialization, preferred time, language & gender
                        </p>
                        <a
                            (click)="openChat()"
                            class="primaryBtn connect-btn mt-5 mt-md-4 mt-xl-5"
                            >Connect Now</a
                        >
                    </div>
                </div>
                <div
                    class="col-md-6 col-lg-7 d-flex align-items-center mt-5 mt-md-0 justify-content-center fade-in"
                >
                    <img
                        appLazyLoad
                        src="../../../../assets/images/have_questions_asset.webp"
                        alt="Connect image"
                        class="connect-img"
                    />
                </div>
            </div>
        </div>
    </section>
    <section class="how-it-works-section" id="how-it-works">
        <div class="container">
            <h2 class="section-title title-with-line">How It Works</h2>
            <hr class="hrDivider howItWorksMargin" />
            <div class="row">
                <div class="col-md-3 text-center">
                    <div class="step">
                        <img
                            appLazyLoad
                            src="../../../../assets/images/Artboard-1.svg"
                            alt=""
                            class="step-img"
                        />
                        <div class="step-name-wrapper">
                            <h3 class="step-name">Book A Counselling Plan</h3>
                        </div>
                        <fa-icon
                            class="step-arrow"
                            [icon]="faLongArrowAltRight"
                        ></fa-icon>
                    </div>
                </div>
                <div class="col-md-3 text-center">
                    <div class="step">
                        <img
                            appLazyLoad
                            src="../../../../assets/images/Artboard-2.svg"
                            alt=""
                            class="step-img"
                        />
                        <div class="step-name-wrapper">
                            <h3 class="step-name">
                                Choose A Counselor & Pick A Time
                            </h3>
                        </div>
                        <fa-icon
                            class="step-arrow"
                            [icon]="faLongArrowAltRight"
                        ></fa-icon>
                    </div>
                </div>
                <div class="col-md-3 text-center">
                    <div class="step">
                        <img
                            appLazyLoad
                            src="../../../../assets/images/Artboard-3.svg"
                            alt=""
                            class="step-img"
                        />
                        <div class="step-name-wrapper">
                            <h3 class="step-name">
                                Join The Session & Chat Afterwards
                            </h3>
                        </div>
                        <fa-icon
                            class="step-arrow"
                            [icon]="faLongArrowAltRight"
                        ></fa-icon>
                    </div>
                </div>
                <div class="col-md-3 text-center">
                    <div class="step mb-0">
                        <img
                            appLazyLoad
                            src="../../../../assets/images/Artboard-4.svg"
                            alt=""
                            class="step-img"
                        />
                        <div class="step-name-wrapper">
                            <h3 class="step-name">
                                Reschedule Or Change Counselor If Needed
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="chat-now-section d-flex">
        <div class="container d-flex align-items-center justify-content-center">
            <p class="chat-now-text">
                Need more details? See how Please Coachworks
            </p>
            <a
                routerLink="online-counseling-psychologists/how-it-works"
                class="primaryBtn chat-now-btn"
                >Click here</a
            >
        </div>
    </div>
    <section class="our-platform-section">
        <h2 class="section-title title-with-line">Our Platform</h2>
        <hr class="hrDivider" />
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div
                    class="col-lg-12 col-xl-6 pe-lg-5 d-flex align-items-center justify-content-center"
                >
                    <div class="our-plateform-img-wrapper">
                        <img
                            appLazyLoad
                            src="../../../../assets/images/our-platform-devices.webp"
                            alt=""
                            class="our-platform-img w-100"
                        />
                        <div
                            class="download-app-wrapper d-md-flex align-items-center text-center text-md-start"
                        >
                            <a
                                target="_blank"
                            >
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Google-Play.webp"
                                    alt=""
                                    class="store-icon from-left slide-in"
                                />
                            </a>
                            <a
                                target="_blank"
                            >
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/App-Store.webp"
                                    alt=""
                                    class="store-icon from-right slide-in"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-6 ps-lg-5 mt-3">
                    <div class="row mt-5">
                        <div
                            class="col-md-6 col-lg-4 col-xl-6 from-left slide-in"
                        >
                            <div class="platform-content-wrapper">
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Artboard-7.svg"
                                    alt=""
                                    class="platform-icon"
                                />
                                <h4 class="platform-content-name">
                                    Secure platform
                                </h4>
                                <p class="platform-content-text">
                                    HIPAA compliant & 256-Bit SSL Secure
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-md-6 col-lg-4 col-xl-6 from-right slide-in"
                        >
                            <div class="platform-content-wrapper">
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Artboard-8.svg"
                                    alt=""
                                    class="platform-icon"
                                />
                                <h4 class="platform-content-name">
                                    Verified Experts
                                </h4>
                                <p class="platform-content-text">
                                    Masters or higher degree, solid practice
                                    experience & 2 step background verification.
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-md-6 col-lg-4 col-xl-6 from-left slide-in"
                        >
                            <div class="platform-content-wrapper">
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Artboard-6.svg"
                                    alt=""
                                    class="platform-icon"
                                />
                                <h4 class="platform-content-name">
                                    Nuanced Matching
                                </h4>
                                <p class="platform-content-text">
                                    Counselling therapist is recommended based
                                    on your major concerns, preferred time,
                                    language, gender & age.
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-md-6 col-lg-4 col-xl-6 from-right slide-in"
                        >
                            <div class="platform-content-wrapper">
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Artboard-9.svg"
                                    alt=""
                                    class="platform-icon"
                                />
                                <h4 class="platform-content-name">
                                    Mood Journal
                                </h4>
                                <p class="platform-content-text">
                                    Track your mental well-being by logging in
                                    your mood & journaling your thoughts.
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-md-6 col-lg-4 col-xl-6 from-left slide-in"
                        >
                            <div class="platform-content-wrapper">
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Artboard-5.svg"
                                    alt=""
                                    class="platform-icon"
                                />
                                <h4 class="platform-content-name">
                                    Happy Place
                                </h4>
                                <p class="platform-content-text">
                                    Unlimited wellness content blogs,
                                    meditation, breathing exercises, educational
                                    videos & podcasts
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-md-6 col-lg-4 col-xl-6 from-right slide-in"
                        >
                            <div class="platform-content-wrapper">
                                <img
                                    appLazyLoad
                                    src="../../../../assets/images/Artboard-10.svg"
                                    alt=""
                                    class="platform-icon"
                                />
                                <h4 class="platform-content-name">
                                    Discussion Forum
                                </h4>
                                <p class="platform-content-text">
                                    Ask anything & initiate discussions with a
                                    community of like-minded users and
                                    psychologists.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="learnMoreHolder">
                <a
                    class="primaryBtn"
                    *ngIf="isLoggedin"
                    routerLink="user-dashboard"
                    >Learn More</a
                >
                <a class="primaryBtn" *ngIf="!isLoggedin" routerLink="sign-up"
                    >Learn More</a
                >
            </div>
        </div>
    </section>
    <section class="testimonial-section">
        <h2 class="section-title">
            What People Say About <span>Please Coach</span>
        </h2>
        <div class="container">
            <div class="testimonial-slider-wrapper">
                <ngx-slick-carousel
                    class="carousel testimonial-slider"
                    #slickModal1="slick-carousel"
                    [config]="testimonialsSlideConfig"
                    (init)="slickInit($event)"
                    (breakpoint)="breakpoint($event)"
                    (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)"
                >
                    <div
                        class="testimonial-slider-item"
                        ngxSlickItem
                        *ngFor="let review of userReviews"
                    >
                        <img
                            appLazyLoad
                            src="../../../../assets/images/blog/quote.webp"
                            alt=""
                            class="quote-sign"
                        />
                        <p class="client-name">{{ review.name }}</p>
                        <!-- Client Name -->
                        <span class="client-title">{{ review.work }}</span>
                        <!-- Client Title -->
                        <div class="client-stars">
                            <fa-icon [icon]="faStar"></fa-icon>
                            <fa-icon [icon]="faStar"></fa-icon>
                            <fa-icon [icon]="faStar"></fa-icon>
                            <fa-icon [icon]="faStar"></fa-icon>
                            <fa-icon [icon]="faStar"></fa-icon>
                        </div>
                        <p class="client-review">
                            {{ review.text }}
                        </p>
                    </div>
                </ngx-slick-carousel>
                <button
                    aria-label="Previous"
                    class="prev"
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                    (click)="prevSlideTestimonials()"
                >
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </button>
                <button
                    aria-label="Next"
                    class="next"
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                    (click)="nextSlideTestimonials()"
                >
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </button>
            </div>
        </div>
    </section>
    <section class="happy-place-section" id="happy-place">
        <h2 class="section-title">
            Your <span class="orangeTxt">Happy Place</span>
        </h2>
        <p class="section-desc text-center">
            Join our community and get access to exclusive content on mental
            wellness.
        </p>
        <div class="container">
            <div class="happy-place-slider-wrapper">
                <div class="happy-place-slider-wrapper">
                    <ngx-slick-carousel
                        class="carousel"
                        #slickModal2="slick-carousel"
                        [config]="testimonialsSlideConfig"
                        (init)="slickInit($event)"
                        (breakpoint)="breakpoint($event)"
                        (afterChange)="afterChange($event)"
                        (beforeChange)="beforeChange($event)"
                    >
                        <div
                            class="happy-place-slider-item"
                            ngxSlickItem
                            *ngFor="let blog of latestBlogs"
                            routerLink="/blog-view/{{ blog?.slug }}"
                        >
                            <img
                                src="{{ blog.featuredURL }}"
                                class="w-100"
                                alt=""
                            />
                            <div class="place-info">
                                <p class="place-tag">By {{ blog.owner }}</p>
                                <h3 class="place-title">{{ blog.title }}</h3>
                                <span class="place-date">{{
                                    blog?.createdAt | date : 'EEEE, dd MMMM'
                                }}</span>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-3">
                    <div class="blogButtonHolder">
                        <button
                            aria-label="Previous"
                            class="prevBlog"
                            (click)="prevSlideBlog()"
                        >
                            <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </button>
                        <button
                            aria-label="Next"
                            class="nextBlog"
                            (click)="nextSlideBlog()"
                        >
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="blogViewHolder">
                <a routerLink="blog" class="primaryBtn">View All</a>
            </div>
        </div>
    </section>
</main>
<div>
    <div class="container">
        <div class="boxRound subsBox">
            <div class="secondaryHeading">
                <h2>
                    The latest mental health news and tips, delivered to your
                    inbox weekly.
                </h2>
                <hr />
            </div>
            <div class="subsEmail formRound1">
                <form [formGroup]="subsForm" (ngSubmit)="onSubmit()">
                    <div class="d-flex">
                        <input
                            type="text"
                            placeholder="Email"
                            formControlName="email"
                            class="form-control emailBox"
                        />
                        <button
                            type="submit"
                            class="primaryBtn subsButton ml-3"
                        >
                            Subscribe
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
