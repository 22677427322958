<div (click)="closenavbar()" class="topMenuMargin">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <i class="fa fa-exclamation-triangle"></i
            ><strong> Disclamer: </strong>This tool should be used for screening
            and monitoring symptom severity and cannot replace a clinical
            assessment and diagnosis. You are encouraged to share these results
            with a mental health professional for a better understanding.
        </p>
        <div class="report-user mt-3">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p><strong>Your Score:</strong></p>
            <p>Your overall Y-BCOS score is {{ bcosScore }}.</p>
            <p><strong>Your Result:</strong></p>
            <p>
                Your responses indicate {{ bcosFinal }} symptoms of OCD.
                <span class="scrollTxt" (click)="scrollToElem('target')"
                    >Detailed Analysis</span
                >
            </p>
            <br />
        </div>
    </div>
    <div class="container graph-section mt-3">
        <div>
            <div>
                <p class="mb-3" *ngIf="bcosScore >= 0 && bcosScore <= 7">
                    Your results may not be a sign of concern, but read on to
                    find out a more detailed analysis of your score as well as a
                    few suggestions from Please Coach that you can always use to
                    improve your lifestyle.
                </p>
                <p class="mb-3" *ngIf="bcosScore >= 8 && bcosScore <= 23">
                    Your results may be slightly concerning, but do not worry
                    too much - Please Coach has got you covered. We offer a
                    variety of services that you can access at any time,
                    anywhere.
                </p>
                <p class="mb-3" *ngIf="bcosScore >= 24 && bcosScore <= 40">
                    Seeing the report can be overwhelming, but Please Coach has
                    got you covered. We offer a variety of services that you can
                    access at any time, anywhere.
                </p>
            </div>
            <h5>Understanding Your OCD Assessment</h5>
            <div class="graph-right">
                <img src="../../../assets/images/ocd-report-graph.webp" />
            </div>
        </div>
    </div>
    <div class="container scores-section mb-4">
        <div class="about-scores report-user">
            <h3 class="mb-3 mt-3">Your Score Interpretation</h3>
            <p class="mb-3">
                The possible range of scores is 0 to 40, where 0 indicates
                ‘subclinical’ symptoms, and 40 indicates ‘severe’ OCD.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="bcosScore >= 0 && bcosScore <= 7"
            >
                A score of 0 to 7 is categorized under subclinical symptoms.
                This means that the intensity of your symptoms is not at a
                detectable level. It is best to consult a healthcare provider
                who can guide you best in terms of the next steps in case you
                are feeling the presence of symptoms. We at Please Coach have
                some of the best mental health professionals who can not only
                help you interpret your result based on your symptoms but can
                also help develop a treatment plan.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="bcosScore >= 8 && bcosScore <= 15"
            >
                A score of 8 to 15 is categorized under mild symptoms. In this
                condition, you might be able to control your symptoms for a good
                amount of time and would be able to hide it from others. Dealing
                with symptoms of OCD can be exhausting, regardless of the
                intensity. You can perform progressive muscle relaxation on a
                regular basis to experience some relief. You can always get in
                touch with our mental health professionals if you feel
                overwhelmed by the situation.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="bcosScore >= 16 && bcosScore <= 23"
            >
                Scores between the range of 16 to 23 indicate moderate symptoms.
                People falling under this category of scores might rationalize
                their behaviours as not that bad and continue living their life.
                It is also possible that symptoms of OCD have not started
                seeping into your daily life routine as yet with this score. If
                it gets too much for you to handle, you can get in touch with
                professionals from Please Coach who are trained to deal with
                OCD.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="bcosScore >= 24 && bcosScore <= 31"
            >
                Scores between the range of 24 to 31 imply severe symptoms. At
                this point, the symptoms of the condition might have started
                interfering with your daily routine and would have made your
                life difficult. It is tough but regular psychotherapy can help
                you ease symptoms so as to continue with your daily routine.
                Several therapy techniques are designed to help deal with OCD
                and Please Coach can provide you with that.
            </p>
            <p
                class="para_scores mb-3"
                id="target"
                *ngIf="bcosScore >= 32 && bcosScore <= 40"
            >
                Extreme symptoms of OCD lie between the score range of 32 to 40.
                It is best to get in touch with a trained mental health
                professional who can help you deal with the symptoms. A
                combination of medicines and psychotherapy would be the best
                route for severe symptoms. It is advised to consult a healthcare
                provider to interpret your report based on your symptoms.
            </p>
        </div>
    </div>
    <div class="container about-scores">
        <div class="report-user">
            <h3 class="">Detailed Analysis</h3>
            <p class="mb-3">
                The Yale-Brown Obsessive Compulsive Scale (Y-BOCS) is made up of
                2 subscale - Obsessions and Compulsions.
            </p>
            <p class="mb-3">
                <strong>Obsessions: </strong>These are intrusive, unwanted and
                unnecessary thoughts that are capable of causing distress,
                anxiety, stress, and other emotions. Your score on this subscale
                is {{ obsessionScore }}, on a range of 0 to 20, where 0 is the
                lowest score and 20 is the highest score.
            </p>
            <p class="mb-3">
                <strong>Compulsions: </strong>These are rituals that people do
                in order to get rid of the intrusive thoughts and their after
                effects. To shut the feelings of anxiety, depression, stress,
                disgust that one feels as a result of the thoughts, people often
                indulge in rituals which provide them momentary relief. Your
                score on this subscale is {{ compulsiveScore }}, on a range of 0
                to 20, where 0 is the lowest score and 20 is the highest score.
            </p>
            <p>
                Let's work on these scores to experience ease in symptom
                intensity.
            </p>
        </div>
    </div>
    <div class="container">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
</div>
