<div (click)="closenavbar()" class="topMenuMargin">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <i class="fa fa-exclamation-triangle"></i
            ><strong> Disclamer: </strong>This tool should be used for screening
            and monitoring symptom severity and cannot replace a clinical
            assessment and diagnosis. You are encouraged to share these results
            with a mental health professional for a better understanding.
        </p>
        <div class="report-user mt-3">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <br />
            <p><strong>Score:</strong></p>
            <p>
                Your ACE score is {{ aceScore }}. Each ACE is associated with an
                increased risk of health problems in the future.
                <span class="scrollTxt" (click)="scrollToElem('target')"
                    >Detailed Analysis</span
                >
            </p>
            <!-- <button type="button" class="btn-report" (click)="reportData()">
        Email Report
      </button> -->
        </div>
    </div>
    <div class="container graph-section mt-3">
        <div>
            <div>
                <p class="mb-3">
                    Seeing the report can be overwhelming, but Please Coach has
                    got you covered. We offer a variety of services that you can
                    access at any time, anywhere.
                </p>
                <h5 class="mb-3">Your Score Interpretation</h5>
            </div>
            <p class="md-3">
                There is no bifurcation of scores in ACE. An increase in the
                score by even one point implies a rougher childhood for the
                person.
            </p>
        </div>
    </div>
    <div class="container mb-3">
        <div class="about-scores mb-3">
            <p class="para_scores">
                A high ACE score is a risk factor for several physical ailments
                like Diabetes Mellitus, Hypertension, heart diseases, etc, and
                also many psychiatric and mental health illnesses like Major
                depressive disorders, anxiety disorders, substance use
                disorders, etc. It can also lead to you committing a violent act
                or being a victim in the future.
            </p>
            <p class="para_scores">
                Early ACEs can lead to impaired neurocognitive development and
                future behavioural issues.
            </p>
            <p class="para_scores">
                You might have had a traumatizing childhood and teen years but
                it’s time to stop being a victim and take a stand for yourself
                and for your own mental health. Processing a difficult childhood
                is difficult and we at Please Coach would like to support you as
                you get through this.
            </p>
            <p class="para_scores" id="target">
                Note: However, this test cannot assess the chronicity, degree or
                intensity of exposure to ACEs which impacts the risk for health
                problems. Also, the equivalent presence of Positive Life
                Experiences (PLEs) is not considered in this test and it can
                reduce the overall risk for all these health problems in the
                presence of ACEs.
            </p>
        </div>
        <div>
            <h5 class="mb-3">Detailed Analysis</h5>
            <p class="mb-3">
                Adverse Childhood Experiences (ACE’s) Questionnaire assesses 10
                types of childhood trauma divided into 2 components.
            </p>
            <p class="mb-3">
                <strong>Personal : </strong>This includes physical abuse, sexual
                abuse, verbal abuse, emotional neglect and physical neglect.
                Your score for this component is {{ subScore1 }}.
            </p>
            <p class="mb-3">
                <strong>Other family members : </strong>Mother being a victim of
                domestic violence, alcoholic parent, a family member in jail,
                family member diagnosed with a mental health disorder,
                disappearance of a parent through death, abandonment or divorce.
                Your score for this component is {{ subScore2 }}.
            </p>
            <p class=""><strong>Note: </strong></p>
            <p>
                Having completed this assessment, you have taken the first step.
                Now, it's time for you to follow the path towards a better and
                richer mental health. The ACE questionnaire is used as a
                screening tool by healthcare professionals and social workers to
                determine the various health risks.
            </p>
            <p>
                IT CAN ONLY BE ADMINISTERED TO THOSE 18 YEARS AND ABOVE- NOT
                SUITABLE FOR CHILDREN AND ADOLESCENTS.
            </p>
        </div>
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
</div>
