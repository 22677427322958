<div class="container-fluid" (click)="closenavbar()">
    <div style="display: flex; align-items: center; margin-bottom: 20px">
        <span
            ><a
                routerLink="../profile"
                style="
                    font-size: 16px;
                    color: #445e93;
                    cursor: pointer;
                    text-decoration: underline;
                "
                >Profile</a
            ></span
        >
        <span style="font-size: 16px">&nbsp;>&nbsp;</span>
        <span style="font-size: 16px; color: #808080">Calendar</span>
    </div>
    <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
    <div class="row justify-content-center d-flex">
        <div class="col-md-12 col-sm-12 col-12">
            <ng-template [ngIf]="isLoader">
                <ngx-skeleton-loader
                    appearance="line"
                    [theme]="{
                        'border-radius': '5px',
                        height: '80px',
                        width: '100%',
                        border: '1px solid white',

                        'margin-top': '10px',
                        'margin-bottom': '10px'
                    }"
                >
                </ngx-skeleton-loader>
                <div class="row justify-content-center d-flex">
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row justify-content-center d-flex">
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row justify-content-center d-flex">
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                </div>

                <div class="row justify-content-center d-flex">
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-2">
                        <ngx-skeleton-loader
                            appearance="line"
                            [theme]="{
                                'border-radius': '5px',
                                height: '80px',
                                width: '100%',
                                border: '1px solid white',

                                'margin-top': '10px',
                                'margin-bottom': '8px'
                            }"
                        >
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="isMonthChangeLoader">
                <div
                    class="col-md-12"
                    style="display: flex; padding-right: 0px; padding-left: 0px"
                >
                    <div
                        class="col-md-6 col-sm-12 col-lg-6 calendar-title"
                        style="padding: 0px"
                    >
                        <h2 style="margin-bottom: 0px; font-size: 28px">
                            {{ currentMonthDate | date : 'MMMM' }}
                            {{ currentMonthDate | date : 'y' }}
                        </h2>
                    </div>
                    <div class="col-md-4 col-sm-12 col-lg-4"></div>
                    <div
                        class="col-md-1 col-sm-1 col-lg-1"
                        style="
                            padding: 0px;
                            text-align: end;
                            padding-right: 5px;
                        "
                    >
                        <button
                            class="btn btn-primary button-shimmer"
                            [disabled]="true"
                            (click)="onPrevClick()"
                        >
                            <span
                                class="fc-icon fc-icon-chevron-left button-icon"
                                style="color: #2c3e50; font-size: 24px"
                            ></span>
                        </button>
                    </div>
                    <div
                        class="col-md-1 col-sm-1 col-lg-1"
                        style="
                            padding: 0px;
                            text-align: end;
                            padding-right: 12px;
                        "
                    >
                        <button
                            [disabled]="true"
                            class="btn btn-primary button-shimmer"
                            (click)="onNextClick()"
                        >
                            <span
                                class="fc-icon fc-icon-chevron-right button-icon"
                                style="color: #2c3e50; font-size: 24px"
                            ></span>
                        </button>
                    </div>
                </div>
                <ngx-skeleton-loader
                    count="30"
                    appearance="line"
                    [theme]="{
                        'border-radius': '5px',
                        height: '90px',
                        width: '121px',
                        border: '1px solid white',
                        'margin-right': '10px',
                        'margin-top': '8px',
                        'margin-bottom': '8px'
                    }"
                >
                </ngx-skeleton-loader>
            </ng-template>
            <div>
                <full-calendar
                    [ngStyle]="{
                        visibility:
                            isLoader || isMonthChangeLoader
                                ? 'hidden'
                                : 'visible',
                        display: isMonthChangeLoader ? 'none' : ''
                    }"
                    class="myCalendar-slots"
                    #calendar
                    [options]="calendarOptions"
                ></full-calendar>
            </div>
        </div>
    </div>
    <form [formGroup]="timeZoneForm">
        <div class="insideFormDivTags">
            <div>
                <div>
                    <h3>Time Zone</h3>
                    <ng-select
                        class="form-control table"
                        formControlName="TimeZone"
                        [items]="TimeZone"
                        (change)="changeTimeZone($event)"
                    ></ng-select>
                </div>
            </div>
        </div>
    </form>
    <div class="row slotsSection" *ngIf="selectedDate">
        <div class="col-lg-5 col-md-6">
            <h5>Add Slots</h5>
            <div class="slotsDropDown formRound">
                <form [formGroup]="addSlotForm">
                    <ng-select
                        class="form-control table"
                        formControlName="slot"
                        *ngIf="selectedDate != null"
                    >
                        <ng-option
                            *ngFor="let slot of slotsArray"
                            value="{{ slot.slot }}"
                            >{{ slot.startTime }} - {{ slot.endTime }}
                        </ng-option>
                    </ng-select>
                </form>
                <button class="buttonTheme1" (click)="addSlot()">Add</button>
            </div>
        </div>
        <div class="col-lg-7 col-md-6 slotsInfo">
            <h5>Available Slots for {{ selectedDate | date : 'longDate' }}</h5>
            <div class="availableSlots">
                <button
                    (click)="deleteSlot(slot)"
                    class="slotButton"
                    *ngFor="let slot of slots"
                >
                    <p>
                        {{ slot.startTime }} - {{ slot.endTime }}
                        <span>
                            <i class="fa fa-trash" aria-hidden="true"></i
                        ></span>
                    </p>
                </button>
            </div>
        </div>
    </div>
    <br />
    <div class="textAlign">
        <a routerLink="../therapist-calender" class="thm-2">Holiday Schedule</a>
    </div>
</div>
