<div class="secPadding" (click)="closenavbar()">
    <div class="container">
        <div class="secondaryHeading">
            <h2>Find A Plan That's Right For You</h2>
            <hr />
            <p>
                Cancel anytime. Same day full refund. Change your therapist
                anytime. No questions asked
            </p>
        </div>
        <div class="planBoxWrap">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 planRes">
                    <div class="planBox">
                        <div class="planContWrap">
                            <div class="planIcon">
                                <img
                                    src="../../../../assets/images/plans-pricing/explore-plan-1.svg"
                                    class="img-fluid"
                                    draggable="false"
                                    alt=""
                                />
                            </div>
                            <div class="planConts">
                                <h2>Diagnosis</h2>
                                <h5>1 Session</h5>
                                <div class="iconsHolder">
                                    <img
                                        src="../../../../assets/images/video.png"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                    <img
                                        src="../../../../assets/images/mic.svg"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                    <img
                                        src="../../../../assets/images/chat.png"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                </div>
                            </div>
                            <div class="planConts">
                                <h3>What to Expect ?</h3>
                                <p>
                                    Understand the root cause and child's
                                    emotional & learning needs
                                </p>
                            </div>
                            <div class="planPrice">
                                <h2>INR 999</h2>
                                <p>( Price per session INR 999 )</p>
                            </div>
                            <div class="planBuy">
                                <a
                                    (click)="buyNow('plan_e')"
                                    class="buttonTheme1"
                                    >Buy Now</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 planRes">
                    <div class="planBox">
                        <div class="planContWrap">
                            <div class="planIcon">
                                <img
                                    src="../../../../assets/images/plans-pricing/childcounselling-remedy.png"
                                    class="img-fluid"
                                    draggable="false"
                                    alt=""
                                />
                            </div>
                            <div class="planConts">
                                <h2>Remedy</h2>
                                <h5>4 Sessions</h5>
                                <div class="iconsHolder">
                                    <img
                                        src="../../../../assets/images/video.png"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                    <img
                                        src="../../../../assets/images/mic.svg"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                    <img
                                        src="../../../../assets/images/chat.png"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                </div>
                            </div>
                            <div class="planConts">
                                <h3>What to Expect ?</h3>
                                <p>
                                    Instilling sense of security, confidence, &
                                    positive behaviour
                                </p>
                            </div>
                            <div class="planPrice">
                                <h2>INR 3799</h2>
                                <p>( Price per session INR 850 )</p>
                            </div>
                            <div class="planBuy">
                                <a
                                    (click)="buyNow('plan_f')"
                                    class="buttonTheme1"
                                    >Buy Now</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 planRes">
                    <div class="planBox">
                        <div class="planContWrap">
                            <div class="planIcon">
                                <img
                                    src="../../../../assets/images/plans-pricing/mind-your-mind-plan-3.svg"
                                    class="img-fluid"
                                    draggable="false"
                                    alt=""
                                />
                            </div>
                            <div class="planConts">
                                <h2>Reinforcement</h2>
                                <h5>12 Sessions</h5>
                                <div class="iconsHolder">
                                    <img
                                        src="../../../../assets/images/video.png"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                    <img
                                        src="../../../../assets/images/mic.svg"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                    <img
                                        src="../../../../assets/images/chat.png"
                                        alt=""
                                        draggable="false"
                                        class="img-fluid"
                                    />
                                </div>
                            </div>
                            <div class="planConts">
                                <h3>What to Expect ?</h3>
                                <p>Habit formation & behaviour modification</p>
                            </div>
                            <div class="planPrice">
                                <h2>INR 9599</h2>
                                <p>( Price per session INR 800 )</p>
                            </div>
                            <div class="planBuy">
                                <a
                                    (click)="buyNow('plan_g')"
                                    class="buttonTheme1"
                                    >Buy Now</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plansTip">
            Sessions do not expire & have lifetime validity. <br />
            Once bought you can schedule it at your convenience.
        </div>
        <div class="plansTip">
            Couldn't find the plan you are looking for? <br />
            No worries, please drop us a call on
            <a href="tel:+91xxxxxxxxxx">+91xxxxxxxxxx</a>, and we'll help you
            out.
        </div>
    </div>
    <div class="faqHolder">
        <div class="faqHeading">
            <h2>Frequently Asked Questions</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="faqQue">
                                <h4>How many sessions does my child need?</h4>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <p>
                                To see meaningful change in behaviour, a child
                                needs at least 12 sessions. However, you can get
                                a diagnosis & treatment plan in 1-3 sessions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-2">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="faqQue">
                                <h4>
                                    My child is under 5 years of age. Will
                                    online therapy work?
                                </h4>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <p>
                                Yes. Child psychologists use approaches that are
                                effective for the age group of the child.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-2">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <h4>
                                Will I get feedback on the child’s progress?
                            </h4>
                        </div>
                        <div class="flip-card-back">
                            <p>
                                Yes, we will share feedback with you as & when
                                required & you can also share your concerns with
                                us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-2">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <h4>Is there an EMI option?</h4>
                        </div>
                        <div class="flip-card-back">
                            <p>
                                Yes, you just have to choose it while checking
                                out.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <h4>Can I attend the session with my child?</h4>
                        </div>
                        <div class="flip-card-back">
                            <p>
                                If the child is under 13 years, yes. If the
                                child is older than that, then no, unless it is
                                a family session. We have to respect the child’s
                                privacy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
