<div class="topMenuMargin" (click)="closenavbar()">
    <div class="container mt-3">
        <p class="disclaimerTxt">
            <strong>
                <i class="fa fa-exclamation-triangle"></i> Disclamer: </strong
            >This tool should be used for screening and monitoring symptom
            severity and cannot replace a clinical assessment and diagnosis. You
            are encouraged to share these results with a mental health
            professional for a better understanding.
        </p>
        <div class="report-user mt-2">
            <h3>
                Getting to know yourself is the first step towards a mentally
                healthier life.
            </h3>
        </div>
    </div>
    <div class="report-section">
        <div class="img-section">
            <img src="../../../assets/images/meet.svg" />
        </div>
        <div class="report-user">
            <h3>Congratulations on taking that first step!</h3>
            <p class="mt-3"><strong>Your Score:</strong></p>
            <p>Psychological Distress: {{ psychologicalDistressScore }}</p>
            <p class="mt-3"><strong>Your Result:</strong></p>
            <p>
                Your score indicates a
                <span
                    >{{ psychologicalDistressPhrase }}
                    <span class="scrollTxt" (click)="scrollToElem('target')"
                        >Detailed Analysis</span
                    >
                </span>
            </p>
            <br />
            <!-- <button type="button" class="btn-report" (click)="reportData()">
          Email Report
        </button> -->
        </div>
    </div>

    <div class="container graph-section mt-3">
        <div>
            <div>
                <p
                    class="mb-3"
                    *ngIf="
                        psychologicalDistressScore >= 10 &&
                        psychologicalDistressScore <= 19
                    "
                >
                    Your results may not be a sign of concern, but read on to
                    find out a more detailed analysis of your score. Please
                    Coach is here to assist you in improving your mental
                    well-being.
                </p>
                <p
                    class="mb-3"
                    *ngIf="
                        psychologicalDistressScore >= 20 &&
                        psychologicalDistressScore <= 29
                    "
                >
                    Your results may be slightly concerning, but do not worry
                    too much - Please Coach has got you covered. We offer a
                    variety of services that you can access at any time,
                    anywhere.
                </p>
                <p
                    class="mb-3"
                    *ngIf="
                        psychologicalDistressScore >= 30 &&
                        psychologicalDistressScore <= 50
                    "
                >
                    Seeing the report can be overwhelming, but Please Coach has
                    got you covered. We offer a variety of services that you can
                    access at any time, anywhere.
                </p>
            </div>

            <h5 class="mt-3">Understanding your Psychological Distress</h5>
            <div class="graph-right">
                <img
                    src="../../../assets/images/free-report-graphs/psychological-distress.png"
                />
            </div>
        </div>
    </div>

    <div class="container scores-section mb-4">
        <div class="about-scores report-user">
            <h5>Your Score Interpretation</h5>
            <p class="mb-3">
                The possible range of scores is from 10 to 50, where low scores
                indicate minimal levels of psychological distress, whereas high
                scores indicate increasing levels of psychological distress
                experienced over the past 4 weeks.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="
                    psychologicalDistressScore >= 10 &&
                    psychologicalDistressScore <= 19
                "
            >
                From the graph above, it is clear that a score of 10 to 19
                indicates that you may currently not be experiencing significant
                feelings of distress. We all react differently to various
                situations in our life, and so a minimal level of distress is
                normal in any person’s life, and should be maintained so as to
                not increase unexpectedly.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="
                    psychologicalDistressScore >= 20 &&
                    psychologicalDistressScore <= 24
                "
            >
                A score of 20 to 24 indicates that you may be experiencing mild
                levels of distress that may reflect any events or current
                situations that you may be going through in any of the
                individual or interpersonal domains of your life, such as work,
                relationships, health, finances, or others. Though this should
                not be taken as an alarming score, when it comes to something
                impacting your mental wellbeing, it is best to nip it in the bud
                - the best way to do so is to seek necessary help whenever you
                can. This can include talking to your friends or family about
                your troubles, but if not that, then seeking appropriate mental
                healthcare is advisable.
            </p>
            <p
                class="para_scores mb-3"
                *ngIf="
                    psychologicalDistressScore >= 25 &&
                    psychologicalDistressScore <= 29
                "
            >
                A score of 25 to 29 indicates that you may be experiencing
                moderate levels of distress that reflect any events or current
                situations that you may be going through in any of the
                individual or interpersonal domains of your life, such as work,
                relationships, health, finances, or others. You may have
                negative reactions to challenges in your daily life, which might
                impair your ability to cope with the changes caused by normal
                life activities. It may also have an effect on your decisions
                and actions related to anything happening in your life
                currently. If the levels of distress increase, you may face
                trouble sleeping, eating, concentrating, and have memory
                problems, amongst other hindrances to your daily functioning. It
                is suggested to seek help as soon as you can, so as to prevent
                any severe repercussions caused by increasing distress. You can
                get in touch with the mental health practitioners at Please
                Coach to help you identify what the cause of this distress is,
                as well as find interventions that work for you, with the
                assurance that you will receive the best help.
            </p>
            <p
                class="para_scores mb-3"
                id="target"
                *ngIf="psychologicalDistressScore >= 30"
            >
                A score of 30 to 50 indicates that you are experiencing high
                levels of distress due to any events or current situations that
                you may be going through in any of the individual or
                interpersonal domains of your life, such as work, relationships,
                health, finances, or others. If you are experiencing high levels
                of psychological distress, you are more likely to develop
                chronic problems that affect them emotionally and physically.
                This is because you are feeling the mental emotions of distress
                and your body’s physical reaction to stress for a long time.
                However, that does not mean it cannot be helped. Dealing with
                stressful situations alone, especially those that lead to severe
                distress, can be a tough task that is close to impossible; so,
                working with a psychologist or psychotherapist is the one way
                you can deal with your emotions effectively. The mental health
                practitioners at Please Coach can help guide you through this
                trying time in your life, in order to develop strategies and use
                techniques to reduce stress.
            </p>
        </div>
    </div>

    <!-- TODO: Add CSS -->
    <div class="container about-scores">
        <div class="">
            <h5 class="">Detailed Analysis</h5>
            <p class="mb-3">
                The two main subscales of the Kessler Psychological Distress
                Scale are Depression and Anxiety. Though the K10 only measures
                psychological distress on a global scale, these two subscales
                can be identified as contributing factors to the distress
                caused. However, they should not be used as individual
                indicators of that particular mental health condition.
            </p>
            <p class="mb-3">
                <strong>Depression scale:</strong> People in this subscale might
                notice a persistent low mood accompanied by an increased
                disinterest in activities. Feeling so upset that not even your
                favourites can cheer you up, constantly feeling fatigued and,
                because of it, having a decrease in productivity, and feeling
                low in self-esteem are some of the things that you may be
                experiencing. Based on your response, your score is
                {{ depressionScore }}.
            </p>
            <p class="mb-3">
                <strong>Anxiety scale:</strong> People in this subscale might
                experience restlessness and have trouble concentrating because
                of it, may experience difficulty falling asleep, constant
                worrying and increased feelings of nervousness to the point that
                calming down is difficult and requires a lot of effort. Based on
                your response, your score is {{ anxietyScore }}.
            </p>
        </div>
    </div>
    <div class="container">
        <div class="scores-btn-section">
            <button class="contact-us-btn">
                <a routerLink="../../../our-counselors">Book A Consultation</a>
            </button>
            <button class="counselling-therapists-btn">
                <a routerLink="../../">Take Another Free Report</a>
            </button>
        </div>
    </div>
    <!-- <div class="disclaimer-section">
        <div class="head-disclaimer">
          <h3>Disclaimer</h3>
          <p>
            This tool should be used for screening and monitoring symptom severity
            and cannot replace a clinical assessment and diagnosis. You are
            encouraged to share these results with a mental health professional.
            Please Coach disclaims any liability, loss or risk incurred as a consequence
            , directly or indirectly, from the use of this assessment & it's
            results.
          </p>
        </div>
      </div> -->
</div>
