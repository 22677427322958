import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FullCalendarComponent, CalendarOptions } from "@fullcalendar/angular";
import { AvailableSlotsService } from "../available-slots.service";
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ProfileService } from '../profile.service';
import { OurCounsellorsService } from 'src/app/guest/our-counsellors.service';
import { BookingService } from '../booking.service';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog.service';
import { COMMON_DATA } from 'src/app/shared/common';
import { FormControl, FormGroup } from '@angular/forms';
import { BootstrapAlert, BootstrapAlertService } from 'src/app/shared/ng-bootstrap-alert/ng-bootstrap-alert';
import clevertap from "clevertap-web-sdk";
import { convertTZ } from 'src/app/shared/util/dateFunctions';

@Component({
  selector: 'app-view-calendar',
  templateUrl: './view-calendar.component.html',
  styleUrls: ['./view-calendar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewCalendarComponent implements OnInit {
  therapistId = "";
  therapist;
  isBookSlotLoader = false;
  events = [];
  eventsAndSlots;
  slots = [];
  selectedDate = null;
  selectedSlot = null;
  calendarStartDate
  calendarEndDate
  TimeZone = COMMON_DATA.TimeZone;
  isDisabled = false;
  actualSlotsData
  isLoading = true;
  isShimmer = true;
  isChangeMonthLoader = false;
  timeZoneForm = new FormGroup({
    TimeZone: new FormControl(""),
  });
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    headerToolbar: {
      center: "",
      start: "title",
      end: "prev next",
    },
    events: [],
    dateClick: this.handleDayClicks.bind(this),
    eventClick: this.handleEventClicks.bind(this),
    datesSet: this.handleDates.bind(this),
    showNonCurrentDates: false,
    eventBackgroundColor: 'transparent',
    eventTextColor: '#6d9566',
    eventBorderColor: 'transparent',
    selectable: true
  };
  constructor(private actRoute: ActivatedRoute,
    private availableSlotsService: AvailableSlotsService,
    private profileService: ProfileService,
    private counselorService: OurCounsellorsService,
    private bookingService: BookingService,
    private router: Router,
    private confirmationService: ConfirmationDialogService,
    private alertService: BootstrapAlertService) { }

  ngOnInit() {
    this.actRoute.paramMap.subscribe((params) => {
      this.therapistId = params.get("id");
      this.counselorService.getCounsellorById(this.therapistId).subscribe((res) => {
        this.therapist = res
        this.isLoading = false;
        if (this.therapist["status"] === "DEACTIVE") {
          this.isDisabled = true;
        }
        this.bookingService.checkplan().subscribe((res) => {
          if (!res['isAllowed']) {
            this.router.navigateByUrl('online-counseling-psychologists/plans-pricing?counselor=' + this.therapistId)
          }
        })
      })
    });
    // this.profileService.getBookingAvailable().subscribe((res) => {
    //   if( !res['availableBooking'] || !res['availableBooking']['bookingAvailable'] || res['availableBooking']['bookingAvailable'] <= 0){
    //     this.router.navigateByUrl('plans-and-pricing?counselor=' + this.therapistId)
    //   } 
    // })
  }

  getProfileData(_callBack) {
    if (!this.timeZoneForm.value.TimeZone) {
      this.profileService.getProfile().subscribe((res) => {
        if (res["TimeZone"]) {
          this.timeZoneForm.setValue({
            TimeZone: res["TimeZone"],
          });
        } else {
          let timeZone = momentTz.tz.guess();
          let zoneIndex = this.TimeZone.findIndex((tz) => tz.tzCode === timeZone);
          if (zoneIndex !== -1) {
            this.timeZoneForm.setValue({
              TimeZone: this.TimeZone[zoneIndex],
            });
          }
        }
        _callBack();
      })
    }
    else {
      _callBack();
    }
  }

  startLoader() {
    this.isChangeMonthLoader = !this.isShimmer && true
  }
  handleDates(args: any) {

    this.handleDataConversion(args)
  }
  handleDataConversion(args: any) {
    this.getProfileData(() => {
      this.slots = []
      this.events = [];
      this.selectedDate = null;
      this.selectedSlot = null;
      this.calendarEndDate = args.endStr
      this.calendarStartDate = args.startStr
      let startDate = this.dateFormatterForQuery(args.startStr)
      let endDate = this.dateFormatterForQuery(args.endStr)
      this.startLoader()
      this.availableSlotsService
        .getSlotsV2(this.therapistId, startDate, endDate)
        .subscribe((data) => {
          this.actualSlotsData = data["availability"]
          let now = moment();
          let slotsData = [];
          data["availability"].forEach((availability) => {
            availability["slots"].forEach((slot) => {
              let slotData = moment.utc(
                availability["date"] + " " + slot,
                "DD_MM_YYYY HH:mm"
              );
              let slotString = momentTz
                .tz(slotData, this.timeZoneForm.value.TimeZone.tzCode)
                .format("DD_MM_YYYY hh:mm A");
              let dateNSlot = slotString.split(" ");
              let dateIndex = slotsData.findIndex(
                (date) => String(date.date) === String(dateNSlot[0])
              );
              let isHolidayExist = data['holidays'] ? data['holidays'].includes(dateNSlot[0]) : false
              if (Number(dateIndex) === -1) {
                slotsData.push({
                  date: dateNSlot[0],
                  slots: [dateNSlot[1] + ' ' + dateNSlot[2]],
                  isHoliday: isHolidayExist
                });
              } else {
                slotsData[dateIndex]["slots"].push(dateNSlot[1] + ' ' + dateNSlot[2]);
              }
            });
          });
          this.eventsAndSlots = slotsData;
          this.eventsAndSlots.forEach((element) => {
            let date = element.date;
            let dayEvent = moment(date, "DD_MM_YYYY");
            let isHolidayExist = data['holidays'] ? data['holidays'].includes(moment(dayEvent).format("DD_MM_YYYY")) : false
            if (moment(now).isSameOrBefore(dayEvent, "day")) {
              this.events = this.events.concat({
                id: moment(dayEvent).format("DD_MM_YYYY"),
                title: '',
                start: dayEvent["_d"],
                allDay: true,
                isHoliday: isHolidayExist,
                className: [isHolidayExist ? 'not-avail' : 'cal-eventClass']
              });
            }
          });
          this.events.push({
            id: '',
            title: '',
            start: new Date(),
            allDay: true,
            className: ['cal-todayClass']
          })
          this.calendarOptions.events = this.events;

          this.isShimmer = false;
          this.isLoading = false;
          this.isChangeMonthLoader = false
        });
    });
  }

  dateFormatterForQuery(date: string) {
    date = date.substring(0, 10);
    let splitDate = date.split("-")
    let finalDateString = ''
    finalDateString = splitDate[2] + '_' + splitDate[1] + '_' + splitDate[0]
    return finalDateString;
  }

  handleDayClicks(args: any) {
    this.selectedDate = null;
    this.selectedSlot = null;
    this.findSlotByDay(args.dateStr)
  }

  handleEventClicks(args: any) {
    this.selectedDate = null;
    this.selectedSlot = null;
    this.findSlotById(args.event._def.publicId)
  }

  findSlotById(id) {
    this.slots = [];
    let eventSlots = this.eventsAndSlots.find(event => event.date == id);
    this.selectedDate = moment(eventSlots.date, 'DD_MM_YYYY')
    let dateObj = moment(id, "DD_MM_YYYY");
    if (eventSlots) {
      if (eventSlots) {
        var currentDateObj = moment.utc().format();
        var currentDate = convertTZ(currentDateObj, this.timeZoneForm.value.TimeZone.tzCode).toString()
        let dateDifference = dateObj.diff(currentDate, 'days')
        let curentDatemoment = moment(currentDate)
        if (dateDifference == 0 && dateObj.format('D') == curentDatemoment.format('D')) {
          // if date is today and check slot time is a past time or will come in future
          let filteredSlots = this.doFilterSlotsAvailale(eventSlots.slots, curentDatemoment)
          let soretedTimes = this.sortSlots(filteredSlots);
          this.slots = soretedTimes;
        }
        else if (dateDifference >= 0) {
          // if date is today or future and check slot time is a past time or will come in future
          let sortSlots = this.sortSlots(eventSlots.slots);
          this.slots = sortSlots;
        }
        else {
          // if date is past and check slot time is a past time or will come in future

        }




      }
    }
  }
  doFilterSlotsAvailale(slots, currentDate) {
    return slots && slots.filter((item) => {
      let current_am_pm = currentDate.format('A')
      let currentHour = parseInt(currentDate.format('hh'))
      let slotHours = parseInt(item.split(':')[0])
      if (current_am_pm.toLowerCase() == 'am' && item.toLowerCase().includes('pm')) {
        return item
      }
      if (current_am_pm.toLowerCase() == 'am' && item.toLowerCase().includes('am')) {
        if (currentHour === 12 && currentHour > slotHours) {
          return item
        }
        if (slotHours > currentHour) {
          if (slotHours !== 12) {
            return item
          }
        }
      }
      if (current_am_pm.toLowerCase() == 'pm' && item.toLowerCase().includes('pm')) {
        if (currentHour === 12 && currentHour > slotHours) {
          return item
        }

        if (slotHours > currentHour) {
          if (slotHours !== 12) {
            return item
          }
        }
      }
    })
  }
  sortSlots(slots) {
    //@ts-ignore
    return slots && slots.sort((a, b) => new Date('1970/01/01 ' + a.toUpperCase()) - new Date('1970/01/01 ' + b.toUpperCase()));
  }

  findSlotByDay(dateStr) {
    this.slots = [];
    let dateObj = moment(dateStr, "YYYY-MM-DD");
    this.selectedDate = dateObj['_d']
    let eventSlots = this.eventsAndSlots.find(event => moment(event.date, 'DD_MM_YYYY').isSame(dateObj, "day"))
    if (eventSlots && !eventSlots?.isHoliday) {
      var currentDateObj = moment.utc().format();
      var currentDate = convertTZ(currentDateObj, this.timeZoneForm.value.TimeZone.tzCode).toString()
      let dateDifference = dateObj.diff(currentDate, 'days')
      let curentDatemoment = moment(currentDate)
      if (dateDifference == 0 && dateObj.format('D') == curentDatemoment.format('D')) {
        let filteredSlots = this.doFilterSlotsAvailale(eventSlots.slots, curentDatemoment)
        let sortedTimes = this.sortSlots(filteredSlots);
        this.slots = sortedTimes;
      }
      else if (dateDifference >= 0) {
        let sortedTimes = this.sortSlots(eventSlots.slots);
        this.slots = sortedTimes;
      }
    }
  }

  selectSlot(slot) {
    this.selectedSlot = slot
  }

  getOffset(timeZoneOffset) {
    let minutes = timeZoneOffset.split(':')[1]
    let hoursInt = parseInt(timeZoneOffset.split(':')[0])
    let minuteInt = parseInt(minutes)
    let getOffsetMinutes = minuteInt / 60
    let convertedOffset = hoursInt + getOffsetMinutes
    return convertedOffset * 60
  }
  bookNow() {
    this.confirmationService.confirm('Are you sure you want to book this session?', '', 'Yes', 'No, select other slot').subscribe((res) => {
      if (res) {
        let dateStr = moment(this.selectedDate).format("DD_MM_YYYY")
        let slotDate = moment(dateStr + ' ' + this.selectedSlot, "DD_MM_YYYY hh:mm A").toDate()
        var offset = this.getOffset(this.timeZoneForm.value.TimeZone.offset)
        var utcDate = moment(new Date(slotDate.getTime() - offset * 60000)).format("DD_MM_YYYY HH:mm")

        let timeSlotHour = moment(this.selectedSlot, ["h:mm A"]).format("HH");
        // let time = moment(this.selectedSlot, ["h:mm A"])
        let timeSlotMinute = moment(this.selectedSlot, ["h:mm A"]).format("mm");
        let date = new Date(this.selectedDate)
        date.setHours(parseInt(timeSlotHour))
        date.setMinutes(parseInt(timeSlotMinute))
        let momentDate = moment(date)
        console.log(moment(momentDate).format('DD MM YYYY HH:mm'))
        let dateNSlot = utcDate.split(" ")
        let sessionObj = {
          therapistId: this.therapistId,
          selectedDate: dateNSlot[0],
          // slot: `${date.getUTCHours()}:${date.getUTCMinutes()}`,
          slot: dateNSlot[1],
        }
        // console.log(sessionObj)
        // let startDate = moment(dateNSlot[0] + ' ' + dateNSlot[1],'DD_MM_YYYY HH:mm')['_d']
        // startDate.setHours(Number(dateNSlot[1].split(":")[0]))
        // sessionObj['startDate'] = startDate
        // let endDate = moment(dateNSlot[0] + ' ' + dateNSlot[1],'DD_MM_YYYY HH:mm')['_d']
        // endDate.setHours(Number(dateNSlot[1].split(":")[0]))
        // endDate.setHours(endDate.getHours() + 1)
        // sessionObj['endDate'] = endDate
        this.isBookSlotLoader = true
        this.bookingService.bookSession(sessionObj).subscribe((res) => {
          this.router.navigate(["session-booked/" + res['booking']['_id']])
          clevertap.event.push("Session Booked", {
            "Therapist Name": this.therapist.firstname + " " + this.therapist.lastname,
            "Session Date": momentTz.tz(this.selectedDate, "Asia/Calcutta").format("DD/MM/YYYY"),
            "Session Time": momentTz.tz(slotDate, "Asia/Calcutta").format("hh:mm A")
          })
          this.isBookSlotLoader = false
        }, (err) => this.isBookSlotLoader = false)
      }
    })
  }

  changeTimeZone(event) {
    if (event) {
      this.profileService
        .updateProfile(this.timeZoneForm.value)
        .subscribe((res) => {
          this.alertService.alert(
            new BootstrapAlert(
              "Time Zone was updated successfully",
              "alert-success"
            )
          );
          console.log(this.selectedDate)
          let args = {
            startStr: this.calendarStartDate,
            endStr: this.calendarEndDate
          }
          this.handleDataConversion(args)
        });
    }
  }
}
