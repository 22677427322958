<div (click)="closenavbar()">
    <div class="container-fluid" *ngIf="firstPart">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-12">
                        <br />
                        <div class="sectionHeading">
                            <h1><b>Child ADHD Assessment</b></h1>
                            <hr />
                        </div>
                    </div>
                    <div class="col-md-3 mt-2 paddingCss">
                        <div class="divTagCss">
                            <img
                                class="img-fluid imageCss"
                                src="../../../assets/images/CHILD ADHD.png"
                            />
                        </div>
                    </div>
                    <div class="col-md-8 paddingCss">
                        <div class="textInside">
                            <p>
                                Welcome to Please Coach's online mental health
                                test. It's free, quick, anonymous & 100%
                                confidential.
                            </p>
                            <br />
                            <h2 class="htag"><b>Introduction</b></h2>
                            <p>
                                The ADHD Rating Scale - IV: Home Version is a
                                parent-report measure, reports of which have to
                                be shown to a healthcare provider who can guide
                                best. Developed by DuPaul and colleagues in
                                1998, it consists of 18 items with a 4-point
                                likert rating scale. Parents have to rate on
                                behalf of their children in the age group of 5
                                to 17 years.
                            </p>
                            <h2 class="htag mt-4"><b>Instruction</b></h2>
                            <p>
                                In this parent-report questionnaire, there are
                                no right or wrong answers. Circle the number
                                that best describes your child’s home behaviour
                                over the past 6 months. Think carefully and take
                                your time to answer the questions
                            </p>
                        </div>
                        <form [formGroup]="userForm1" name="userForm1">
                            <h5 class="htag mt-4"><b>Demographics </b></h5>
                            <h5>Sex</h5>
                            <ul class="unstyled options_list">
                                <div class="align-options">
                                    <li>
                                        <label class="labelCss">
                                            <span class="options_listing"
                                                >Male</span
                                            >
                                            <br />
                                            <input
                                                type="radio"
                                                class="align-input"
                                                value="Male"
                                                name="sex"
                                                formControlName="sex"
                                            />
                                        </label>
                                    </li>
                                </div>
                                <div class="align-options">
                                    <li>
                                        <label class="labelCss">
                                            <span class="options_listing"
                                                >Female</span
                                            >
                                            <br />
                                            <input
                                                type="radio"
                                                class="align-input"
                                                value="Female"
                                                name="sex"
                                                formControlName="sex"
                                            />
                                        </label>
                                    </li>
                                </div>
                            </ul>
                            <div
                                *ngIf="
                                    userForm1.controls.sex.errors?.required &&
                                    userForm1.touched
                                "
                                class="errorContainer"
                            >
                                <p>Sex is required.</p>
                            </div>
                            <h5 class="mt-4">Age</h5>
                            <input
                                id="number"
                                formControlName="age"
                                name="age"
                                placeholder="0"
                                min="5"
                                max="17"
                                class="form-control"
                            />
                            <div
                                *ngIf="userForm1.controls.age.errors?.required"
                                class="errorContainer"
                            >
                                <p>Age of the child.</p>
                            </div>
                            <div
                                *ngIf="userForm1.controls.age.errors?.pattern"
                                class="errorContainer"
                            >
                                <p>Age should a number.</p>
                            </div>
                            <div
                                *ngIf="
                                    (userForm1.controls.age.errors?.min ||
                                        userForm1.controls.age.errors?.max) &&
                                    userForm1.controls.age.touched
                                "
                                class="errorContainer"
                            >
                                <p>Age should be between 5 to 17.</p>
                            </div>
                            <h5 class="mt-4">Grade</h5>
                            <input
                                id="text"
                                formControlName="grade"
                                name="grade"
                                placeholder="1st"
                                class="form-control"
                            />
                            <div
                                *ngIf="
                                    userForm1.controls.grade.errors?.required &&
                                    userForm1.touched
                                "
                                class="errorContainer"
                            >
                                <p>Grade is required.</p>
                            </div>
                            <h5 class="mt-4">Completed By</h5>
                            <ul class="unstyled options_list">
                                <div class="align-options">
                                    <li>
                                        <label class="labelCss">
                                            <span class="options_listing"
                                                >Mother</span
                                            >
                                            <br />
                                            <input
                                                type="radio"
                                                class="align-input"
                                                value="Mother"
                                                name="completedBy"
                                                formControlName="completedBy"
                                            />
                                        </label>
                                    </li>
                                </div>
                                <div class="align-options">
                                    <li>
                                        <label class="labelCss">
                                            <span class="options_listing"
                                                >Father</span
                                            >
                                            <br />
                                            <input
                                                type="radio"
                                                class="align-input"
                                                value="Father"
                                                name="completedBy"
                                                formControlName="completedBy"
                                            />
                                        </label>
                                    </li>
                                </div>
                                <div class="align-options">
                                    <li>
                                        <label class="labelCss">
                                            <span class="options_listing"
                                                >Guardian</span
                                            >
                                            <br />
                                            <input
                                                type="radio"
                                                class="align-input"
                                                value="Guardian"
                                                name="completedBy"
                                                formControlName="completedBy"
                                            />
                                        </label>
                                    </li>
                                </div>
                                <div class="align-options">
                                    <li>
                                        <label class="labelCss">
                                            <span class="options_listing"
                                                >Grandparent</span
                                            >
                                            <br />
                                            <input
                                                type="radio"
                                                class="align-input"
                                                value="Grandparent"
                                                name="completedBy"
                                                formControlName="completedBy"
                                            />
                                        </label>
                                    </li>
                                </div>
                            </ul>
                            <div
                                *ngIf="
                                    userForm1.controls.completedBy.errors
                                        ?.required && userForm1.touched
                                "
                                class="errorContainer"
                            >
                                <p>
                                    Please specify who is completing the
                                    assessment
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="submitButtonClass text-center">
            <button
                class="thm-2"
                (click)="ShowHide()"
                [style.opacity]="userForm1.valid ? '1' : '.5'"
                [disabled]="!userForm1.valid"
            >
                Next
            </button>
        </div>
        <br />
    </div>
    <div class="container-fluid" *ngIf="secondPart">
        <form [formGroup]="freeReportForm" name="freeReportForm">
            <ul class="unstyled main-body">
                <div class="question-section">
                    <h5>{{ questions[currentIndex].questionText }}</h5>
                    <ul
                        class="unstyled options_list"
                        id="question_{{ questions[currentIndex].qno }}"
                    >
                        <div
                            *ngFor="
                                let answer of answers[currentIndex];
                                let idx1 = index
                            "
                            class="align-options"
                        >
                            <li>
                                <label class="labelCss">
                                    <span class="options_listing">{{
                                        answer.answerText
                                    }}</span>
                                    <br />
                                    <input
                                        type="radio"
                                        class="align-input"
                                        value="{{ idx1 }}"
                                        name="{{
                                            questions[currentIndex].label
                                        }}"
                                        formControlName="{{
                                            questions[currentIndex].label
                                        }}"
                                    />
                                </label>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="form-btn">
                    <button
                        *ngIf="currentIndex == 0"
                        type="button"
                        class="btn-back"
                        (click)="instruction1()"
                    >
                        BACK
                    </button>
                    <button
                        *ngIf="currentIndex > 0"
                        type="button"
                        class="btn-back"
                        (click)="previousPage()"
                    >
                        BACK
                    </button>
                    <button
                        *ngIf="questions.length - 1 != currentIndex"
                        class="btn btn-submit thm-2"
                        (click)="currentIndexInc()"
                    >
                        Next
                    </button>
                    <button
                        *ngIf="questions.length - 1 == currentIndex"
                        class="btn btn-submit"
                        type="submit"
                        data-toggle="modal"
                        data-target="#myModal"
                        (click)="showModal()"
                        [disabled]="!freeReportForm?.valid"
                    >
                        Submit
                    </button>
                    <div class="container-progress">
                        <div
                            [hidden]="!(progressPercentage > 0)"
                            class="progressBarCls"
                            [ngStyle]="{ width: progressPercentage + '%' }"
                        ></div>
                    </div>
                </div>
            </ul>
        </form>
    </div>
    <div class="modal fade" id="myModal" *ngIf="!hideModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Free Report</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        &times;
                    </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <p>
                        Great! You're almost done. Please enter these details to
                        generate report.
                    </p>
                    <form [formGroup]="userForm2" name="userForm2">
                        <input
                            id="name"
                            formControlName="fullname"
                            name="fullname"
                            placeholder="xyz"
                            [ngClass]="{
                                'has-error':
                                    !userForm2.controls.fullname.valid &&
                                    userForm2.controls.fullname.touched,
                                'has-success':
                                    userForm2.controls.fullname.valid &&
                                    userForm2.controls.fullname.touched
                            }"
                        />
                        <input
                            type="email"
                            id="email"
                            formControlName="email"
                            name="email"
                            placeholder="xyz@gmail.com"
                            [ngClass]="{
                                'has-error':
                                    !userForm2.controls.email.valid &&
                                    userForm2.controls.email.touched,
                                'has-success':
                                    userForm2.controls.email.valid &&
                                    userForm2.controls.email.touched
                            }"
                        />
                        <div class="phoneHolder">
                            <input
                                type="tel"
                                id="phone"
                                formControlName="phone"
                                name="phone"
                                class="form-control phoneControl"
                                [ngClass]="{
                                    'has-error':
                                        userForm2.controls.phone.errors
                                            ?.invalidPhone ||
                                        (userForm2.controls.phone.errors
                                            ?.required &&
                                            userForm2.controls.phone.touched)
                                }"
                                ng2TelInput
                                (hasError)="checkError($event)"
                                (ng2TelOutput)="getNumber($event)"
                                (intlTelInputObject)="telInputObject($event)"
                                (countryChange)="onCountryChange($event)"
                                [ng2TelInputOptions]="{
                                    initialCountry: userCountryCode.countryCode
                                }"
                            />
                        </div>
                        <div
                            class="errorContainer"
                            *ngIf="
                                userForm2.controls.phone.errors?.invalidPhone
                            "
                        >
                            <p>Please enter a valid phone number.</p>
                        </div>
                    </form>
                    <div class="modal-footer">
                        <button
                            type="submit"
                            class="btn-submit"
                            value="Submit"
                            (click)="onSubmit()"
                            data-dismiss="modal"
                            [disabled]="!userForm2?.valid"
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
