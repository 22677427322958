<div>
    <div>
        <div class="logoHolderCus">
            <img
                src="../../../assets/images/logo.png"
                class="img-fluid"
                alt=""
            />
        </div>
        <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
        <div class="homePageBannerWrap">
            <div class="BannerImageHolder pcView">
                <img
                    src="../../../assets/images/conversation-heads-banner.jpg"
                    draggable="false"
                    class="img-fluid"
                    alt="Please Coachmental health conversation with counselor therapist concept"
                />
            </div>
            <div class="BannerImageHolder mobileView">
                <img
                    src="../../../assets/images/campaign/new-square.png"
                    draggable="false"
                    alt="Please Coachmental health conversation with counselor therapist concept"
                />
            </div>
            <div class="bannerTextHolder">
                <h3>Talk. Resolve. Heal.</h3>
                <h1>Online counselling therapy with top psychologists</h1>
            </div>
        </div>
    </div>
    <div class="pleasecoachMessagepart">
        <div class="container">
            <div class="row therapistRow">
                <div class="left-column col-md-7">
                    <div class="backgroundImgMesg">
                        <h3>
                            We have the best professionals from all over the
                            world to help you
                        </h3>
                    </div>
                </div>
                <div class="right-column col-md-5">
                    <div class="imageWrap">
                        <img
                            src="../../../../assets/images/talkTherapist.svg"
                            class="img-fluid"
                            draggable="false"
                            alt="Therapist having a session with client through mobile"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ourCounsellorsMainHolder">
        <div class="container-fluid">
            <div class="secondaryHeading">
                <h2>Our Counselling Therapists</h2>
                <hr />
            </div>
            <div class="languageHolder">
                <h2>Select a language</h2>
                <div class="languageBoxHolder">
                    <div
                        *ngFor="let language of languages"
                        class="languageBox"
                        [ngClass]="{ isSelected: selectedLanguage == language }"
                        (click)="selectLanguage(language)"
                    >
                        <p>{{ language }}</p>
                    </div>
                </div>
            </div>
            <div class="row fontSize justify-content-center">
                <div
                    class="col-sm-12 col-md-12 col-lg-6"
                    *ngFor="let therapist of therapists"
                >
                    <div class="teambookbox d-flex flex-wrap">
                        <div class="teamBoxImage teamImageCss">
                            <img
                                src="{{ therapist?.imageURL }}"
                                draggable="false"
                                class="img-fluid"
                            />
                        </div>
                        <div class="teamContentBox">
                            <h4>
                                {{ therapist?.firstname }}
                                {{ therapist?.lastname }}
                            </h4>
                            <div class="teameachContentDiv">
                                <h5>
                                    <i class="fa fa-star qualiﬁcationStar"></i>
                                    Qualiﬁcation
                                </h5>
                                <div
                                    *ngIf="
                                        therapist?.jobqualification ==
                                            'Other (Please Mention)';
                                        else elsepart
                                    "
                                >
                                    <p>
                                        {{
                                            therapist?.jobqualificationOtherOptionAnswer
                                        }}
                                    </p>
                                </div>
                                <ng-template #elsepart>
                                    <p>{{ therapist?.jobqualification }}</p>
                                </ng-template>
                                <div *ngIf="!therapist?.jobqualification">
                                    <p>-</p>
                                </div>
                            </div>
                            <div class="teameachContentDiv">
                                <h5>
                                    <i
                                        class="fa fa-heart specializationHeart"
                                    ></i>
                                    Specialization
                                </h5>
                                <p
                                    class="pTagcss"
                                    *ngIf="therapist?.specializeareas"
                                >
                                    {{ therapist?.specializeareas.join(', ') }}
                                </p>
                            </div>
                        </div>
                        <div class="teamAboutBox">
                            <p>{{ therapist?.AboutPerson }}</p>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="isLoading"
                    class="d-flex justify-content-center loadingSpinner"
                >
                    <div
                        class="spinner-border"
                        style="width: 2rem; height: 2rem"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                    <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
                    <h3 class="loadingText">&nbsp; Loading...</h3>
                </div>
                <div class="text-center" *ngIf="!isLoading">
                    <a
                        *ngIf="showNext"
                        (click)="nextPage()"
                        class="loadMoreText"
                    >
                        Load More Counselors
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center flex-column">
        <div class="heading-text" style="margin-top: 30px">
            What Our Users Say
        </div>
    </div>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <swiper
                    [slidesPerView]="1"
                    [spaceBetween]="20"
                    [pagination]="{
                        clickable: true,
                        dynamicBullets: true
                    }"
                    [centeredSlides]="true"
                    [cssMode]="true"
                    [navigation]="true"
                    [autoplay]="{
                        delay: 3500,
                        disableOnInteraction: true
                    }"
                    [breakpoints]="{
                        '640': {
                            slidesPerView: 1
                        },
                        '768': {
                            slidesPerView: 1
                        },
                        '1024': {
                            slidesPerView: 3
                        }
                    }"
                    [loop]="true"
                    [loopFillGroupWithBlank]="true"
                    class="mySwiper"
                >
                    <ng-template
                        swiperSlide
                        class="carousel-card"
                        *ngFor="let item of userReviews"
                    >
                        <div class="carousel-card">
                            <div class="sub-text">
                                <b>{{ item.name }}</b>
                            </div>
                            <div class="small-text" style="margin-top: 6px">
                                {{ item.work }}
                            </div>
                            <div class="rating" style="margin-top: 30px"></div>
                            <div
                                class="sub-text card-content-width testimonialsTxt"
                                style="margin-top: 30px"
                            >
                                <p>
                                    {{ item.text }}
                                </p>
                            </div>
                            <div class="quote"></div>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>
    <div class="ecosystemHolder">
        <div class="ecoWrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="ecoIndHolder">
                            <img
                                src="../../../assets/images/campaign/trust-picture1.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="ecoIndHolder resSecCss">
                            <img
                                src="../../../assets/images/campaign/trust-picture2.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="ecoIndHolder resSecCss">
                            <img
                                src="../../../assets/images/campaign/trust-picture3.jpg"
                                alt=""
                                class="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boxRoundCircle howHeading">
            <div class="secondaryHeading">
                <h2>How It Works</h2>
                <hr />
                <p>
                    The most simple & convenient way to access talk therapy -
                    anytime, anywhere, any device.
                </p>
            </div>
        </div>
    </div>
    <div class="howItContainer">
        <div class="container">
            <div class="howItWrap">
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/choose-counseling-therapy-plan.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="choose counseling therapy sessions plan"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Choose The Right Plan</h3>
                            <p>
                                Decide on the number of sessions you would like
                                to opt for.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/know-your-counseling-needs.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="answer questions and know your counseling therapy need, get matched with counseling psychologists"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Know Your Needs</h3>
                            <p>
                                Answer a few quick questions and get a therapist
                                recommendation right away.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/get-matched-with-counseling-psychologist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="find online counselor, get matched with best psychologists suited to your needs"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Get Matched</h3>
                            <p>
                                Choose the recommended therapist or talk to a
                                matching expert who will connect you with the
                                right therapist based on your needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/schedule-video-session-with-counselor.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="choose time slot for counseling therapy session and schedule session with counselor"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Schedule A Session</h3>
                            <p>
                                Choose a convenient time slot and get an
                                appointment with your therapist.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/connect-on-video-sessions-with-therapist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="connect on and join high quality video sessions with your counseling therapist using mobile app or browser"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Get Therapy</h3>
                            <p>
                                At the scheduled time, join the session with
                                your therapist using the mobile application or
                                web browser.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/chat-with-counseling-therapist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="regular chat messaging with your personal counseling therapist on mobile and web app"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Regular Messages</h3>
                            <p>
                                In addition to the scheduled video sessions, you
                                can reach out to your therapist via voice
                                messages or chat. The therapist will respond 1-2
                                times a day, based on availability.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/pleasecoach24x7-support-helpdesk.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="24x7 support and helpdesk by Please Coach, reschedule sessions, change counselor"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Continuous Support</h3>
                            <p>
                                You have the flexibility to reschedule sessions
                                or change the counsellor at any point. For any
                                issues or support, Please Coach helpdesk has you
                                covered.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="getStartedBtn getstartedHolder">
        <button class="buttonTheme2" (click)="chatWithUs()">
            Chat with Us
        </button>
        <button routerLink="../../new-signup" class="buttonTheme1">
            Get Started
        </button>
    </div>
</div>
