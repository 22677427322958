import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

const sessionBackendUrl = environment.apiUrl + "/user/booking/";
const userBackendUrl = environment.apiUrl + "/user/user/";
@Injectable({
  providedIn: "root",
})
export class BookingService {
  constructor(private http: HttpClient) { }

  sessionPagin(status, skip, limit) {
    return this.http.get<any>(
      sessionBackendUrl +
      "sessionsPagin?status=" +
      status +
      "&skip=" +
      skip +
      "&limit=" +
      limit
    );
  }

  getSession(id) {
    return this.http.get(sessionBackendUrl + id);
  }
  joinSession(id) {
    return this.http.get(sessionBackendUrl + 'join-session/' + id);
  }

  bookSession(sessionObj) {
    return this.http.post(sessionBackendUrl + 'v2', sessionObj);
  }

  cancelSession(sessionId) {
    return this.http.get(sessionBackendUrl + "cancel/" + sessionId);
  }

  getRecommendation() {
    return this.http.get(sessionBackendUrl + "getRecommendedTherapist");
  }

  checkplan() {
    return this.http.get(
      userBackendUrl + "checkSessionAvailability"
    );
  }

  calcTimeDiff = function (date2, date1) {
    if (date1 > date2) {
      // swap
      var result = this.calcTimeDiff(date1, date2);
      result.years = -result.years;
      result.months = -result.months;
      result.days = -result.days;
      result.hours = -result.hours;
      result.minutes = -result.minutes;
      return result;
    }
    result = {
      years: date2.getYear() - date1.getYear(),
      months: date2.getMonth() - date1.getMonth(),
      days: date2.getDate() - date1.getDate(),
      hours: date2.getHours() - date1.getHours(),
      minutes: date2.getMinutes() - date1.getMinutes(),
      seconds: date2.getSeconds() - date1.getSeconds(),
      milliseconds: date2.getMilliseconds() - date1.getMilliseconds(),
    };
    if (result.milliseconds < 0) {
      result.seconds--;
      result.milliseconds += 1000;
    }
    if (result.seconds < 0) {
      result.minutes--;
      result.seconds += 60;
    }
    if (result.minutes < 0) {
      result.hours--;
      result.minutes += 60;
    }
    if (result.hours < 0) {
      result.days--;
      result.hours += 24;
    }
    if (result.days < 0) {
      result.months--;
      // days = days left in date1's month,
      //   plus days that have passed in date2's month
      var copy1 = new Date(date1.getTime());
      copy1.setDate(32);
      result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
    }
    if (result.months < 0) {
      result.years--;
      result.months += 12;
    }
    delete result.milliseconds;
    delete result.seconds;
    return result;
  };

  getRating(id) {
    return this.http.get(sessionBackendUrl + 'getRating/' + id)
  }

  setRating(id, obj) {
    return this.http.post(sessionBackendUrl + 'setRating/' + id, obj)
  }
}
