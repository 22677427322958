<div class="bodyPageWrap">
    <div>
        <div *ngIf="isShimmerLoading; else MainLoaderComplete">
            <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                    'border-radius': '12px',
                    height: '225px',
                    width: '100%',
                    display: 'flex !important',
                    'margin-top': '3px',
                    'margin-right': '10px'
                }"
            ></ngx-skeleton-loader>
        </div>

        <ng-template #MainLoaderComplete>
            <div class="boxRoundCircle">
                <bootstrap-alert alertPosition="top-center"></bootstrap-alert>

                <div class="afterRoundBox">
                    <div class="sectionIcon">
                        <label class="chooseImage">
                            <span
                                class="choosingImageScript"
                                *ngIf="userData?.imageUrl"
                            >
                                <img
                                    src="{{ imageUrl }}/{{
                                        userData?.imageUrl
                                    }}"
                                    draggable="false"
                                    alt="img"
                                    class="img-fluid"
                                />
                            </span>
                            <span
                                class="choosingImageScript"
                                *ngIf="!userData?.imageUrl"
                            >
                                <img
                                    src="../../../assets/images/user.png"
                                    draggable="false"
                                    alt="img"
                                    class="img-fluid"
                                />
                            </span>
                            <div class="col-lg-12">
                                <!-- <div ngf-drop="uploadFiles($files)" ngf-select="uploadFiles($files)" class="chooseImageLine"
                ngf-select="ngf-select" ng-class="{
                  'bg-default': !image,
                  'bg-danger': userForm.image.$error.pattern,
                  'bg-success': !userForm.image.$error.pattern && image
                }" ng-model="image" name="image" ngf-drag-over-class="'dragover'" ngf-valid-only="true"
                ngf-invalid-class="bg-danger" ngf-multiple="false" ngf-pattern="'image/*'">
                Choose Image
                <br />
                (Ideal Dimensions - 200px by 200px )
              </div> -->
                            </div>
                        </label>
                    </div>
                    <div class="sectionHeading">
                        <h2>Hello {{ userName }}</h2>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="container">
            <div class="formRound">
                <div
                    *ngIf="isShimmerLoading; else changePasswordLoadingComplete"
                >
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '20px',
                            height: '50px',
                            width: '20%',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-left': '80%',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>
                <ng-template #changePasswordLoadingComplete>
                    <div class="calendarCss">
                        <a routerLink="../view-calendar" class="thm-2">
                            My Calendar</a
                        >
                    </div>
                </ng-template>

                <div
                    *ngIf="isShimmerLoading; else mainFormLoader"
                    style="text-align: center"
                >
                    <ngx-skeleton-loader
                        appearance="line"
                        [theme]="{
                            'border-radius': '12px',
                            height: '50px',
                            width: '50%',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                        appearance="line"
                        count="10"
                        [theme]="{
                            'border-radius': '12px',
                            height: '40px',
                            width: '80%',
                            display: 'flex !important',
                            'margin-top': '3px',
                            'margin-right': '10px'
                        }"
                    ></ngx-skeleton-loader>
                </div>

                <ng-template #mainFormLoader>
                    <form [formGroup]="therapistForm" name="TherapistForm">
                        <div class="sectionPadding-top profileFileSelect">
                            <div class="sectionHeading">
                                <h3>Personal Information</h3>
                                <hr />
                            </div>
                            <div class="insideFormDivTags">
                                <h3>First Name</h3>
                                <div>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        formControlName="firstname"
                                        class="form-control"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>Last Name</h3>
                                <div>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        formControlName="lastname"
                                        class="form-control"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>Email</h3>
                                <div>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        formControlName="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        class="form-control"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>Contact Number</h3>
                                <div *ngIf="userCountryCode">
                                    <input
                                        type="text"
                                        id="phoneInput"
                                        formControlName="phone"
                                        class="phoneControl"
                                        [ngClass]="{
                                            'has-error':
                                                therapistForm.controls.phone
                                                    .errors?.invalidPhone
                                        }"
                                        ng2TelInput
                                        (hasError)="checkError($event)"
                                        (ng2TelOutput)="getNumber($event)"
                                        (intlTelInputObject)="
                                            telInputObject($event)
                                        "
                                        (countryChange)="
                                            onCountryChange($event)
                                        "
                                        [ng2TelInputOptions]="{
                                            initialCountry:
                                                userCountryCode.countryCode
                                        }"
                                    />
                                </div>
                                <div
                                    class="invalidMessage"
                                    *ngIf="
                                        therapistForm.controls.phone.errors
                                            ?.invalidPhone
                                    "
                                >
                                    <p class="error-validation">
                                        Please enter a valid phone number.
                                    </p>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>DOB</h3>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Datepicker"
                                        class="form-control"
                                        bsDatepicker
                                        [bsConfig]="{
                                            dateInputFormat: 'DD MMM Y'
                                        }"
                                        formControlName="dob"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="sectionPadding-top">
                            <div class="sectionHeading">
                                <h3>Matching Criteria</h3>
                                <hr />
                            </div>
                            <div class="insideFormDivTags">
                                <h3>What is your gender?</h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        formControlName="gender"
                                        [items]="gender"
                                    ></ng-select>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    What is your most relevant qualification for
                                    this job?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        formControlName="jobqualification"
                                        [items]="jobqualification"
                                    >
                                    </ng-select>
                                    <div
                                        class="col-md-8 Qualification"
                                        *ngIf="
                                            userData?.jobqualification ==
                                            'Other (Please Mention)'
                                        "
                                    >
                                        <input
                                            type="text"
                                            placeholder="Other Qualification*"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    How much actual counselling work experience
                                    do you have?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        formControlName="counsellingexperience"
                                        [items]="counsellingexperience"
                                    ></ng-select>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    How many hours can you commit to counselling
                                    on Please Coach per week?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        formControlName="counsellingPleasecoachPerWeek"
                                        [items]="counsellingPleasecoachPerWeek"
                                    ></ng-select>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    Would you be working elsewhere apart from
                                    practicing via Please Coach?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        formControlName="WorkingElseWhereApartPleasecoach"
                                        [items]="WorkingElseWhereApartPleasecoach"
                                    ></ng-select>
                                    <p>
                                        Please note that we are fine with your
                                        other engagements as long as you do not
                                        poach customers from Please Coach.
                                    </p>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    Are there any specific areas that you
                                    specialise in?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        (change)="addSpecializeAreaToArray()"
                                        formControlName="specializeareas"
                                        [items]="specializeareas1"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div style="padding-top: 10px"></div>
                            <table>
                                <tr *ngFor="let i of selectedSpecializeAreas">
                                    <td>
                                        <i
                                            class="fa fa-plus-circle"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td>{{ i.name }}</td>
                                    <td
                                        (click)="
                                            removeSpecializeAreaFromArray(i)
                                        "
                                    >
                                        <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                </tr>
                            </table>
                            <div style="padding-top: 30px"></div>
                            <div class="insideFormDivTags">
                                <h3>
                                    What therapy techniques are you experienced
                                    in?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        (change)="addtherapeuticExperties()"
                                        formControlName="therapeuticExperties"
                                        [items]="therapeuticExperties"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div style="padding-top: 10px"></div>
                            <table>
                                <tr
                                    *ngFor="
                                        let i of selectedtherapeuticExperties
                                    "
                                >
                                    <td>
                                        <i
                                            class="fa fa-plus-circle"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td>{{ i }}</td>
                                    <td
                                        (click)="
                                            removetherapeuticExpertiesFromArray(
                                                i
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                </tr>
                            </table>
                            <div style="padding-top: 20px"></div>
                            <div class="insideFormDivTags">
                                <h3>Describe Yourself</h3>
                                <div>
                                    <textarea
                                        class="form-group col-md-12"
                                        type="text"
                                        placeholder="Describe yourself in 40-50 words *"
                                        style="
                                            background: #f6f8f9;
                                            border: 1px solid #f6f8f9;
                                            border-radius: 25px;
                                            padding: 10px;
                                        "
                                        formControlName="AboutPerson"
                                        rows="5"
                                    ></textarea>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    How would you rate your fluency in English
                                    and other languages?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        [items]="language"
                                        (change)="addLanguageToArray()"
                                        formControlName="language"
                                    ></ng-select>
                                    <table>
                                        <tr>
                                            <th>Language</th>
                                            <th>Native</th>
                                            <th>Fluent</th>
                                            <th>Average</th>
                                        </tr>

                                        <tr
                                            *ngFor="
                                                let language of selectedLanguageArray;
                                                let idx = index;
                                                trackBy: indexTracker
                                            "
                                        >
                                            <td>
                                                {{ language.language }}
                                            </td>
                                            <td>
                                                <input
                                                    name="{{
                                                        language.language
                                                    }}"
                                                    [checked]="
                                                        language.fluency ==
                                                        'Native'
                                                    "
                                                    [(ngModel)]="
                                                        selectedLanguageArray[
                                                            idx
                                                        ].fluency
                                                    "
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    type="radio"
                                                    value="Native"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="{{
                                                        language.language
                                                    }}"
                                                    [checked]="
                                                        language.fluency ==
                                                        'Fluent'
                                                    "
                                                    [(ngModel)]="
                                                        selectedLanguageArray[
                                                            idx
                                                        ].fluency
                                                    "
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    type="radio"
                                                    value="Fluent"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="{{
                                                        language.language
                                                    }}"
                                                    [checked]="
                                                        language.fluency ==
                                                        'Average'
                                                    "
                                                    [(ngModel)]="
                                                        selectedLanguageArray[
                                                            idx
                                                        ].fluency
                                                    "
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    type="radio"
                                                    value="Average"
                                                />
                                            </td>
                                            <td>
                                                <i
                                                    class="fa fa-times"
                                                    (click)="
                                                        removeLanguageFromArray(
                                                            language.language
                                                        )
                                                    "
                                                    aria-hidden="true"
                                                ></i>
                                            </td>
                                        </tr>
                                        <tr></tr>
                                    </table>
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    Do you have the required infrastructure for
                                    a video call?
                                </h3>
                                <div>
                                    <input
                                        type="checkbox"
                                        formControlName="RequiredInfrastructureForVideoCallDevice"
                                    />
                                    Device(laptop/smartphone/desktop with
                                    webcam)
                                    <br />
                                    <input
                                        type="checkbox"
                                        formControlName="RequiredInfrastructureForVideoCallInternet"
                                    />
                                    High speed Internet
                                    <br />
                                    <input
                                        type="checkbox"
                                        formControlName="RequiredInfrastructureForVideoCallEnvironment"
                                    />
                                    Conducive environment(quiet personal spot)
                                    <br />
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>How do you offer therapy?</h3>
                                <div>
                                    <input
                                        type="checkbox"
                                        formControlName="audioTherapy"
                                    />
                                    Audio
                                    <br />
                                    <input
                                        type="checkbox"
                                        formControlName="videoTherapy"
                                    />
                                    Video
                                    <br />
                                    <input
                                        type="checkbox"
                                        formControlName="chatTherapy"
                                    />
                                    Chat
                                    <br />
                                </div>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    Are you comfortable checking and responding
                                    to chat messages and email 2-3 times a day
                                    apart from your scheduled calls?
                                </h3>
                                <div>
                                    <ng-select
                                        class="form-control table"
                                        formControlName="ChatMessage"
                                        [items]="ChatMessage"
                                    ></ng-select>
                                </div>
                                <p
                                    class="error-validation"
                                    *ngIf="
                                        therapistForm.controls.ChatMessage
                                            .errors?.required
                                    "
                                >
                                    This field is required
                                </p>
                            </div>
                            <div class="insideFormDivTags">
                                <h3>
                                    What is your preferred time for a session?
                                </h3>
                                <div>
                                    <div>
                                        <h3>Time Slot</h3>
                                        <ng-select
                                            class="form-control table"
                                            formControlName="TimeSlott"
                                            [items]="TimeSlott"
                                        ></ng-select>
                                        <p
                                            class="error-validation"
                                            *ngIf="
                                                therapistForm.controls.TimeSlott
                                                    .errors?.required
                                            "
                                        >
                                            This field is required
                                        </p>
                                    </div>

                                    <div>
                                        <h3 style="margin-top: 1rem">
                                            Time Zone
                                        </h3>
                                        <ng-select
                                            class="form-control table"
                                            formControlName="TimeZone"
                                            [items]="TimeZone"
                                        ></ng-select>
                                        <p
                                            class="error-validation"
                                            *ngIf="
                                                therapistForm.controls.TimeZone
                                                    .errors?.required
                                            "
                                        >
                                            This field is required
                                        </p>
                                    </div>
                                    <!-- <table [width]="100">
                  <tr>
                    <th [width]="25">Time Slot</th>
                    <th [width]="25">AM/PM</th>
                    <th [width]="50">Time Zone</th>
                  </tr>
                  <tr style="background-color: white">
                    <td>
                      <ng-select
                        class="form-control table"
                        formControlName="TimeSlott"
                        [items]="TimeSlott"
                      ></ng-select>
                    </td>
                    <td>
                      <ng-select
                        class="form-control table"
                        formControlName="AMORPM"
                        [items]="AMORPM"
                      ></ng-select>
                    </td>
                    <td>
                      <ng-select
                        class="form-control table"
                        formControlName="TimeZone"
                        [items]="TimeZone"
                      ></ng-select>
                    </td>
                  </tr>
                </table> -->
                                </div>
                            </div>
                            <!-- Removed unnecessary fields, might look into this again -->
                            <!-- <div class="insideFormDivTags">
                <h3>PAN No.</h3>
                <div>
                  <input type="text" id="panno" name="panno" formControlName="pan_no" class="form-control"
                    placeholder="" [ngClass]="{
                    'has-error':
                      !therapistForm.controls.pan_no.valid &&
                      therapistForm.controls.pan_no.touched,
                    'has-success':
                      therapistForm.controls.pan_no.valid &&
                      therapistForm.controls.pan_no.touched
                  }" />
                </div>
                <div *ngIf="
                  therapistForm.controls.pan_no.errors?.required &&
                  therapistForm.controls.pan_no.touched
                " class="errorContainer">
                  <p class="error-validation">PAN is required</p>
                </div>
                <div *ngIf="
                  therapistForm.controls.pan_no.errors?.pattern &&
                  therapistForm.controls.pan_no.touched
                " class="errorContainer">
                  <p class="error-validation">PAN is invalid</p>
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>BankAccount Number:</h3>
                <div>
                  <input type="text" id="bankAccNo" name="bankAccNo" formControlName="bankAccount_no"
                    class="form-control" placeholder="Enter your account number here" [ngClass]="{
                    'has-error':
                      !therapistForm.controls.bankAccount_no.valid &&
                      therapistForm.controls.bankAccount_no.touched,
                    'has-success':
                      therapistForm.controls.bankAccount_no.valid &&
                      therapistForm.controls.bankAccount_no.touched
                  }" />
                </div>
                <div *ngIf="
                  therapistForm.controls.bankAccount_no.errors?.required &&
                  therapistForm.controls.bankAccount_no.touched
                " class="errorContainer">
                  <p class="error-validation">Bank Account Number is required</p>
                </div>
                <div *ngIf="
                  therapistForm.controls.bankAccount_no.errors?.pattern &&
                  therapistForm.controls.bankAccount_no.touched
                " class="errorContainer">
                  <p class="error-validation">Bank Account Number is invalid</p>
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>Aadhar Number:</h3>
                <div>
                  <input type="text" id="Aadharno" name="Aadharno" formControlName="aadhar_no" class="form-control"
                    placeholder="Enter your Aadhar Card Number here*" [ngClass]="{
                    'has-error':
                      !therapistForm.controls.aadhar_no.valid &&
                      therapistForm.controls.aadhar_no.touched,
                    'has-success':
                      therapistForm.controls.aadhar_no.valid &&
                      therapistForm.controls.aadhar_no.touched
                  }" />
                </div>
                <div *ngIf="
                  therapistForm.controls.aadhar_no.errors?.required &&
                  therapistForm.controls.aadhar_no.touched
                " class="errorContainer">
                  <p class="error-validation">Aadhar Card Number is required</p>
                </div>
                <div *ngIf="
                  therapistForm.controls.aadhar_no.errors?.pattern &&
                  therapistForm.controls.aadhar_no.touched
                " class="errorContainer">
                  <p class="error-validation">Aadhar Card Number is invalid</p>
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>Last Degree Upload:</h3>
                <div *ngIf="showDegreeUpload || !userData?.LastDegreeUpload">
                  <input class="form-control" type="file" (change)="uploadLastDegree($event)" name="files" />
                  <a *ngIf="userData?.LastDegreeUpload" class="cancelUploadBtn" (click)="cancelUploadBox('DEG')">
                    Cancel
                  </a>
                </div>
                <div *ngIf="userData?.LastDegreeUpload && !showDegreeUpload" class="documentUpHolder">
                  <p (click)="
                    downloadAttachment(
                      userData.LastDegreeUpload.key,
                      userData.LastDegreeUpload.fileName
                    )
                  ">
                    {{ userData.LastDegreeUpload.fileName }}
                  </p>
                  <button class="uploadDocBtn" (click)="showUploadBox('DEG')">
                    Upload New
                  </button>
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>Experience Letter Upload:</h3>
                <div *ngIf="showExpUpload || !userData?.experienceletter">
                  <input class="form-control" type="file" (change)="uploadExperienceLetter($event)" name="files" />
                  <a *ngIf="userData?.experienceletter" class="cancelUploadBtn" (click)="cancelUploadBox('EXP')">
                    Cancel
                  </a>
                </div>
                <div *ngIf="userData?.experienceletter && !showExpUpload" class="documentUpHolder">
                  <p>{{ userData?.experienceletter?.fileName }}</p>
                  <button class="uploadDocBtn" (click)="showUploadBox('EXP')">
                    Upload New
                  </button>
                </div>
              </div>
              <div class="insideFormDivTags">
                <h3>Resume Upload:</h3>
                <div *ngIf="showResumeUpload || !userData?.resume">
                  <input class="form-control" type="file" (change)="uploadResume($event)" name="files" />
                  <a *ngIf="userData?.resume" class="cancelUploadBtn" (click)="cancelUploadBox('RES')">
                    Cancel
                  </a>
                </div>
                <div *ngIf="userData?.resume && !showResumeUpload" class="documentUpHolder">
                  <p>{{ userData?.resume?.fileName }}</p>
                  <button class="uploadDocBtn" (click)="showUploadBox('RES')">
                    Upload New
                  </button>
                </div>
              </div> -->
                        </div>
                        <div class="row submitbtnn text-center">
                            <div class="col-lg-5 extraCss">
                                <button
                                    class="thm-2"
                                    routerLink="../view-calendar"
                                    [style.display]="showBtn ? 'block' : 'none'"
                                >
                                    Set Your Recurring Schedule
                                </button>
                            </div>
                            <div class="col-lg-4 extraCss">
                                <a
                                    [style.display]="showBtn ? 'block' : 'none'"
                                    routerLink="../changepassword"
                                    class="thm-2"
                                >
                                    Change Password</a
                                >
                            </div>
                            <div class="col-lg-3 extraCss">
                                <div
                                    *ngIf="isLoading"
                                    class=""
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        margin-left: -55px;
                                    "
                                >
                                    <div
                                        class="spinner-border"
                                        style="
                                            width: 2rem;
                                            height: 2rem;
                                            margin-left: 55px;
                                            margin-top: 23px;
                                        "
                                        role="status"
                                    >
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
                                </div>
                                <button
                                    [style.opacity]="
                                        !therapistForm.controls.TimeSlott.errors
                                            ?.required &&
                                        !therapistForm.controls.TimeZone.errors
                                            ?.required &&
                                        !therapistForm.controls.ChatMessage
                                            .errors?.required
                                            ? '1'
                                            : '0.5'
                                    "
                                    [style.cursor]="
                                        !therapistForm.controls.TimeSlott.errors
                                            ?.required &&
                                        !therapistForm.controls.TimeZone.errors
                                            ?.required &&
                                        !therapistForm.controls.ChatMessage
                                            .errors?.required
                                            ? 'pointer'
                                            : 'not-allowed'
                                    "
                                    class="thm-2"
                                    *ngIf="!isLoading"
                                    (click)="onSubmit()"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
</div>
