<div class="secPadding" (click)="closenavbar()">
  <bootstrap-alert alertPosition="top-center"></bootstrap-alert>
  <div class="container">
    <div class="secondaryHeading">
      <h2>Sign Up</h2>
      <hr />
    </div>
    <div class="loginLine">
      <h3>
        Already have an account ?
        <a [routerLink]="['/login']" [queryParams]="redirectObj">Log In</a>
      </h3>
    </div>
    <div class="row align-items-center">
      <div class="col-md-6 borderInRight">
        <div class="signUpForm formRound">
          <form
            [formGroup]="signUpForm"
            (ngSubmit)="onSubmit()"
            name="signUpForm"
          >
            <div class="form-group">
              <input
                type="text"
                name="name"
                id="name"
                formControlName="name"
                placeholder="Name *"
                class="form-control"
                [ngClass]="{
                  'has-error':
                    !signUpForm.controls.name.valid &&
                    signUpForm.controls.name.touched,
                  'has-success':
                    signUpForm.controls.name.valid &&
                    signUpForm.controls.name.touched
                }"
              />
              <div
                *ngIf="
                  signUpForm.controls.name.errors?.required &&
                  signUpForm.controls.name.touched
                "
                class="errorContainer"
              >
                <p>Name is required</p>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                name="email"
                id="email"
                formControlName="email"
                placeholder="Email *"
                class="form-control"
                [ngClass]="{
                  'has-error':
                    !signUpForm.controls.email.valid &&
                    signUpForm.controls.email.touched,
                  'has-success':
                    signUpForm.controls.email.valid &&
                    signUpForm.controls.email.touched
                }"
              />
              <div
                *ngIf="
                  signUpForm.controls.email.errors?.required &&
                  signUpForm.controls.email.touched
                "
                class="errorContainer"
              >
                <p>Email is required</p>
              </div>
              <div
                *ngIf="
                  signUpForm.controls.email.errors?.pattern &&
                  signUpForm.controls.email.touched
                "
                class="errorContainer"
              >
                <p>Email is invalid</p>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                id="phoneInput"
                formControlName="phone"
                name="phone"
                class="form-control phoneControl"
                [ngClass]="{
                  'has-error':
                    signUpForm.controls.phone.errors?.invalidPhone ||
                    (signUpForm.controls.phone.errors?.required &&
                      signUpForm.controls.phone.touched)
                }"
                ng2TelInput
                (hasError)="checkError($event)"
                (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)"
                (countryChange)="onCountryChange($event)"
                [ng2TelInputOptions]="{
                  initialCountry: userCountryCode.countryCode
                }"
              />
              <div
                *ngIf="
                  signUpForm.controls.phone.errors?.required &&
                  signUpForm.controls.phone.touched
                "
                class="errorContainer"
              >
                <p>Phone number is required</p>
              </div>
              <div
                *ngIf="
                  signUpForm.controls.phone.errors?.pattern &&
                  signUpForm.controls.phone.touched
                "
                class="errorContainer"
              >
                <p>Phone number should contain only numbers</p>
              </div>
              <div
                *ngIf="
                  signUpForm.controls.phone.errors?.invalidPhone &&
                  signUpForm.controls.phone.touched
                "
                class="errorContainer"
              >
                <p>Phone number is not valid</p>
              </div>
            </div>
            <div class="input-group">
              <input
                [type]="isShowPassword ? 'text' : 'password'"
                name="password"
                id="password"
                formControlName="password"
                placeholder="Password *"
                class="form-control"
                [ngClass]="{
                  'has-error':
                    !signUpForm.controls.password.valid &&
                    signUpForm.controls.password.touched,
                  'has-success':
                    signUpForm.controls.password.valid &&
                    signUpForm.controls.password.touched
                }"
              />
              <span class="eyeIcon">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-eye-slash': !isShowPassword,
                    'fa-eye': isShowPassword
                  }"
                  (click)="showPasswordToggle()"
                ></i>
              </span>
            </div>
            <div
              *ngIf="
                signUpForm.controls.password.errors?.required &&
                signUpForm.controls.password.touched
              "
              class="errorContainer"
            >
              <p>Password is required</p>
            </div>
            <div
              *ngIf="
                signUpForm.controls.password.errors?.pattern &&
                signUpForm.controls.password.touched
              "
              class="errorContainer"
            >
              <p>
                Password must be minimum 8 characters, 1 lower case letter, 1
                number and 1 special character
              </p>
            </div>
            <div class="form-group">
              <div *ngIf="isLoading" class="d-flex justify-content-start">
                <div
                  class="spinner-border"
                  style="
                    width: 2rem;
                    height: 2rem;
                    margin-left: 55px;
                    margin-top: 23px;
                  "
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <!-- <ng-lottie
                height="150px"
                [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie> -->
              </div>
              <button
                *ngIf="!isLoading"
                class="buttonTheme1"
                type="submit"
                [disabled]="!signUpForm.valid"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6">
        <div class="socialLoginWrap">
          <ul>
            <li #signUpRef>
              <a (click)="loginWithGoogle()">
                <img
                  src="../../../assets/images/google.png"
                  class="img-fluid"
                  draggable="false"
                  alt="social login google"
                />
                <span>Sign Up with Google</span>
              </a>
            </li>
            <li>
              <a (click)="loginWithFacebook()">
                <img
                  src="../../../assets/images/facebook.png"
                  class="img-fluid"
                  draggable="false"
                  alt="social login facebook"
                />
                <span>Sign Up with Facebook</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="brief">
      By signing up, you agree to our
      <a routerLink="../online-counseling-psychologists/terms-of-service">
        Terms Of Service</a
      >
      <br />
      and acknowledge that you have read our
      <a routerLink="../online-counseling-psychologists/privacy-policy"
        >Privacy Policy</a
      >
    </div>
  </div>
</div>
