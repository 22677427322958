<div (click)="closenavbar()">
    <div class="secPadding">
        <div class="container">
            <div class="boxRoundCircle howHeading">
                <div class="secondaryHeading">
                    <h2>How It Works</h2>
                    <hr />
                    <p>
                        The most simple & convenient way to access talk therapy
                        - anytime, anywhere, any device.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="howItContainer">
        <div class="container">
            <div class="howItWrap">
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/choose-counseling-therapy-plan.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="choose counseling therapy sessions plan"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Choose The Right Plan</h3>
                            <p>
                                Decide on the number of sessions you would like
                                to opt for.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/know-your-counseling-needs.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="answer questions and know your counseling therapy need, get matched with counseling psychologists"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Know Your Needs</h3>
                            <p>
                                Answer a few quick questions and get a therapist
                                recommendation right away.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/get-matched-with-counseling-psychologist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="find online counselor, get matched with best psychologists suited to your needs"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Get Matched</h3>
                            <p>
                                Choose the recommended therapist or talk to a
                                matching expert who will connect you with the
                                right therapist based on your needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/schedule-video-session-with-counselor.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="choose time slot for counseling therapy session and schedule session with counselor"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Schedule A Session</h3>
                            <p>
                                Choose a convenient time slot and get an
                                appointment with your therapist.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/connect-on-video-sessions-with-therapist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="connect on and join high quality video sessions with your counseling therapist using mobile app or browser"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Get Therapy</h3>
                            <p>
                                At the scheduled time, join the session with
                                your therapist using the mobile application or
                                web browser.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../assets/images/how-counseling-therapy-works-detail/chat-with-counseling-therapist.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="regular chat messaging with your personal counseling therapist on mobile and web app"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Regular Messages</h3>
                            <p>
                                In addition to the scheduled video sessions, you
                                can reach out to your therapist via voice
                                messages or chat. The therapist will respond 1-2
                                times a day, based on availability.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="howItGroup">
                    <div class="howItGroupWidth">
                        <div class="howitGIcon">
                            <img
                                src="../../../../assets/images/how-counseling-therapy-works-detail/pleasecoach24x7-support-helpdesk.svg"
                                class="img-fluid"
                                draggable="false"
                                alt="24x7 support and helpdesk by Please Coach, reschedule sessions, change counselor"
                            />
                        </div>
                    </div>
                    <div class="howItGroupWidth">
                        <div class="howItContBox">
                            <h3>Continuous Support</h3>
                            <p>
                                You have the flexibility to reschedule sessions
                                or change the counsellor at any point. For any
                                issues or support, Please Coach helpdesk has you
                                covered.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="getStartedButton">
                <a
                    *ngIf="isLoggedin"
                    routerLink="../../user-dashboard"
                    class="buttonTheme1"
                    >Get Started</a
                >
                <a
                    *ngIf="!isLoggedin"
                    [routerLink]="['../../sign-up']"
                    [queryParams]="{
                        redirect:
                            'online-counseling-psychologists/plans-pricing'
                    }"
                    class="buttonTheme1"
                    >Get Started</a
                >
            </div>
        </div>
    </div>

    <app-meet-therapists></app-meet-therapists>
</div>
